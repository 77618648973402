import React, { Component } from 'react';
import { getAnalytics } from '../../api';

interface IState {
  data?: any;
}

class Analytics extends Component<any, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: undefined,
    };
  }

  public async componentDidMount() {
    const analytics = await getAnalytics();
    this.setState({ data: analytics });
  }

  public getOtherData = (data: any) => {
    if (data) {
      const { AllMessagesForQuotes, nurseFirstLogins, ...rest } = data;
      return rest;
    }
    return null;
  };

  public render() {
    const { data } = this.state;
    const otherData = this.getOtherData(data);
    return (
      <div style={{ overflow: 'auto', padding: 12 }}>
        <h1>messages</h1>
        <div>{data && JSON.stringify(data.AllMessagesForQuotes, null, 2)}</div>

        <h1>first logins</h1>
        <div>{data && JSON.stringify(data.nurseFirstLogins, null, 2)}</div>

        <h1>other data</h1>
        <div>{otherData && JSON.stringify(otherData, null, 2)}</div>
      </div>
    );
  }
}

export default Analytics;
