import React from 'react';
import styled from 'styled-components';

import { FullPage, Centered } from '../../common';
import { StyledPasswordForm } from './styles';

export const PasswordFormLayout = ({ children }: any) => {
  return (
    <FullPageScroll>
      <Centered>
        <StyledPasswordForm>{children}</StyledPasswordForm>
      </Centered>
    </FullPageScroll>
  );
};

const FullPageScroll = styled(FullPage)`
  overflow: auto;
`;
