import React from 'react';
import styled from 'styled-components';
import { device } from '../../common';

// CHANNEL GROUP
interface IChannelGroupContainerProps {
  activeChannelId?: number | null;
  id?: string;
  children: React.ReactNode;
}

const ChannelGroupContainer: React.SFC<IChannelGroupContainerProps> = (props) => {
  const { activeChannelId, ...rest } = props;
  return <div {...rest} />;
};

export const StyledChannelContainer = styled(ChannelGroupContainer)`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.aveannaDarkBlue};
  flex-direction: column;
  flex: 1;
  overflow: auto;

  display: ${({ activeChannelId }) => (activeChannelId ? 'none' : 'flex')};

  /* above mobile L */
  @media ${device.mobileL} {
    display: flex;
    flex: 1;
    min-width: 200px;
    /* match 1:6 flex styling on container when there is no chat window (prevent full screen stretch) */
    max-width: Calc(1 / 6 * 100vw);
    border-right: 1px solid #ddd;
  }
`;
// SINGLE CHANNEL
interface IChannelContainerProps {
  id: string;
  onClick: () => void;
  isActive: boolean;
  children?: React.ReactNode;
}

const ChannelContainer: React.SFC<IChannelContainerProps> = (props) => {
  const { isActive, ...rest } = props;
  return <div {...rest} />;
};

export const StyledChannel = styled(ChannelContainer)`
  display: flex;
  flex-shrink: 0;
  align-content: center;
  padding: 12px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  font-weight: 600;
  word-break: break-all;
  background-color: ${({ isActive, theme }) => (isActive ? 'white' : theme.colors.aveannaDarkBlue)};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.defaultFontColor : theme.colors.defaultLightFontColor};

  /* :first-child {
    border-top: 1px solid #ddd;
  } */

  @media ${device.mobileL} {
    display: flex;
  }
`;

const Indicator: React.SFC<{ isActive: boolean }> = (props) => {
  const { isActive, ...rest } = props;
  return <div {...rest} />;
};

export const StyledIndicator = styled(Indicator)`
  margin-right: 6px;
  align-self: center;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: ${({ theme, isActive }) => (isActive ? 'red' : 'white')};
`;
