import { types } from './constants';

export interface ISearchState {
  searchTerm: string;
  searchStartDate: Date;
  searchEndDate: Date;
  isDefaultState: boolean;
}

const initialState: ISearchState = {
  searchTerm: '',
  searchStartDate: new Date(new Date().setDate(new Date().getDate() - 30)),
  searchEndDate: new Date(),
  isDefaultState: true,
};

const defaultState = initialState;

export const searchReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case types.SET_SEARCH_START_DATE: {
      const { searchStartDate } = payload;

      let endDate = state.searchEndDate;

      if (searchStartDate > state.searchEndDate) {
        // never let the startDate go past the endDate
        endDate = searchStartDate;
      }

      return {
        ...state,
        searchStartDate: searchStartDate,
        searchEndDate: endDate,
        isDefaultState: false,
      };
    }
    case types.SET_SEARCH_END_DATE: {
      const { searchEndDate } = payload;

      let startDate = state.searchStartDate;

      if (searchEndDate < state.searchStartDate) {
        // never let the startDate go past the endDate
        startDate = searchEndDate;
      }

      return {
        ...state,
        searchEndDate: searchEndDate,
        searchStartDate: startDate,
        isDefaultState: false,
      };
    }
    case types.SET_SEARCH_TERM: {
      const { searchTerm } = payload;
      return {
        ...state,
        searchTerm: searchTerm,
        isDefaultState: false,
      };
    }
    case types.SET_DEFAULT_SEARCH_PARAMETERS: {
      const { isDefault } = payload;
      return {
        ...defaultState,
        isDefaultState: isDefault,
      };
    }

    default: {
      return state;
    }
  }
};
