const successTimeout = 1500;
const staticGuidelines = [
  {
    message: 'Password will expire in 90 days',
  },
  {
    message: '5 failed attempts will lockout the account for 30 minutes',
  },
  {
    message: 'The password must not have been used within the following number of days: 365',
  },
];

const passwordRequirementsMessage =
  'Password requirements: 8 characters, 1 lowercase, 1 uppercase and 1 number';
/* <PasswordGuideChip text="Minimum number of characters required: 8" isSatisfied={false} />
<PasswordGuideChip text="Requires 1 uppercase, 1 lowercase, 1 number" isSatisfied={true} />
<PasswordGuideChip text="Password will expire in 90 days" />
<PasswordGuideChip text="5 failed attempts will lockout the account for 1 hour" />
<PasswordGuideChip text="Password must not have been used within 1 year" /> */

export { successTimeout, staticGuidelines, passwordRequirementsMessage };
