import React, { Component } from 'react';

import { Channel, StyledChannelContainer, StyledChannel } from '../components';
import { IGetNewMessagesParams, IChannel } from '../interfaces';
import NewChannelModal from '../components/NewChannelModal';
interface IProps {
  refetchMessages: () => void;
  setActiveChannel: (id: number) => void;
  getNewMessages: ({ channelId, lastMessageId }: IGetNewMessagesParams) => void;
  clearActiveChannel: () => void;
  activeChannelId: number;
  channels: any;
  user: any;
}

const channelRefreshRate = 10000;

class Channels extends Component<IProps, any> {
  constructor(props: any) {
    super(props);
    this.state = { openModal: false };
  }
  private refetchAllChannelMessages: any = null;

  public componentDidMount() {
    const { refetchMessages } = this.props;
    this.refetchAllChannelMessages = setInterval(refetchMessages, channelRefreshRate);
  }

  public componentWillUnmount() {
    const { clearActiveChannel } = this.props;
    clearInterval(this.refetchAllChannelMessages);
    clearActiveChannel();
  }

  public render() {
    const { channels, user, setActiveChannel, getNewMessages, activeChannelId } = this.props;
    return (
      <StyledChannelContainer id="channels" activeChannelId={activeChannelId}>
        {this.state.openModal && (
          <NewChannelModal closeModal={() => this.setState({ openModal: false })} />
        )}
        <StyledChannel
          isActive={false}
          id={'create-group'}
          onClick={() => this.setState({ openModal: true })}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '50px',
              margin: '6px',
              backgroundColor: 'white',
              color: 'rgba(0,117,201,1)',
              borderRadius: '6px',
            }}
          >
            <span style={{ textAlign: 'center', padding: '6px', wordBreak: 'keep-all' }}>
              Create Custom Group
            </span>
          </div>
        </StyledChannel>
        {channels &&
          channels.map((tempChannel: IChannel) => {
            const isActive = tempChannel.id === activeChannelId;
            return (
              <Channel
                key={tempChannel.id}
                channel={tempChannel}
                isActive={isActive}
                user={user}
                setActiveChannel={setActiveChannel}
                getNewMessages={getNewMessages}
              />
            );
          })}
      </StyledChannelContainer>
    );
  }
}

export default Channels;
