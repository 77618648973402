import React, { useEffect, useState } from 'react';
import StyledDateRangePicker from '../../../common/StyledDateRangePicker';
import moment, { Moment } from 'moment';
import { ISearchState } from '../../../Aides/searchReducer';
import { ISearchActions } from '../../../Aides/actions';
import {
  StyledAideShiftSearch,
  RefreshButton,
  StandardButton,
} from '../../../Aides/components/AidesShiftSearch/AideShiftSearchStyledComponents';
import { useHistory } from 'react-router-dom';

export interface IAideShiftSearchComponentProps {
  actions: IAideShiftSearchComponentActions;
  props: {
    searchParams: ISearchState;
    search: (startDate: Date, endDate: Date) => void;
    patient?: any;
  };
}

export interface ISetterAction {
  setterAction: (value?: any) => void;
  newValue: Date | string | undefined;
}

export interface IAideShiftSearchComponentActions {
  SearchActions: ISearchActions;
  setSearchParameterAndRequest: (setterActions: ISetterAction[], shouldRequest?: boolean) => void;
  requestSearch: () => void;
}

const pad = (num: string | number) => ('0' + num).slice(-2);
const mmss = (secs: number) => {
  const minutes = Math.floor(secs / 60);
  secs = secs % 60;
  return `${pad(minutes)}:${pad(secs)}`;
};

const searchOptions: string[] = [
  '"Pending"',
  '"Reviewing"',
  '"Approved"',
  'Any name',
  '"Location"',
];
const getRandomOption = () => searchOptions[Math.floor(Math.random() * searchOptions.length)];
export const PatientShiftSearchComponent = ({
  props: { searchParams, search, patient },
  actions,
}: IAideShiftSearchComponentProps) => {
  const router = useHistory();
  const [focusedInput, setFocusedInput] = useState<any>('');
  const [timer, setTimer] = useState<null | any>(null);
  const [time, setTime] = useState(0);
  const { searchStartDate, searchEndDate, searchTerm, isDefaultState } = searchParams;
  const { setDefaultSearchParameters, setSearchTerm, setSearchStartDate, setSearchEndDate } =
    actions.SearchActions;
  const { setSearchParameterAndRequest, requestSearch } = actions;

  const dispatchSearchParamsAndRequest = (
    startDate: Moment | null,
    endDate: Moment | null,
    shouldRequest: boolean
  ) => {
    const params = [];
    if (startDate) {
      params.push({ setterAction: setSearchStartDate, newValue: startDate.toDate() });
    }
    if (endDate) {
      params.push({ setterAction: setSearchEndDate, newValue: endDate.toDate() });
    }
    setSearchParameterAndRequest(params, shouldRequest);
    resetTimer();
  };

  const changeDates = (startDate: any, endDate: any) => {
    dispatchSearchParamsAndRequest(startDate, endDate, false);
  };

  const handleOnClose = (startDate: Moment | null, endDate: Moment | null) => {
    dispatchSearchParamsAndRequest(startDate, endDate, true);
  };

  const incrementTime = () => {
    setTime((time) => time + 1);
  };

  useEffect(() => {
    const interval = setInterval(incrementTime, 1000);
    setTimer(interval);
  }, []);

  const refreshData = () => {
    search(searchStartDate, searchEndDate);
    resetTimer();
  };

  const resetTimer = () => {
    clearInterval(timer);
    setTimer(null);
    setTime(0);
    const interval = setInterval(incrementTime, 1000);
    setTimer(interval);
  };

  return (
    <StyledAideShiftSearch>
      <div style={{ zIndex: 3 }}>
        <StyledDateRangePicker
          futureLimit={1}
          startDate={moment(searchStartDate)}
          startDateId={'shiftSearchStartDate'}
          endDate={moment(searchEndDate)}
          endDateId={'shiftSearchEndDate'}
          onDatesChange={(value1: any, value2: any) => changeDates(value1, value2)}
          focusedInput={focusedInput}
          onFocusChange={(value: any) => setFocusedInput(value)}
          onClose={handleOnClose}
        />
      </div>
      <div>Last Refreshed: {mmss(time)}</div>
      <RefreshButton onClick={refreshData}>Refresh Data</RefreshButton>
      <StandardButton secondary onClick={() => router.push(`/patients/${patient.id}/forms`)}>
        Patient Forms
      </StandardButton>
    </StyledAideShiftSearch>
  );
};
