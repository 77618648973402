import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { ReactNode } from 'react';
import { GPSAlertResponseMessage } from '../../../../../common/enums';
import { ICaregiverShiftsData } from '../../../../../common/interfaces';
import { HtmlTooltip } from '../../HtmlToolTip/HtmlToolTip';

export const DistanceInColumnRenderer = ({
  value,
}: GridRenderCellParams<ICaregiverShiftsData>): ReactNode => {
  if (!value) {
    return null;
  }

  return Render(value.clockInDistance, value.clockInAlert);
};

export const DistanceOutColumnRenderer = ({
  value,
}: GridRenderCellParams<ICaregiverShiftsData>): ReactNode => {
  if (!value) {
    return null;
  }

  return Render(value.clockOutDistance, value.clockOutAlert);
};

const Render = (distance: number | null, alert: string | null): ReactNode => {
  if (distance === null) {
    return (
      <span>
        <HtmlTooltip
          title="GPS Not captured"
          children={<span>{GPSAlertResponseMessage.NoGpsData}</span>}
        />
      </span>
    );
  }

  const formattedDistance = `${distance} mi`;

  if (!alert) {
    return formattedDistance;
  }

  return (
    <span>
      <HtmlTooltip title={alert} children={<span>{formattedDistance}</span>} />
    </span>
  );
};
