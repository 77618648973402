import React from 'react';
import { Section } from './DVRFormSection';
import { UpdateFormState, ISection } from './interfaces';

// CONTROLS GEN
export const generateFormControls = ({
  formTemplate,
  formState,
  updateFormState,
}: {
  formTemplate: any;
  formState: any;
  updateFormState: UpdateFormState;
}) => {
  return (
    <div>
      {formTemplate && formTemplate.form && (
        <div>
          {formTemplate.form.map((tempSection: ISection) => {
            return (
              <Section
                key={tempSection.sectionTitle}
                section={tempSection}
                formState={formState}
                updateFormState={updateFormState}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
