import { types } from './constants';
import { saveClockInOut, getQAShifts } from '../api';

export const setCurrentShift = (shift: any) => {
  return {
    type: types.SET_CURRENT_SHIFT,
    payload: { shift },
  };
};

export const setCurrentEditedShift = (editedShift: any) => {
  return {
    type: types.SET_CURRENT_EDITED_SHIFT,
    payload: { editedShift },
  };
};

export const setCurrentClockInOut = (clockInOut: any) => {
  return {
    type: types.SET_CURRENT_CLOCK_IN_OUT,
    payload: { clockInOut },
  };
};

export const revertShiftEdits = () => {
  return {
    type: types.REVERT_SHIFT_EDITS,
  };
};

export const clearCurrentShift = () => {
  return {
    type: types.CLEAR_CURRENT_SHIFT,
  };
};

export const setCurrentEditedDVR = (editedDVR: any) => {
  return {
    type: types.SET_EDITED_DVR,
    payload: { editedDVR },
  };
};

export const setSaveCurrentShift = (shift: any) => {
  return async (dispatch: any) => {
    const { clockInOut } = shift;
    await saveClockInOut(clockInOut);
    await dispatch({
      type: types.SET_SAVE_CURRENT_SHIFT,
      payload: { shift },
    });
    const qaShifts = await getQAShifts();
    await dispatch({
      type: types.SET_QA_SHIFTS,
      payload: { qaShifts: qaShifts },
    });
  };
};

export const setQAShifts = () => {
  return async (dispatch: any) => {
    const qaShifts = await getQAShifts();
    await dispatch({
      type: types.SET_QA_SHIFTS,
      payload: { qaShifts: qaShifts },
    });
  };
};

export const setSearchStartDate = (searchStartDate: Date) => {
  return (dispatch: any) => {
    dispatch({
      type: types.SET_SEARCH_START_DATE,
      payload: { searchStartDate: searchStartDate },
    });
  };
};

export const setSearchEndDate = (searchEndDate: Date) => {
  return (dispatch: any) => {
    dispatch({
      type: types.SET_SEARCH_END_DATE,
      payload: { searchEndDate: searchEndDate },
    });
  };
};

export const setSearchTerm = (searchTerm: string) => {
  return (dispatch: any) => {
    dispatch({
      type: types.SET_SEARCH_TERM,
      payload: { searchTerm: searchTerm },
    });
  };
};

export const setDefaultSearchParameters = (isDefault: boolean) => {
  return (dispatch: any) => {
    dispatch({
      type: types.SET_DEFAULT_SEARCH_PARAMETERS,
      payload: { isDefault: isDefault },
    });
  };
};

export const SearchActions = {
  setSearchStartDate,
  setSearchEndDate,
  setSearchTerm,
  setDefaultSearchParameters,
};

export interface ISearchActions {
  setSearchStartDate: (startDate: Date) => void;
  setSearchEndDate: (endDate: Date) => void;
  setSearchTerm: (term: string) => void;
  setDefaultSearchParameters: (isDefault: boolean) => void;
}
