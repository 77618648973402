import React, { Component } from 'react';
import Sections from '../components/Sections';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

class AdminView extends Component {
  public render() {
    return (
      <div>
        <h2>Admin Portal</h2>
        <Sections />
      </div>
    );
  }
}

export default AdminView;
