import React, { useEffect, useState } from 'react';
// @ts-ignore — no types provided
import { useToasts } from 'react-toast-notifications';
import {
  clearLocalUser,
  isCacheOutdated as checkIsCacheOutdated,
  ToastAppearance,
} from '../../utils';
import { CacheBustConfirmToast } from './CacheBustConfirmToast';
import { interfaces } from '../../common';

const TEN_MINUTES = 1000 * 60 * 10;

export const CacheBuster = ({ ...props }: ICacheInputs) => {
  const { actions } = props;
  let newCodeAvailableReminder: any = null;
  const { addToast } = useToasts();
  const [isUpdateNotified, setIsUpdateNotified] = useState(false);
  const [countNotify, setIsUpdateNotifiedCount] = useState(0);

  const onDismissedUpdateNotification = () => {
    newCodeAvailableReminder = setTimeout(notifyUserOfUpdateAvailable, TEN_MINUTES);
  };

  const onConfirmCacheBust = async () => {
    if (caches) {
      const tempCacheKeys = await caches.keys();
      tempCacheKeys.forEach((tempCacheKey: any) => caches.delete(tempCacheKey));
    }

    if (newCodeAvailableReminder) {
      clearTimeout(newCodeAvailableReminder);
      newCodeAvailableReminder = 0;
    }

    await clearLocalUser(); // await this since it handles local storage
    actions.clearAppData();
    await actions.getAppSettings(undefined);

    //@ts-ignore: reload actually takes a boolean in FireFox to force skipping cache
    window.location.reload(true);
  };

  const notifyUserOfUpdateAvailable = () => {
    addToast(<CacheBustConfirmToast onConfirm={async () => await onConfirmCacheBust()} />, {
      appearance: ToastAppearance.INFO,
      onDismiss: () => onDismissedUpdateNotification(),
    });
  };

  useEffect(() => {
    const cacheBust = async () => {
      if (isUpdateNotified) {
        // user should already be aware they're out of date, bail early
        return;
      } else {
        const tempIsNewVersionAvailable = await checkIsCacheOutdated();
        if (tempIsNewVersionAvailable) {
          if (countNotify < 1) {
            setIsUpdateNotifiedCount(countNotify + 1);
            setIsUpdateNotified(true);
            notifyUserOfUpdateAvailable();
          }
        }
      }
    };

    cacheBust();

    // useEffect return is the cleanup method
    return (): void => {
      if (newCodeAvailableReminder) {
        clearTimeout(newCodeAvailableReminder);
        newCodeAvailableReminder = 0;
      }
    };
  });

  return null;
};

interface ICacheInputs {
  actions: {
    getAppSettings: (user?: interfaces.IUser) => void;
    clearAppData: () => void;
  };
}
