import React from 'react';
import styled from 'styled-components';
import MaterialIcon from 'material-icons-react';

type Props = {
  key: string;
  color: string;
  lat?: number;
  lng?: number;
};

const Marker = styled.div`
  position: absolute;
  top: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 50%;

  i {
    margin-left: 2.5px;
    margin-top: 2.5px;
  }
`;

export class HomeMarker extends React.PureComponent<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    return (
      <Marker style={{ backgroundColor: this.props.color }}>
        <MaterialIcon icon={'home'} color={'white'} key={'home'} />
      </Marker>
    );
  }
}
