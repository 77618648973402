import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  api,
  getApprovedSchedulerFeaturesEnabled,
  getAppSettings,
  getAndSetReasonCodes,
  authCodeSignIn,
  getAllLocations,
} from '../../api';
import { ISetUserInput } from '../interfaces';
import { interfaces } from '../../common';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setAppFeatureFlags as setFeatureFlags, setUser as setUserAction } from '../index';
import * as actions from '../../routing/actions';
import { initOnlyEvvShiftsFilter, storeLocalUser } from '../../utils';
import { handleLandingPageRoutes } from '../../routing/routeHandling';
import { getPkceCodeChallenge } from '../../utils/user';
import { isCookieAuthEnabled } from '../../utils/app';
import { setUserLocations as setLocations } from '../actions';

interface IProps extends RouteComponentProps {
  setUser: ({ user, token }: ISetUserInput) => (dispatch: any) => Promise<void>;
  setAppFeatureFlags: (featureFlags: interfaces.IFeatureFlags) => void;
  user: any;
  setAllReasonCodes: (reasons: interfaces.IReasonCodeObject) => void;
  setAppSettings: (appSettings: interfaces.IAppSetting[] | null) => void;
  setUserLocations: (locations: interfaces.ILocation) => void;
}

const OneLoginAuth = ({ ...props }: IProps) => {
  useEffect(() => {
    const loginUser = async (search: any) => {
      const loginResponse = await getAuthToken(search);

      if (loginResponse.status === 200) {
        await setUserLocally(loginResponse);
        await setUserAndAppSettings(props, loginResponse);
        onSuccessfulLogin(loginResponse.body.user, props.history);
      }
    };
    loginUser(props.location.search).then();
  }, []);
  return null;
};

async function getAuthToken(search: any) {
  const searchParams = new URLSearchParams(search);
  const authCode = searchParams.get('code');
  const redirectUri = process.env.REACT_APP_ONE_LOGIN_REDIRECT_URI;
  const pkceCodeChallenge = getPkceCodeChallenge();
  const application = 'Scheduler';

  if (pkceCodeChallenge) {
    const codeChallenge = pkceCodeChallenge.pkce;

    if (authCode && redirectUri && codeChallenge) {
      return await authCodeSignIn(
        'api/User/AuthCodeAuthenticate',
        authCode.trim(),
        redirectUri.trim(),
        codeChallenge.trim(),
        application.trim()
      );
    }
  }

  return null;
}

async function setUserLocally(loginResponse: any) {
  const token = loginResponse.body.token || 'Obsolete';
  const accessToken = loginResponse.body.accessToken || 'Obsolete';
  const refreshToken = loginResponse.body.refreshToken || 'Obsolete';
  const oneloginIdToken = loginResponse.body.originalIdToken || 'Obsolete';
  const tempUser = loginResponse.body.user;
  storeLocalUser(tempUser, token, accessToken, refreshToken, oneloginIdToken);
}

async function setUserAndAppSettings(props: any, loginResponse: any) {
  const { setUser, setAppSettings, setAppFeatureFlags, setAllReasonCodes, setUserLocations } = props;
  const user = loginResponse.body.user;
  const token = loginResponse.body.token || 'Obsolete';
  const featureFlags = await getApprovedSchedulerFeaturesEnabled();
  setAppFeatureFlags(featureFlags);

  await setUser({ user: user, token: token });
  await getAndSetReasonCodes(setAllReasonCodes);
  initOnlyEvvShiftsFilter();
  const tempAppSettings = await getAppSettings(user.locationId);
  setAppSettings(tempAppSettings);
  const locations = await getAllLocations();
  setUserLocations(locations);
}

function onSuccessfulLogin(user: any, history: any) {
  const successRoute = handleLandingPageRoutes(user);
  history.push(successRoute);
}

const mapStateToProps = ({ user }: any) => {
  return { user };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setUser: setUserAction,
      setAppFeatureFlags: setFeatureFlags,
      setAllReasonCodes: (allReasons: interfaces.IReasonCodeObject) =>
        actions.setAllReasons(allReasons),
      setAppSettings: (appSettings: interfaces.IAppSetting[] | null) =>
        actions.setAppSettings(appSettings),
      setUserLocations: setLocations,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OneLoginAuth);
