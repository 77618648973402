import { api } from './api';
import { get } from './apiCallHelper';

export const getNursesForLocation = async (locationId: number) => {
  return await get(`/api/Location/Nurses/${locationId}`);
};

export const getPatientsForLocation = async (locationId: number) => {
  return await get(`/api/Location/Patients/${locationId}`);
};

export const getLocation = async (locationId: number) => {
  return await get(`/api/Location/${locationId}`);
};

export const getCostCenterLocationsForLocation = async (locationId: number) => {
  return await get(`/api/Location/CostCenters/${locationId}`);
};
