import React, { useEffect, useState } from 'react';
import MaterialIcon from 'material-icons-react';
import { Table } from '../../Shifts/components/Table';
import PatientsScheduledAndPerformed from './PatientsScheduledAndPerformed';
import { theme } from '../../common';
import {
  getAllPatientsForLocation,
  getAllPatientsForLocationWithUnprocessedShifts,
} from '../../api/patient';
import SearchStyledComponents from '../../Aides/components/AidesShiftSearch/AideShiftSearchStyledComponents';
import { IPatient } from '../PatientInterfaces';
import { useHistory } from 'react-router-dom';
const Patients = () => {
  const router = useHistory();
  const COLUMNS = [
    {
      Header: 'PATIENT NAME',
      id: 'name',
      accessor: (row: any) => `${row.givenName} ${row.familyName}`,
      minWidth: 100,
      Cell: (row: any) => {
        return (
          <div
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              const selectedPatient = row.original;
              if (selectedPatient.externalSource === 'Encore') {
                router.push(`/patients/${selectedPatient.id}/forms`);
              } else {
                setSelected(selectedPatient);
              }
            }}
          >
            {row.value}
          </div>
        );
      },
    },
    {
      Header: 'MR ID',
      accessor: 'searchField',
      minWidth: 100,
      sortMethod: (a: any, b: any) => {
        const numA = Number(a);
        const numB = Number(b);
        if (isNaN(numA) || isNaN(numB)) {
          return a > b ? 1 : a < b ? -1 : 0;
        }
        return numA > numB ? 1 : numA < numB ? -1 : 0;
      },
      Cell: (row: any) => {
        return <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>{row.value}</div>;
      },
    },
    {
      Header: 'LOCATION',
      accessor: 'location',
      minWidth: 100,
      Cell: (row: any) => {
        const address = row.original.location.address;
        const urlEncodedAddress = address.split(' ').join('+');
        return (
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            <a
              href={'https://www.google.com/maps/search/?api=1&query=' + urlEncodedAddress}
              target={'_blank'}
            >
              {address}
            </a>
          </div>
        );
      },
    },
    {
      Header: 'PATIENT SOURCE',
      accessor: 'externalSource',
      minWidth: 100,
      Cell: (row: any) => {
        const patientSource = row.original.externalSource;
        return (
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>{patientSource}</div>
        );
      },
    },
    {
      Header: 'PHONE',
      accessor: 'phoneNumber',
      minWidth: 100,
      Cell: (row: any) => {
        const phoneNumber =
          row.original.location.phoneNumber !== null ? row.original.location.phoneNumber : 'N/A';
        return (
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>{phoneNumber}</div>
        );
      },
    },
    {
      Header: 'AGE',
      accessor: 'age',
      minWidth: 100,
      Cell: (row: any) => {
        return (
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>{row.original.age}</div>
        );
      },
    },
    {
      Header: 'SEX',
      accessor: 'sex',
      minWidth: 100,
      Cell: (row: any) => {
        return (
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>{row.original.sex}</div>
        );
      },
    },
    {
      Header: 'WEIGHT',
      accessor: 'weight',
      minWidth: 100,
      Cell: (row: any) => {
        return (
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            {row.original.weight}
          </div>
        );
      },
    },
  ];

  const [allPatients, setAllPatients] = useState<IPatient[]>([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<any | null>(null);
  const [filter, setFilter] = useState<string>('');

  useEffect(() => {
    async function effect() {
      setAllPatients(await getAllPatientsForLocation());
      setLoading(false);
    }

    effect();
  }, []);

  const patientsToShow = filter
    ? allPatients.filter(
        (patient) =>
          `${patient.givenName} ${patient.familyName}`
            .toLowerCase()
            .includes(filter.toLowerCase()) || patient.searchField.includes(filter)
      )
    : allPatients;

  return selected !== null ? (
    <PatientsScheduledAndPerformed
      goBack={() => setSelected(null)}
      patient={selected}
      removeSelected={() => setSelected(null)}
    />
  ) : (
    <>
      <div style={{ padding: 24, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div
          style={{
            flex: 1,
            color: theme.colors.defaultFontColor,
            fontSize: theme.fontSizeLg,
          }}
        >
          Patient Management
        </div>
        <SearchStyledComponents.StyledAideShiftSearch style={{ flex: 2, justifyContent: 'start' }}>
          <SearchStyledComponents.StyledSearchBox>
            <SearchStyledComponents.StyledKeywordInput
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
            />
            <SearchStyledComponents.StyledSearchIcon onClick={() => setFilter('')}>
              <MaterialIcon icon="close" />
            </SearchStyledComponents.StyledSearchIcon>
          </SearchStyledComponents.StyledSearchBox>
        </SearchStyledComponents.StyledAideShiftSearch>
      </div>
      <Table
        defaultPageSize={25}
        minRows={10}
        showPagination={true}
        data={patientsToShow}
        columns={COLUMNS}
        loading={loading}
        loadingText="Loading..."
        sortable={true}
        resizable={true}
        style={{
          width: 'unset',
          height: '80%',
          overflow: 'hidden',
          boxShadow: 'none',
          border: 'none',
        }}
      />
    </>
  );
};

export default Patients;
