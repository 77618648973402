import { createGlobalStyle } from 'styled-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

export const theme = {
  fontSizeXs: '10px',
  fontSizeSm: '14px',
  fontSizeMd: '18px',
  fontSizeLg: '24px',
  fontSizeH1: '48px',
  fontSizeH2: '36px',
  fontFamilyDefault: `'Roboto', sans-serif`,
  buttonBorderRadius: `6px`,
  colors: {
    tooltipGrey: '#96897a',
    tooltipGray: '#96897a',
    tooltipNeutralBg: '#f8f7f6',
    tooltipNeutralBorder: '#d5d0ca',
    tooltipNeutralText: '#807365',
    tooltipSuccessBg: '#d6f6da',
    tooltipSuccessBorder: '#a4e9ab',
    tooltipSuccessText: '#2e9b29',
    tooltipErrorBg: '#fde0de',
    tooltipErrorBorder: '#f5c1af',
    tooltipErrorText: '#c4521b',
    tooltipInfoBg: '#edf7ff',
    tooltipInfoBorder: '#a9d0fe',
    tooltipInfoText: '#0578CA',
    defaultFontColor: '#333',
    defaultLightFontColor: 'white',
    selectedColor: 'rgba(222, 243, 247, 1)',
    warning: 'maroon',
    error: '#a51023',
    aveannaPrimary: '#0075C9',
    aveannaDarkBlue: 'rgba(0, 117, 201, 1)',
    aveannaDarkBlueHex: '#0075c9',
    aveannaDarkBlueTextColor: `#505980`,
    aveannaFadedBlue: 'rgba(18, 119, 198, .5)',
    aveannaLightBlue: 'rgba(114, 205, 223, 1)',
    aveannaGray: 'rgba( 0, 0, 0, .6)',
    aveannaGrey: 'rgba( 0, 0, 0, .6)',
    aveannaDarkGrey: 'rgba(128, 128, 128, 1)',
    aveannaDarkGray: 'rgba(128, 128, 128, 1)',
    aveannaDarkBlueGray: 'rgb(102, 122, 136)',
    backgroundGrey: 'rgba(245, 245, 247, 1)',
    backgroundGray: 'rgba(245, 245, 247, 1)',
    chatGray: '#EAEAEA',
    chatGrey: '#EAEAEA',
    textGray: '#555555',
    white: '#fff',
    black: `#333`,
    footerButtonGreen: '#74BD4F',
    footerButtonRed: '#AE0625',
    formTaskLabel: `rgba(95, 196, 218, 0.05)`,
    formTaskText: `#5c5c5c`,
    formButtonActive: `rgba(95, 196, 218, 0.2)`,
    formButtonBorder: `rgba(0, 0, 0, 0.12)`,
    toastInfo: `#2684ff`,
    htmlDarkGray: '#A9A9A9',
    lightGray: '#D6D6D6',
    lightestGray: '#E8E8E8',
    lightBlue: '#3F9DC9',
  },
  defaultBoxShadow: `2px 2px 4px rgba(0, 0, 0, 0.16)`,

  // these NEED to be strings when actually used
  // however they're numbers here so we can add them
  // if we need to. end result needs px appened to end
  headerHeight: 75,
  footerHeight: 50,
};

// aveannaDarkBlue: 'rgba(18, 119, 198, 1)',

export const GlobalStyle = createGlobalStyle`
    /* MAKE THIS DANG THING FULL SCREEN! */
    html {
      height: 100%;
      font-family: 'Roboto', sans-serif;
      overflow: auto;
      /* font-family: 'Major Mono Display', monospace; */
    }

    body {
      background-color: #F5F6FA;
      font-family: 'Roboto', sans-serif;
    }

    h1 {
      font-size: ${theme.fontSizeH1};
      color: ${theme.colors.aveannaDarkBlue};
    }

    h2 {
      font-size: ${theme.fontSizeH2};
      color: ${theme.colors.aveannaDarkBlue};
      margin-top: 12px;
      margin-bottom: 24px;
    }

    p {
      color: ${theme.colors.aveannaDarkBlueGray};
      font-size: ${theme.fontSizeMd}
    }

    #root {
    }

    .react-datepicker-wrapper {
      display: flex;
      flex: 1;
    }

    .DateRangePickerInput {
      display: flex;
    }

    .DateInput DateInput_1 {
      background-color: #505980;
    }

    .DateInput_input DateInput_input_1 {
      background-color: #505980;
    }

    .CalendarDay__selected_span {
      background: #505980;
      color: ${theme.colors.white};
      border: 1px solid ${theme.colors.white};
      /* default styles include a border */
    }
    
     /* Will edit selected date or the endpoints of a range of dates */
    .CalendarDay__selected {
      background: ${theme.colors.aveannaDarkBlue};
      color: ${theme.colors.white};
      border: 1px solid ${theme.colors.white};
    }
    
    /* Will edit when hovered over. _span style also has this property */
    .CalendarDay__selected:hover {
      background: ${theme.colors.aveannaDarkBlue};
    }
    
     /* Will edit when the second date (end date) in a range of dates */
     /* is not yet selected. Edits the dates between your mouse and said date */
    .CalendarDay__hovered_span:hover,
    .CalendarDay__hovered_span {
      background: ${theme.colors.aveannaDarkBlue};
      color: ${theme.colors.white};
      border: 1px solid ${theme.colors.white};
    }

    .DateInput_input {
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      color: #505980;
      background-color: #fff;
      width: 100%;
      padding: 11px 11px 9px;
      border: 0;
      border-top: 0;
      border-right: 0;
      border-bottom: 2px solid transparent;
      border-left: 0;
      border-radius: 0;
    }

    .DateRangePickerInput_arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10px;
      color: #505980;
      z-index: 0;
      margin-left: 10px;
    }

    .DateRangePickerInput {
      display: flex;
      justify-content: center;
      width: 210px;
    }

    .DateInput {
      margin: 0;
      padding: 0;
      background: #fff;
      position: relative;
      display: inline-block;
      width: 100px;
      vertical-align: middle;
    }

`;
