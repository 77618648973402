import React from 'react';
import { ShiftFooterContainer, ShiftFooterButtonContainer } from '../ShiftPanel/styles';
import { ShiftFooterButton } from '../ShiftDetails/ShiftFooterButtons';
import { AddNewShiftConfirmation } from './AddNewShiftConfirmation';

interface IProps {
  confirmAction: () => void;
  onConfirm: (component: React.ReactNode) => void;
  disabled?: boolean;
  onBack: () => void;
  onClose: () => void;
  refreshData?: () => void;
  confirmMessage: string;
}

export const AddNewShiftFooter = ({
  confirmAction,
  onConfirm,
  onBack,
  onClose,
  disabled = false,
  confirmMessage = '',
  refreshData,
}: IProps) => {
  const action = async () => {
    await confirmAction();
    if (refreshData) {
      await refreshData();
    }
  };

  const openAddNewShiftConfirmation = async () => {
    onConfirm(
      <AddNewShiftConfirmation
        message={confirmMessage}
        toastSuccessMessage="Shift successfully added!"
        onConfirm={action}
        onBack={onBack}
        onClose={onClose}
      />
    );
  };

  return (
    <ShiftFooterContainer>
      <ShiftFooterButtonContainer>
        <div>
          <ShiftFooterButton
            disabled={disabled}
            onClick={openAddNewShiftConfirmation}
            data-cy={'shift-footer-add-new-shift-btn'}
          >
            Add New Shift
          </ShiftFooterButton>
        </div>
      </ShiftFooterButtonContainer>
    </ShiftFooterContainer>
  );
};
