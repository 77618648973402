import * as React from 'react';
import styled from 'styled-components';

interface ICheckboxProps {
  title: string;
  checked: boolean;
  onPress?: any;
}

const defaultCheckboxSize = 18;

const StyledFlexRow = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 24px;
`;

export const FormCheckbox = ({ checked, title, onPress }: ICheckboxProps) => {
  return (
    <StyledFlexRow onClick={onPress}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onPress}
        style={{ width: defaultCheckboxSize, height: defaultCheckboxSize, marginBottom: 4 }}
      />
      <p style={{ marginLeft: 6 }}>{title}</p>
    </StyledFlexRow>
  );
};

export const FormRadio = ({ checked, title, onPress }: ICheckboxProps) => {
  return (
    <StyledFlexRow onClick={onPress}>
      <input
        type="radio"
        checked={checked}
        onChange={onPress}
        style={{ width: defaultCheckboxSize, height: defaultCheckboxSize, marginBottom: 4 }}
      />
      <p style={{ marginLeft: 6 }}>{title}</p>
    </StyledFlexRow>
  );
};

export const FormTextArea = styled.textarea`
  display: flex;
  flex: 1;
  padding: 12px;

  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.backgroundGrey}`};

  font-family: monospace;
  resize: none;
`;

export const FormCheckboxContainer: any = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 24px;
  padding-right: 24px;
`;
