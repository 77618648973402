import React from 'react';
import styled from 'styled-components';

import { StyledButton, device } from '../../common';

interface IProps {
  messageText: string;
  onConfirm: () => void;
  onClose: () => void;
}

export const MessageConfirmModal = ({ messageText, onConfirm, onClose }: IProps) => {
  return (
    <Modal>
      <ModalHeader>
        <h2>Send Message to All Caregivers?</h2>
      </ModalHeader>
      <ModalContent>
        <p>
          Send the following message to all Caregivers at this location? This action can not be
          undone.
        </p>
        <ModalQuote>{messageText}</ModalQuote>
      </ModalContent>
      <ModalFooter>
        <StyledButton tertiary style={{ marginRight: 12 }} onClick={onClose}>
          Cancel
        </StyledButton>
        <StyledButton onClick={onConfirm}>Confirm</StyledButton>
      </ModalFooter>
    </Modal>
  );
};

// STYLES
const Modal = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ModalContent = styled.div`
  padding: 24px;
`;

const ModalQuote = styled.div`
  padding: 24px;
  border-radius: 4px;
  background-color: #eee;
  border-left: 4px solid #ddd;
  font-family: monospace;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  height: 75px;

  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.warning};

  h2 {
    font-size: 20px;
    align-self: center;
  }

  @media ${device.mobileM} {
    h2 {
      font-size: 24px;
    }
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  padding: 24px;
`;
