import ReactGA, { ga } from 'react-ga';
import { getGATrackingId } from '../utils/app';

export interface IAnalyticOptions {
  eventLabel?: string;
  eventValue?: number;
  isEventNonInteractive?: boolean;
}

export const sendAnalyticsEvent = (
  eventCategory: Category,
  eventAction: string,
  options?: IAnalyticOptions
): void => {
  if (getGATrackingId()) {
    try {
      ReactGA.event({
        category: eventCategory,
        action: eventAction,
        label: options ? options.eventLabel : undefined,
        value: options ? options.eventValue : undefined,
        nonInteraction: options ? options.isEventNonInteractive : undefined,
      });
    } catch (error) {
      console.error(error);
    }
  }
};

export const enableAnalytics = (): void => {
  const trackingId = getGATrackingId();

  if (trackingId) {
    console.log('Turning on Google analytics');
    ReactGA.initialize([
      {
        gaOptions: {
          cookieDomain: 'auto',
          siteSpeedSampleRate: 100,
        },
        trackingId: trackingId,
      },
    ]);
  }
};

export const sendAnalyticsPageView = (path: string): void => {
  ReactGA.pageview(path);
};

export interface IAnalyticOptions {
  eventLabel?: string;
  eventValue?: number;
  isEventNonInteractive?: boolean;
}

export type Category = 'CaregiverShifts';
