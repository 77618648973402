import React from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import './DateRangePicker.css';
import moment, { Moment } from 'moment/moment';
import { useCallback } from 'react';

type Props = {
  className: string | undefined;
  startDate: Moment;
  endDate: Moment;
  onChange: (starDate: Moment, endDate: Moment) => void;
  onCalendarClose?: () => void;
};

const CustomDateRangePicker = (props: Props) => {
  const onChange = useCallback(
    (dates: Date[]) => {
      props.onChange(moment(dates[0]), moment(dates[1]));
    },
    [props.onChange]
  );

  return (
    <DateRangePicker
      className={props.className}
      calendarAriaLabel="Toggle calendar"
      clearAriaLabel="Clear value"
      dayAriaLabel="Day"
      monthAriaLabel="Month"
      nativeInputAriaLabel="Date"
      onChange={onChange as any}
      value={[props.startDate.toDate(), props.endDate.toDate()]}
      yearAriaLabel="Year"
      clearIcon={null}
      onCalendarClose={props.onCalendarClose}
      //@ts-ignore
      calendarType="US"
      maxDate={new Date()}
    />
  );
};

export default CustomDateRangePicker;
