export enum FormTypes {
  DailyVisit = 'DAILY_VISIT',
  SecurityCompliance = 'SECURITY_COMPLIANCE',
}

export enum ControlTypes {
  Checkbox = 'checkbox',
  Radio = 'radio',
  Textarea = 'textarea',
  Images = 'images',
}
