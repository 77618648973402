import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { ROUTES } from '../../routing/routing.constants';
import { Aides, AidesNewSubmissions, AidesAllShifts, AidesRejectedShifts } from './';
import SingleAide from '../SingleAide/SingleAide';
import { SideNav, interfaces } from '../../common';
import { userCanNavigateToAides } from '../../routing/routeHandling';
import { useSelector } from 'react-redux';
import { handleLandingPageRoutes } from '../../routing/routeHandling';

const aidesSideNavConfig: interfaces.ISideNavRouteConfig[] = [
  {
    title: 'All Shifts',
    route: `${ROUTES.AIDES}${ROUTES.ALL_SHIFTS}`,
    icon: 'calendar_today',
  },
  {
    title: 'New Submissions',
    route: `${ROUTES.AIDES}`,
    icon: 'list_alt',
  },
  {
    title: 'Shifts Under Review',
    route: `${ROUTES.AIDES}${ROUTES.REJECTED}`,
    icon: 'cancel_presentation',
  },
];

// MASTER AIDES COMPONENT, USED FOR SUB-ROUTING
export const AidesRouter = ({ match }: RouteComponentProps) => {
  const { user, featureFlags } = useSelector(({ user }: any) => user);
  const shouldRoute = userCanNavigateToAides(user) || !featureFlags.accessControlSC714;
  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <SideNav routeConfig={aidesSideNavConfig} />
      <div style={{ flex: 1, height: `100vh` }}>
        <Switch>
          {shouldRoute ? (
            [
              <Route exact key={`${match.url}`} path={`${match.url}`} component={Aides} />,
              <Route
                exact
                key={`${match.url}`}
                path={`${match.url}${ROUTES.NEW_SUBMISSIONS}`}
                component={AidesNewSubmissions}
              />,
              <Route path={`${match.url}${ROUTES.ALL_SHIFTS}`} component={AidesAllShifts} />,
              <Route
                exact
                path={`${match.url}${ROUTES.REJECTED}`}
                component={AidesRejectedShifts}
              />,
              <Route path={`${match.url}/:id`} component={SingleAide} />,
            ]
          ) : (
            <Redirect to={handleLandingPageRoutes(user)} />
          )}
        </Switch>
      </div>
    </div>
  );
};
