import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/browser';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

// @ts-ignore - no types provided
import { ToastProvider, DefaultToastContainer } from 'react-toast-notifications';

import { GlobalStyle, theme } from './common';
import { Routing } from './routing';
import { persistor, store } from './redux/config';
import { generateVersionStrings } from './utils';
import { PersistGate } from 'redux-persist/integration/react';

if (window.document.location.href.toString().toLowerCase().includes('local') === false) {
  Sentry.init({
    dsn: 'https://d5d6de02b5444d2baaad3a71b62e29b1@sentry.io/1377120',
    environment: generateVersionStrings().sentryEnvironment,
    release: generateVersionStrings().release,
  });
} else {
  console.warn('sentry', 'sentry is turned off on this env.');
}

// ALLOWS FOR THE TOASTS TO BE INFRONT OF EVERYTHING
const ToastContainerOverride = (props: any) => (
  <DefaultToastContainer
    className="toast-container"
    style={{ zIndex: 999, marginTop: theme.headerHeight }}
    {...props}
  />
);

console.log(`you're currently running release:`, generateVersionStrings().release);

const App = () => (
  <Fragment>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <ToastProvider components={{ ToastContainer: ToastContainerOverride }}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Routing />
            </MuiPickersUtilsProvider>
          </PersistGate>
        </Provider>
      </ToastProvider>
    </ThemeProvider>
  </Fragment>
);

export default App;
