import { INurse, IOrientationRequest } from '../common/interfaces';
import { interfaces } from '../common';
import moment from 'moment';
import { get, post, postNullHeader, put } from './apiCallHelper';

const genericErrorText = 'Something went wrong, please try again';

export const getShiftsForLocation = async (patientId: number, startDate: Date, endDate: Date) => {
  const startDateValue = moment(startDate).format('YYYY-MM-DD');
  const endDateValue = moment(endDate).format('YYYY-MM-DD');
  const uri = `/api/Shift/Patient?patientId=${patientId}&startDate=${startDateValue}&endDate=${endDateValue}`;

  const response = await get(uri);
  return response.body;
};

export const matchClockInOutToShift = async (clockInOutId: number, shift: any) => {
  const response = await post('/api/Shift/MatchClockInOutToShift', {
    body: {
      clockInOutId,
      newShift: shift,
    },
  });
  return response.body;
};

export const getAllShifts = async () => {
  const tempAllShiftsResponse = await get('/api/Scheduler/Shift');

  if (tempAllShiftsResponse.status >= 300) {
    throw new Error(genericErrorText);
  }

  return tempAllShiftsResponse.body;
};

export const getAvailableShifts = async () => {
  return await get('/api/Scheduler/Shift?available=true');
};

export const getAppliedShifts = async () => {
  const tempAppliedShiftsResponse = await get('/api/Scheduler/Shift?pending=true&available=false');

  if (tempAppliedShiftsResponse.status >= 300) {
    throw new Error(genericErrorText);
  }

  return tempAppliedShiftsResponse.body;
};

export const getAwardedShifts = async () => {
  const tempAwardedResponse = await get('/api/Scheduler/Shift?awarded=true&available=false');

  if (tempAwardedResponse.status >= 300) {
    throw new Error(genericErrorText);
  }

  return tempAwardedResponse.body;
};

export const getSentShifts = async () => {
  const tempSentShiftResponse = await get('/api/Scheduler/Shift?requested=true&available=false');

  if (tempSentShiftResponse.status >= 300) {
    throw new Error(genericErrorText);
  }

  return tempSentShiftResponse.body;
};

export const getScheduledShifts = async () => {
  const tempScheduledShiftsResponse = await get('/api/Scheduler/Shift?scheduled=true');

  if (tempScheduledShiftsResponse.status >= 300) {
    throw new Error(genericErrorText);
  }

  return tempScheduledShiftsResponse.body;
};

export const getOrientRequests = async (officeId: number): Promise<IOrientationRequest[]> => {
  try {
    const orientRequestResponse = await get(
      `/api/Scheduler/OrientationRequests/Location/${officeId}`
    );

    if (orientRequestResponse.status >= 300) {
      throw new Error('Non 200 status code received from API');
    }

    return orientRequestResponse.body;
  } catch (error) {
    console.log('error in Orient API Request: ', error);
    throw new Error('Could not get orientation request shifts shifts');
  }
};

// ============================================================================================

export const getNursesByShift = async (shiftKey: string): Promise<INurse[]> => {
  const tempNursesByShift = await get(
    `/api/Scheduler/Shift/Nurse?shiftKey=${encodeURIComponent(shiftKey)}`
  );

  if (tempNursesByShift.status >= 300) {
    throw new Error('Non 200 status code received from API');
  }

  return tempNursesByShift.body;
};

export const getAvailableNursesByShift = async (shiftKey: string) => {
  return await get(
    `/api/Scheduler/Shift/Nurse?shiftKey=${encodeURIComponent(
      shiftKey
    )}&requested=false&pending=false&awarded=false`
  );
};

export const getRequestedNursesByShift = async (shiftKey: string): Promise<interfaces.INurse[]> => {
  const tempRequestedNurseByShift = await get(
    `/api/Scheduler/Shift/Nurse?shiftKey=${encodeURIComponent(shiftKey)}&requested=true`
  );

  if (tempRequestedNurseByShift.status >= 300) {
    throw new Error('Non 200 status code received from API');
  }

  return tempRequestedNurseByShift.body;
};

export const getPendingNursesByShift = async (shiftKey: string) => {
  return await get(
    `/api/Scheduler/Shift/Nurse?shiftKey=${encodeURIComponent(shiftKey)}&pending=true`
  );
};

export const getAwardedNursesByShift = async (shiftKey: string) => {
  return await get(
    `/api/Scheduler/Shift/Nurse?shiftKey=${encodeURIComponent(shiftKey)}&awarded=true`
  );
};

// ============================================================================================
export const requestOrientationForShift = async (shiftKey: string, nurseId: any) => {
  const tempForm = new FormData();
  tempForm.append('nurseId', nurseId);
  tempForm.append('shiftKey', shiftKey);
  return await postNullHeader(`/api/Scheduler/OrientationRequest`, {
    body: tempForm,
  });
};

// Bulk route for sending shifts to nurses (giving them the STAR in the mobile app)
export const requestNursesForShift = async (shiftKey: string, nurseIds: interfaces.ID[]) => {
  if (nurseIds.length <= 0) {
    throw new Error('No nurses selected, could not send request.');
  }

  let errorIds: interfaces.ID[] | null = null;

  const tempRequests = nurseIds.map((tempNurseId: interfaces.ID) => ({
    shiftKey,
    nurseId: tempNurseId,
    requested: true,
  }));
  const tempBulkRequestResponse = await post(`/api/Scheduler/Shift/Requests`, {
    body: tempRequests,
  });

  const tempErrorIds = getBulkResponseErrorIds(tempBulkRequestResponse.body);
  if (tempErrorIds.length > 0) {
    // we found an error;
    errorIds = tempErrorIds;
  }

  if (tempBulkRequestResponse.status >= 300) {
    console.log(
      `error with network request to send bulk requests, status: ${tempBulkRequestResponse.status}`
    );
    throw new Error(`Something went wrong sending shifts, please try again...`);
  }

  return { response: tempBulkRequestResponse.body, errorIds };
};

interface IShiftRequestResponse {
  value: boolean;
  key: interfaces.ID;
}

const getBulkResponseErrorIds = (bulkResponse: IShiftRequestResponse[]) => {
  const tempErrorIds: interfaces.ID[] = bulkResponse.reduce(
    (accumulator: interfaces.ID[], tempResponseItem: IShiftRequestResponse) => {
      if (tempResponseItem.value === false) {
        accumulator.push(tempResponseItem.key);
      }
      return accumulator;
    },
    []
  );

  return tempErrorIds;
};

export const removeRequestNurseForShift = async (shiftKey: string, nurseId: any) => {
  return await put(`/api/Scheduler/Shift/Request`, {
    body: {
      shiftKey,
      nurseId,
      requested: false,
    },
  });
};

export const awardNurseShift = async (shiftKey: string, nurseId: any) => {
  return await put(`/api/Scheduler/Shift/Request`, {
    body: {
      shiftKey,
      nurseId,
      awarded: true,
    },
  });
};

export const removeAwardedNurseShift = async (shiftKey: string, nurseId: any) => {
  return await put(`/api/Scheduler/Shift/Request`, {
    body: {
      shiftKey,
      nurseId,
      awarded: false,
    },
  });
};

export const cancellationReasons = {
  NurseIllness: 5,
  NoShow: 8,
  DeclinedPatient: 9,
  VacationHoliday: 21,
  ScheduleConflict: 22,
  InclementWeather: 27,
  DeclinedShiftForPatient: 31,
  DeclinedGeographyForPatient: 32,
  FamilyEmergency: 33,
  DelaySocNurseNotAvailable: 34,
};

export const cancelConfirmedShift = async (
  shiftKey: string,
  nurseId: any,
  cancellationReasonCode: any
) => {
  return await put(`/api/Scheduler/Shift/Request`, {
    body: {
      shiftKey,
      nurseId,
      canceled: true,
      cancellationReasonCode: parseInt(cancellationReasonCode, 10),
    },
  });
};

export const remindNursesForShift = async (shiftKey: string) => {
  return await post(`/api/Scheduler/Shift/Remind`, {
    body: {
      shiftKey,
    },
  });
};

export const getTotalHoursPerPayPeriod = async (nurseId: number) => {
  const result = await get(`api/Scheduler/PayPeriods/${nurseId}`);
  return result.body;
};
