import { AdminPortalActions } from './constants';
import { IExternalSource } from '../common/interfaces';
import { getAllExternalSources } from '../api/admin';

const externalSourcesLoadedSuccessfully = (externalSources: IExternalSource[]) => {
  return {
    type: AdminPortalActions.EXTERNAL_SOURCES_LOADED_SUCCESSFULLY,
    payload: { externalSources },
  };
};

const externalSourcesFailedToLoad = (error: Error) => {
  return {
    type: AdminPortalActions.EXTERNAL_SOURCES_FAILED_TO_LOAD,
    payload: { error },
  };
};

export const getExternalSources = async (dispatch: any) => {
  try {
    const externalSources: IExternalSource[] = await getAllExternalSources();
    dispatch(externalSourcesLoadedSuccessfully(externalSources));
  } catch (error) {
    console.log('Error ocurred when loading external sources', error);
    dispatch(externalSourcesFailedToLoad(error as Error));
  }
};
