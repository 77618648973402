import { CaregiverShiftUpdate, ICaregiverShiftsData } from '../common/interfaces';
import { get, put } from './apiCallHelper';
import { Moment } from 'moment';

export const getCaregiverShiftsData = async (
  startDate?: Moment,
  endDate?: Moment
): Promise<ICaregiverShiftsData[]> => {
  try {
    const queryString = getQueryString(startDate, endDate);
    const caregiverShiftsResponse = await get(`/api/CaregiverShifts${queryString}`);
    return caregiverShiftsResponse.body;
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
};

const getQueryString = (startDate?: Moment, endDate?: Moment): string => {
  if (!startDate && !endDate) {
    return '';
  }

  const format = 'YYYY-MM-DDTHH:mm:ss';

  if (!startDate) {
    return `?endTime=${endDate!.format(format)}`;
  }

  if (!endDate) {
    return `?startTime=${startDate.format(format)};`;
  }

  return `?startTime=${startDate.format(format)}&endTime=${endDate.format(format)}`;
};

export const updateCaregiverShift = async (
  body: CaregiverShiftUpdate
): Promise<ICaregiverShiftsData> => {
  try {
    const response = await put(`/api/CaregiverShifts`, { body });

    if (response.status >= 300) {
      console.error(
        `Update clock in out responded with status code ${response.status} and
        text ${response.statusText}`
      );
      throw new Error(
        'Something went wrong when sending a QA Nurse Review request, please try again.'
      );
    }

    return response.body;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
