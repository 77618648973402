import { api } from './api';
import { getLocalUser } from '../utils';
import { clearPkceCodeChallenge } from '../utils/user';

export const generateHeaders = (contentType?: string | null) => {
  var headers: any = {};

  if (contentType !== undefined) {
    headers['Content-Type'] = contentType;
  }

  return headers;
};

export const get = async (endpoint: string, options?: { body: any }) => {
  if (options && options.body) {
    const response = await api.get(endpoint, { body: options.body, headers: generateHeaders() });
    return response;
  } else {
    const response = await api.get(endpoint, { headers: generateHeaders() });
    return response;
  }
};

export const post = async (endpoint: string, options?: { body: any }) => {
  if (options && options.body) {
    const response = await api.post(endpoint, { body: options.body, headers: generateHeaders() });
    return response;
  } else {
    const response = await api.post(endpoint, { headers: generateHeaders() });
    return response;
  }
};

export const put = async (endpoint: string, options?: { body: any }) => {
  if (options && options.body) {
    const response = await api.put(endpoint, { body: options.body, headers: generateHeaders() });
    return response;
  } else {
    const response = await api.put(endpoint, { headers: generateHeaders() });
    return response;
  }
};

export const postNullHeader = async (endpoint: string, options: { body: any }) => {
  const response = await api.post(endpoint, {
    body: options.body,
    headers: generateHeaders(null),
  });
  return response;
};

export const retrieveImage = async (endpoint: string) => {
  const user = getLocalUser();
  if (user) {
    const headers = { 'Content-Type': 'image/png', Accept: '*/*' }

    return await api.get(endpoint, {
      // raw is very important here, it uses the raw fetch repsonse
      // which is what we need to use the blob
      raw: true,
      headers,
    });
  }
};

export const authCodeSignIn = async (
  endpoint: string,
  authcode: string,
  redirecturi: string,
  codeverifier: string,
  application: string
) => {
  const rawResponse = await api.post(endpoint, {
    body: {
      authcode,
      redirecturi,
      codeverifier,
      application,
    },
  });
  clearPkceCodeChallenge();
  return rawResponse;
};

export const signIn = async (endpoint: string, username: string, password: string) => {
  const rawResponse = await api.post(endpoint, {
    body: {
      username,
      password,
    },
  });
  return rawResponse;
};

export const oneLoginFeatureFlag = async () =>
  await api.get('/api/Feature/SetupFeatureFlags/Scheduler');
