import React, { useState, useEffect, FormEvent, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';

// @ts-ignore — no types provided
import { useToasts } from 'react-toast-notifications';

import { resetPassword } from '../../api';
import { checkIfValidEmailAddress, isPasswordValid, ToastAppearance } from '../../utils';
import { ROUTES } from '../../routing';
import {
  StyledInputGroup,
  StyledInputLabel,
  StyledTextInput,
  StyledPasswordInput,
  StyledSubmitButton,
  StyledErrorDisplay,
} from '../../common';
import { PasswordFormLayout, PasswordGuidelines } from '../components';
import { staticGuidelines, passwordRequirementsMessage } from '../constants';
import { IPasswordChip } from '../interfaces';
import { Amelia } from '../../login/components';
import * as Sentry from '@sentry/browser';

import { getAmeliaHealthCheckEndpoint } from '../../utils/app';
import { getErrorMessage } from '../../common/useToastNotifications';

interface IRouteParams {
  email: string;
}

type Props = {} & RouteComponentProps<IRouteParams>;

export const ResetPassword = ({ history, match }: Props) => {
  const [email, setEmail] = useState<string>(match.params.email || '');
  const [resetToken, setResetToken] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [networkError, setNetworkError] = useState<string>('');
  const [errors, setErrors] = useState<any>(null);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [ameliaVisibility, toggleAmeliaVisibility] = useState<boolean>(true);
  const [ameliaIsRunning, setAmeliaIsRunning] = useState<boolean>(false);

  const { addToast } = useToasts();

  useEffect(() => {
    let tempErrors = {};

    if (email !== '' && !checkIfValidEmailAddress(email)) {
      tempErrors = { ...tempErrors, email: 'Not a valid email address' };
    } else {
      tempErrors = { ...tempErrors, email: null };
    }

    if (resetToken !== '' && resetToken.length < 4) {
      tempErrors = { ...tempErrors, resetToken: 'Not a valid reset token' };
    } else {
      tempErrors = { ...tempErrors, resetToken: null };
    }

    if (newPassword !== '' && !isPasswordValid(newPassword)) {
      tempErrors = { ...tempErrors, newPassword: 'Does not meet password requirements' };
    } else {
      tempErrors = { ...tempErrors, newPassword: null };
    }

    if (confirmPassword !== '' && newPassword !== confirmPassword) {
      tempErrors = {
        ...tempErrors,
        confirmPassword: 'New password and confirm password must match',
      };
    } else {
      tempErrors = { ...tempErrors, confirmPassword: null };
    }

    setErrors({ ...errors, ...tempErrors });

    fetch(getAmeliaHealthCheckEndpoint())
      .then((response) => response.json())
      .then((data) => (data.status === 'UP' ? setAmeliaIsRunning(true) : setAmeliaIsRunning(false)))
      .catch((err) => {
        Sentry.captureException('Amelia health check error message' + err);
        console.log('Amelia health check error message: ', err);
        // Error for some reason, no Amelia visible
        setAmeliaIsRunning(false);
      });

    // if there is a single error message, than the form is not valid
    const isError =
      Object.values(tempErrors).filter((tempValue) => tempValue !== null).length > 0 ? true : false;
    setIsValid(!isError);
  }, [newPassword, confirmPassword, isValid, email, resetToken]);

  const submitForm = async (event?: FormEvent<HTMLInputElement>) => {
    setIsSubmitting(true);
    setNetworkError('');

    if (event) {
      event.preventDefault();
    }

    try {
      await resetPassword({ email, newPassword, resetToken });

      addToast(`You've successfully changed your password`, {
        appearance: ToastAppearance.SUCCESS,
        autoDismiss: true,
      });
      // GOTO login -or- go ahead and log them in
      history.push(ROUTES.LOGIN);
    } catch (error) {
      setNetworkError(getErrorMessage(error));
      setIsSubmitting(false);
    }
  };

  //  GENERATE GUIDELINE CHIPS
  const tempPasswordGuidelines: IPasswordChip[] = [
    { message: passwordRequirementsMessage, isSatisfied: isPasswordValid(newPassword) },
    {
      message: 'New Password and Confirm Password must match',
      isSatisfied: newPassword !== '' && newPassword === confirmPassword,
    },
    ...staticGuidelines,
  ];

  return (
    <Fragment>
      <PasswordFormLayout>
        <h2>Reset Password</h2>
        <div>
          <p>
            We've sent you an email with a reset code. Enter it below in the "Reset Code" input.
          </p>
          <p> If you did not receive an email, please contact support.</p>
        </div>
        <StyledInputGroup style={{ marginTop: 12 }}>
          <StyledInputLabel>Email</StyledInputLabel>
          <StyledTextInput
            placeholder="email address"
            name="email"
            error={errors && errors.email}
            value={email}
            onChange={(event: any) => {
              setEmail(event.target.value);
            }}
            autoComplete="on"
          />
        </StyledInputGroup>
        <StyledInputGroup style={{ marginTop: 6 }}>
          <StyledInputLabel>Reset Code</StyledInputLabel>
          <StyledTextInput
            placeholder="Reset Code"
            error={errors && errors.resetToken}
            name="resetToken"
            value={resetToken}
            onChange={(event: any) => {
              setResetToken(event.target.value);
            }}
            autoComplete="off"
          />
        </StyledInputGroup>
        <StyledInputGroup style={{ marginTop: 6 }}>
          <StyledInputLabel>New Password</StyledInputLabel>
          <StyledPasswordInput
            placeholder="Password"
            name="newPassword"
            errors={errors}
            value={newPassword}
            onChange={(event: any) => {
              setNewPassword(event.target.value);
            }}
          />
          <StyledPasswordInput
            placeholder="Confirm Password"
            name="confirmPassword"
            errors={errors}
            value={confirmPassword}
            onChange={(event: any) => {
              setConfirmPassword(event.target.value);
            }}
          />
        </StyledInputGroup>
        <StyledErrorDisplay errorMessage={networkError} />
        <PasswordGuidelines guidelines={tempPasswordGuidelines} />
        <StyledSubmitButton
          type="submit"
          value="RESET"
          onClick={submitForm}
          disabled={!isValid || isSubmitting}
        />
      </PasswordFormLayout>
      {ameliaIsRunning && (
        <Amelia
          ameliaVisibility={ameliaVisibility}
          toggleAmeliaVisibility={() => toggleAmeliaVisibility(!ameliaVisibility)}
        />
      )}
    </Fragment>
  );
};
