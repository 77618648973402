import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import React from 'react';
import moment, { Moment } from 'moment';
// @ts-ignore
import { DateRangePicker } from 'react-dates';
import MaterialIcon from 'material-icons-react';

import { GlobalStyle, theme } from '../common';

const StyledDateRangePicker = (props: any): any => {
  const handleOnDatesChange = (startDate: Moment | null, endDate: Moment | null) => {
    props.onDatesChange(startDate, endDate);
  };
  const handleOnClose = (startDate: Moment | null, endDate: Moment | null) => {
    props.onClose(startDate, endDate);
  };
  const handleOnFocusChange = (focusedInput: any) => {
    props.onFocusChange(focusedInput);
  };
  const calculateIfOutsideRange = (day: any) => {
    return (
      (props.pastLimit != null &&
        day
          .endOf('day')
          .isBefore(moment(new Date(), 'MM-DD-YYYY').subtract(props.pastLimit, 'days'))) ||
      (props.futureLimit != null &&
        day.endOf('day').isAfter(moment(new Date(), 'MM-DD-YYYY').add(props.futureLimit, 'days')))
    );
  };

  return (
    <div
      style={{
        paddingLeft: 10,
        paddingRight: 24,
        border: `1px solid ${theme.colors.chatGrey}`,
        alignItems: 'center',
        display: 'flex',
        backgroundColor: theme.colors.white,
        borderRadius: 5,
        zIndex: 0,
      }}
    >
      <MaterialIcon icon="today" color="#505980" size={26} />
      <DateRangePicker
        startDate={props.startDate}
        startDateId={props.startDateId}
        endDate={props.endDate}
        endDateId={props.endDateId}
        onDatesChange={({ startDate, endDate }) => handleOnDatesChange(startDate, endDate)}
        focusedInput={props.focusedInput}
        onFocusChange={handleOnFocusChange}
        onClose={({ startDate, endDate }) => handleOnClose(startDate, endDate)}
        isOutsideRange={(day) => calculateIfOutsideRange(day)}
        noBorder={true}
        hideKeyboardShortcutsPanel={true}
        customArrowIcon={<div style={{ fontSize: 32 }}>-</div>}
      />
    </div>
  );
};

export default StyledDateRangePicker;
