export enum ROUTES {
  LOGIN = '/',
  PASSWORD = '/password',
  REQUEST_PASSWORD_RESET = '/request-reset',
  CHANGE_PASSWORD = '/change',
  RESET_PASSWORD = '/reset',
  SHIFTS = '/shifts',
  AIDES = '/aides',
  CAREGIVER_SHIFTS = '/caregiver-shifts',
  ADMIN_VIEW = '/admin-view',
  ALL_SHIFTS = '/all-shifts',
  QA_SHIFTS = '/qa-shifts',
  OPEN_SHIFTS = '/shifts/open',
  REJECTED = '/rejected',
  NEW_SUBMISSIONS = '/new-submissions',
  DASHBOARD = '/dashboard',
  MESSAGES = '/messages',
  ANALYTICS = '/analytics',
  MAP = '/map',
  ADMIN = '/admin',
  ONBOARD_USERS = '/onboarding',
  ADOPTION_RATES = '/adoption-rates',
  PATIENTS = '/patients',
  UNRECONCILED = '/unreconciled',
  FORMS = '/forms',
  ONE_LOGIN = '/oneLogin',
}

export enum LABELS {
  ADMIN = 'Admin',
  DASHBOARD = 'Dashboard',
  AIDES = 'Caregivers',
  PATIENTS = 'Patients',
  EVV_AIDES = 'EVV Caregivers',
  EVV_PATIENTS = 'EVV Patients',
  QA_SHIFTS = 'QA Workflow',
  MESSAGES = 'Messages',
  OPEN_SHIFTS = 'Shifts',
  MAP = 'Map',
  CAREGIVER_SHIFTS = 'Caregiver Shifts',
  ADMIN_VIEW = 'Admin Portal',
}

export enum ROUTE_PARAMS {
  EXPIRED = 'expired',
  OPEN = 'open',
  SENT = 'sent',
  APPLIED = 'applied',
  AWARDED = 'awarded',
  SCHEDULED = 'scheduled',
  ORIENT_REQUESTS = 'orient-requests',
}
