import { ISection, ITask, IFormButton, IOption } from './interfaces';
import { ControlTypes } from './constants';
import { getFormGeneratorVersion } from '../../../utils/app';
import { getErrorMessage } from '../../../common/useToastNotifications';

export const generateStateFromExistingJSON = (form: any) => {
  try {
    const tempState = JSON.parse(form);
    const flatFormStateFromExisiting = tempState.form.reduce(
      (accumulator: any, tempFormTuple: { id: string; value: any }) => {
        return { ...accumulator, [tempFormTuple.id]: tempFormTuple.value };
      },
      {}
    );

    // console.log('form state — ', tempState);
    // console.log('flatFormState — ', flatFormStateFromExisiting);

    return { ...tempState, form: flatFormStateFromExisiting };
  } catch (error) {
    console.log('error generating formstate from existing form — ', error);
    return null;
  }
};

const generatorVersion = getFormGeneratorVersion();

// STATE GENERATION
export const generateStateFromTemplate = (formTemplate: any) => {
  const { form, ...formMetaData } = formTemplate;

  try {
    const tempFormState = form.reduce((allSections: ISection, currentSection: ISection) => {
      return {
        ...allSections,
        ...formatSection(currentSection),
      };
    }, {});

    // FINAL FORM STATE
    return {
      form: { ...tempFormState },
      generatorVersion,
      ...formMetaData,
    };
  } catch (error) {
    console.log('error in form generation— ', error);
    console.log('generatorVersion — ', generatorVersion);
    return {
      generatorVersion,
      error: { message: 'No form could be found...', errorStack: getErrorMessage(error) },
    };
  }
};

const formatSection = (section: ISection) => {
  return {
    // this is a difference with how the Mobile / Web versions work,
    // Mobile requires the .tasks to be renamed to .data (for the SectionList component to work)
    ...section.tasks.reduce<any>((allTasks: ITask, tempTask: ITask) => {
      if (tempTask.controlType === ControlTypes.Textarea) {
        return tempTask.taskId ? { ...allTasks, [tempTask.taskId]: null } : { ...allTasks };
      } else if (tempTask.controlType === ControlTypes.Images) {
        return { ...allTasks };
      } else {
        return {
          ...allTasks,
          ...formatTask(tempTask),
        };
      }
    }, {}),
  };
};

// this combines the options and buttons into a flat structure
const formatTask = (task: ITask) => {
  return {
    ...task.options.reduce<any>((allOptions: IOption, currentOption: IOption) => {
      return {
        ...allOptions,
        [currentOption.id]: null,
      };
    }, {}),
    ...task.buttons.reduce<any>((allButtons: IFormButton, currentButton: IFormButton) => {
      return {
        ...allButtons,
        [currentButton.id]: null,
      };
    }, {}),
  };
};

// in use with the ToS
export const generateStateFromSectionlessTemplate = (
  formTemplate: any,
  defaultValue: boolean = false
) => {
  const { form, ...formMetaData } = formTemplate;
  const tempFormState = {
    ...form.reduce((allItems: any, currentItem: any) => {
      return {
        ...allItems,
        [currentItem.id]: defaultValue,
      };
    }, {}),
  };
  return tempFormState;
};

// Target State
// {
//   form: {
//     "Hygeine/Grooming.Bathing.Performed": { value: true, id: '', timestamp, ...},
//     "Hygeine/Grooming.Bathing.Refused": false,
//     "Hygeine/Grooming.Bathing.Chair": true,
//     "Hygeine/Grooming.Bathing.Tub": false,
//     "Hygeine/Grooming.Bathing.Shower": false,
//   },
//   ...formMetaData
// }
