import MaterialIcon from 'material-icons-react';

import React, { Component } from 'react';
import styled from 'styled-components';
import { ShiftPanelHeader, ShiftPanelHeaderLeft, ShiftPanelHeaderText } from './ShiftSidebar';
import { connect } from 'react-redux';
import {
  setCurrentShift as setCurrentShiftAction,
  clearCurrentShift as clearCurrentShiftAction,
} from '../actions';
import { ReasonDropdown } from '../components/ReasonDropdown';
import DatePicker from 'react-datepicker';
import { AddNewShiftFooter } from '../components/AddNewShiftFooter';
import moment from 'moment';
import {
  createClockInOut,
  getDvrByClockInOutReviewId,
  rejectShift as saveNewShiftWithReason,
} from '../../api';
import { getBlankDailyVisitRecordForClockOut } from '../../api/form';
import { getTotalHours } from '../../utils/shifts';
import { TotalHoursComponent } from '../ShiftDetails/ShiftDetails';

const SECTION_SPACER_HEIGHT = 24;

type IProps = {
  onClose: () => void;
  templateShift: any;
  user: any;
  timeInCorrectionOptions: any;
  timeOutCorrectionOptions: any;
  newShiftCorrectionOptions: any;
  refreshData?: () => void;
};

type IState = {
  newShiftReason: any;
  clockInReason: any;
  clockOutReason: any;
  clockInTime: Date | null;
  clockOutTime: Date | null;
  isSubPanelOpen: boolean;
  subPanelComponent: any;
  invalidReasons: string[];
  showInvalidReasons: boolean;
  isLoading: boolean;
};

class NewShiftSidebar extends Component<IProps, IState> {
  state: IState = {
    newShiftReason: {} as any,
    clockInReason: {} as any,
    clockOutReason: {} as any,
    clockInTime: null,
    clockOutTime: null,
    isSubPanelOpen: false,
    subPanelComponent: null,
    invalidReasons: [],
    showInvalidReasons: false,
    isLoading: false,
  };

  constructor(props: IProps) {
    super(props);
    this.setNewShiftReason = this.setNewShiftReason.bind(this);
    this.setClockInReason = this.setClockInReason.bind(this);
    this.setClockOutReason = this.setClockOutReason.bind(this);
    this.setClockInTime = this.setClockInTime.bind(this);
    this.setClockOutTime = this.setClockOutTime.bind(this);
    this.openSubPanel = this.openSubPanel.bind(this);
    this.closeSubPanel = this.closeSubPanel.bind(this);
    this.getConfirmMessage = this.getConfirmMessage.bind(this);
    this.setShowInvalidReasons = this.setShowInvalidReasons.bind(this);
    this.handleSaveNewReview = this.handleSaveNewReview.bind(this);
  }

  private handleSaveNewReview = async () => {
    this.setState({ isLoading: true });
    // Get data to send to server.
    const { templateShift } = this.props;
    const { clockInTime, clockOutTime, clockInReason, clockOutReason, newShiftReason } = this.state;
    const blankDailyVisitRecord = await getBlankDailyVisitRecordForClockOut();

    // Format data for server.
    const formattedClockInTime = moment(clockInTime!).format();
    const formattedClockOutTime = moment(clockOutTime!).format();
    const formattedBlankDailyVisitRecord = JSON.stringify(blankDailyVisitRecord);
    const clockInOutBody = {
      nurseId: templateShift.aide.aideId,
      patientId: templateShift.patient.id,
      clockInTime: formattedClockInTime,
      clockOutTime: formattedClockOutTime,
      blankDailyVisitRecord: formattedBlankDailyVisitRecord,
    };

    // Create the clockInOut.
    const clockInOut = await createClockInOut(clockInOutBody);
    // Use the clockInOut to access the full, server-treated shift.
    const hydratedShift = await getDvrByClockInOutReviewId(clockInOut.clockInId);
    // Get the clockInOut from the shift.`
    const review = hydratedShift.body.clockInOut;
    // Append the reason codes.
    review.clockInTimeCorrectionId = clockInReason.value;
    review.clockOutTimeCorrectionId = clockOutReason.value;
    // Save clockInOut with reason codes. Last param 'true' to override default to reject status.
    await saveNewShiftWithReason(review, [newShiftReason], true);
    this.setState({ isLoading: false });
  };

  closeSubPanel() {
    this.setState({
      subPanelComponent: null,
      isSubPanelOpen: false,
    });
  }

  openSubPanel(subPanelComponent: any) {
    this.setState({ subPanelComponent, isSubPanelOpen: true });
  }

  setClockInTime(clockInTime: Date) {
    this.setState({ clockInTime });
  }
  setClockOutTime(clockOutTime: Date) {
    this.setState({ clockOutTime });
  }
  setNewShiftReason(newShiftReason: any) {
    this.setState({ newShiftReason });
  }
  setClockInReason(clockInReason: any) {
    this.setState({ clockInReason });
  }
  setClockOutReason(clockOutReason: any) {
    this.setState({ clockOutReason });
  }
  setShowInvalidReasons(showInvalidReasons: boolean) {
    this.setState({ showInvalidReasons });
  }

  getConfirmMessage() {
    if (this.state.isLoading) return 'Processing...';
    const { user, templateShift } = this.props;
    const { clockInTime, clockOutTime } = this.state;

    const userString = `${user.givenName} ${user.familyName}`;
    const aideString = templateShift.aide.aideName;
    const dateString = clockInTime
      ? moment(clockInTime!).format('MM/DD/YYYY')
      : '[Error retrieving shift date]';
    const clockInString = clockInTime
      ? moment(clockInTime!).format('h:mm A')
      : '[Error retrieving clock in time]';
    const clockOutString = clockOutTime
      ? moment(clockOutTime!).format('h:mm A')
      : '[Error retrieving clock out time]';

    return `I, ${userString}, spoke with ${aideString} and verified he/she worked on ${dateString} from ${clockInString} to ${clockOutString}.`;
  }

  renderCustomDatePickerInput({ value, disabled }: { value: any; disabled: boolean }) {
    return disabled ? (
      <Row>
        <DisabledDatePickerInput>
          <DatePickerBlock>{moment(value).format('MM/DD/YYYY')}</DatePickerBlock>
          <DatePickerBlock>{moment(value).format('hh:mm A')}</DatePickerBlock>
        </DisabledDatePickerInput>
      </Row>
    ) : (
      <>
        <DatePickerInput>
          <DatePickerBlock>{moment(value).format('MM/DD/YYYY')}</DatePickerBlock>
          <DatePickerBlock>{moment(value).format('hh:mm A')}</DatePickerBlock>
        </DatePickerInput>
      </>
    );
  }

  render() {
    const {
      setNewShiftReason,
      setClockInReason,
      setClockOutReason,
      setClockInTime,
      setClockOutTime,
      setShowInvalidReasons,
      openSubPanel,
      closeSubPanel,
      getConfirmMessage,
      renderCustomDatePickerInput,
      handleSaveNewReview,
    } = this;
    const {
      onClose,
      templateShift,
      timeInCorrectionOptions,
      timeOutCorrectionOptions,
      newShiftCorrectionOptions,
      refreshData,
    } = this.props;
    const {
      newShiftReason,
      clockInReason,
      clockOutReason,
      clockInTime,
      clockOutTime,
      isSubPanelOpen,
      subPanelComponent,
      showInvalidReasons,
      isLoading,
    } = this.state;

    function formValidator() {
      let invalidReasons = [];

      if (!newShiftReason.value) {
        invalidReasons.push('Please select a reason for adding a new shift manually.');
      }
      if (!clockInReason.value) {
        invalidReasons.push('Please select a reason for adjusting clock in time.');
      }
      if (!clockOutReason.value) {
        invalidReasons.push('Please select a reason for adjusting clock out time.');
      }
      if (!clockInTime) {
        invalidReasons.push('Please supply a clock in time.');
      }
      if (!clockOutTime) {
        invalidReasons.push('Please supply a clock out time.');
      }
      if (clockInTime && clockOutTime && moment(clockInTime!).isAfter(moment(clockOutTime!))) {
        invalidReasons.push('Clock in time is after clock out time.');
      }

      if (invalidReasons.length) {
        return { isValid: false, invalidReasons };
      }

      return { isValid: true, invalidReasons };
    }

    const confirmMessage = getConfirmMessage();
    const totalEditedHours: number = getTotalHours(
      clockOutTime || new Date(),
      clockInTime || new Date()
    );
    const warnEditHours: boolean = Number(totalEditedHours) < 3 || Number(totalEditedHours) > 13;

    return (
      <SidebarContainer>
        {!isSubPanelOpen ? (
          <>
            <ShiftPanelHeader>
              <NewShiftPanelHeaderLeft
                onClick={() => {
                  onClose();
                }}
              >
                <MaterialIcon icon={'close'} key={'close'} />
                <ShiftPanelHeaderText>CANCEL</ShiftPanelHeaderText>
              </NewShiftPanelHeaderLeft>
            </ShiftPanelHeader>

            <ShiftPanelContent>
              {/* Patient / Aide info */}
              <NewShiftHeader>Add New Shift</NewShiftHeader>
              <NewShiftSubheaderContainer>
                <NewShiftSubheader>
                  <NewShiftSubheaderLabel>Caregiver</NewShiftSubheaderLabel>
                  <NewShiftSubheaderContent>{templateShift.aide.aideName}</NewShiftSubheaderContent>
                </NewShiftSubheader>
                <NewShiftSubheader>
                  <NewShiftSubheaderLabel>Patient</NewShiftSubheaderLabel>
                  <NewShiftSubheaderContent>{templateShift.patient.name}</NewShiftSubheaderContent>
                </NewShiftSubheader>
              </NewShiftSubheaderContainer>

              <div style={{ height: SECTION_SPACER_HEIGHT }} />

              <TotalHoursComponent
                warnEditHours={warnEditHours}
                isEditMode={true}
                totalEditedHours={totalEditedHours}
                totalHours={0}
              />
              <div style={{ height: SECTION_SPACER_HEIGHT }} />

              <FormContainer>
                {/* Clock In Reason */}
                <Row>
                  <RowLabel>Clock In</RowLabel>
                  <RowContent>
                    <ReasonDropdown
                      reasonOptions={timeInCorrectionOptions}
                      reason={clockInReason}
                      setReason={(clockInReason: any) => {
                        setClockInReason(clockInReason);
                      }}
                    ></ReasonDropdown>
                  </RowContent>
                </Row>

                {/* Clock In Time */}
                <Row>
                  <RowLabel></RowLabel>
                  <RowContent>
                    <DatePicker
                      timeIntervals={5}
                      disabled={!clockInReason.value}
                      selected={clockInTime ? clockInTime : new Date()}
                      onChange={(date: Date) => {
                        setClockInTime(date);
                      }}
                      maxDate={new Date()}
                      showTimeSelect
                      timeInputLabel="Time:"
                      customInput={
                        <CustomTimeInput data-cy={'aides-new-shift-ci-time'}>
                          {renderCustomDatePickerInput({
                            value: clockInTime ? clockInTime : new Date(),
                            disabled: !clockInReason.value,
                          })}
                        </CustomTimeInput>
                      }
                    />
                  </RowContent>
                </Row>

                {/* Clock Out Reason */}
                <Row>
                  <RowLabel>Clock Out</RowLabel>
                  <RowContent>
                    <ReasonDropdown
                      reasonOptions={timeOutCorrectionOptions}
                      reason={clockOutReason}
                      setReason={(clockOutReason: any) => {
                        setClockOutReason(clockOutReason);
                      }}
                    ></ReasonDropdown>
                  </RowContent>
                </Row>

                {/* Clock Out Time */}
                <Row>
                  <RowLabel></RowLabel>
                  <RowContent>
                    <DatePicker
                      timeIntervals={5}
                      disabled={!clockOutReason.value}
                      selected={clockOutTime ? clockOutTime : new Date()}
                      onChange={(date: Date) => {
                        setClockOutTime(date);
                      }}
                      maxDate={new Date()}
                      showTimeSelect
                      timeInputLabel="Time:"
                      customInput={
                        <CustomTimeInput data-cy={'aides-new-shift-co-time'}>
                          {renderCustomDatePickerInput({
                            value: clockOutTime ? clockOutTime : new Date(),
                            disabled: !clockOutReason.value,
                          })}
                        </CustomTimeInput>
                      }
                    />
                  </RowContent>
                </Row>

                <FieldHeader>Why is this shift being added manually?</FieldHeader>
                <ReasonDropdown
                  reasonOptions={newShiftCorrectionOptions}
                  reason={newShiftReason}
                  setReason={(newShiftReason: any) => {
                    setNewShiftReason(newShiftReason);
                  }}
                ></ReasonDropdown>

                <Row>
                  {isLoading && <>Processing...</>}
                  <AddNewShiftFooter
                    confirmAction={() => handleSaveNewReview()}
                    confirmMessage={confirmMessage}
                    disabled={!formValidator().isValid}
                    onConfirm={openSubPanel}
                    onBack={closeSubPanel}
                    onClose={() => {
                      onClose();
                    }}
                    refreshData={refreshData}
                  />
                  {!formValidator().isValid && (
                    <AddNewShiftFooterHoverHelp
                      onClick={() => setShowInvalidReasons(!this.state.showInvalidReasons)}
                    >
                      Why is this disabled?
                      <MaterialAdjustor>
                        <MaterialIcon icon="help" />
                      </MaterialAdjustor>
                    </AddNewShiftFooterHoverHelp>
                  )}
                </Row>

                {!formValidator().isValid && showInvalidReasons && (
                  <RequiredActions>
                    {formValidator().invalidReasons.map((invalidReason: string, index: number) => {
                      return <RequiredActionItem key={index}>{invalidReason}</RequiredActionItem>;
                    })}
                  </RequiredActions>
                )}
              </FormContainer>
            </ShiftPanelContent>
          </>
        ) : (
          subPanelComponent
        )}
      </SidebarContainer>
    );
  }
}

const placeholder = [{ value: 1, label: 'Placeholder options' }];
const mapStateToProps = ({ appData, user }: any) => {
  return {
    // Placeholder
    // timeInCorrectionOptions: placeholder,
    // timeOutCorrectionOptions: placeholder,
    // newShiftCorrectionOptions: placeholder,

    // The REAL stuff from appData
    timeInCorrectionOptions: appData.timeInCorrectionOptions,
    timeOutCorrectionOptions: appData.timeOutCorrectionOptions,
    newShiftCorrectionOptions: appData.newShiftReasonOptions,

    user: user.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCurrentShift: (shift: any) => dispatch(setCurrentShiftAction(shift)),
    clearCurrentShift: () => dispatch(clearCurrentShiftAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewShiftSidebar);

const RequiredActions = styled.ul``;

const RequiredActionItem = styled.li`
  font-style: italic;
  color: red;
`;

const SidebarContainer = styled.div`
  position: relative;
  overflow-y: auto;
  justify-content: space-between;
  max-width: 40vw;
  min-width: 600px;
  font-size: 1.2rem;
`;

const ShiftPanelContent = styled.div`
  padding: 0.5rem 1.5rem;
`;

const NewShiftHeader = styled.div`
  font-size: 2rem;
  font-weight: bold;
  padding-bottom: 1rem;
`;

const NewShiftSubheader = styled.div`
  font-size: 1.5rem;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const NewShiftSubheaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const NewShiftSubheaderLabel = styled.div`
  font-weight: bold;
`;

const FormContainer = styled.div`
  padding-top: 3rem;
`;

const DatePickerInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  border: 1px solid #00000077;
  border-radius: 5px;
  cursor: pointer;
`;

const DisabledDatePickerInput = styled(DatePickerInput)`
  cursor: not-allowed;
  color: #919191;
  background-color: rgba(219, 219, 219);
`;

const DatePickerBlock = styled.div`
  flex: 1;
  padding: 0.3rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.2rem 0;
`;

const RowLabel = styled.div`
  flex: 1;
`;

const RowContent = styled.div`
  flex: 2;
`;

const FieldHeader = styled.div`
  padding: 1rem 0 0.3rem 0;
  font-style: italic;
`;

const NewShiftSubheaderContent = styled.div``;

const CustomTimeInput = styled.div``;

const NewShiftPanelHeaderLeft = styled(ShiftPanelHeaderLeft)``;

const MaterialAdjustor = styled.span`
  position: relative;
  top: 5px;
`;

const AddNewShiftFooterHoverHelp = styled.div`
  font-size: 0.8rem;
  font-style: italic;
  cursor: help;
`;
