// @ts-ignore
import FormData from 'form-data';
import { ShiftClockInOutStatus } from '../common';
import moment, { Moment } from 'moment';
import { get, post, postNullHeader, put } from './apiCallHelper';
import { formatRejectionReasons } from './reasonCodes';

const Source = 'Scheduler';

export const getAllAides = async (nurseId: number = 0, patientId: number = 0) => {
  let queryParams = '';
  if (nurseId > 0) {
    queryParams += `?nurseId=${nurseId}`;
  }

  if (patientId > 0) {
    if (queryParams.length > 0) {
      queryParams += '&';
    }
    queryParams += `patientId=${patientId}`;
  }
  const aidesResponse = await get(`/api/ClockInOut/Review${queryParams}`);
  return aidesResponse.body;
};

export const getSnapShot = async (nurseId: number = 0, patientId: number = 0) => {
  const aidesResponse = await get(`/api/ClockInOut/SnapShot`);
  return aidesResponse.body;
};

export const getQAShifts = async () => {
  const aidesResponse = await get(`/api/ClockInOut/QA`);
  return aidesResponse.body;
};

export interface ISearchReviewsInput {
  searchTerm: string;
  searchStartDate: Date;
  searchEndDate: Date;
}

export function createSearchReviewsInput(
  startDate: Date,
  endDate: Date,
  term: string
): ISearchReviewsInput {
  return {
    searchStartDate: startDate,
    searchEndDate: endDate,
    searchTerm: term,
  };
}

export const getUnmatchedClockInOutsForPatient = async (
  patientId: number,
  startDate: Moment,
  endDate: Moment
) => {
  const startDateValue = startDate.format('YYYY-MM-DD');
  const endDateValue = endDate.format('YYYY-MM-DD');
  const clockInOuts = await get(
    `/api/ClockInOut/Unmatched?patientId=${patientId}` +
      `&startDate=${startDateValue}&endDate=${endDateValue}`
  );
  return clockInOuts.body;
};

export const searchReviews = async (searchParams: ISearchReviewsInput) => {
  const { searchStartDate, searchEndDate, searchTerm } = searchParams;
  const maxDate = moment();
  const minDate = moment().subtract(30, 'days');
  const firstDay = searchStartDate
    ? moment(searchStartDate).format('YYYY-MM-DD')
    : minDate.format();
  const lastDay = searchEndDate ? moment(searchEndDate).format('YYYY-MM-DD') : maxDate.format();
  const term = searchTerm;
  const queryString = `firstDay=${firstDay}&lastDay=${lastDay}&term=${term}`;
  try {
    const searchResponse = await get(`/api/ClockInOut/Search?${queryString}`);
    return searchResponse.body;
  } catch (e) {
    console.error(e);
  }
};

export const approveShiftStatus = async (clockInOut: any, rejectionReasons: any) => {
  const formattedRejectionReasons = formatRejectionReasons(clockInOut.id, rejectionReasons);
  const tempFinalRejectionReasons = formattedRejectionReasons ? formattedRejectionReasons : null;

  console.log('clockInOut — ', clockInOut);
  const statusUpdateResponse = await put(`/api/ClockInOut`, {
    body: {
      ...clockInOut,
      clockInOutRejectionReasons: tempFinalRejectionReasons,
      status: ShiftClockInOutStatus.Approved,
    },
  });
  console.log('statusUpdateRes — ', statusUpdateResponse);
  if (statusUpdateResponse.status >= 300) {
    throw new Error('Something went wrong approving your shift, please try again.');
  }
};

export const getAllRejections = async () => {
  const rejectionsResponse = await get(`/api/ClockInOut/Rejections`);
  return rejectionsResponse.body;
};

// clockInOutReasons empty array will blow them away
// null will leave them BE as is
export const rejectShift = async (
  clockInOut: any,
  rejectionReasons: any,
  overrideRejectStatus: boolean = false
) => {
  const formattedRejectionReasons = formatRejectionReasons(clockInOut.id, rejectionReasons);
  const tempFinalRejectionReasons = formattedRejectionReasons ? formattedRejectionReasons : null;
  const status = overrideRejectStatus ? clockInOut.status : ShiftClockInOutStatus.Rejected;

  const statusUpdateResponse = await put(`/api/ClockInOut`, {
    body: {
      ...clockInOut,
      clockInOutRejectionReasons: tempFinalRejectionReasons,
      status,
    },
  });
  console.log('status update response', statusUpdateResponse);
  if (statusUpdateResponse.status >= 300) {
    throw new Error('Something went wrong approving your shift, please try again.');
  }
};

export const getSingleAide = async (clockInOutId: number) =>
  await get(`/api/Form/DailyVisit/${clockInOutId}`);

export const saveClockInOut = async (body: any) => {
  const messageResponse = await put('api/ClockInOut', { body });
  return messageResponse.body;
};

export const createClockInOut = async (body: any) => {
  try {
    const clockInForm = { ClockIn: {} };
    let clockInResponse;
    let clockOutResponse;

    clockInForm.ClockIn = {
      PatientID: body.patientId,
      NurseProfileID: body.nurseId,
      LocalTime: body.clockInTime,
      ClockInNote: '',
      HasSignature: false,
      Source: Source,
    };

    clockInResponse = await post('api/ClockInOut/v2/ClockIn', {
      body: clockInForm,
    });

    const tempClockOutForm = new FormData();

    tempClockOutForm.append('ClockOut.ClockInId', clockInResponse.body.clockInId);
    tempClockOutForm.append('ClockOut.LocalTime', body.clockOutTime);
    tempClockOutForm.append('ClockOut.FormData', body.blankDailyVisitRecord);
    tempClockOutForm.append('ClockOut.HasNurseSignature', 'false');
    tempClockOutForm.append('ClockOut.HasPatientSignature', 'false');
    tempClockOutForm.append('ClockOut.Source', Source);

    clockOutResponse = await postNullHeader('api/ClockInOut/ClockOut', {
      body: tempClockOutForm,
    });

    return clockOutResponse.body;
  } catch (e) {
    console.log(e);
  }
};

export const getAuditLog = async (clockInOutId: number) => {
  const auditLog = await get(`/api/ClockInOut/AuditLogs/${clockInOutId}`);
  return auditLog;
};
