import { types } from './constants';
import { TabNames } from './components/Tabs';

const tabsInitialState = TabNames.OpenShifts;

export const tabsReducer = (
  state = tabsInitialState,
  { type, payload }: { type: string; payload: TabNames }
) => {
  switch (type) {
    case types.SET_CURRENT_TAB:
      return payload;
    default:
      return state;
  }
};

const initialState = [{ filterType: 'default', filterValue: '' }];

export const openShiftsFiltersReducer = (
  state = [...initialState],
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case types.ADD_FILTER_OPEN_SHIFTS:
      return [...state, ...initialState];
    case types.REMOVE_FILTER_OPEN_SHIFTS:
      return [...state.slice(0, payload), ...state.slice(payload + 1)];
    case types.SET_FILTER_OPEN_SHIFTS:
      return [
        ...state.slice(0, payload.index),
        { ...state[payload.index], ...payload.filter },
        ...state.slice(payload.index + 1),
      ];
    case types.CLEAR_FILTERS_OPEN_SHIFTS:
      return [...initialState];
    default:
      return state;
  }
};

export const sentShiftsFiltersReducer = (
  state = [...initialState],
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case types.ADD_FILTER_SENT_SHIFTS:
      return [...state, ...initialState];
    case types.REMOVE_FILTER_SENT_SHIFTS:
      return [...state.slice(0, payload), ...state.slice(payload + 1)];
    case types.SET_FILTER_SENT_SHIFTS:
      return [
        ...state.slice(0, payload.index),
        { ...state[payload.index], ...payload.filter },
        ...state.slice(payload.index + 1),
      ];
    case types.CLEAR_FILTERS_SENT_SHIFTS:
      return [...initialState];
    default:
      return state;
  }
};
export const appliedShiftsFiltersReducer = (
  state = [...initialState],
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case types.ADD_FILTER_APPLIED_SHIFTS:
      return [...state, ...initialState];
    case types.REMOVE_FILTER_APPLIED_SHIFTS:
      return [...state.slice(0, payload), ...state.slice(payload + 1)];
    case types.SET_FILTER_APPLIED_SHIFTS:
      return [
        ...state.slice(0, payload.index),
        { ...state[payload.index], ...payload.filter },
        ...state.slice(payload.index + 1),
      ];
    case types.CLEAR_FILTERS_APPLIED_SHIFTS:
      return [...initialState];
    default:
      return state;
  }
};
export const awardedShiftsFiltersReducer = (
  state = [...initialState],
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case types.ADD_FILTER_AWARDED_SHIFTS:
      return [...state, ...initialState];
    case types.REMOVE_FILTER_AWARDED_SHIFTS:
      return [...state.slice(0, payload), ...state.slice(payload + 1)];
    case types.SET_FILTER_AWARDED_SHIFTS:
      return [
        ...state.slice(0, payload.index),
        { ...state[payload.index], ...payload.filter },
        ...state.slice(payload.index + 1),
      ];
    case types.CLEAR_FILTERS_AWARDED_SHIFTS:
      return [...initialState];
    default:
      return state;
  }
};

export const confirmedShiftsFiltersReducer = (
  state = [...initialState],
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case types.ADD_FILTER_CONFIRMED_SHIFTS:
      return [...state, ...initialState];
    case types.REMOVE_FILTER_CONFIRMED_SHIFTS:
      return [...state.slice(0, payload), ...state.slice(payload + 1)];
    case types.SET_FILTER_CONFIRMED_SHIFTS:
      return [
        ...state.slice(0, payload.index),
        { ...state[payload.index], ...payload.filter },
        ...state.slice(payload.index + 1),
      ];
    case types.CLEAR_FILTERS_CONFIRMED_SHIFTS:
      return [...initialState];
    default:
      return state;
  }
};

export const orientRequestsFiltersReducer = (
  state = [...initialState],
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case types.ADD_FILTER_ORIENT_REQUESTS:
      return [...state, ...initialState];
    case types.REMOVE_FILTER_ORIENT_REQUESTS:
      return [...state.slice(0, payload), ...state.slice(payload + 1)];
    case types.SET_FILTER_ORIENT_REQUESTS:
      return [
        ...state.slice(0, payload.index),
        { ...state[payload.index], ...payload.filter },
        ...state.slice(payload.index + 1),
      ];
    case types.CLEAR_FILTERS_ORIENT_REQUESTS:
      return [...initialState];
    default:
      return state;
  }
};
