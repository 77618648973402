import React, { ReactNode, useState } from 'react';
import styled, { css, StyledComponentProps } from 'styled-components';
import MaterialIcon from 'material-icons-react';

import { theme as aveannaTheme } from './styles';
import { device } from './device/deviceSizes';
import Check from '../assets/check.png';

interface IPasswordInput {
  name: string;
  value: string;
  onChange: any;
  placeholder?: string;
  errors?: any;
}

export const StyledPasswordInput = ({
  name,
  value,
  onChange,
  placeholder = 'password',
  errors,
}: IPasswordInput) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  return (
    <StyledInputDiv error={errors && errors[name]}>
      <RawPasswordInput
        type={isVisible ? undefined : 'password'}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        autoComplete="on"
      />
      <VisibilityIconContainer onClick={() => setIsVisible(!isVisible)}>
        <MaterialIcon
          icon={isVisible ? 'visibility_off' : 'visibility'}
          color={aveannaTheme.colors.aveannaDarkBlueHex}
        />
      </VisibilityIconContainer>
    </StyledInputDiv>
  );
};

const RawPasswordInput = styled.input`
  display: flex;
  flex: 1;
  border: none;
  color: ${({ theme }) => theme.colors.aveannaDarkBlue};
  font-size: ${({ theme }) => theme.fontSizeMd};

  @media ${device.mobileL} {
    height: 36px;
    font-size: ${({ theme }) => theme.fontSizeMd};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.aveannaFadedBlue};
  }
`;

const VisibilityIconContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 6;
  cursor: pointer;
`;

interface IStyledInputDivProps {
  error: boolean;
}

// Same visual appearance as the styled text input
// So we can nest an icon in there without losing the look
export const StyledInputDiv = styled.div<IStyledInputDivProps>`
  display: flex;
  align-items: center;
  height: 36px;
  padding: 6px 12px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.aveannaDarkBlue};
  color: ${({ theme }) => theme.colors.aveannaDarkBlue};
  font-size: ${({ theme }) => theme.fontSizeMd};
  margin-bottom: 12px;

  /* above mobile L */
  @media ${device.mobileL} {
    width: 386px;
    height: 36px;
    font-size: ${({ theme }) => theme.fontSizeMd};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.aveannaFadedBlue};
  }

  /* ERROR STATE */
  ${(props) =>
    props.error &&
    css`
      /* border-color: ${({ theme }) => theme.colors.error}; */
      border-color: red;
      border-width: 1px;
    `}
`;

interface IStyledInputProps {
  error?: boolean;
}

export const StyledTextInput = styled.input<IStyledInputProps>`
  height: 36px;
  padding: 6px 12px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.aveannaDarkBlue};
  color: ${({ theme }) => theme.colors.aveannaDarkBlue};
  font-size: ${({ theme }) => theme.fontSizeMd};
  margin-bottom: 12px;

  /* above mobile L */
  @media ${device.mobileL} {
    width: 386px;
    height: 36px;
    font-size: ${({ theme }) => theme.fontSizeMd};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.aveannaFadedBlue};
  }

  /* ERROR STATE */
  ${(props) =>
    props.error &&
    css`
      /* border-color: ${({ theme }) => theme.colors.error}; */
      border-color: red;
      border-width: 1px;
    `}
`;

export const StyledInputLabel = styled.label`
  margin-bottom: 2px;
  color: ${({ theme }) => theme.colors.aveannaDarkBlue};
  font-size: ${({ theme }) => theme.fontSizeSm};

  @media ${device.mobileL} {
    width: 386px;
    font-size: ${({ theme }) => theme.fontSizeSm};
  }
`;

export const StyledInputGroup = styled.div`
  width: 90%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  margin-bottom: 24px;

  @media ${device.mobileL} {
    width: auto;
  }
`;

interface ICheckboxProps {
  checked: boolean;
  title?: string | null;
  onClick?: () => void;
  name?: string;
  align?: 'left' | 'right';
  children?: ReactNode;
  disabled?: boolean;
}

const Checkbox: React.SFC<ICheckboxProps> = (props) => {
  const { checked, onClick, title, align, ...rest } = props;
  return <div {...rest} />;
};

const CheckboxWrapper = styled(Checkbox)`
  display: flex;
  flex-shrink: 0;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.aveannaDarkBlue};
  background-color: ${({ checked, theme }) => (checked ? theme.colors.aveannaDarkBlue : 'white')};
  box-sizing: border-box;
  cursor: pointer;
  ${(props) =>
    props.align
      ? props.align === 'left'
        ? css`
            margin-right: 12px;
            order: 0;
          `
        : // is right aligned
          css`
            order: 1;
            margin-left: 12px;
          `
      : // default
        css`
          margin-left: 12px;
        `};
`;

const CheckboxControl = styled.div`
  display: flex;
`;

const CheckboxTitle = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.aveannaDarkBlue};
  font-size: ${({ theme }) => theme.fontSizeSm};
  align-items: center;
`;

export const StyledCheckbox = ({
  checked,
  title,
  onClick,
  align,
  disabled = false,
}: ICheckboxProps) => (
  <CheckboxControl onClick={onClick}>
    <CheckboxWrapper checked={checked} align={align} disabled={disabled}>
      {checked && <img src={Check} style={{ maxWidth: '100%' }} />}
    </CheckboxWrapper>
    {title && <CheckboxTitle style={{ display: 'flex' }}>{title}</CheckboxTitle>}
  </CheckboxControl>
);

interface ISearchBoxProps {
  placeholder?: string;
  onClick?: () => void;
  onChange?: () => void;
  children?: ReactNode;
}

const SearchBarContainer = styled.div`
  position: relative;
  display: flex;
`;

const SearchBar = styled.input`
  width: 100%;
  height: 30px;
  padding-left: 40px;
  outline: none;
  font-size: 16px;
`;

const SearchIcon = styled.i`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  height: 26px;
`;

export const StyledSearchBar = ({ placeholder, onClick, onChange }: ISearchBoxProps) => (
  <SearchBarContainer>
    <SearchIcon>
      <MaterialIcon icon="search" color="#505980" size={26} />
    </SearchIcon>
    <SearchBar placeholder={placeholder} />
  </SearchBarContainer>
);
