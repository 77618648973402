import { storeLocalNotifications } from '../utils';
import { NotificationReducerType, NotificationStateKeys } from './constants';

const notificationsInitialState = {
  [NotificationStateKeys.IS_OPEN_SHIFT_NOTIFIED]: false,
  [NotificationStateKeys.IS_SENT_SHIFT_NOTIFIED]: false,
  [NotificationStateKeys.IS_APPLIED_SHIFT_NOTIFIED]: false,
  [NotificationStateKeys.IS_AWARDED_SHIFT_NOTIFIED]: false,
  [NotificationStateKeys.IS_SCHEDULED_SHIFT_NOTIFIED]: false,
  [NotificationStateKeys.IS_ORIENTATION_REQUEST_NOFITIED]: false,
};

export const notificationReducer = (
  state = notificationsInitialState,
  { type, payload }: { type: NotificationReducerType; payload: any }
) => {
  switch (type) {
    case NotificationReducerType.SET_SHIFT_NOTIFICATION:
      const nextState = { ...state, ...payload };
      storeLocalNotifications(nextState);
      return nextState;
    case NotificationReducerType.SET_IS_OPEN_NOTIFIED:
      return {
        ...state,
        [NotificationStateKeys.IS_OPEN_SHIFT_NOTIFIED]: payload,
      };
    case NotificationReducerType.SET_IS_SENT_NOTIFIED:
      return {
        ...state,
        [NotificationStateKeys.IS_SENT_SHIFT_NOTIFIED]: payload,
      };
    case NotificationReducerType.SET_IS_APPLIED_NOTIFIED:
      return {
        ...state,
        [NotificationStateKeys.IS_APPLIED_SHIFT_NOTIFIED]: payload,
      };
    case NotificationReducerType.SET_IS_AWARDED_NOTIFIED:
      return {
        ...state,
        [NotificationStateKeys.IS_AWARDED_SHIFT_NOTIFIED]: payload,
      };
    default:
      return state;
  }
};
