import { types } from './constants';
import { interfaces, TimeCodeNames, LocationCodeNames, RejectionCodeNames } from '../common';
import { NewShiftCodeNames } from '../common/enums';

export interface IAppDataStore {
  appSettings: interfaces.IAppSetting[] | null;
  allReasonCodes: any;
  allReasonCodesOptions: interfaces.IReasonCodeObject[];
  allRejectionReasons: any;
  allRejectionReasonOptions: interfaces.IReasonCodeObject[];
  timeReasons: any;
  timeReasonOptions: interfaces.IReasonCodeObject[];
  locationReasons: any;
  locationReasonOptions: LocationCodeNames[];
  // Individual code options
  locationOutCorrectionOptions: interfaces.IReasonCodeObject[];
  locationInCorrectionOptions: interfaces.IReasonCodeObject[];
  timeInCorrectionOptions: interfaces.IReasonCodeObject[];
  timeOutCorrectionOptions: interfaces.IReasonCodeObject[];
  rejectionOverrideOptions: interfaces.IReasonCodeObject[];
  rejectionRejectOptions: interfaces.IReasonCodeObject[];
  featureFlags: interfaces.IFeatureFlags[];
}

const loginInitialState: IAppDataStore = {
  appSettings: null,
  // These include ALL grouped (so Location IN AND OUT, etc)
  allRejectionReasons: null,
  allRejectionReasonOptions: [],
  timeReasons: null,
  timeReasonOptions: [],
  locationReasons: null,
  locationReasonOptions: [],
  allReasonCodes: null,
  allReasonCodesOptions: [],
  // Individual code options
  locationOutCorrectionOptions: [],
  locationInCorrectionOptions: [],
  timeInCorrectionOptions: [],
  timeOutCorrectionOptions: [],
  rejectionOverrideOptions: [],
  rejectionRejectOptions: [],
  featureFlags: [],
};

export const appDataReducer = (
  state = loginInitialState,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case types.SET_ALL_REASON_CODES:
      return {
        ...state,
        ...payload,
        allReasonCodesOptions: [...generateOptions(payload.allReasonCodes)],
        allRejectionReasonOptions: generateOptions(payload.allRejectionReasons),
        locationReasonOptions: generateOptions(payload.locationReasons),
        timeReasonOptions: generateOptions(payload.timeReasons),
        locationInCorrectionOptions: generateOptions(
          payload[LocationCodeNames.EVVLocationInCorrection]
        ),
        locationOutCorrectionOptions: generateOptions(
          payload[LocationCodeNames.EVVLocationOutCorrection]
        ),
        timeInCorrectionOptions: generateOptions(payload[TimeCodeNames.EVVTimeInCorrection]),
        timeOutCorrectionOptions: generateOptions(payload[TimeCodeNames.EVVTimeOutCorrection]),
        rejectionOverrideOptions: generateOptions(payload[RejectionCodeNames.EVVRejectOverride]),
        rejectionRejectOptions: generateOptions(payload[RejectionCodeNames.EVVReject]),
        newShiftReasonOptions: generateOptions(payload[NewShiftCodeNames.EVVNew]),
      };
    case types.SET_TIME_REASONS:
      return {
        ...state,
        timeReasons: payload.timeReasons,
        timeReasonOptions: generateOptions(payload.timeReasonOptions),
      };
    case types.SET_LOCATION_REASONS:
      return {
        ...state,
        locationReasons: payload.locationReasons,
        locationReasonOptions: generateOptions(payload.locationReasonOptions),
      };
    case types.SET_REJECTION_REASONS:
      return {
        ...state,
        rejectionReasons: payload.rejectionReasons,
        rejectionReasonOptions: generateOptions(payload.rejectionReasonOptions),
      };
    case types.SET_NEW_SHIFT_REASONS:
      return {
        ...state,
        newShiftReasons: payload.newShiftReasons,
        newShiftReasonOptions: generateOptions(payload.newShiftReasonOptions),
      };
    case types.SET_APP_SETTINGS:
      return {
        ...state,
        appSettings: payload.appSettings ? [...payload.appSettings] : null,
      };
    case types.CLEAR_APP_DATA:
      return loginInitialState;
    default:
      return state;
  }
};

const generateOptions = (reasons: interfaces.IReasonCode[]) => {
  try {
    return reasons
      ? reasons.map((tempRejectionReason: interfaces.IReasonCode) => {
          return {
            value: tempRejectionReason.id,
            label: `${tempRejectionReason.code}: ${tempRejectionReason.text}`,
          };
        })
      : [];
  } catch (error) {
    console.warn('error generating options for reason codes — ', error);
    return [];
  }
};
