import React, { Component, Fragment } from 'react';

import Close from '../../assets/close.png';
import LeftArrow from '../../assets/left-arrow.png';
import { findIndex } from 'lodash';

import {
  requestNursesForShift,
  requestOrientationForShift,
  getNursesByShift,
  getOrientRequests,
} from '../../api';
import { ModalWrapper, Loading, interfaces } from '../../common';
import { NoNursesFound } from '../components/ModalBody';
import { NurseListItem, NurseListItemControlType } from '../../Nurses';
import { nurseOrientedCode } from '../enums';
import ReactGA from 'react-ga';

import {
  ContentWrapper,
  TitleWrapper,
  Title,
  ListItemWrapper,
  MobileCloseModalButton,
  CloseModalButton,
  ShiftInfoHeader,
  FooterWrapper,
  ToastWrapper,
  FooterButton,
  SubHeader,
  SubHeaderText,
  SelectAllButton,
} from '../components/ModalBody';
import { getErrorMessage } from '../../common/useToastNotifications';

enum ToastText {
  success = 'Open shift(s) sent',
  failed = 'Something Went Wrong',
  nurseNotOriented = 'The selected caregiver is not oriented to this patient',
  nurseAlreadyApplied = 'The selected caregiver has already applied for this shift',
}

interface IState {
  selectedNursesIds: interfaces.ID[];
  selectedOrientNurseIds: number[];
  orientationRequests: any[];
  orientedNurses: interfaces.INurse[];
  otherNurses: interfaces.INurse[];
  canApplyNurses: interfaces.INurse[];
  isLoading: boolean;
  isSubmitting: boolean;
  showToast: boolean;
  toastText: ToastText | string;
}

export class AvailableNurseListModal extends Component<any, IState> {
  constructor(props: any) {
    super(props);

    const { selectedNursesIds = [] } = this.props;

    this.state = {
      selectedNursesIds,
      selectedOrientNurseIds: [],
      orientationRequests: [],
      orientedNurses: [],
      otherNurses: [],
      canApplyNurses: [],
      isLoading: true,
      isSubmitting: false,
      toastText: ToastText.success,
      showToast: false,
    };
  }

  public componentDidMount = async () => {
    const { shift, nurse, closeModal } = this.props;
    const selectedNursesIds = [];
    let appliedAlreadyIds: number[] = [];

    try {
      const [nursesByShiftResponse, orientationResponse] = await this.getShiftData();
      const tempOrientationRequestedNurseProfileIds: interfaces.ID[] =
        this.getOrientedNurseProfileIds(orientationResponse);
      const [orientedNurses, otherNurses] = this.getNurses(
        nursesByShiftResponse,
        tempOrientationRequestedNurseProfileIds
      );

      if (shift.applications) {
        appliedAlreadyIds = shift.applications.map(({ applicant: { id } }: any) => id);
      }

      const canApplyNurses = orientedNurses.filter(
        ({ id }: any) => !appliedAlreadyIds.includes(id)
      );

      if (
        nurse &&
        findIndex(canApplyNurses, (tempNurse: interfaces.INurse) => tempNurse.id === tempNurse.id) >
          -1
      ) {
        // If a nurse is provided in props and can apply, preselect their ID
        selectedNursesIds.push(nurse.id);
      }

      this.setState({ orientedNurses, otherNurses, canApplyNurses, selectedNursesIds }, () => {
        this.setState({ isLoading: false });
        // Show a toast message if there is a nurse provided in props and they are unassignable
        this.showNurseMessage();
      });
    } catch (error) {
      this.setState({ showToast: true, toastText: ToastText.failed, isSubmitting: false });
      setTimeout(() => closeModal(), 1500);
    }
  };

  private getShiftData = async (): Promise<any> => {
    const { shift } = this.props;

    try {
      const [nursesByShiftResponse, orientationResponse] = await Promise.all([
        getNursesByShift(shift.key),
        getOrientRequests(shift.patient.officeLocationId),
      ]);

      return [nursesByShiftResponse, orientationResponse];
    } catch (error) {
      console.log('something went wrong — ');
    }
  };

  private getOrientedNurseProfileIds = (
    orientationResponse: interfaces.IOrientationRequest[]
  ): number[] | string[] => {
    const { shift } = this.props;

    const shiftOrientationRequests = orientationResponse.filter(
      (tempOrientationRequest: interfaces.IOrientationRequest) =>
        tempOrientationRequest.shiftKey === shift.key
    );

    const tempIds: number[] | string[] = shiftOrientationRequests.map(
      (tempOrientRequestedNurse: any) => {
        return tempOrientRequestedNurse.nurseProfileId;
      }
    );

    return tempIds;
  };

  private getNurses = (
    nursesByShiftResponse: interfaces.INurse[],
    orientationRequestedNurseProfileIds: interfaces.ID[]
  ) => {
    const orientedNurses = nursesByShiftResponse.filter((tempNurse: interfaces.INurse) => {
      return tempNurse.oriented === nurseOrientedCode.isOriented;
    });

    const tempOtherNurses = nursesByShiftResponse.filter((tempNurse: interfaces.INurse) => {
      return tempNurse.oriented !== nurseOrientedCode.isOriented;
    });

    const otherNurses = tempOtherNurses.map((tempNurse: interfaces.INurse) => {
      return {
        ...tempNurse,
        isOrientationRequestPending: orientationRequestedNurseProfileIds.includes(tempNurse.id),
      };
    });

    return [orientedNurses, otherNurses];
  };

  public addSelectedNurse = (id: number) => {
    this.setState((prevState: IState) => {
      prevState.selectedNursesIds.push(id);
      return prevState;
    });
  };

  public removeSelectedNurse = (id: number) => {
    this.setState((prevState: IState) => {
      return {
        ...prevState,
        selectedNursesIds: prevState.selectedNursesIds.filter((val: interfaces.ID) => val !== id),
      };
    });
  };

  public addSelectedOrientNurse = (id: number) => {
    this.setState((prevState: IState) => {
      prevState.selectedOrientNurseIds.push(id);
      return prevState;
    });
  };

  public removeSelectedOrientNurse = (id: number) => {
    this.setState((prevState: IState) => {
      return {
        ...prevState,
        selectedOrientNurseIds: prevState.selectedOrientNurseIds.filter(
          (val: number) => val !== id
        ),
      };
    });
  };

  public toggleSelection = (id: number) => {
    if (this.state.selectedNursesIds.includes(id)) {
      this.removeSelectedNurse(id);
      return;
    }
    this.addSelectedNurse(id);
  };

  public toggleOrientedSelection = (id: number) => {
    if (this.state.selectedOrientNurseIds.includes(id)) {
      this.removeSelectedOrientNurse(id);
      return;
    }
    this.addSelectedOrientNurse(id);
  };

  public selectAll = () => {
    const allIds = this.state.canApplyNurses.map((nurse: interfaces.INurse) => nurse.id);

    // filter out pending requests sent and just get their IDs
    const nonOrientedPendingNurses = this.state.otherNurses.filter(
      (nurse: interfaces.INurse) => !nurse.isOrientationRequestPending
    );
    const selectableNonOrientedIds = nonOrientedPendingNurses.map(
      (nurse: interfaces.INurse) => nurse.id
    );
    this.setState({ selectedNursesIds: allIds, selectedOrientNurseIds: selectableNonOrientedIds });
  };

  public renderOrientedNurseList = () => {
    if (this.state.canApplyNurses.length > 0) {
      return this.state.canApplyNurses.map((nurse: interfaces.INurse) => {
        const checked = this.state.selectedNursesIds.includes(nurse.id) ? true : false;
        return (
          <NurseListItem
            key={nurse.userId + nurse.externalId}
            nurse={nurse}
            controlType={NurseListItemControlType.CHECKBOX}
            checked={checked}
            handleClick={() => this.toggleSelection(nurse.id)}
          />
        );
      });
    } else {
      return <NoNursesFound />;
    }
  };

  public renderOtherNurseList = () => {
    const otherNurses = this.state.otherNurses;

    if (otherNurses.length > 0) {
      return otherNurses.map((nurse: interfaces.INurse) => {
        const checked = this.state.selectedOrientNurseIds.includes(nurse.id) ? true : false;
        return (
          <NurseListItem
            key={nurse.userId + nurse.externalId}
            controlType={
              nurse.isOrientationRequestPending
                ? NurseListItemControlType.ORIENTATION_REQUESTED
                : NurseListItemControlType.CHECKBOX
            }
            checked={checked}
            nurse={nurse}
            handleClick={
              nurse.isOrientationRequestPending
                ? null
                : () => this.toggleOrientedSelection(nurse.id)
            }
          />
        );
      });
    } else {
      return <NoNursesFound />;
    }
  };

  public renderSendShift = () => {
    const { isSubmitting, selectedNursesIds, selectedOrientNurseIds, showToast } = this.state;
    if (selectedNursesIds.length > 0 || (selectedOrientNurseIds.length > 0 && !showToast)) {
      return (
        <FooterWrapper id="modal-footer">
          <FooterButton disabled={isSubmitting} onClick={() => this.handleSendShift()}>
            Send Shift
          </FooterButton>
        </FooterWrapper>
      );
    }
  };

  public renderToast = () => {
    if (this.state.showToast) {
      return (
        <ToastWrapper failed={this.state.toastText !== ToastText.success}>
          {this.state.toastText}
        </ToastWrapper>
      );
    }
  };

  public showNurseMessage = () => {
    if (this.props.nurse) {
      // If a nurse is provided in props but is unavailable to apply, show a toast message
      const applications = this.props.shift.applications;
      const nurseId = this.props.nurse.id;

      if (
        applications &&
        findIndex(applications, (app: any) => app.applicant.id === nurseId) > -1
      ) {
        this.setState({ showToast: true, toastText: ToastText.nurseAlreadyApplied });
      } else if (findIndex(this.state.otherNurses, (nurse: any) => nurse.id === nurseId) > -1) {
        this.setState({ showToast: true, toastText: ToastText.nurseNotOriented });
      }
    }
  };

  public handleSendShift = async () => {
    const { closeModal, shift } = this.props;
    const { selectedNursesIds, selectedOrientNurseIds } = this.state;
    let tempBulkNurseResponse;

    this.setState({ isSubmitting: true });

    ReactGA.event({
      category: 'Sent Shift',
      action: 'send shift from AvailabledNurseListModal.tsx',
      label: 'Send Shift',
      nonInteraction: false,
    });

    try {
      if (selectedNursesIds.length > 0) {
        tempBulkNurseResponse = await requestNursesForShift(shift.key, selectedNursesIds);
      }

      const nonOrientedRequests = selectedOrientNurseIds.map(async (nurseId: any) => {
        return await requestOrientationForShift(shift.key, nurseId);
      });

      const nonOrientedResponses = await Promise.all(nonOrientedRequests);

      if (tempBulkNurseResponse && tempBulkNurseResponse.errorIds) {
        throw new Error(this.getNurseErrorMessage(tempBulkNurseResponse.errorIds));
      }

      if (nonOrientedResponses.every((res: any) => res.status === 200)) {
        this.setState({ showToast: true, toastText: ToastText.success, isSubmitting: false });
        setTimeout(() => closeModal(), 1500);
      } else {
        throw new Error(ToastText.failed);
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      const errorMessageDisplayLength = errorMessage.length > 60 ? 1500 : 7500;
      this.setState({ showToast: true, toastText: errorMessage, isSubmitting: false });
      setTimeout(() => closeModal(), errorMessageDisplayLength);
    }
  };

  private getNurseErrorMessage = (errorIds: interfaces.ID[]) => {
    const tempNurses = this.getNursesFromErrorIds(errorIds);
    if (tempNurses && tempNurses.length > 0) {
      const tempNurseNames = tempNurses.map(
        (tempNurse: interfaces.INurse, currentIndex: number) => {
          return `${currentIndex === 0 ? '' : ' '}${tempNurse.user.givenName} ${
            tempNurse.user.familyName
          }`;
        }
      );
      return `Something went wrong requesting ${tempNurseNames} for the shift...`;
    }
    return `Something went wrong...`;
  };

  private getNursesFromErrorIds = (errorIds: interfaces.ID[]): interfaces.INurse[] | undefined => {
    const { orientedNurses } = this.state;

    const tempNurses: interfaces.INurse[] | undefined = errorIds.reduce(
      (accumulator: any, tempId: interfaces.ID) => {
        const tempNurse: interfaces.INurse | undefined = orientedNurses.find(
          (tempOrientedNurse: interfaces.INurse) => tempOrientedNurse.id === tempId
        );
        if (tempNurse) {
          accumulator.push(tempNurse);
        }
        return accumulator;
      },
      []
    );

    return tempNurses;
  };

  public render() {
    const { closeModal } = this.props;
    const { isLoading, canApplyNurses } = this.state;
    return (
      <ModalWrapper id="available-nurse-modal" closeModal={closeModal}>
        <ContentWrapper id="modal-content-wrapper">
          <TitleWrapper>
            <MobileCloseModalButton onClick={() => closeModal()}>
              <img src={LeftArrow} />
            </MobileCloseModalButton>
            <Title>Available Caregivers</Title>
            <CloseModalButton onClick={() => closeModal()}>
              <img src={Close} />
            </CloseModalButton>
          </TitleWrapper>
          <ShiftInfoHeader {...this.props.shift} />
          <ListItemWrapper>
            {isLoading ? (
              <Loading />
            ) : (
              <Fragment>
                <SubHeader>
                  <SubHeaderText>Oriented Caregivers</SubHeaderText>
                  {canApplyNurses.length > 0 && (
                    <SelectAllButton onClick={() => this.selectAll()}>
                      {canApplyNurses.length > 1 ? 'Select All' : 'Select'}
                    </SelectAllButton>
                  )}
                </SubHeader>
                {this.renderOrientedNurseList()}
                <SubHeader>
                  <SubHeaderText>Other Caregivers</SubHeaderText>
                </SubHeader>
                {this.renderOtherNurseList()}
              </Fragment>
            )}
          </ListItemWrapper>
          {this.renderSendShift()}
          {this.renderToast()}
        </ContentWrapper>
      </ModalWrapper>
    );
  }
}
