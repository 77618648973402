import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { BlobProvider, Document, Page, pdf, StyleSheet, Text, View } from '@react-pdf/renderer';
import JSZip from 'jszip';
import { StyledButton, theme } from '../../common';
import { getDvrByClockInOutReviewId } from '../../api';
import moment from 'moment';
// @ts-ignore
import { useToasts } from 'react-toast-notifications';
import { StyledMenuItem } from '../containers/Actions';
import { IFormSubmission, IFormTemplate } from './DVRForm/interfaces';
import { OPT_OUT_VERBIAGE } from '../../utils/constants';

const { colors } = theme;
const DATE_FORMAT = 'MMMM Do YYYY';
const TIME_FORMAT = 'h:mm a';

interface IProps {
  shift?: any;
  clockInOutId?: number | string;
}

interface ISignatureComponentInputs {
  name?: string;
  nurseId?: string;
  title: string;
  hasSignature: boolean;
}
const DEFAULT_PDF_FONT_SIZE = 12;

export const ShiftDetailsPrinter = ({ shift, clockInOutId }: IProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [document, setDocument] = useState<JSX.Element | null>(null);

  useEffect(() => {
    const createDocument = async () => {
      const tempShift = shift ? shift : await getShiftFromClockInOutId(clockInOutId);
      if (tempShift.form) {
        const tempFormHTML = generateHTMLFromFormData(
          tempShift.form.jsonData,
          tempShift.templateJson
        );
        const tempHeaderHTML = generateHeaderHTML(tempShift);
        const footerHTML = generateFooterHTML(tempShift);
        setDocument(
          generateMyDocument(
            tempHeaderHTML,
            tempFormHTML,
            footerHTML,
            tempShift.patient.searchField
          )
        );
      }
      setIsLoading(false);
    };
    createDocument();
  }, []);

  return isLoading ? (
    <StyledButton style={styles.buttonStyle} disabled isLoading>
      Loading...
    </StyledButton>
  ) : !document ? (
    <StyledButton style={styles.buttonStyle} disabled isLoading>
      Could not print document.
    </StyledButton>
  ) : (
    <BlobProvider document={document}>
      {({ url }) => (
        <StyledButton
          style={styles.buttonStyle}
          onClick={() => {
            window.open(url == null ? undefined : url, '_blank');
          }}
        >
          Print
        </StyledButton>
      )}
    </BlobProvider>
  );
};

export const zipAllDvrs = async (shifts: any[]) => {
  const zip = new JSZip();
  const erroredZips: string[] = [];
  await Promise.all(
    shifts.map(async (shift) => {
      const document = await printWithoutComponent({ shift, clockInOutId: shift.id });
      if (document) {
        const dateObj = moment(shift.adjustedClockOutTimeStamp);
        zip.file(
          `DVR-${dateObj.format('hhmmssa-MMDDYY')}-${shift.patientId}.pdf`,
          await pdf(document).toBlob()
        );
      } else {
        erroredZips.push(shift.id);
      }
    })
  );
  const file = await zip.generateAsync({ type: 'blob' });
  const url = window.URL.createObjectURL(file);
  window.open(url, 'DVRs.zip');
  return erroredZips;
};

export const printWithoutComponent = async ({ shift, clockInOutId }: IProps) => {
  const tempShift = shift && shift.form ? shift : await getShiftFromClockInOutId(clockInOutId);
  if (!tempShift || !tempShift.form) {
    console.log('No DVR found for clockInOutID ' + clockInOutId);
    return null;
  }
  const tempFormHTML = generateHTMLFromFormData(tempShift.form.jsonData, tempShift.templateJson);
  const tempHeaderHTML = generateHeaderHTML(tempShift);
  const footerHTML = generateFooterHTML(tempShift);
  return generateMyDocument(
    tempHeaderHTML,
    tempFormHTML,
    footerHTML,
    tempShift.patient.searchField
  );
};

const generateMyDocument = (
  headerHTML: JSX.Element,
  formHTML: JSX.Element[],
  footerHTML: JSX.Element,
  patientId: string
) => (
  <Document title={`DVR ${patientId} - ${new Date().toLocaleString()}.pdf`}>
    <Page size="A4" wrap>
      <View style={styles.pageView}>
        {headerHTML}
        {formHTML}
        {footerHTML}
      </View>
    </Page>
  </Document>
);

export const DVRActionComponent = (props: any) => {
  const [document, setDocument] = useState<JSX.Element | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const getDocument = async () => {
      setDocument(
        await printWithoutComponent({ shift: props.shift, clockInOutId: props.shift.id })
      );
      setIsLoading(false);
    };
    getDocument();
  }, []);
  return isLoading ? (
    <StyledMenuItem>Loading...</StyledMenuItem>
  ) : !document ? (
    <StyledMenuItem>Could not load document.</StyledMenuItem>
  ) : (
    <BlobProvider document={document}>
      {({ url }) => (
        <StyledMenuItem onClick={() => window.open(url == null ? undefined : url, '_blank')}>
          Print DVR
        </StyledMenuItem>
      )}
    </BlobProvider>
  );
};

// helpers
const getShiftFromClockInOutId = async (id?: number | string) => {
  if (!id) {
    throw new Error('No shift or shift ID provided');
  }

  const tempDVR = await getDvrByClockInOutReviewId(id);
  return tempDVR.body;
};

const generateHTMLFromFormData = (formData: string, templateJSON: string): JSX.Element[] => {
  if (!formData) {
    // No form short circuit
    throw new Error('No form data provided');
  }
  const tempFormData: IFormSubmission = JSON.parse(formData);
  const template: IFormTemplate = templateJSON && JSON.parse(templateJSON);
  if (tempFormData.form) {
    const tempSections = tempFormData.form.reduce(
      (accumulator: { [key: string]: any }, tempSection) => {
        // Section.Control.Task <--- ID FORMAT
        const tempSplitId = tempSection.id.split('.');
        const tempSectionName = tempSplitId[0];
        const tempSectionControl = tempSplitId[1];
        const tempSectionTask = tempSplitId[tempSplitId.length - 1];

        return accumulator[tempSectionName]
          ? {
              ...accumulator,
              [tempSectionName]: {
                ...accumulator[tempSectionName],
                [tempSectionControl]: {
                  ...accumulator[tempSectionName][tempSectionControl],
                  [tempSectionTask]: tempSection.value,
                },
              },
            }
          : {
              ...accumulator,
              [tempSectionName]: { [tempSectionControl]: { [tempSectionTask]: tempSection.value } },
            };
      },
      {}
    );
    const hydratedFormSections = addDisabledFieldsToForm(tempSections, template);
    return generateHTMLFromFormSubmission(hydratedFormSections);
  }
  throw new Error('No form data provided to print.');
};

const isNotesField = (activity: string, tasks: any) =>
  (activity && String(activity).toLowerCase().includes('notes')) || !activity || !!getNotes(tasks);
const getNotes = (tasks: any) => (tasks.Notes ? tasks.Notes : tasks.notes ? tasks.notes : null);
const renderActivityRow = (activity: string, tasks: any) =>
  isNotesField(activity, tasks) ? (
    <Text style={[styles.black, { width: '100%' }]}>*Notes: {getNotes(tasks)}</Text>
  ) : (
    <View style={{ width: '100%', flexDirection: 'row' }}>
      <Text style={styles.tableRowActivityText}>{convertPascalToWordsWithSpaces(activity)}</Text>
      <View style={styles.tableRowOtherContents}>
        <View style={{ width: '43%', flexDirection: 'row' }}>
          {renderPerformedRefusedUI(tasks.Performed)}
          {renderPerformedRefusedUI(tasks.Refused)}
        </View>
        <View style={styles.tableRowTasksView}>
          {Object.entries(tasks).map(([task, value], idx: number) => {
            return (
              task !== 'Performed' &&
              task !== 'Refused' && (
                <View key={`${task}${idx}`} style={styles.eachTask}>
                  {createCheckboxIcon(!!value)}
                  <Text style={[styles.black, { flexShrink: 0 }]}>
                    {convertPascalToWordsWithSpaces(task)}
                  </Text>
                </View>
              )
            );
          })}
        </View>
      </View>
    </View>
  );
const generateHTMLFromFormSubmission = (submission: { [key: string]: any }): JSX.Element[] => {
  return Object.entries(submission).map(([subtitle, activities]) => {
    const sortedActivities = Object.entries(activities as any).sort((value1, value2) => {
      if (isNotesField(value1[0], value1[1])) {
        return 1;
      }
      if (isNotesField(value2[0], value2[1])) {
        return -1;
      }
      return 0;
    });
    return (
      <View key={subtitle} style={{ flexShrink: 0, display: 'flex' }}>
        <View style={[styles.subtitleView, { flexShrink: 0 }]}>
          <Text style={styles.white}>{subtitle}</Text>
        </View>
        {sortedActivities.map(([activity, tasks]) => {
          return (
            <View key={activity} style={styles.tableRowView}>
              {renderActivityRow(activity, tasks)}
            </View>
          );
        })}
      </View>
    );
  });
};

const renderPerformedRefusedUI = (value: string | null) => {
  return (
    <View
      style={[
        styles.tableRowYesNoView,
        { backgroundColor: value ? colors.lightBlue : colors.lightestGray },
      ]}
    >
      <Text style={{ fontSize: DEFAULT_PDF_FONT_SIZE, color: value ? colors.white : colors.black }}>
        {value ? 'YES' : 'NO'}
      </Text>
    </View>
  );
};

const createCheckboxIcon = (isFilled: boolean) => {
  return (
    <View style={styles.checkBoxOuterView}>
      {isFilled && <View style={styles.checkBoxInnerView} />}
    </View>
  );
};

const convertPascalToWordsWithSpaces = (word: string): string => {
  if (word) {
    return word.replace(/([A-Z])/g, ' $1');
  }
  return '';
};

/* HEADER AREA */
const generateHeaderHTML = (shift: any) => {
  const { user: nurse } = shift.careGiver;
  const { patient, form, clockInOut: shiftDetails } = shift;

  const shiftInTime = moment(shiftDetails.adjustedClockInTimeStamp).format(TIME_FORMAT);
  const shiftOutTime = moment(shiftDetails.adjustedClockOutTimeStamp).format(TIME_FORMAT);
  const shiftDate = moment(shiftDetails.adjustedClockInTimeStamp).format(DATE_FORMAT);
  const formTitle = JSON.parse(form.jsonData).title;
  const dayOfTheWeek = moment(shiftDetails.adjustedClockInTimeStamp).format('dddd');

  return (
    <>
      <View style={styles.headerContainer}>
        <View style={{ flexDirection: 'column' }}>
          <Text style={styles.blackBold}>{formTitle}</Text>
          <Text style={styles.black}>
            Patient: {patient.name}, MR# {patient.searchField}
          </Text>
          <Text style={styles.black}>
            Caregiver: {nurse.givenName} {nurse.familyName}, {shift.careGiver.credentials} ID{' '}
            {shift.careGiver.externalId}
          </Text>
        </View>
        <View style={{ flexDirection: 'column' }}>
          <Text style={styles.black}>{dayOfTheWeek}</Text>
          <Text style={styles.black}>{shiftDate}</Text>
          <Text style={styles.black}>
            {shiftInTime} — {shiftOutTime}
          </Text>
        </View>
      </View>

      <View style={styles.titleView}>
        <View style={styles.tableTitleRowContainer}>
          <View style={styles.activities}>
            <Text style={styles.black}>ACTIVITIES</Text>
          </View>
          <View style={styles.performedRefused}>
            <Text style={styles.black}>PERFORMED</Text>
          </View>
          <View style={styles.performedRefused}>
            <Text style={styles.black}>REFUSED</Text>
          </View>
          <View style={styles.tasks}>
            <Text style={styles.black}>TASKS</Text>
          </View>
        </View>
      </View>
    </>
  );
};

/* FOOTER AREA */
const generateFooterHTML = (shift: any) => {
  const { user: nurse } = shift.careGiver;
  const { patient, clockInOut } = shift;
  const nurseClockOutSignatureId = `CG${clockInOut.id}`;
  const deviceClockOutTimeStamp = clockInOut.deviceClockOutTimeStamp;
  const {
    patientClockOutSignatureExists,
    isPatientClockOutSignatureOptOut,
    nurseClockOutSignatureExists,
    isNurseClockOutSignatureOptOut,
  } = clockInOut;
  const shiftInTime = moment(clockInOut.adjustedClockInTimeStamp);
  const shiftOutTime = moment(clockInOut.adjustedClockOutTimeStamp);
  const clockInCode =
    clockInOut.clockInTimeCorrection != null ? '|| ' + clockInOut.clockInTimeCorrection.code : '';
  const clockInText =
    clockInOut.clockInTimeCorrection != null ? clockInOut.clockInTimeCorrection.text : '';
  const clockOutCode =
    clockInOut.clockOutTimeCorrection != null ? '|| ' + clockInOut.clockOutTimeCorrection.code : '';
  const clockOutText =
    clockInOut.clockOutTimeCorrection != null ? clockInOut.clockOutTimeCorrection.text : '';
  const clockInReason = '' + clockInCode + ' ' + clockInText;
  const clockOutReason = '' + clockOutCode + ' ' + clockOutText;
  const statusState = clockInOut.status === 1 ? 'Approved by ' : 'Modified by ';
  const statusModifier =
    clockInOut.modifier.givenName +
    ' ' +
    clockInOut.modifier.familyName +
    ' on ' +
    clockInOut.modifiedOn;
  let statusReason = '';
  if (clockInOut.clockInOutRejectionReasons != null) {
    clockInOut.clockInOutRejectionReasons.forEach((value: any) => {
      statusReason += ` || ${value.reason.code} ${value.reason.text}`;
    });
  }

  const statusText = statusState + statusModifier + statusReason;

  const getNurseSignatureInfo = (
    signatureOptOut: boolean,
    name: string | undefined,
    nurseId: string | undefined
  ): string => {
    const optOutVerbiage = signatureOptOut ? OPT_OUT_VERBIAGE : '';
    const signatureDetails = `Electronically signed by ${name}, ${nurseId} on ${deviceClockOutTimeStamp}`;

    return optOutVerbiage + signatureDetails;
  };

  const getPatientSignatureInfo = (hasSignature: boolean, signatureOptOut: boolean) => {
    const signatureDetails = `Electronically signed by Patient/Guardian on ${deviceClockOutTimeStamp}.`;

    if (signatureOptOut) {
      return OPT_OUT_VERBIAGE + signatureDetails;
    }
    if (hasSignature) {
          return signatureDetails;
    }
    return 'Patient/Guardian Signature: Not available to sign';
  };

  const getSignatureHTML = ({
    title,
    name,
    nurseId,
    hasSignature,
  }: ISignatureComponentInputs): JSX.Element => {
    return title === 'Caregiver' ? (
      <Text style={styles.footerRow}>
        {getNurseSignatureInfo(isNurseClockOutSignatureOptOut, name, nurseId)}
      </Text>
    ) : (
      <Text style={styles.footerRow}>
        {getPatientSignatureInfo(hasSignature, isPatientClockOutSignatureOptOut)}
      </Text>
    );
  };
  const patientSignatureHTMLInput = {
    title: `Patient`,
    hasSignature: patientClockOutSignatureExists,
    isPatientClockOutSignatureOptOut: isPatientClockOutSignatureOptOut,
  };
  const nurseSignatureHTMLInput = {
    title: `Caregiver`,
    name: `${nurse.givenName} ${nurse.familyName}`,
    nurseId: nurse.id,
    hasSignature: nurseClockOutSignatureExists,
    isNurseClockOutSignatureOptOut: isNurseClockOutSignatureOptOut,
  };

  return (
    <View style={styles.footerContainer}>
      <View key="Notes" style={{ flexShrink: 0, display: 'flex' }}>
        <View style={[styles.subtitleView, { flexShrink: 0 }]}>
          <Text style={styles.white}>Notes</Text>
        </View>
        <View key="Clock in Note" style={styles.tableRowView}>
          <Text style={[styles.black, { width: '100%' }]}>
            Clock in Note: {clockInOut.clockInNote}
          </Text>
        </View>
        <View key="Clock out Note" style={styles.tableRowView}>
          <Text style={[styles.black, { width: '100%' }]}>
            Clock out Note: {clockInOut.clockOutNote}
          </Text>
        </View>
      </View>

      <Text style={styles.footerRow}>
        Clock in on {shiftInTime.toString()} with Lat: {clockInOut.clockInLatitude} / Long:{' '}
        {clockInOut.clockInLongitude} {clockInReason}
      </Text>
      <Text style={styles.footerRow}>
        Clock out on {shiftOutTime.toString()} with Lat: {clockInOut.clockOutLatitude} / Long:{' '}
        {clockInOut.clockOutLongitude} {clockOutReason}
      </Text>
      <Text style={styles.footerRow}>{statusText}</Text>
      {getSignatureHTML(nurseSignatureHTMLInput)}
      {getSignatureHTML(patientSignatureHTMLInput)}
    </View>
  );
};

const addDisabledFieldsToForm = (
  answerSections: { [key: string]: any },
  template: IFormTemplate
) => {
  const disabledFields: { [key: string]: any } = {};

  if (!template || !template.form) return answerSections;

  template.form
    .filter((section) => section.tasks.length === 0)
    .map((section) => {
      disabledFields[section.sectionTitle] = {};
    });

  return { ...disabledFields, ...answerSections };
};

// Create styles
const styles = StyleSheet.create({
  pageView: {
    width: '100%',
    height: '100%',
    flexShrink: 0,
    backgroundColor: colors.white,
    padding: 10,
  },
  // button
  buttonStyle: {
    width: 'fit-content',
    padding: 12,
    fontWeight: 'bold',
    fontSize: 14,
  },
  // checkbox
  checkBoxOuterView: {
    width: DEFAULT_PDF_FONT_SIZE,
    height: DEFAULT_PDF_FONT_SIZE,
    borderWidth: 1,
    borderColor: colors.black,
    marginLeft: DEFAULT_PDF_FONT_SIZE / 2,
    marginRight: DEFAULT_PDF_FONT_SIZE / 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkBoxInnerView: {
    width: DEFAULT_PDF_FONT_SIZE * 0.75,
    height: DEFAULT_PDF_FONT_SIZE * 0.75,
    borderWidth: 1,
    borderColor: colors.lightBlue,
    backgroundColor: colors.lightBlue,
  },
  // header
  headerContainer: { flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 5 },
  blackBold: { color: colors.black, fontSize: DEFAULT_PDF_FONT_SIZE, fontWeight: 'bold' },
  black: { color: colors.black, fontSize: DEFAULT_PDF_FONT_SIZE },
  titleView: {
    width: '100%',
    flexDirection: 'row',
    paddingTop: 2,
    paddingBottom: 2,
    borderTopWidth: 1,
    borderTopColor: colors.textGray,
    backgroundColor: colors.lightestGray,
  },
  tableTitleRowContainer: { width: '100%', flexDirection: 'row' },
  white: { fontSize: DEFAULT_PDF_FONT_SIZE, color: colors.white, textAlign: 'center' },
  // columns
  activities: { width: '30%', justifyContent: 'center', alignItems: 'center', paddingRight: 5 },
  performedRefused: { width: '15%', justifyContent: 'center', alignItems: 'center' },
  tasks: { width: '40%', justifyContent: 'center', alignItems: 'center' },
  // body
  subtitleView: {
    flexDirection: 'row',
    // padding: DEFAULT_PDF_FONT_SIZE / 2,
    backgroundColor: colors.textGray,
    alignItems: 'center',
  },
  tableRowView: {
    flexShrink: 0,
    flexDirection: 'row',
    alignContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: colors.textGray,
    display: 'flex',
  },
  tableRowActivityText: {
    width: '30%',
    fontSize: DEFAULT_PDF_FONT_SIZE,
    color: colors.black,
    paddingLeft: 3,
  },
  /* if given width: '70%', this area doesn't overflow to the right. but this also helps to not 'wrapping' tasks */
  tableRowOtherContents: { width: '70%', flexDirection: 'row' },
  tableRowYesNoView: { width: '43%', alignItems: 'center', justifyContent: 'center' },
  tableRowTasksView: { width: '40%', flexDirection: 'row', flexWrap: 'wrap' },
  eachTask: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
  // footer
  footerContainer: { flexDirection: 'column', flexShrink: 0, flexWrap: 'wrap' },
  footerRow: {
    flex: 1,
    flexDirection: 'column',
    fontSize: 6,
    color: colors.black,
    paddingTop: 5,
  },
});
