import styled from 'styled-components';
import { device } from '../../common';

export const StyledLoginForm = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;

  /* above mobile L */
  @media ${device.mobileL} {
    position: relative;
    width: 400px;
    height: 250px;
    padding: 48px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 1px 1px 2px 1px rgba(33, 33, 33, 0.1);
  }
`;

export const StyledLogo = styled.img`
  margin-bottom: 12px;
  height: 128px;
  position: absolute;
  top: 8%;
  z-index: 100;

  /* above mobile L */
  @media ${device.mobileL} {
    position: relative;
    top: auto;
    height: 192px;
  }
`;
