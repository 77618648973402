import React, { forwardRef } from 'react';
import { updateUserLocation } from '../../../api/admin';
import { useSelector } from 'react-redux';
import { LocationEditorComponent } from '../LocationEditorComponent';
import { formatLocationForDisplay } from '../../locationColumnUtility';

export const UserLocationDescription = (props: any) => {
  const locationOptions = useSelector(({ admin }: any) => admin.locations);
  if (!locationOptions) {
    return null;
  }

  const selectedUserLocation = locationOptions.find(
    (x: { id: number }) => x.id === props.data.locationId
  );

  if (!selectedUserLocation) {
    return null;
  }

  return formatLocationForDisplay(selectedUserLocation);
};

export const UserLocationEditor = forwardRef((props: any, ref) => {
  // I'm not happy about the hard coded width as seen below. Ideally we can find a better
  // way to hook into the AgGrid popup width calculation.
  return (
    <LocationEditorComponent
      locationId={props.data.locationId}
      updateLocation={(newLocationId: number) => updateUserLocation(props.data, newLocationId)}
      data={props.data}
      node={props.node}
      ref={ref}
      backingFieldName="locationId"
    />
  );
});
