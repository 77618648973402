import React, { Component, Fragment } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import styled from 'styled-components';
import MaterialIcon from 'material-icons-react';

import { IPatient, IShift } from '../../Shifts/interfaces';
import { PatientListItem } from './PatientListItem';
import { ShiftListItem } from './ShiftListItem';
import { Handle } from './Handle';

type Props = {
  filteredPatients: IPatient[];
  filteredShifts: IShift[];
  selectedPatient: IPatient | null;
  onSelectPatient: (patient: IPatient | null) => void;
  onSendShift: (shift: IShift) => void;
  selectedDistance: number;
  onDistanceChange: (distance: number) => void;
};

const marks = {
  1: { style: { color: 'white' }, label: '1' },
  2: { style: { color: 'white' }, label: '5' },
  3: { style: { color: 'white' }, label: '10' },
  4: { style: { color: 'white' }, label: '30' },
  5: { style: { color: 'white' }, label: '50' },
};

const SideBarContainer = styled.div`
  width: 415px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const SideBarHeaderContainer = styled.div`
  background-color: #5186ec;
  color: white;
  flex: 0;
  padding: 8px;
  line-height: 24px;
`;

const PatientName = styled.div`
  font-weight: bold;
`;

const PatientLevel = styled.div`
  font-size: 14px;
  display: inline-block;
  margin-right: 20px;
`;

const PatientAddress = styled.div`
  font-size: 14px;
`;

const PatientDetails = styled.div`
  display: inline-block;
  font-size: 14px;
`;

const ScrollContainer = styled.div`
  overflow: auto;
  background-color: white;
  flex: 1;
`;

const ShiftCountContainer = styled.div`
  background-color: #3e68cf;
  padding: 15px 20px;
  color: white;
  font-weight: bold;
  text-align: right;
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.36);
  z-index: 0;
`;

const SliderContainer = styled.div`
  padding-eft: 5px;
  padding-right: 20px;
  padding-bottom: 25px;
`;

const EmptyListContainer = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const EmptyListText = styled.div`
  padding: 10px;
`;

const BackToResultsButton = styled.button`
  color: #0076bb;
  padding: 15px 10px;
  flex: 0;
  text-align: left;
  align-items: center;
  display: flex;
  font-size: 16px;
  border: none;
  box-shadow: inset 0px 7px 14px -7px rgba(0, 0, 0, 0.36);
  cursor: pointer;
`;

export class SideBar extends React.PureComponent<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  private renderEmptyItem(iconName: string, emptyText: string) {
    return (
      <EmptyListContainer>
        <MaterialIcon icon={iconName} size={'large'} />
        <EmptyListText>{emptyText}</EmptyListText>
      </EmptyListContainer>
    );
  }

  private renderPatientList() {
    if (this.props.filteredPatients.length) {
      return this.props.filteredPatients.map((patient: IPatient) => {
        return (
          <PatientListItem
            onSelectPatient={this.props.onSelectPatient}
            key={patient.id}
            patient={patient}
          />
        );
      });
    } else {
      return this.renderEmptyItem('person_pin', 'No patients found!');
    }
  }

  private renderShiftList() {
    if (this.props.filteredShifts.length) {
      return this.props.filteredShifts.map((shift: IShift) => {
        return <ShiftListItem key={shift.key} onSendShift={this.props.onSendShift} shift={shift} />;
      });
    } else {
      return this.renderEmptyItem('work', 'No shifts found!');
    }
  }

  private getPatientAgeSexWeight() {
    // @ts-ignore:strict-null-checks
    return `${this.props.selectedPatient.age}/${this.props.selectedPatient.sex}/${this.props.selectedPatient.weight}`;
  }

  private getHandle(props: any) {
    // Remove the classname we get from react slider so we can override the styles.
    delete props.className;
    return <Handle {...props} />;
  }

  public render() {
    return (
      <SideBarContainer>
        {this.props.selectedPatient ? (
          <Fragment>
            <BackToResultsButton
              onClick={() => {
                this.props.onSelectPatient(null);
              }}
            >
              Back To Results
            </BackToResultsButton>
            <SideBarHeaderContainer>
              <PatientName>{this.props.selectedPatient.name}</PatientName>
              <PatientLevel>{`Level ${this.props.selectedPatient.skillLevel}`}</PatientLevel>
              <PatientDetails>{this.getPatientAgeSexWeight()}</PatientDetails>
              <PatientAddress>{this.props.selectedPatient.address}</PatientAddress>
            </SideBarHeaderContainer>
            <ShiftCountContainer>{this.props.filteredShifts.length} Shifts</ShiftCountContainer>
            <ScrollContainer>{this.renderShiftList()}</ScrollContainer>
          </Fragment>
        ) : (
          <Fragment>
            <SideBarHeaderContainer>
              <h5>Distance From address</h5>
              <SliderContainer>
                <Slider
                  min={1}
                  max={5}
                  marks={marks}
                  handle={this.getHandle}
                  value={this.props.selectedDistance}
                  onChange={this.props.onDistanceChange}
                  railStyle={{
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  }}
                  trackStyle={{
                    backgroundColor: 'white',
                  }}
                  dotStyle={{
                    display: 'none',
                  }}
                />
              </SliderContainer>
            </SideBarHeaderContainer>
            <ShiftCountContainer>{this.props.filteredPatients.length} Patients</ShiftCountContainer>
            <ScrollContainer>{this.renderPatientList()}</ScrollContainer>
          </Fragment>
        )}
      </SideBarContainer>
    );
  }
}
