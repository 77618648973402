import React from 'react';
import { useSelector } from 'react-redux';

import { interfaces } from '../../../common';
import styles from './ShiftEditReview.module.css';

interface IProps {
  title: string;
  previousData: number;
  currentData: number;
}

export const EditedCorrectionItem = ({ title, previousData, currentData }: IProps) => {
  const allReasonCodesOptions = useSelector(({ appData }: any) => appData.allReasonCodesOptions);

  return currentData !== previousData ? (
    <div className={styles.editGroup}>
      <div className={styles.editGroup}>
        <div className={styles.editTitleContainer}>
          <p className={styles.editTitle}>{title}</p>
        </div>
        <div style={{ flex: 1.5, flexDirection: 'column' }}>
          <p className={styles.editData}>{getReason(previousData, allReasonCodesOptions)}</p>
        </div>
      </div>
      <div className={styles.editGroup}>
        <div className={styles.editTitleContainer}>
          <p className={styles.editTitle}>{title}</p>
        </div>
        <div style={{ flex: 1.5, flexDirection: 'column' }}>
          <p className={styles.editData}>{getReason(currentData, allReasonCodesOptions)}</p>
        </div>
      </div>
    </div>
  ) : null;
};

const getReason = (id: number | string, allReasonCodesOptions: any[]) => {
  const tempReason = allReasonCodesOptions.find(
    (tempReasonCodeOption: interfaces.IDropdownOption) => tempReasonCodeOption.value === id
  );

  return tempReason ? tempReason.label : `N/A`;
};
