import React, { Component, Fragment } from 'react';

import Close from '../../assets/close.png';
import LeftArrow from '../../assets/left-arrow.png';

import { getPendingNursesByShift, awardNurseShift } from '../../api/shifts';
import { IShift } from '../interfaces';

import { ModalWrapper, Loading, interfaces } from '../../common';
import { NoNursesFound } from '../components/ModalBody';

import { NurseListItem, NurseListItemControlType } from '../../Nurses';
import ReactGA from 'react-ga';

import {
  ContentWrapper,
  TitleWrapper,
  Title,
  ListItemWrapper,
  MobileCloseModalButton,
  CloseModalButton,
  ShiftInfoHeader,
  FooterWrapper,
  ToastWrapper,
  FooterButton,
  SubHeader,
  SubHeaderText,
} from '../components/ModalBody';

enum ToastText {
  success = 'Shift Awarded',
  failed = 'Something Went Wrong',
}

interface IState {
  selectedNurseId: number | null;
  nurses: interfaces.INurse[];
  toastText: ToastText;
  showToast: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
}

interface IProps {
  closeModal: () => void;
  shift: IShift;
}

export class AppliedNurseListModal extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedNurseId: null,
      nurses: [],
      showToast: false,
      toastText: ToastText.success,
      isSubmitting: false,
      isLoading: true,
    };
  }

  public componentWillMount = async () => {
    const res = await getPendingNursesByShift(this.props.shift.key);

    if (res.status === 200) {
      this.setState({ nurses: res.body });
    }

    this.setState({ isLoading: false });
  };

  public selectNurse = (id: number) => {
    this.setState({ selectedNurseId: id });
  };

  public renderAppliedNurseList = () => {
    const { nurses, selectedNurseId } = this.state;

    if (nurses.length > 0) {
      return nurses.map((nurse: interfaces.INurse) => {
        const nurseAsApplicant = this.props.shift.applications.find(
          (item: any) => item.applicant.id === nurse.id
        );
        const applicationDate = nurseAsApplicant.requestTime;
        const checked = selectedNurseId === nurse.id ? true : false;
        const nurseWithApplicationDate = { ...nurse, applicationDate };
        return (
          <NurseListItem
            key={nurse.userId + nurse.externalId}
            nurse={nurseWithApplicationDate}
            controlType={NurseListItemControlType.RADIO}
            checked={checked}
            handleClick={() => this.selectNurse(nurse.id)}
          />
        );
      });
    } else {
      return <NoNursesFound />;
    }
  };

  public renderAwardShift = () => {
    const { selectedNurseId, showToast, isSubmitting } = this.state;
    if (selectedNurseId && !showToast) {
      return (
        <FooterWrapper>
          <FooterButton disabled={isSubmitting} onClick={() => this.handleSendShift()}>
            Award Shift
          </FooterButton>
        </FooterWrapper>
      );
    }
  };

  public renderToast = () => {
    const { showToast, toastText } = this.state;
    if (showToast) {
      return (
        <ToastWrapper failed={toastText === ToastText.failed}>{this.state.toastText}</ToastWrapper>
      );
    }
  };

  public handleSendShift = async () => {
    const { shift, closeModal } = this.props;
    const { selectedNurseId } = this.state;

    ReactGA.event({
      category: 'Sent Shift',
      action: 'handleRemind on AppliedNurseListModal.tsx',
      label: 'Sent Shift Remind',
      nonInteraction: false,
    });

    this.setState({ isSubmitting: true });
    try {
      const res = await awardNurseShift(shift.key, selectedNurseId);

      if (res.status === 200) {
        this.setState({ showToast: true, toastText: ToastText.success, isSubmitting: false });
        setTimeout(() => closeModal(), 1500);
      } else {
        throw new Error(`Error with awarding shift, status code: ${res.status}`);
      }
    } catch (error) {
      this.setState({ showToast: true, toastText: ToastText.failed, isSubmitting: false });
      setTimeout(() => closeModal(), 1500);
    }
  };

  public render() {
    const { closeModal, shift } = this.props;
    const { isLoading } = this.state;
    return (
      <ModalWrapper closeModal={closeModal}>
        <ContentWrapper>
          <TitleWrapper>
            <MobileCloseModalButton onClick={() => closeModal()}>
              <img src={LeftArrow} />
            </MobileCloseModalButton>
            <Title>Shift Applicants</Title>
            <CloseModalButton onClick={() => closeModal()}>
              <img src={Close} />
            </CloseModalButton>
          </TitleWrapper>
          <ShiftInfoHeader {...shift} />
          <ListItemWrapper>
            {isLoading ? (
              <Loading />
            ) : (
              <Fragment>
                <SubHeader>
                  <SubHeaderText>Caregivers</SubHeaderText>
                </SubHeader>
                {this.renderAppliedNurseList()}
              </Fragment>
            )}
          </ListItemWrapper>
        </ContentWrapper>
        {this.renderAwardShift()}
        {this.renderToast()}
      </ModalWrapper>
    );
  }
}
