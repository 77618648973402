import React, { useEffect, useState } from 'react';
import { DataGrid, GridRowId } from '@mui/x-data-grid';
import getColumns from './Columns/tableColumns';
import { ICaregiverShiftsData } from '../../../common/interfaces';
import { TabInfo } from '../Tabs/Tabs';
import { getFilterFunction } from '../DataHelpers';
import CustomToolbar from './CaregiverShiftsToolbar';
import { zipAllDvrs } from '../../../Aides/ShiftDetails/ShiftDetailsPrinter';
import './Columns/columns.css';
import '../../caregiverShifts.css';

type Props = {
  currentTab: TabInfo;
  isDataLoading: boolean;
  rows: ICaregiverShiftsData[];
  onShiftUpdated: (shiftData: ICaregiverShiftsData) => void;
};

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const CaregiverShiftsDataGrid = ({ currentTab, isDataLoading, rows, onShiftUpdated }: Props) => {
  const [filteredRows, setFilteredRows] = useState<ICaregiverShiftsData[]>([]);
  const [searchText, setSearchText] = React.useState('');
  const [selection, setSelection] = useState<GridRowId[]>([]);
  const [pageSize, setPageSize] = useState<number>(20);

  useEffect(() => {
    updateRows(rows);
  }, rows);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    updateRows(rows, searchValue);
  };

  const updateRows = (
    allNewRows: ICaregiverShiftsData[],
    searchValue: string = searchText
  ): void => {
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');

    const filterFunction = getFilterFunction(currentTab.status, currentTab.isRejected);
    const partiallyFilteredRows = allNewRows.filter(filterFunction);
    const fullyFilteredRows = partiallyFilteredRows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        if (row[field] === null) {
          return false;
        }
        return searchRegex.test(row[field].toString());
      });
    });
    setFilteredRows(fullyFilteredRows);
  };

  const onPrintSelectedForms = async (): Promise<void> => {
    const data = rows.filter((x) => selection.some((y) => x.id === y));
    await zipAllDvrs(data);
  };

  return (
    <div className="tableContainer">
      <div
        className="ag-theme-alpine shiftTables"
        id="shifts-table"
        data-test-id="data-shifts-table"
      >
        <DataGrid
          components={{ Toolbar: CustomToolbar }}
          rows={filteredRows}
          columns={getColumns(onShiftUpdated)}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50, 100]}
          loading={isDataLoading}
          selectionModel={selection}
          onSelectionModelChange={setSelection}
          onPageSizeChange={setPageSize}
          checkboxSelection
          componentsProps={{
            toolbar: {
              value: searchText,
              onQuickFilterChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                requestSearch(event.target.value),
              clearQuickFilter: () => requestSearch(''),
              onPrintSelectedForms: onPrintSelectedForms,
            },
          }}
        />
      </div>
    </div>
  );
};

export default CaregiverShiftsDataGrid;
