import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import RouteGuard from './RouteGuard';
import { Content } from '../../common';
import { EnvironmentName } from '../../utils';
import FatalErrorBoundary from '../../ErrorBoundaries/FatalErrorBoundary';
import { enableAnalytics } from '../../analytics';

export class Routing extends Component {
  public componentDidMount() {
    enableAnalytics();
  }

  public render() {
    return (
      <Router>
        {/* <FatalErrorBoundary> */}
        <EnvironmentName />
        <Content id="main-content" isLogin={false}>
          <RouteGuard />
        </Content>
        {/* </FatalErrorBoundary> */}
      </Router>
    );
  }
}
