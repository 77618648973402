import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import moment from 'moment';

import { IShift } from '../interfaces';
import { device } from '../../common/';

export const ContentWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media ${device.mobileL} {
    padding: 12px;
  }
`;

export const TitleWrapper: any = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30px;
  text-align: center;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  padding: 6px 6px 0 6px;
`;

export const Title: any = styled.div`
  flex-grow: 1;
`;

export const ShiftInfoWrapper: any = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid lightgray;
  padding: 5px 5px 5px 20px;
  min-height: 45px;
  color: rgba(0, 0, 0, 0.6);
`;

export const ListItemWrapper: any = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`;

export const MobileCloseModalButton: any = styled.button`
  border: none;
  cursor: pointer;
  width: 50px;
  height: 30px;
  background-color: transparent;
  margin-bottom: 6px;

  @media ${device.mobileL} {
    visibility: hidden;
  }
`;

export const CloseModalButton: any = styled.button`
  border: none;
  cursor: pointer;
  width: 50px;
  height: 30px;
  background-color: transparent;
  margin-bottom: 6px;

  visibility: hidden;

  @media ${device.mobileL} {
    visibility: inherit;
  }
`;

export const Info: any = styled.span`
  text-align: center;
`;

export const ShiftInfoHeader = ({ start, end, patient: { name, skillLevel }, hours }: IShift) => (
  <ShiftInfoWrapper>
    <div style={{ fontWeight: 500, paddingBottom: '3px' }}>
      {name} - {moment(start).format('MMM DD')}
    </div>
    <div style={{ display: 'flex', flexGrow: 1, fontSize: '14px' }}>
      <Info>{`Level ${skillLevel}`}</Info>
      &nbsp;|&nbsp;
      <Info>{`${moment(start).format('LT')}`}</Info>
      <Info>&nbsp;{` - `}&nbsp;</Info>
      <Info>{`${moment(end).format('LT')}`}</Info>
      &nbsp; &nbsp;
      <Info>{`${hours} Hours`}</Info>
    </div>
  </ShiftInfoWrapper>
);

export const SubHeader: any = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  color: #707070;
  font-size: 16px;
  font-weight: 600;
  font-family: sans-serif;
`;

export const SelectAllButton: any = styled.button`
  border: none;
  background-color: transparent;
  color: #0075c9;
  font-size: 16px;
  font-weight: 600;
  font-family: sans-serif;
  cursor: pointer;
  margin: 8px 14px 8px 8px;
`;

export const SubHeaderText: any = styled.div`
  margin: 8px 8px 8px 12px;
`;

export const slideUp: any = keyframes`
  0% {
    transform: translateY(15px);
    opacity: 0;
  }

 75% {
  opacity: 0.3;
 }

  100% {
    transform: translateY(-15);
    opacity: 1;
  }
`;

export const toastSlideLeft: any = keyframes`
  0% {
    transform: translateX(100vw);
  }

  100% {
    transform: translateX(0);
  }
`;

export const FooterWrapper: any = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.2);

  width: 100vw;
  height: 50px;
  margin-top: auto;
  position: relative;
  bottom: 0;
  background-color: white;

  animation-name: ${slideUp};
  animation-direction: forward;
  animation-duration: 500ms;
  animation-timing-function: ease-out;

  @media ${device.mobileL} {
    box-shadow: none;
    position: static;
    width: 100%;
    animation: none;
  }
`;

export const FooterButton: any = styled.button`
  border: none;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: white;
  padding: 10px 16px 10px 16px;
  background-color: #0075c9;
  border-radius: 4px;
  cursor: pointer;

  ${({ secondary }: any) =>
    secondary &&
    css`
      color: #0075c9;
      background-color: white;
    `}

  ${({ disabled }: any) =>
    disabled &&
    css`
      color: white;
      background-color: #c5c5c5;
      cursor: wait;
    `}
`;

export const ToastWrapper: any = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background-color: ${({ failed }: { failed?: boolean }) => (failed ? '#c90000' : '#18b105')};
  color: white;
  margin-top: auto;
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 40px;

  animation-name: ${toastSlideLeft};
  animation-direction: forward;
  animation-duration: 300ms;
  animation-timing-function: ease-out;

  @media ${device.mobileL} {
    position: static;
    width: 100%;
    animation-name: ${slideUp};
    animation-direction: forward;
    animation-duration: 500ms;
    animation-timing-function: ease-out;
  }
`;

export const CardToastWrapper: any = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background-color: ${({ failed }: { failed?: boolean }) => (failed ? '#c90000' : '#18b105')};
  color: white;
  margin-top: auto;
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 35px;

  position: static;
  width: 100%;

  animation-name: ${slideUp};
  animation-direction: forward;
  animation-duration: 500ms;
  animation-timing-function: ease-out;
`;

const StyledNoNursesFound: any = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.7);
  margin: 8px 0 8px 0;
`;

export const NoNursesFound = () => <StyledNoNursesFound>None Found</StyledNoNursesFound>;
