import React, { Component } from 'react';

import '../../amelia.css';

import personIcon from '../../assets/scheduler-3.png';
import { getAmeliaWebViewSourceUrl } from '../../utils/app';

type IProps = {
  ameliaVisibility: boolean;
  toggleAmeliaVisibility: () => void;
};

class Amelia extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    return (
      <div className="chat-overlay">
        <div className="chat-overlay-wrapper">
          <div className="chat-overlay-header-mobile">
            <img className="close" src={personIcon} alt="toggle chat overlay" />
          </div>
          {this.props.ameliaVisibility && (
            <div className="chat-overlay-wrapper">
              <iframe
                id="receiver"
                src={getAmeliaWebViewSourceUrl()}
                frameBorder="0"
                width="100%"
                height="100%"
                allow="geolocation; microphone; camera"
              >
                <p>Your browser does not support iframes.</p>
              </iframe>
            </div>
          )}
          <div
            className="chat-overlay-header"
            id="overlayHeaderBubble"
            onClick={() => this.props.toggleAmeliaVisibility()}
          >
            <img
              className="chat-overlay-header-img close"
              src={personIcon}
              alt="toggle chat overlay"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Amelia;
