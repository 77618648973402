import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { SideNav, interfaces } from '../common';
import { ROUTES } from '../routing/routing.constants';
import { Dashboard, AdoptionRates } from './containers';
import { userCanNavigateToDashboard } from '../routing/routeHandling';
import { useSelector } from 'react-redux';
import { handleLandingPageRoutes } from '../routing/routeHandling';

const sideNavRouteConfig: interfaces.ISideNavRouteConfig[] = [
  {
    title: 'Adoption Rates',
    route: `${ROUTES.DASHBOARD}${ROUTES.ADOPTION_RATES}`,
    icon: 'how_to_reg',
  },
];

// MASTER DASHBOARD COMPONENT, USED FOR SUB-ROUTING
export const DashboardRouter = ({ match }: RouteComponentProps) => {
  const userToCheck = useSelector(({ user }: any) => user.user);
  const featureFlags = useSelector(({ user }: any) => user.featureFlags);
  const shouldRoute = userCanNavigateToDashboard(userToCheck) || !featureFlags.accessControlSC714;
  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <SideNav routeConfig={sideNavRouteConfig} />
      <div style={{ flex: 1, height: `100vh` }}>
        <Switch>
          {shouldRoute ? (
            [
              <Route exact path={`${match.url}`} component={Dashboard} />,
              <Route
                exact
                path={`${match.url}${ROUTES.ADOPTION_RATES}`}
                component={AdoptionRates}
              />,
            ]
          ) : (
            <Redirect to={handleLandingPageRoutes(userToCheck)} />
          )}
        </Switch>
      </div>
    </div>
  );
};
