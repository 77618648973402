import { types } from './constants';
import { TabNames } from './components/Tabs';
import { tabFilterNames } from './enums';
import { IFilter } from './interfaces';

export const setCurrentTab = (currentTab: TabNames) => {
  return {
    payload: currentTab,
    type: types.SET_CURRENT_TAB,
  };
};

const generateType = (name: tabFilterNames) => {
  switch (name) {
    case tabFilterNames.openShiftsFilters:
      return 'OPEN';
    case tabFilterNames.sentShiftsFilters:
      return 'SENT';
    case tabFilterNames.appliedShiftsFilters:
      return 'APPLIED';
    case tabFilterNames.awardedShiftsFilters:
      return 'AWARDED';
    case tabFilterNames.confirmedShiftsFilters:
      return 'CONFIRMED';
  }
};

export const addFilter = (filterTab: tabFilterNames, newFilter: IFilter) => {
  return {
    payload: newFilter,
    // @ts-ignore
    type: types[`ADD_FILTER_${generateType(filterTab)}_SHIFTS`],
  };
};
export const removeFilter = (filterTab: tabFilterNames, filterIndex: number) => {
  return {
    payload: filterIndex,
    // @ts-ignore
    type: types[`REMOVE_FILTER_${generateType(filterTab)}_SHIFTS`],
  };
};
export const setFilter = (filterTab: tabFilterNames, filterIndex: number, filter: IFilter) => {
  return {
    payload: { filter, index: filterIndex },
    // @ts-ignore
    type: types[`SET_FILTER_${generateType(filterTab)}_SHIFTS`],
  };
};
export const clearFilters = (filterTab: tabFilterNames) => {
  return {
    // @ts-ignore
    type: types[`CLEAR_FILTERS_${generateType(filterTab)}_SHIFTS`],
  };
};
