import { AnyAction, CombinedState, combineReducers, Reducer } from 'redux';
import { loginReducer } from '../login';
import { messagesReducer } from '../messaging';
import { notificationReducer } from '../notifications';
import {
  tabsReducer,
  openShiftsFiltersReducer,
  sentShiftsFiltersReducer,
  appliedShiftsFiltersReducer,
  awardedShiftsFiltersReducer,
  confirmedShiftsFiltersReducer,
  orientRequestsFiltersReducer,
} from '../Shifts/reducers';
import { aidesReducer } from '../Aides';
import { appDataReducer } from '../routing/reducers';
import { tabFilterNames } from '../Shifts/enums';
import { adminReducer } from '../Admin';
import { searchReducer } from '../Aides/searchReducer';
import storage from 'redux-persist/lib/storage';

const appReducer = combineReducers<Reducer>({
  appData: appDataReducer,
  user: loginReducer,
  messages: messagesReducer,
  currentShiftTab: tabsReducer,
  notifications: notificationReducer,
  aides: aidesReducer,
  admin: adminReducer,
  searchReducer: searchReducer,
  [tabFilterNames.openShiftsFilters]: openShiftsFiltersReducer,
  [tabFilterNames.sentShiftsFilters]: sentShiftsFiltersReducer,
  [tabFilterNames.appliedShiftsFilters]: appliedShiftsFiltersReducer,
  [tabFilterNames.awardedShiftsFilters]: awardedShiftsFiltersReducer,
  [tabFilterNames.confirmedShiftsFilters]: confirmedShiftsFiltersReducer,
  [tabFilterNames.orientRequestsFilters]: orientRequestsFiltersReducer,
});

const rootReducer = (
  state: CombinedState<Reducer<any, AnyAction>> | undefined,
  action: AnyAction
) => {
  if (action.type === 'RESET_STATE') {
    storage.removeItem('persist:root');
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
