import React from 'react';
import moment from 'moment';

import styles from './ShiftEditReview.module.css';

interface IProps {
  title: string;
  previousData: Date;
  currentData: Date;
}

export const EditedTimeItem = ({ title, previousData, currentData }: IProps) => {
  return new Date(currentData).getTime() !== new Date(previousData).getTime() ? (
    <div className={styles.editGroup}>
      <div className={styles.editGroup}>
        <div className={styles.editTitleContainer}>
          <p className={styles.editTitle}>{title}</p>
        </div>
        <div style={{ flex: 1.5, flexDirection: 'column' }}>
          <p className={styles.editData}>{moment(previousData).format('MM/DD/YYYY')}</p>
          <p className={styles.editData}>{moment(previousData).format('hh:mm A')}</p>
        </div>
      </div>
      <div className={styles.editGroup}>
        <div className={styles.editTitleContainer}>
          <p className={styles.editTitle}>{title}</p>
        </div>
        <div style={{ flex: 1.5, flexDirection: 'column' }}>
          <p className={styles.editData}>{moment(currentData).format('MM/DD/YYYY')}</p>
          <p className={styles.editData}>{moment(currentData).format('hh:mm A')}</p>
        </div>
      </div>
    </div>
  ) : null;
};
