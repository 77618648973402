import React, { Component, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import { find, findIndex, filter, includes } from 'lodash';
import moment from 'moment';
import { RouteComponentProps } from 'react-router';

import { FullPage } from '../../common/layout';
import { getLocation, getAllShifts, getNursesForLocation, getPatientsForLocation } from '../../api';
import { PatientMapMarker } from '../components/PatientMapMarker';
import { NurseMapMarker } from '../components/NurseMapMarker';
import { SideBar } from '../components/SideBar';
import { SearchBar } from '../components/SearchBar';
import { IPatient, IShift } from '../../Shifts/interfaces';
import { INurse } from '../../common/interfaces';
import { DistanceOptions, skillLevelOptions } from '../constants';
import { localStorageKeys } from '../../utils/constants';
import { AvailableNurseListModal } from '../../Shifts/containers/AvailableNurseListModal';
import { Loading } from '../../common/loading';

import {
  checkForNewMessages as checkForNewMessagesAction,
  getMessages as getAllMessagesAction,
} from '../../messaging';
import { getGoogleMapsKey } from '../../utils/app';

const messagesPollRate = 10000; // 10s

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
`;

const MapContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const SearchBarBackdrop = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
  justify-content: center;
`;

type Props = {
  center: any;
  zoom: number;
  getNewMessages: () => void;
  getInitialMessage: () => void;
  isFetchingMessages: boolean;
  officeId: number;
} & RouteComponentProps;

class Map extends Component<Props, any> {
  private maps: any;
  private map: any;
  private placesService: any;
  private geocoder: any;
  private marker: any;
  private googleMapsApiKey: string;

  constructor(props: any) {
    super(props);

    this.googleMapsApiKey = getGoogleMapsKey();

    this.state = {
      shifts: [],
      filteredPatients: [],
      filteredShifts: [],
      distance: DistanceOptions[3].value,
      nurses: [],
      patients: [],
      location: null,
      error: false,
      date: null,
      openModal: false,
      shiftsLoading: true,
      nursesLoading: true,
      patientsLoading: true,
      locationLoading: true,
      selectedOption: null,
      selectedCoords: null,
      selectedPatient: null,
      selectedNurse: null,
      selectedShift: null,
      selectedLevels: [],
      searchApplied: false,
      center: {
        lat: 34.163772,
        lng: -84.799615,
      },
      zoom: 11,
    };
  }

  public static defaultProps = {
    center: {
      lat: 34.163772,
      lng: -84.799615,
    },
    zoom: 11,
  };

  private getAllMessages: any = null;

  public componentDidMount = async () => {
    this.getShiftData();
    this.getNurses();
    this.getPatients();
    this.getLocationData();
    this.loadInitialMessageData();
  };

  public componentWillUnmount() {
    clearInterval(this.getAllMessages);
  }

  private loadInitialMessageData = async () => {
    this.props.getInitialMessage();
    this.getAllMessages = setInterval(this.checkAllChannelsForNewMessages, messagesPollRate);
  };

  private checkAllChannelsForNewMessages = () => {
    const { isFetchingMessages, getNewMessages } = this.props;
    if (!isFetchingMessages) {
      getNewMessages();
    }
  };

  public getNurses = async () => {
    let res;
    try {
      res = await getNursesForLocation(this.props.officeId);

      if (res.status < 300) {
        this.setState({
          nurses: res.body,
          error: false,
          nursesLoading: false,
        });
      } else {
        this.setState({ error: true });
      }
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    }
  };

  public getPatients = async () => {
    let res;
    try {
      res = await getPatientsForLocation(this.props.officeId);

      if (res.status < 300) {
        this.setState({
          patients: res.body,
          error: false,
          patientsLoading: false,
        });
      } else {
        this.setState({ error: true });
      }
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    }
  };

  public getShiftData = async () => {
    this.setState({ loading: true });

    let res;
    try {
      res = await getAllShifts();

      this.setState({ shifts: res, error: false, shiftsLoading: false });
      // All of this is happening in shifts.ts - DW
      // console.log(res.status);

      // if (res.status < 300) {
      //   console.log("HEY WE SHOULD HAVE SET THE SHIFT");
      //   this.setState({
      //     shifts: res.body,
      //     error: false,
      //     shiftsLoading: false,
      //   });
      // } else {
      //   this.setState({ error: true });
      // }
    } catch (e) {
      console.error('getShiftData() failed, ', e);
      this.setState({ error: true });
    }
  };

  public getLocationData = async () => {
    let res;
    try {
      res = await getLocation(this.props.officeId);

      if (res.status < 300) {
        this.setState({ office: res.body }, () => {
          this.setState({
            center: {
              lat: this.state.office.latitude,
              lng: this.state.office.longitude,
            },
            locationLoading: false,
          });
        });
      } else {
        this.setState({ error: true });
      }
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    }
  };

  public renderPatientMapMarkers = () => {
    if (!this.state.selectedOption) {
      return;
    }
    return this.state.filteredPatients.map((patient: IPatient) => {
      const isSelectedPatient =
        this.state.selectedPatient && this.state.selectedPatient.id === patient.id;

      return (
        <PatientMapMarker
          key={patient.id}
          skillLevel={patient.skillLevel}
          patient={patient}
          isSelectedPatient={isSelectedPatient}
          onSelectPatient={this.onSelectPatient}
          lat={patient.location.latitude}
          lng={patient.location.longitude}
        />
      );
    });
  };

  public renderNurseMarker = () => {
    if (!this.state.selectedOption) {
      return;
    }
    if (this.state.selectedOption.type === 'nurse') {
      return (
        <NurseMapMarker
          lat={this.state.selectedOption.coords.lat}
          lng={this.state.selectedOption.coords.lng}
          nurse={this.state.selectedOption}
        />
      );
    } else {
      return null;
    }
  };

  public loadOptions = (inputValue: string, callback: (items: any) => void) => {
    this.placesService.getPlacePredictions({ input: inputValue }, (results: any, status: any) => {
      let formattedResults;
      if (status !== this.maps.places.PlacesServiceStatus.OK) {
        formattedResults = [];
      } else {
        formattedResults = results.map((item: any) => {
          return {
            value: item.description,
            label: item.description,
            arbitrary: 'data',
            type: 'mapData',
            place_id: item.place_id,
          };
        });
      }
      const filteredNurses = this.filterNurses(inputValue);
      formattedResults = filteredNurses
        .map((nurse: INurse) => {
          return {
            label: `${nurse.user.givenName} ${nurse.user.familyName}`,
            value: nurse.id,
            type: 'nurse',
            image: nurse.user.imageUri,
            coords: {
              lat: nurse.location.latitude,
              lng: nurse.location.longitude,
            },
          };
        })
        .concat(formattedResults);

      callback(formattedResults);
    });
  };

  public filterNurses = (inputValue: string) => {
    return this.state.nurses.filter((nurse: INurse) => {
      const fullName = `${nurse.user.givenName.toLowerCase()} ${nurse.user.familyName.toLowerCase()}`;
      return fullName.includes(inputValue.toLowerCase()) && nurse.location !== null;
    });
  };

  public onSelectAddress = (selectedOption: any) => {
    const updatedState: any = { selectedOption };

    if (selectedOption && selectedOption.type === 'nurse') {
      // find nurse
      updatedState.selectedNurse = find(this.state.nurses, (nurse: INurse) => {
        return nurse.id === selectedOption.value;
      });
    }

    this.setState(updatedState);
  };

  public onSelectSkillLevel = (selectedLevel: any) => {
    const prevSelectedAll =
      findIndex(this.state.selectedLevels, (item: any) => item.value === 'all') > -1;
    const currSelectedAll = findIndex(selectedLevel, (item: any) => item.value === 'all') > -1;

    // if select all was previously checked and is not checked anymore, uncheck all
    // if select all was previously checked and is still checked, remove select all from selection
    // if selected all was previously unchecked and is now checked, check all
    if (!prevSelectedAll && currSelectedAll) {
      this.setState({ selectedLevels: skillLevelOptions });
    } else if (prevSelectedAll && !currSelectedAll) {
      this.setState({ selectedLevels: [] });
    } else if (prevSelectedAll && currSelectedAll) {
      this.setState({ selectedLevels: filter(selectedLevel, (item: any) => item.value !== 'all') });
    } else if (
      !prevSelectedAll &&
      !currSelectedAll &&
      selectedLevel.length === skillLevelOptions.length - 1
    ) {
      this.setState({ selectedLevels: skillLevelOptions });
    } else {
      this.setState({ selectedLevels: selectedLevel });
    }
  };

  public onDateChange = (date: Date | null, event: SyntheticEvent<any, Event> | undefined) => {
    this.setState({ date });
  };

  public handleApiLoaded = (map: any, maps: any) => {
    this.maps = maps;
    this.map = map;
    this.placesService = new maps.places.AutocompleteService();
    this.geocoder = new maps.Geocoder();
  };

  public onClearSearch = () => {
    this.setState({
      filteredPatients: [],
      date: null,
      selectedOption: null,
      selectedLevels: [],
      selectedPatient: null,
      searchApplied: false,
      center: {
        lat: this.state.office.latitude,
        lng: this.state.office.longitude,
      },
      zoom: 11,
    });
  };

  public onSearch = () => {
    this.resetMarker();
    if (this.state.selectedOption.type === 'nurse') {
      this.setState({ center: this.state.selectedOption.coords, zoom: 13, selectedPatient: null });
      this.setState({ selectedCoords: this.state.selectedOption.coords }, this.filterPatients);
    } else {
      this.geocoder.geocode(
        { placeId: this.state.selectedOption.place_id },
        (results: any, status: any) => {
          const coords = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          };
          this.marker = new this.maps.Marker({
            position: coords,
            map: this.map,
          });
          this.setState({ center: coords, zoom: 13, selectedPatient: null });
          this.setState({ selectedCoords: coords }, this.filterPatients);
        }
      );
    }
    this.setState({ searchApplied: true });
  };

  public filterPatients = () => {
    let filteredPatients = this.state.patients;
    if (this.state.date) {
      // filter shifts by date
      const patientIds: number[] = [];
      this.state.shifts.map((shift: IShift) => {
        const momentStart = moment(shift.start);
        const momentEnd = moment(shift.end);

        if (
          momentStart.isSame(this.state.date, 'day') ||
          momentEnd.isSame(this.state.date, 'day')
        ) {
          patientIds.push(shift.patient.id);
        }
      });

      filteredPatients = filteredPatients.filter((patient: IPatient) => {
        return includes(patientIds, patient.id);
      });
    }
    if (this.state.selectedLevels.length) {
      if (findIndex(this.state.selectedLevels, (item: any) => item.value === 'all') === -1) {
        const skillLevels = this.state.selectedLevels.map((item: any) => {
          return item.value;
        });
        filteredPatients = filteredPatients.filter((patient: IPatient) => {
          return includes(skillLevels, patient.skillLevel);
        });
      }
    }
    filteredPatients = filteredPatients.filter((patient: IPatient) => {
      return (
        distance(
          this.state.selectedCoords.lat,
          this.state.selectedCoords.lng,
          patient.location.latitude,
          patient.location.longitude
        ) <= DistanceOptions[this.state.distance].distance
      );
    });

    this.setState({ filteredPatients });
  };

  public resetMarker = () => {
    if (this.marker) {
      this.marker.setMap(null);
      this.marker = null;
    }
  };

  public clearDate = () => {
    this.setState({ date: null });
  };

  public onSelectPatient = (patient: IPatient | null) => {
    this.setState({ selectedPatient: patient }, this.filterShifts);
  };

  public filterShifts = () => {
    let filteredShifts = [];
    if (this.state.selectedPatient !== null) {
      filteredShifts = this.state.shifts.filter((shift: IShift) => {
        // filter by date if selected
        return shift.patient.id === this.state.selectedPatient.id;
      });
      if (this.state.date) {
        filteredShifts = filteredShifts.filter((shift: IShift) => {
          const momentStart = moment(shift.start);
          const momentEnd = moment(shift.end);

          return (
            momentStart.isSame(this.state.date, 'day') || momentEnd.isSame(this.state.date, 'day')
          );
        });
      }
    }

    this.setState({ filteredShifts });
  };

  public onMapChange = ({ center, zoom }: any) => {
    if (center && zoom) {
      this.setState({ center, zoom });
    }
  };

  public onDistanceChange = (value: number) => {
    this.setState({ distance: value }, this.filterPatients);
  };

  public onSendShift = (shift: IShift) => {
    this.setState({
      selectedShift: shift,
      openModal: true,
    });
  };

  public renderModal = () => {
    const closeModalFunction = () => this.setState({ openModal: false }, () => this.getShiftData());

    return (
      <AvailableNurseListModal
        shift={this.state.selectedShift}
        closeModal={closeModalFunction}
        nurse={this.state.selectedNurse}
      />
    );
  };

  private isLoading() {
    return (
      this.state.shiftsLoading ||
      this.state.nursesLoading ||
      this.state.patientsLoading ||
      this.state.locationLoading
    );
  }

  public render() {
    return (
      <FullPage>
        <PageContainer>
          {this.isLoading() && (
            <LoadingContainer>
              <Loading />
            </LoadingContainer>
          )}
          {!this.state.searchApplied && (
            <SearchBarBackdrop>
              <SearchBar
                loadOptions={this.loadOptions}
                onSelectAddress={this.onSelectAddress}
                onSelectSkillLevel={this.onSelectSkillLevel}
                onDateChange={this.onDateChange}
                selectedDate={this.state.date}
                selectedLevels={this.state.selectedLevels}
                onSearch={this.onSearch}
                onClear={this.onClearSearch}
                clearDate={this.clearDate}
                mode="floating"
                selectedOption={this.state.selectedOption}
              />
            </SearchBarBackdrop>
          )}
          {this.state.searchApplied && (
            <SearchBar
              loadOptions={this.loadOptions}
              onSelectAddress={this.onSelectAddress}
              onSelectSkillLevel={this.onSelectSkillLevel}
              onDateChange={this.onDateChange}
              selectedDate={this.state.date}
              selectedLevels={this.state.selectedLevels}
              onSearch={this.onSearch}
              onClear={this.onClearSearch}
              clearDate={this.clearDate}
              mode="fixed"
              selectedOption={this.state.selectedOption}
            />
          )}
          <MapContainer>
            {this.state.searchApplied && (
              <SideBar
                filteredPatients={this.state.filteredPatients}
                filteredShifts={this.state.filteredShifts}
                onSelectPatient={this.onSelectPatient}
                onDistanceChange={this.onDistanceChange}
                onSendShift={this.onSendShift}
                selectedDistance={this.state.distance}
                selectedPatient={this.state.selectedPatient}
              />
            )}
            <GoogleMapReact
              defaultCenter={this.props.center}
              defaultZoom={this.props.zoom}
              center={this.state.center}
              zoom={this.state.zoom}
              yesIWantToUseGoogleMapApiInternals
              onChange={this.onMapChange}
              onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
              bootstrapURLKeys={{
                key: this.googleMapsApiKey,
                //@ts-ignore - TODO Need to test this error before resolving.
                libraries: ['places'],
              }}
            >
              {this.state.searchApplied && this.renderPatientMapMarkers()}
              {this.state.searchApplied && this.renderNurseMarker()}
            </GoogleMapReact>
          </MapContainer>
        </PageContainer>
        {this.state.openModal && this.renderModal()}
      </FullPage>
    );
  }
}

function distance(lat1: number, lon1: number, lat2: number, lon2: number) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;

    return dist;
  }
}

const mapStateToProps = ({ messages, user }: any) => {
  return {
    hasUnreadMessage: messages.hasUnreadMessage,
    isFetchingMessages: messages.isFetching,
    officeId: user.user.locationId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      getNewMessages: checkForNewMessagesAction,
      getInitialMessage: getAllMessagesAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
