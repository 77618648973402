import styled from 'styled-components';

export const ShiftsWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TableLayoutWrapper: any = styled.div`
  display: flex;
  flex: 5;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-start;

  background-color: #f5f6fa;
  overflow-y: scroll;

  /* offset the tab bar and initial filters */
  /* max-height: Calc(100% - 50px); */
`;

export const CardLayoutWrapper: any = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-start;

  background-color: #f5f6fa;

  /* offset the tab bar */
  max-height: Calc(100% - 50px);
  overflow-y: scroll;
`;
