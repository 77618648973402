import React, { Component } from 'react';
import {
  StyledInputLabel,
  StyledTextInput,
  StyledSubmitButton,
  StyledInputGroup,
  StyledErrorDisplay,
  StyledCheckbox,
} from '../../common';
import { checkIfValidEmailAddress } from '../../utils';
import { IUserSignInInput } from '../interfaces';
import { StyledLoginForm } from './StyledLoginForm';
import { Version } from './Version';

interface IProps {
  formState: IUserSignInInput;
  updateForm: any;
  submitForm: any;
  error?: string;
  isSubmitting: boolean;
  updateIsRemembered: () => void;
  toggleAmeliaVisibility: () => void;
  ameliaIsRunning: boolean;
}

class UserNameForm extends Component<IProps> {
  private isFormValid = (): boolean => {
    const {
      formState: { email },
    } = this.props;
    if (checkIfValidEmailAddress(email)) {
      return false;
    }
    return true;
  };

  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { formState, updateForm, submitForm, error, isSubmitting, updateIsRemembered } =
      this.props;

    return (
      <StyledLoginForm onSubmit={submitForm}>
        <StyledInputGroup style={{ marginTop: 6 }}>
          <StyledInputLabel>Email</StyledInputLabel>
          <StyledTextInput
            placeholder="email address"
            name="email"
            value={formState.email}
            onChange={updateForm}
            autoComplete="on"
          />
          <StyledCheckbox
            align="left"
            title="Remember me"
            checked={formState.isRemembered}
            onClick={updateIsRemembered}
          />
        </StyledInputGroup>
        <StyledErrorDisplay errorMessage={error} style={{ marginTop: 12 }} />
        <StyledSubmitButton
          type="submit"
          value="CONTINUE"
          onClick={submitForm}
          disabled={this.isFormValid() || isSubmitting}
        />
        <Version />
      </StyledLoginForm>
    );
  }
}

export default UserNameForm;
