import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { formatDateTime } from '../../DataHelpers';

const timeOutColumn: GridColDef = {
  field: 'adjustedClockOutTimeStamp',
  headerName: 'Time Out',
  maxWidth: 110,
  valueFormatter: (params: GridValueFormatterParams<string>) => {
    return formatDateTime(params.value, 'LT');
  },
};

export default timeOutColumn;
