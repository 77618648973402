import React, { Component } from 'react';

import { saveDVREdits } from '../../../api';
import { generateStateFromExistingJSON, generateStateFromTemplate } from './DVRStateGenerator';
import { generateFormControls } from './DVRFormControlGenerator';
import { UpdateFormState, IFormUpdateInput } from './interfaces';

interface IProps {
  formTemplateJSON: string;
  jsonData?: any;
  initialFormState?: any;
  // shift: any;
  onFormUpdate: any;
}

interface IState {
  formState: any;
}

export class DVRFormContainer extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      formState: null,
    };
  }

  public componentDidMount() {
    const { jsonData, initialFormState } = this.props;

    // TODO when we are creating new forms
    // const tempNewFormState = generateStateFromTemplate(JSON.parse(shift.templateJson));

    // if we pass in raw form json data from an existing review
    if (jsonData) {
      const tempFormState = generateStateFromExistingJSON(jsonData);
      this.setState({ formState: tempFormState });
    }

    // if we repopulate it with existing state
    if (initialFormState) {
      this.setState({ formState: initialFormState });
    }
  }

  private updateFormState: UpdateFormState = ({ id, value, fieldsToReset }: IFormUpdateInput) => {
    const { formState } = this.state;
    const tempResetFields = this.resetFields(fieldsToReset);
    const newFormState = {
      ...formState,
      form: {
        ...formState.form,
        ...tempResetFields,
        [id]: value,
      },
    };

    this.setState({ formState: newFormState });
    this.returnFormState(newFormState);
  };

  private resetFields = (fieldsToReset?: string[]) => {
    if (fieldsToReset) {
      return fieldsToReset.reduce((allResetFields: object, currentFieldToReset: string) => {
        return {
          ...allResetFields,
          [currentFieldToReset]: null,
        };
      }, {});
    }
    return null;
  };

  private returnFormState = (newFormState: any) => {
    const { onFormUpdate } = this.props;
    onFormUpdate(newFormState);
  };

  public render() {
    const { formState } = this.state;
    const { formTemplateJSON } = this.props;

    return formState && formTemplateJSON ? (
      <div>
        {generateFormControls({
          formState,
          formTemplate: JSON.parse(formTemplateJSON),
          updateFormState: this.updateFormState,
        })}
      </div>
    ) : (
      <div>No existing DVR</div>
    );
  }
}
