import React, { Component, useState } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FilterNames } from '../enums';

import { connect } from 'react-redux';
import { addFilter, removeFilter, setFilter, clearFilters } from '../actions';
import { bindActionCreators } from 'redux';

import 'react-datepicker/dist/react-datepicker.css';

export const FilterLabel = styled.label`
  font-size: 18px;
`;

export const FilterInput: any = styled.input.attrs({ autocomplete: 'off' })`
  font-size: 18px;
  padding-left: 5px;
  border: none;
  height: 100%;
`;

export const StyledDatePicker: any = styled(DatePicker)`
  font-size: 18px;
  padding-left: 5px;
  border: none;
  height: 100%;
  width: 100%;
`;

export const FilterButton: any = styled.button<{ secondary: boolean }>`
  font-size: 18px;
  font-weight: 600;
  border: none;
  height: 30px;
  background-color: #505980;
  color: white;
  border-radius: 5px;

  :hover {
    cursor: pointer;
  }

  ${(props) =>
    props.secondary &&
    css`
      box-sizing: border-box;
      color: #505980;
      background-color: white;
      border: 2px solid #505980;
    `}
`;

export const FilterClear: any = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  border: none;
  height: 100%;
  color: darkred;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 3px;

  :hover {
    cursor: pointer;
  }
`;

export const FilterInputWrapper: any = styled.div`
  box-sizing: border-box;
  background-color: white;
  display: flex;
  align-items: center;
  border: 2px solid gray;
  border-radius: 5px;
  height: 30px;
  /* width: 250px; */
  position: relative;

  padding: 2px 0px 2px 2px;
`;

export const FilterGroup: any = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FilterTypeSelect: any = styled.select`
  font-size: 18px;
  font-weight: 600;
  border: none;
  height: 30px;
  background-color: #505980;
  color: white;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;

  :hover {
    cursor: pointer;
  }
`;

const TypeaheadWrapper: any = styled.div`
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
  width: 250px;
  position: absolute;
  top: 30px;
  z-index: 99999;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

const TypeaheadItem: any = styled.div`
  border-bottom: 1px solid lightgray;
  padding-left: 3px;
  cursor: pointer;

  :hover {
    background-color: aliceblue;
  }
`;

const FiltersWrapper: any = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: flex-start;
  margin-bottom: 4px;
`;

const TypeaheadList = ({
  typeaheadValues,
  filterValue,
  handleClick,
  filterId,
  show,
}: {
  filterId: string;
  typeaheadValues: any;
  filterValue: string;
  handleClick: (valueToAdd: string) => void;
  show: boolean;
}) => {
  if (!show) {
    return null;
  }

  if (!filterValue) {
    return null;
  }

  const lastValue = filterValue.split(',').pop() || '';
  const valueToCheck: string = lastValue.trim().toLowerCase();
  if (!Boolean(valueToCheck)) {
    return null;
  }
  const matches: any = new Set();

  typeaheadValues.forEach((typeahead: string) => {
    if (typeahead.toLowerCase().startsWith(valueToCheck)) {
      matches.add(typeahead);
    }
  });

  typeaheadValues.forEach((typeahead: string) => {
    if (typeahead.toLowerCase().includes(valueToCheck)) {
      matches.add(typeahead);
    }
  });

  return (
    <>
      <div style={{ borderBottom: '2px solid lightgray', color: 'gray' }}>Suggestions</div>
      {[...matches].map((value: string, index: number) => (
        <TypeaheadItem
          key={value}
          onClick={() => {
            handleClick(`${value}, `);
            const filterInput = document.getElementById(filterId);
            // @ts-ignore
            filterInput.focus();
            setTimeout(() => {
              // @ts-ignore
              filterInput.scrollLeft = filterInput.scrollWidth;
              // @ts-ignore
              filterInput.focus();
            }, 0);
          }}
        >
          {value}
        </TypeaheadItem>
      ))}
    </>
  );
};

const FilterOptions = () => (
  <>
    <option value="default" disabled>
      Select a filter
    </option>
    {Object.keys(FilterMap).map((name) => (
      <option key={name} value={name}>
        {name}
      </option>
    ))}
  </>
);

const textFilter = (filterValue: string, shifts: any, key: string) => {
  const values = filterValue.toLowerCase().trim().split(',').filter(Boolean);
  return shifts.filter(({ patient }: any) =>
    values.some((name: string) => {
      return patient[key].toLowerCase().includes(name);
    })
  );
};

const FilterMap = {
  [FilterNames['Before Date']]: (filterValue: string, shifts: any) => {
    return shifts.filter(({ start }: any) => moment(filterValue).isSameOrAfter(start));
  },
  [FilterNames['After Date']]: (filterValue: string, shifts: any) => {
    return shifts.filter(({ start }: any) => {
      const isAfter = moment(filterValue).isSameOrBefore(start);
      return isAfter;
    });
  },
  [FilterNames['Patient Name']]: (filterValue: string, shifts: any) => {
    const key = 'name';
    return textFilter(filterValue, shifts, key);
  },
  [FilterNames['Patient ID']]: (filterValue: string, shifts: any) => {
    return shifts.filter(({ patient }: any) => patient.searchField.includes(filterValue));
  },
  [FilterNames['Min Skill Level']]: (filterValue: string, shifts: any) => {
    return shifts.filter(({ patient }: any) => patient.skillLevel >= parseInt(filterValue, 10));
  },
  [FilterNames['Max Skill Level']]: (filterValue: string, shifts: any) => {
    return shifts.filter(({ patient }: any) => patient.skillLevel <= parseInt(filterValue, 10));
  },
  [FilterNames.Location]: (filterValue: string, shifts: any) => {
    const key = 'address';
    return textFilter(filterValue, shifts, key);
  },
  [FilterNames['Primary Diagnosis']]: (filterValue: string, shifts: any) => {
    const key = 'primaryDiagnosis';
    return textFilter(filterValue, shifts, key);
  },
};

const Filter = ({ filterType, filterValue, filterIndex, updater, typeaheadValues }: any) => {
  let placeholder;
  let disableInput = false;
  let inputOptions: any = { type: 'text' };
  switch (filterType) {
    case FilterNames['After Date']:
      inputOptions = { type: 'date' };
      break;
    case FilterNames['Before Date']:
      inputOptions = { type: 'date' };
      break;
    case FilterNames['Patient Name']:
      placeholder = "Patient's name";
      inputOptions = { type: 'typeahead' };
      break;
    case FilterNames['Patient ID']:
      placeholder = "Patient's ID";
      inputOptions = { type: 'typeahead' };
      break;
    case FilterNames['Min Skill Level']:
      placeholder = 'Skill level at least';
      inputOptions = { type: 'number', step: '1', min: '0' };
      break;
    case FilterNames['Max Skill Level']:
      placeholder = 'Skill level at most';
      inputOptions = { type: 'number', step: '1', min: '0' };
      break;
    case FilterNames.Location:
      placeholder = 'Enter an Address';
      inputOptions = { type: 'typeahead' };
      break;
    case FilterNames['Primary Diagnosis']:
      placeholder = 'Diagnosis';
      inputOptions = { type: 'typeahead' };
      break;
    default:
      placeholder = 'Select a filter';
      disableInput = true;
  }

  const [show, updateShow] = useState(true);

  return (
    <FilterGroup>
      <FilterTypeSelect
        value={filterType}
        onChange={({ target: { value } }: any) => updater({ filterType: value, filterValue: '' })}
      >
        <FilterOptions />
      </FilterTypeSelect>
      <FilterInputWrapper
        onFocus={() => {
          setTimeout(() => {
            console.log('filter input wrapper update show - true');
            updateShow(true);
          }, 100);
        }}
        onBlur={() => {
          setTimeout(() => {
            console.log('filter input wrapper update show - false');
            updateShow(false);
          }, 100);
        }}
      >
        {inputOptions.type === 'date' && (
          <>
            <StyledDatePicker
              placeholderText="Click to select a date"
              selected={filterValue}
              onChange={(value: string) => updater({ filterValue: value })}
            />
            <FilterClear
              onClick={() => updater({ filterValue: '' })}
              style={{ visibility: filterValue ? 'visible' : 'hidden' }}
            >
              X
            </FilterClear>
          </>
        )}
        {inputOptions.type === 'typeahead' && (
          <>
            <TypeaheadWrapper>
              <TypeaheadList
                typeaheadValues={typeaheadValues}
                filterValue={filterValue}
                filterId={`shifts-filter-input-${filterIndex}`}
                handleClick={(valueToAdd: string) => {
                  const newFilter = filterValue.split(',');
                  newFilter[newFilter.length - 1] = valueToAdd;
                  const newFilterString = newFilter.join();
                  updater({ filterValue: newFilterString });
                }}
                show={show}
              />
            </TypeaheadWrapper>

            <FilterInput
              {...inputOptions}
              autoComplete={'off'}
              name={inputOptions.type + '-input'}
              id={`shifts-filter-input-${filterIndex}`}
              placeholder={placeholder}
              disabled={disableInput}
              value={filterValue}
              onChange={({ target: { value } }: any) => updater({ filterValue: value })}
            />
            <FilterClear
              onClick={() => updater({ filterValue: '' })}
              style={{ visibility: filterValue ? 'visible' : 'hidden' }}
            >
              X
            </FilterClear>
          </>
        )}
        {inputOptions.type !== 'typeahead' && inputOptions.type !== 'date' && (
          <>
            <FilterInput
              {...inputOptions}
              autoComplete={'off'}
              name="filter-input"
              placeholder={placeholder}
              disabled={disableInput}
              value={filterValue}
              onChange={({ target: { value } }: any) => updater({ filterValue: value })}
            />
            <FilterClear
              onClick={() => updater({ filterValue: '' })}
              style={{ visibility: filterValue ? 'visible' : 'hidden' }}
            >
              X
            </FilterClear>
          </>
        )}
      </FilterInputWrapper>
    </FilterGroup>
  );
};

class FiltersComponent extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      names: [],
      ids: [],
      diagnosis: [],
      locations: [],
    };
  }
  public componentWillReceiveProps({ shifts }: any) {
    const names = new Set(shifts.map(({ patient }: any) => patient.name));
    const ids = new Set(shifts.map(({ patient }: any) => patient.searchField));
    const diagnosis = new Set(shifts.map(({ patient }: any) => patient.primaryDiagnosis));
    const locations = new Set(shifts.map(({ patient }: any) => patient.address));

    this.setState({ names, ids, diagnosis, locations });
  }
  public componentDidUpdate(_: any, prevState: any) {
    if (this.state.names.length !== prevState.names.length) {
      this.handleApplyFilters();
    }
  }

  public filterUpdater = (index: number, newState: any) => {
    this.props.setFilter(this.props.filterTab, index, newState);
  };
  public addFilterGroup = () => {
    this.props.addFilter(this.props.filterTab);
  };
  public removeFilterGroup = (index: number) => {
    this.props.removeFilter(this.props.filterTab, index);
    setTimeout(() => this.handleApplyFilters());
  };
  public renderFilters = () => {
    if (this.props[this.props.filterTab]) {
      return this.props[this.props.filterTab].map(
        ({ filterType, filterValue }: any, index: number) => {
          let typeaheadValues: any;
          switch (filterType) {
            case FilterNames['Patient Name']:
              typeaheadValues = this.state.names;
              break;
            case FilterNames['Patient ID']:
              typeaheadValues = this.state.ids;
              break;
            case FilterNames.Location:
              typeaheadValues = this.state.locations;
              break;
            case FilterNames['Primary Diagnosis']:
              typeaheadValues = this.state.diagnosis;
              break;
            default:
              typeaheadValues = [];
          }
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            >
              <Filter
                filterIndex={index}
                updater={(newState: any) => this.filterUpdater(index, newState)}
                filterType={filterType}
                filterValue={filterValue}
                typeaheadValues={typeaheadValues}
              />
              <FilterButton
                style={{
                  marginLeft: '10px',
                  width: '30px',
                  visibility: this.props[this.props.filterTab].length <= 1 ? 'hidden' : 'visible',
                }}
                onClick={() => this.removeFilterGroup(index)}
              >
                -
              </FilterButton>
            </div>
          );
        }
      );
    } else {
      return null;
    }
  };

  public handleApplyFilters = () => {
    const filtered = this.props[this.props.filterTab].reduce(
      (acc: any, { filterType, filterValue }: any) => {
        if (filterType === 'default') {
          return acc;
        }
        // @ts-ignore
        return FilterMap[filterType](filterValue, acc);
      },
      [...this.props.shifts]
    );
    this.props.setFilteredShifts(filtered);
  };

  public handleClearFilters = () => {
    this.props.clearFilters(this.props.filterTab);
    setTimeout(() => {
      this.handleApplyFilters();
    }, 0);
  };

  public render() {
    return (
      <FiltersWrapper>
        <div>
          {this.renderFilters()}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FilterButton style={{ width: '30px' }} onClick={this.addFilterGroup}>
              +
            </FilterButton>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '3px' }}>
            <FilterButton secondary onClick={this.handleClearFilters}>
              Clear Filters
            </FilterButton>
            <FilterButton onClick={this.handleApplyFilters}>Apply Filters</FilterButton>
          </div>
        </div>
      </FiltersWrapper>
    );
  }
}

const mapStateToProps = ({
  openShiftsFilters,
  sentShiftsFilters,
  appliedShiftsFilters,
  awardedShiftsFilters,
  confirmedShiftsFilters,
  orientRequestsFilters,
}: any) => {
  return {
    openShiftsFilters,
    sentShiftsFilters,
    appliedShiftsFilters,
    awardedShiftsFilters,
    confirmedShiftsFilters,
    orientRequestsFilters,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      addFilter,
      removeFilter,
      setFilter,
      clearFilters,
    },
    dispatch
  );
};

export const Filters: any = connect(mapStateToProps, mapDispatchToProps)(FiltersComponent as any);
