import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import ShiftsView from './ShiftsView';
import { useSelector } from 'react-redux';
import {
  handleLandingPageRoutes,
  userCanNavigateToCaregiverShifts,
} from '../../routing/routeHandling';

// MASTER CAREGIVER SHIFTS COMPONENT, USED FOR SUB-ROUTING
export const CaregiverShiftsViewRouter = ({ match }: RouteComponentProps) => {
  const userToCheck = useSelector(({ user }: any) => user.user);
  const featureFlags = useSelector(({ user }: any) => user.featureFlags);
  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <div style={{ flex: 1, height: `100vh` }}>
        <Switch>
          {userCanNavigateToCaregiverShifts(userToCheck, featureFlags) ? (
            <Route exact path={`${match.url}`} component={ShiftsView} />
          ) : (
            <Redirect to={handleLandingPageRoutes(userToCheck)} />
          )}
        </Switch>
      </div>
    </div>
  );
};
