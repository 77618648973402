import { GridColumns } from '@mui/x-data-grid/models/colDef';
import { ICaregiverShiftsData } from '../../../../common/interfaces';
import statusColumn from './statusColumn';
import dateColumn from './dateColumn';
import caregiverColumn from './caregiverColumn';
import patientColumn from './patientColumn';
import timeInColumn from './timeInColumn';
import timeOutColumn from './timeOutColumn';
import distanceInColumn from './distanceInColumn';
import distanceOutColumn from './distanceOutColumn';
import clockInNoteColumn from './clockInNoteColumn';
import clockOutNoteColumn from './clockOutNoteColumn';
import alertsColumn from './alertsColumn';
import getActionsColumn from './actionsColumn';

const getColumns = (
  onShiftUpdated: (shiftData: ICaregiverShiftsData) => void
): GridColumns<ICaregiverShiftsData> => [
  statusColumn,
  dateColumn,
  caregiverColumn,
  patientColumn,
  timeInColumn,
  timeOutColumn,
  distanceInColumn,
  distanceOutColumn,
  clockInNoteColumn,
  clockOutNoteColumn,
  alertsColumn,
  getActionsColumn(onShiftUpdated),
];

export default getColumns;
