import React, { Component, Fragment } from 'react';
import { Route, withRouter, RouteComponentProps, Switch } from 'react-router-dom';

import { Header, Footer, WithTracker } from '../../common';
import { ROUTES } from '../routing.constants';

import { NotificationReceiver } from '../../notifications';
import { Login } from '../../login';
import { Password } from '../../password';
import { DashboardRouter } from '../../dashboard';
import { Chat } from '../../messaging';
import { Analytics } from '../../analytics';
import { Map } from '../../map';
import { AidesRouter } from '../../Aides';
import { CaregiverShiftsViewRouter } from '../../CaregiverShifts/containers/CaregiverShiftsViewRouter';
import { ShiftsContainer as Shifts } from '../../Shifts/containers/Shifts';
import { NotFound } from '../components/NotFound';
import { AdminRouter } from '../../Admin';
import { QANurseRouter } from '../../QANurse';
import { PatientsRouter } from '../../Patients/containers/PatientsRouter';
import { AdminViewRouter } from '../../AdminPortal/containers/AdminViewRouter';

class AuthRouter extends Component<RouteComponentProps, any> {
  public render() {
    const { history } = this.props;
    return (
      <Fragment>
        <NotificationReceiver />
        <Header id="header" />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path={ROUTES.PASSWORD} component={Password} />
          <Route exact path={ROUTES.MESSAGES} component={WithTracker(Chat)} />
          <Route exact path={ROUTES.ANALYTICS} component={WithTracker(Analytics)} />
          <Route exact path={ROUTES.MAP} component={WithTracker(Map)} />
          <Route path={ROUTES.AIDES} component={WithTracker(AidesRouter)} />
          <Route
            path={ROUTES.CAREGIVER_SHIFTS}
            component={WithTracker(CaregiverShiftsViewRouter)}
          />
          <Route path={ROUTES.ADMIN_VIEW} component={WithTracker(AdminViewRouter)} />
          <Route path={ROUTES.ALL_SHIFTS} component={WithTracker(QANurseRouter)} />
          <Route path={ROUTES.QA_SHIFTS} component={WithTracker(QANurseRouter)} />
          <Route path={`${ROUTES.SHIFTS}/:tabName`} component={Shifts} />
          <Route path={ROUTES.ADMIN} component={AdminRouter} />
          <Route path={ROUTES.DASHBOARD} component={WithTracker(DashboardRouter)} />
          <Route path={ROUTES.PATIENTS} component={WithTracker(PatientsRouter)} />
          <Route component={WithTracker(NotFound)} />
        </Switch>
        <Footer history={history} />
      </Fragment>
    );
  }
}

export default withRouter(AuthRouter);
