import React from 'react';

import MaterialIcon from 'material-icons-react';
import { ImageActivityValues } from './ActivityImages';
import styles from './ShiftDetails.module.css';

interface IProps {
  dvr: any;
}

export const Activities = ({ dvr }: IProps) => {
  const activities = dvr.fields ? dvr.fields : null;

  if (!activities) {
    return <div>No Daily Visit Record found.</div>;
  }

  const activitiesDisplay = {} as any;
  for (const activity of activities) {
    const { section, control, display, typeName, value } = activity;

    if (!activitiesDisplay[section]) {
      activitiesDisplay[section] = {} as any;
    }

    if (!activitiesDisplay[section][control]) {
      activitiesDisplay[section][control] = {} as any;
      activitiesDisplay[section][control].performed = null;
      activitiesDisplay[section][control].refused = null;
      activitiesDisplay[section][control].values = [] as any;
    }

    if (display === 'Performed' && typeName === 'button') {
      activitiesDisplay[section][control].performed = value === 'True' ? true : null;
    } else if (display === 'Refused' && typeName === 'button') {
      activitiesDisplay[section][control].refused = value === 'True' ? true : null;
    } else if (activity.value) {
      if (typeName === 'textarea') {
        activitiesDisplay[section][control].values.push(value);
        activitiesDisplay[section][control].performed = true;
      } else if (typeName === 'images') {
        activitiesDisplay[section][control].performed = value === 'True' ? true : null;
        // remove all whitespace and then split into single values
        activitiesDisplay[section][control].values = value.replace(/ /g, '').split(',');
        activitiesDisplay[section][control].type = 'image';
      } else if (activity.value === 'True') {
        activitiesDisplay[section][control].values.push(display);
      }
    }
  }

  const renderActivities = () => {
    const sections = Object.keys(activitiesDisplay);

    return (
      <div className={styles.Activities_Section}>
        {sections.map((sectionName) => {
          return (
            <div key={sectionName}>
              <div className={styles.Activities_Section__Header}>{sectionName.toUpperCase()}</div>
              <div className={styles.Activities_Section__body}>
                {Object.keys(activitiesDisplay[sectionName]).map(
                  (controlName: any, index: number) => {
                    return (
                      <div key={index}>
                        <div className={styles.Activities_Section__Control}>
                          {/* performed */}
                          {activitiesDisplay[sectionName][controlName].performed === true ? (
                            <div>
                              <MaterialIcon icon="check" color="green"></MaterialIcon>
                            </div>
                          ) : null}

                          {/* refused */}
                          {activitiesDisplay[sectionName][controlName].refused === true ? (
                            <div>
                              <MaterialIcon icon="block" color="maroon"></MaterialIcon>
                            </div>
                          ) : null}

                          {/* no entry */}
                          {activitiesDisplay[sectionName][controlName].performed === null &&
                          activitiesDisplay[sectionName][controlName].refused === null ? (
                            <div>
                              <MaterialIcon icon="trip_origin" color="grey"></MaterialIcon>
                            </div>
                          ) : null}

                          <div className={styles.Activites_Section__Control___name}>
                            {
                              `${controlName}
                        ` /* This is a very important line break. */
                            }
                            {getValueDisplay(activitiesDisplay[sectionName][controlName])}
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return <div className={styles.Activities}>{renderActivities()}</div>;
};

const getValueDisplay = (activity: any) => {
  switch (activity.type) {
    case 'image':
      return <ImageActivityValues images={activity.values} />;
    default:
      return (
        <span className={styles.Activities_Section__Control___value}>{`${activity.values.join(
          ', '
        )}`}</span>
      );
  }
};
