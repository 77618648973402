import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { updateNurseExternalId } from '../../../api/admin';
import { Input } from '@material-ui/core';
import { useToasts } from 'react-toast-notifications';
import { ToastAppearance, getDefaultToastConfig } from '../../../utils';

export const ExternalIdEditor = forwardRef((props: any, ref) => {
  const { node } = props;
  const originalExternalId = props.data.nurseExternalId;
  const [externalId, setExternalId] = useState(originalExternalId);
  const { addToast } = useToasts();

  const updateExternalId = async (nurseId: number, newExternalId: string) => {
    if (externalId !== originalExternalId) {
      try {
        await updateNurseExternalId(nurseId, newExternalId);
        addToast(
          originalExternalId !== null
            ? `External ID updated from ${originalExternalId} to ${newExternalId}`
            : `External ID updated to ${newExternalId}`,
          getDefaultToastConfig(ToastAppearance.SUCCESS)
        );
      } catch (error) {
        addToast((error as Error).message, getDefaultToastConfig(ToastAppearance.WARNING));
        node.setDataValue('nurseExternalId', originalExternalId);
      }
    }
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        updateExternalId(props.data.nurseId, externalId);
        return externalId;
      },
    };
  });

  return (
    <Input
      id="user-externalid-input"
      defaultValue={originalExternalId}
      onChange={(e) => setExternalId(e.currentTarget.value)}
    />
  );
});

export default ExternalIdEditor;
