import styled from 'styled-components';

export const ConfirmSubPanelContainer = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  /* flex: 1; */
  display: inline-block;
  padding: 0px 24px 6px 24px;
`;

export const ConfirmButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
`;

export const ConfirmMessageContainer = styled.div`
  margin-bottom: 12px;
`;

export const CofirmationErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error};
`;

export const ShiftFooterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin: 12px 0px;
`;

export const ShiftFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ShiftFooterEditMessage = styled.div`
  color: red;
  font-style: italic;
  font-size: 1rem;
`;

export const ClearReasonButton = styled.button`
  display: flex;
  justify-content: center;
  margin-left: 2px;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: ${({ theme }) => theme.buttonBorderRadius};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const AddRejectionButton = styled.button`
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 6px 12px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.buttonBorderRadius};
  border: ${({ theme, color }) => `1px solid ${color}`};
  color: ${({ theme, color }) => `1px solid ${color}`};
`;
