import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import MaterialIcon from 'material-icons-react';
// @ts-ignore error: TS7016
import Sidebar from 'react-sidebar';

import { getDvrByClockInOutReviewId, saveClockInOut } from '../../api';
import { StyledCheckbox, theme } from '../../common';
import {
  setCurrentShift as setCurrentShiftAction,
  clearCurrentShift as clearCurrentShiftAction,
} from '../actions';
import { Table, TableActionButton } from '../../Shifts/components/Table';
import { ShiftSidebar } from '../ShiftPanel/ShiftSidebar';
import styles from './AidesRejectedShifts.module.css';
import ReusableTooltip, { GetSourceAlert } from '../../common/ReusableTooltip';
import { StatusType } from '../../utils';
import AideShiftSearch from '../components/AidesShiftSearch/AideShiftSearchContainer';
import { searchReviews, ISearchReviewsInput, createSearchReviewsInput } from '../../api/clockInOut';
import { ISearchState } from '../searchReducer';
import { zipAllDvrs } from '../ShiftDetails/ShiftDetailsPrinter';
import {
  getOnlyEvvShiftFilter,
  isEvvSource,
  ONLY_EVV_SHIFT_FILTER,
  setOnlyEvvShiftFilter,
} from '../../utils/app';

export const search = async (
  startDate: Date,
  endDate: Date,
  term: string,
  setIsLoading: any,
  setError: any,
  setReviews: any
) => {
  let collection: any = [];
  try {
    const searchParams: ISearchReviewsInput = createSearchReviewsInput(startDate, endDate, term);
    setIsLoading && setIsLoading(true);
    collection = await searchReviews(searchParams);
    collection = collection ? collection : [];
  } catch (e) {
    console.error(e);
    setError && setError(true);
    setIsLoading && setIsLoading(false);
    return;
  }

  setError && setError(false);
  setIsLoading && setIsLoading(false);

  setReviews(collection);
  return collection;
};

interface ITableData {
  shiftDate: string;
  aideFullName: string;
  patientName: string;
  reviewedBy: string;
}

interface IProps extends RouteComponentProps {
  setCurrentShift: (shift: any) => void;
  clearCurrentShift: () => void;
}

interface IProps extends RouteComponentProps {
  setCurrentShift: (shift: any) => void;
  clearCurrentShift: () => void;
  searchParams: ISearchState;
}

type IState = {
  isShiftPanelOpen: boolean;
  error: boolean;
  loading: boolean;
  allShifts: any;
  filteredShifts: any;
  hydratedShift: any;
  review: any;
  selected: any;
  printAllLoading: boolean;
  onlyEvvShifts: boolean;
};

class AidesAllShifts extends Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.setIsLoading = this.setIsLoading.bind(this);
    this.setReviews = this.setReviews.bind(this);
    this.setError = this.setError.bind(this);
    this.filterEvvShifts = this.filterEvvShifts.bind(this);
  }

  public state: IState = {
    isShiftPanelOpen: false,
    error: false,
    loading: false,
    allShifts: [],
    filteredShifts: [],
    hydratedShift: null as any,
    review: {},
    selected: {},
    printAllLoading: false,
    onlyEvvShifts: getOnlyEvvShiftFilter(),
  };

  private getSearchReviews = async (searchParams: ISearchReviewsInput) => {
    try {
      search(
        searchParams.searchStartDate,
        searchParams.searchEndDate,
        searchParams.searchTerm,
        this.setIsLoading,
        this.setError,
        this.setReviews
      );
    } catch (error) {
      console.error(error);
      this.setState({ error: true, loading: false, allShifts: [], filteredShifts: [] });
    }
  };

  private filterEvvShifts(event?: any) {
    const onlyEvvShifts = event ? event.target.checked : this.state.onlyEvvShifts;
    if (event) {
      this.setState({
        onlyEvvShifts: event.target.checked,
      });
      setOnlyEvvShiftFilter(event.target.checked);
    }

    if (onlyEvvShifts) {
      const filteredList = this.state.allShifts.filter((review: any) => {
        return isEvvSource(review);
      });
      this.setState({
        filteredShifts: filteredList,
      });
    } else {
      this.setState({
        filteredShifts: this.state.allShifts,
      });
    }
  }

  private getTableData(allShifts: []) {
    const tableData: ITableData[] = [];
    allShifts.map((obj: any) => {
      const aideFullName = obj.nurseProfile.user.givenName + ' ' + obj.nurseProfile.user.familyName;
      tableData.push({
        shiftDate: obj.adjustedClockInTimeStamp,
        aideFullName,
        patientName: obj.patient.givenName + ' ' + obj.patient.familyName,
        reviewedBy: obj.modifier.givenName + ' ' + obj.modifier.familyName,
        externalId: obj.patient.externalId,
        externalSource: obj.patient.externalSource,
        searchField: obj.patient.searchField,
        ...obj,
      });
    });
    this.setState(
      {
        allShifts: tableData,
        filteredShifts: tableData,
        loading: false,
      },
      this.filterEvvShifts
    );
  }

  public setIsLoading = (isLoading: boolean) => {
    this.setState({ loading: isLoading });
  };

  public setReviews = (reviews: any) => {
    this.setState({ allShifts: reviews, filteredShifts: reviews }, () => {
      if (reviews.length) {
        this.getTableData(reviews);
      }
    });
  };

  public setError = (error: boolean) => {
    this.setState({ error });
  };

  private handleOnClickReview = async (shift: any) => {
    const { setCurrentShift } = this.props;
    const htmlResponse = await getDvrByClockInOutReviewId(shift.id);
    const hydratedShift = htmlResponse.body;

    setCurrentShift(hydratedShift);
    this.setState({ isShiftPanelOpen: true, hydratedShift, review: shift });
  };

  private getColumns() {
    return [
      {
        Header: (
          <StyledCheckbox
            checked={
              Object.keys(this.state.selected).length > 0 &&
              Object.keys(this.state.selected).length === this.state.allShifts.length
            }
            onClick={() => {
              if (Object.keys(this.state.selected).length === this.state.allShifts.length) {
                this.setState({ selected: {} });
              } else {
                const newSelected: any = {};
                this.state.allShifts.map((shift: any) => (newSelected[shift.id] = shift));
                this.setState({
                  selected: newSelected,
                });
              }
            }}
          />
        ),
        accessor: 'checkbox',
        minWidth: 100,
        style: {
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        },
        Cell: (row: any) => {
          return (
            <StyledCheckbox
              checked={this.state.selected[row.original.id]}
              onClick={() => {
                const selected = this.state.selected;
                selected[row.original.id]
                  ? delete selected[row.original.id]
                  : (selected[row.original.id] = row.original);
                this.setState({ selected });
              }}
            />
          );
        },
      },
      {
        Header: 'SHIFT DATE',
        accessor: 'shiftDate',
        minWidth: 100,
        Cell: (row: any) => {
          return <div>{moment(row.original.shiftDate).format('MM-DD-YYYY')}</div>;
        },
      },
      {
        Header: 'CAREGIVER',
        accessor: 'aideFullName',
        minWidth: 100,
        Cell: (row: any) => {
          return (
            <div>
              <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                {row.original.aideFullName}
              </div>
            </div>
          );
        },
      },
      {
        Header: 'PATIENT NAME',
        accessor: 'patientName',
        minWidth: 100,
      },
      {
        Header: 'MR ID',
        accessor: 'searchField',
        minWidth: 100,
      },
      {
        Header: 'REVIEWED BY',
        accessor: 'reviewedBy',
        minWidth: 100,
      },
      {
        Header: 'QA',
        accessor: 'qa',
        minWidth: 80,
        Cell: (row: any) => {
          const data: ITableData = row.original;

          // default value for StatusType.PENDING
          let iconText = '';
          let iconName = '';
          let iconColor = '';

          if (row.original.qaComplete) {
            iconName = 'check';
            iconText = 'Complete';
            iconColor = 'green';
          } else if (row.original.qa) {
            iconName = 'check_circle';
            iconText = 'Marked';
            iconColor = 'darkRed';
          } else if (row.original.systemQA) {
            iconName = 'system_update';
            iconText = 'Random';
            iconColor = 'darkOrange';
          }
          return (
            <div style={{ fontSize: '0.9rem', fontWeight: 700 }}>
              <div>
                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                  <MaterialIcon key={iconName} icon={iconName} color={iconColor} size="small" />
                </div>
                <div
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    paddingLeft: 10,
                    color: iconColor,
                  }}
                >
                  {iconText}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'ALERTS',
        accessor: 'alerts',
        minWidth: 100,
        style: { overflow: 'visible' },
        Cell: (row: any) => {
          const timeAlerts = row.original.timeAlerts;
          const locationAlerts = row.original.locationAlerts;
          const overlapAlerts = row.original.overlapAlerts;
          const officeAlerts = row.original.officeAlerts;
          const patientSource = [row.original.externalSource];

          const timeAlertsExist = timeAlerts && timeAlerts.length > 0;
          const locationAlertsExist =
            locationAlerts &&
            ((locationAlerts.clockInAlert && locationAlerts.clockInAlert.length > 0) ||
              (locationAlerts.clockOutAlert && locationAlerts.clockOutAlert.length > 0));
          const overlapAlertsExist = overlapAlerts && overlapAlerts.length > 0;
          const officeAlertsExist = officeAlerts && officeAlerts.length > 0;
          const alertsExist =
            timeAlertsExist || locationAlertsExist || overlapAlertsExist || officeAlertsExist;

          return (
            <>
              {alertsExist ? null : <MaterialIcon icon="check" color="green" size="small" />}
              <ReusableTooltip
                zIndex={300}
                fontColor={'black'}
                backgroundColor={'white'}
                placement={'bottom'}
                data={timeAlerts}
                tooltipHeader={'Time Issues'}
                iconName={'access_time'}
                iconColor={'maroon'}
                autoWidth={true}
              />

              <ReusableTooltip
                zIndex={300}
                fontColor={'black'}
                backgroundColor={'white'}
                placement={'bottom'}
                data={locationAlerts}
                tooltipHeader={'Location Issues'}
                iconName={'room'}
                iconColor={'maroon'}
                autoWidth={true}
              />

              <ReusableTooltip
                zIndex={300}
                fontColor={'black'}
                backgroundColor={'white'}
                placement={'bottom'}
                data={overlapAlerts}
                tooltipHeader={'Overlap Issues'}
                iconName={'settings_overscan'}
                iconColor={'maroon'}
                autoWidth={true}
              />

              <ReusableTooltip
                zIndex={300}
                fontColor={'black'}
                backgroundColor={'white'}
                placement={'bottom'}
                data={officeAlerts}
                tooltipHeader={'Office Issues'}
                iconName={'location_city'}
                iconColor={'maroon'}
                autoWidth={true}
              />
              <ReusableTooltip
                zIndex={300}
                fontColor={'black'}
                backgroundColor={'white'}
                placement={'bottom'}
                data={patientSource}
                tooltipHeader={'Patient Source'}
                iconName={'account_circle'}
                iconColor={'maroon'}
                autoWidth={true}
              />
              {GetSourceAlert('Clock In Source', row.original.clockInSource)}
              {GetSourceAlert('Clock Out Source', row.original.clockOutSource)}
            </>
          );
        },
      },
      {
        Header: 'STATUS',
        accessor: 'status',
        sortMethod: (a: any, b: any) => {
          return a > b ? 1 : a < b ? -1 : 0;
        },
        minWidth: 100,
        Cell: (row: any) => {
          const data: ITableData = row.original;

          // default value for StatusType.PENDING
          let iconText = 'Pending';
          let iconName = 'error';
          let iconColor = 'darkorange';

          if (row.original.status === StatusType.APPROVED) {
            iconName = 'check_circle';
            iconText = 'Approved';
            iconColor = 'green';
          } else if (row.original.status === StatusType.REJECTED) {
            iconText = 'Reviewing';
            iconName = 'cancel';
            iconColor = 'maroon';
          }
          return (
            <div style={{ fontSize: '0.9rem', fontWeight: 700 }}>
              <div>
                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                  <MaterialIcon key={iconName} icon={iconName} color={iconColor} size="small" />
                </div>
                <div
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    paddingLeft: 10,
                    color: iconColor,
                  }}
                >
                  {iconText}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'ACTIONS',
        accessor: null,
        minWidth: 100,
        Cell: (row: any) => {
          const data: ITableData = row.original;
          return (
            <TableActionButton
              data-cy={'review-btn-aides'}
              onClick={() => {
                this.handleOnClickReview(data);
              }}
              style={{
                cursor: 'pointer',
                margin: '0.5rem 1rem 0.5rem 1rem',
                padding: '0.5rem 1rem 0.5rem 1rem',
                pointerEvents: 'auto',
                fontSize: '0.9rem',
                fontWeight: 700,
                color: 'white',
                backgroundColor: '#0075c9',
                borderWidth: 0,
                borderRadius: 6,
              }}
            >
              Review
            </TableActionButton>
          );
        },
      },
    ];
  }

  private async handleSaveReview(review: any) {
    const { clearCurrentShift } = this.props;

    await saveClockInOut(review);
    clearCurrentShift();
    this.setState({ isShiftPanelOpen: false, hydratedShift: null });
  }

  private handleCloseReview = () => {
    const { clearCurrentShift } = this.props;

    clearCurrentShift();
    this.setState({ isShiftPanelOpen: false, hydratedShift: null });
  };

  public componentDidMount() {
    const { searchParams } = this.props;
    this.getSearchReviews(searchParams);
  }

  public render() {
    const { searchParams } = this.props;

    return (
      <div className={styles.SA_Parent}>
        <Sidebar
          sidebar={
            <ShiftSidebar
              dvr={this.state.hydratedShift}
              onClose={this.handleCloseReview}
              onSaveNew={() => {}}
              onSave={(review: any) => this.handleSaveReview(review)}
              review={this.state.review}
              refreshData={() => this.getSearchReviews(searchParams)}
            />
          }
          pullRight={true}
          open={this.state.isShiftPanelOpen}
          styles={{
            sidebar: {
              display: 'flex',
              background: 'white',
              zIndex: 6,
            },
            overlay: { zIndex: 5 },
          }}
        >
          <div style={{ padding: 24, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ flex: 1 }}>
              <div style={{ color: theme.colors.defaultFontColor, fontSize: theme.fontSizeLg }}>
                All Shifts
              </div>
            </div>
            <div style={{ flex: 3 }}>
              <AideShiftSearch
                setIsLoading={this.setIsLoading}
                setReviews={this.setReviews}
                setError={this.setError}
              />
            </div>
            <div style={{ flex: 1 }}>
              <TableActionButton
                onClick={async () => {
                  this.setState({ printAllLoading: true });
                  await zipAllDvrs(Object.values(this.state.selected));
                  this.setState({ printAllLoading: false });
                }}
              >
                {this.state.printAllLoading ? 'Loading...' : 'Print Selected Shifts'}
              </TableActionButton>
            </div>
          </div>
          <label>
            <input
              type="checkbox"
              checked={this.state.onlyEvvShifts}
              onChange={this.filterEvvShifts}
            />
            {ONLY_EVV_SHIFT_FILTER}
          </label>
          <Table
            defaultPageSize={100}
            minRows={10}
            showPagination={true}
            data={this.state.filteredShifts}
            columns={this.getColumns()}
            resizable={true}
            loading={this.state.loading}
            loadingText="Loading..."
            noDataText={
              this.state.error
                ? 'Error fetching data.'
                : 'No results. Try searching for the name of a patient, aide, or reviewed by.'
            }
            sortable={true}
            style={{
              width: 'unset',
              height: '90%',
              overflow: 'hidden',
              boxShadow: 'none',
              border: 'none',
            }}
          />
        </Sidebar>
      </div>
    );
  }
}

const mapStateToProps = ({ searchReducer }: any) => {
  return {
    searchParams: searchReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCurrentShift: (shift: any) => dispatch(setCurrentShiftAction(shift)),
    clearCurrentShift: () => dispatch(clearCurrentShiftAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AidesAllShifts);
