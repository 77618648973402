import MaterialIcon from 'material-icons-react';
import React, { useState, MouseEvent, FocusEvent } from 'react';
import styled from 'styled-components';

interface IMoreVertButtonProps {
  handleClick: () => void;
}

interface IActionsProps {
  menuItems?: IMenuItemProps[];
}

interface IMenuItemsProps {
  menuItems?: IMenuItemProps[];
  blur: () => void;
}

interface IMenuItemProps {
  name: string;
  action?: () => void;
  component?: JSX.Element;
}

const StyledActionsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const StyledVertIconButton = styled.button`
  cursor: pointer;
  background: inherit;
  border: none;
  outline: none;
`;

const StyledMoreVertButtonContainer = styled.div`
  position: absolute;
`;

const StyledMenuItems = styled.div`
  outline: none;
  border: ${({ theme }) => `1px solid ${theme.colors.aveannaDarkBlueGray}`};
  border-radius: 5px;
  box-shadow: ${({ theme }) => `${theme.defaultBoxShadow}`};
  position: absolute;
  min-width: 10rem;
  right: 80%;
  background-color: white;
`;

export const StyledMenuItem = styled.div`
  cursor: pointer;
  color: ${({ theme }) => `${theme.colors.aveannaDarkBlue}`};
  margin: 0.5rem;
  font-size: 1.1rem;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledMenuMessage = styled.div`
  color: ${({ theme }) => `${theme.colors.aveannaDarkBlue}`};
  margin: 0.5rem;
  font-size: 1.1rem;
`;

const MoreVertIcon = () => <MaterialIcon icon="more_vert" />;

const MoreVertButton = (props: IMoreVertButtonProps) => (
  <StyledVertIconButton
    onClick={() => {
      props.handleClick();
    }}
  >
    <MoreVertIcon />
  </StyledVertIconButton>
);

const MenuItem = (props: IMenuItemProps) => (
  <StyledMenuItem
    onMouseDown={(event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      if (props.action) {
        props.action();
      }
    }}
  >
    {props.name}
  </StyledMenuItem>
);

const MenuMessage = (props: IMenuItemProps) => <StyledMenuMessage>{props.name}</StyledMenuMessage>;

const MenuItems = (props: IMenuItemsProps) => {
  const [isProcessing, setIsProcessing] = useState(false);
  if (!props.menuItems || !props.menuItems.length) {
    return <>No Actions Available</>;
  }

  return (
    <StyledMenuItems
      onBlur={(event: FocusEvent) => {
        if (!isProcessing) {
          props.blur();
        }
      }}
    >
      {!isProcessing &&
        props.menuItems.map((menuItem) => {
          if (menuItem.component) {
            return <div key={menuItem.name}>{menuItem.component}</div>;
          }
          return (
            <MenuItem
              key={menuItem.name}
              name={menuItem.name}
              action={
                !isProcessing
                  ? async () => {
                      setIsProcessing(true);
                      if (menuItem.action) {
                        await menuItem.action();
                      }
                      setIsProcessing(false);
                      props.blur();
                    }
                  : () => {}
              }
            ></MenuItem>
          );
        })}
      {isProcessing && (
        <MenuMessage key="processing" name="Processing..." action={() => {}}></MenuMessage>
      )}
    </StyledMenuItems>
  );
};

export const Actions = (props: IActionsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <StyledActionsContainer>
      <StyledMoreVertButtonContainer>
        {isOpen && (
          <MenuItems
            blur={() => {
              setIsOpen(false);
            }}
            menuItems={props.menuItems}
          />
        )}

        <MoreVertButton
          handleClick={() => {
            setIsOpen(!isOpen);
          }}
        />
      </StyledMoreVertButtonContainer>
    </StyledActionsContainer>
  );
};
