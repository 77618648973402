import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { device } from './device/deviceSizes';

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.aveannaDarkBlue};
  text-decoration: none;

  :visited {
    color: ${({ theme }) => theme.colors.aveannaDarkBlue};
  }

  :hover {
    color: ${({ theme }) => theme.colors.aveannaLightBlue};
  }
`;

interface INavLinkProps {
  isActive: boolean;
  href: string;
  style?: any;
  children?: any;
  small?: boolean;
}

const NavLink: React.SFC<INavLinkProps> = (props) => {
  const { isActive, href, small, ...rest } = props;
  return (
    <Link {...rest} to={href}>
      {props.children}
    </Link>
  );
};

export const StyledNavLink: any = styled(NavLink)`
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.aveannaDarkBlue : theme.colors.aveannaGray};
  font-size: ${({ theme }) => theme.fontSizeSm};
  margin-right: 48px;
  text-decoration: none;
  letter-spacing: 1px;

  /* above mobile L */
  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.fontSizeMd};
  }

  ${(props) =>
    props.small &&
    css`
      font-size: ${props.theme.fontSizeXs};
      margin-right: 0px;
      margin-bottom: 6px;

      /* above mobile L */
      @media ${device.tablet} {
        font-size: ${({ theme }) => theme.fontSizeSm};
      }
    `}
`;
// NOT A LINK (as in, it is just styled the same but has no shared behaviour)
interface INavItemProps {
  isActive: boolean;
  style?: any;
  children?: any;
}

const NavItem: React.SFC<INavItemProps> = (props) => {
  const { isActive, ...rest } = props;
  return <div {...rest}>{props.children}</div>;
};

export const StyledNavItem: any = styled(NavItem)`
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.aveannaDarkBlue : theme.colors.aveannaGray};
  font-size: ${({ theme }) => theme.fontSizeSm};
  margin-right: 24px;
  text-decoration: none;
  letter-spacing: 1px;

  /* above mobile L */
  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.fontSizeMd};
  }
`;
