import React, { Component } from 'react';
import styled from 'styled-components';
import { noop } from 'lodash';

const CustomHandle = styled.div`
  position: absolute;
  margin-left: -4px;
  margin-top: -3px;
  width: 10px;
  height: 10px;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  background-color: #fff;
  border: none;
  touch-action: pan-x;
  left: ${(props: any) => props.offset}%;
  &:after {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    left: -5px;
    border: 2px solid white;
  }
`;

export class Handle extends React.PureComponent<any, any> {
  constructor(props: any) {
    super(props);
  }

  public clickFocus() {
    noop();
  }

  public render() {
    return <CustomHandle {...this.props} />;
  }
}
