import { api } from './';
import { IUserSignInInput } from '../login/interfaces';
import {
  storeLocalUser,
  setLocalUserRemembered,
  clearLocalRememberedUser,
  PasswordStatus,
} from '../utils';
import { get, post, authCodeSignIn, signIn } from './apiCallHelper';
import { isCookieAuthEnabled } from '../utils/app';

export const userLogin = async ({ email, password, isRemembered }: IUserSignInInput) => {
  if (isRemembered) {
    setLocalUserRemembered(email);
  } else {
    clearLocalRememberedUser();
  }

  try {
    const loginResponse = await post('api/User/Authenticate', {
      body: {
        username: email.trim(),
        password: password.trim(),
        application: 'Scheduler',
      },
    });

    // check the response status code
    switch (loginResponse.status) {
      case 403:
        throw new Error(await loginResponse.text());
      case 422:
        throw new Error('There was an issue logging in, check your credentials and try again.');
      default:
        break;
    }

    if (loginResponse.body.user === undefined) {
      throw new Error('User Does not Exist');
    }

    // assign jwt to the api master obj
    const monolithToken = loginResponse.body.token || 'Obsolete';

    if (!isCookieAuthEnabled()) {
      await api.jwt(monolithToken);
    }

    const tempUser = loginResponse.body.user;
    storeLocalUser(tempUser, monolithToken, '', '', '');

    return {
      user: tempUser,
      token: loginResponse.body.token,
      ...getPasswordStatus(tempUser),
    };
  } catch (error) {
    console.log('login error: ', error);
    throw new Error('There was an issue logging in, check your credentials and try again.');
  }
};

export const userLoginAuthCode = async (
  authcode: string,
  redirecturi: string,
  codeverifier: string,
  application: string
) => {
  try {
    const loginResponse = await authCodeSignIn(
      'api/User/AuthCodeAuthenticate',
      authcode.trim(),
      redirecturi.trim(),
      codeverifier.trim(),
      application.trim()
    );

    // check the response status code
    switch (loginResponse.status) {
      case 403:
        throw new Error(await loginResponse.text());
      case 422:
        throw new Error('There was an issue logging in, check your credentials and try again.');
      default:
        break;
    }

    // assign jwt to the api master obj
    const monolithToken = loginResponse.body.token || 'Obsolete';
    const oneloginAccessToken = loginResponse.body.accessToken || 'Obsolete';
    const oneloginRefreshToken = loginResponse.body.refreshToken || 'Obsolete';
    const oneloginIdToken = loginResponse.body.originalIdToken || 'Obsolete';

    if (!isCookieAuthEnabled()) {
      await api.jwt(monolithToken);
    }

    const tempUser = loginResponse.body.user;
    storeLocalUser(
      tempUser,
      monolithToken,
      oneloginAccessToken,
      oneloginRefreshToken,
      oneloginIdToken
    );

    return {
      user: tempUser,
      token: loginResponse.body.token,
      ...getPasswordStatus(tempUser),
    };
  } catch (error) {
    console.log('login error: ', error);
    throw new Error('There was an issue logging in, check your credentials and try again.');
  }
};

export const getUsersByOfficeId = async (officeId: number) => {
  return await get(`/api/User/OfficeUsers/${officeId}`);
};

export const resendEmailNotification = async (userId: number) => {
  return await post(`/api/User/${userId}/ResendActivationEmail`);
};

const getPasswordExpiryInDays = (lastUpdate: Date) => {
  const mustChangeTimeInMs = lastUpdate.getTime() + expirationTimeMs;
  return Math.ceil((mustChangeTimeInMs - Date.now()) / dayInMs);
};

// TIME
const dayInMs = 24 * 60 * 60 * 1000;
const expirationTimeInDays = 90;
const expirationTimeMs = expirationTimeInDays * dayInMs;
const warningDays = 7;
const forceRedirectDays = 3;
const forceRedirectMs = forceRedirectDays * dayInMs;
const warningDaysMs = warningDays * dayInMs;

const getPasswordStatus = (user: any) => {
  if (user.mustChangePassword) {
    return {
      passwordStatus: PasswordStatus.MUST_CHANGE_PASSWORD,
      nextRequiredPasswordChangeDays: 0,
    };
  }

  // Shouldn't be a valid scenario in real world, setting
  // Status to OKAY to basically skip this scenario and
  // log user in as usual
  if (!user.lastPasswordUpdate) {
    return {
      passwordStatus: PasswordStatus.OKAY,
      nextRequiredPasswordChangeDays: 0,
    };
  }

  const lastUpdate = new Date(user.lastPasswordUpdate);
  const mustChangeTimeInMs = lastUpdate.getTime() + expirationTimeMs;

  if (mustChangeTimeInMs <= Date.now() + forceRedirectMs) {
    return {
      passwordStatus: PasswordStatus.EXPIRATION_CRITICAL_WARNING,
      nextRequiredPasswordChangeDays: getPasswordExpiryInDays(lastUpdate),
    };
  } else if (mustChangeTimeInMs <= Date.now() + warningDaysMs) {
    return {
      passwordStatus: PasswordStatus.EXPIRATION_WARNING,
      nextRequiredPasswordChangeDays: getPasswordExpiryInDays(lastUpdate),
    };
  } else {
    return {
      passwordStatus: PasswordStatus.OKAY,
      mustChangeTimeInDays: getPasswordExpiryInDays(lastUpdate),
    };
  }
};
