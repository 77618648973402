import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { ROUTES } from '../../routing';
import { ChangePassword, RequestPasswordReset, ResetPassword } from './';

// MASTER PASSWORD COMPONENT, USED FOR SUB-ROUTING
export const Password = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route path={`${match.url}${ROUTES.CHANGE_PASSWORD}/:status`} component={ChangePassword} />
      <Route path={`${match.url}${ROUTES.CHANGE_PASSWORD}`} component={ChangePassword} />
      <Route
        path={`${match.url}${ROUTES.REQUEST_PASSWORD_RESET}`}
        component={RequestPasswordReset}
      />
      {/* One with and one without param, so you can still nav directly */}
      <Route path={`${match.url}${ROUTES.RESET_PASSWORD}/:email`} component={ResetPassword} />
      <Route path={`${match.url}${ROUTES.RESET_PASSWORD}`} component={ResetPassword} />
    </Switch>
  );
};
