import moment from 'moment';

export const parseTime = (time: string, timezone: string): moment.Moment | null => {
  if (!time) {
    return null;
  }
  if (!timezone) {
    return moment(time);
  }

  return moment.tz(time, timezone);
};

export const formatDateString = (time: string, timezone: string): string | null => {
  const formattedDate = parseTime(time, timezone);
  return formattedDate ? formattedDate.format('hh:mm A') : '?';
};
