import { sortBy } from 'lodash';
import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import GoToBuilderButton from '../../builder/GoToBuilderButton';
import { Table } from '../../Shifts/components/Table';
import usePatient from '../hooks/usePatient';
import MaterialIcon from 'material-icons-react';

const Header = styled.div`
  flex: 1;
  color: ${(props) => props.theme.colors.defaultFontColor};
  font-size: ${(props) => props.theme.fontSizeLg};
`;

const Cell = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const isPastActivation = (date: string) => {
  const d = new Date(date);
  return d <= new Date();
};

const formatActiveDate = (date: string) => {
  if (isPastActivation(date)) date = '';
  return formatDate(date);
};

const formatDate = (date: string, noDateMessage = 'Past Activation') =>
  date ? new Date(date).toLocaleDateString() : noDateMessage;

interface IRouteParams {
  patientId: string;
}

const PatientForms = () => {
  const { patientId } = useParams<IRouteParams>();
  const { patient, forms, loading } = usePatient(patientId, { includeForms: true });
  return (
    <Fragment>
      <div style={{ display: 'flex', justifyContent: 'space-apart', margin: 10 }}>
        <Header>Patient Forms {loading ? '' : `for ${patient && patient.fullName}`}</Header>
        {!loading && (
          <GoToBuilderButton
            patient={patient}
            lastForm={forms && Array.isArray(forms) && forms[0]}
          />
        )}
      </div>
      <Table
        columns={[
          {
            Header: 'Form Name',
            accessor: 'title',
          },
          {
            Header: 'Activates On',
            accessor: 'activateOn',
            Cell: (row: any) => <Cell>{formatActiveDate(row.original.activateOn)}</Cell>,
          },
          {
            Header: 'Created On',
            accessor: 'createdOn',
            Cell: (row: any) => <Cell>{formatDate(row.original.createdOn)}</Cell>,
          },
          {
            Header: 'Is Active',
            accessor: '',
            Cell: (row: any) => <MaterialIcon icon={row.original.active ? 'check' : 'clear'} />,
          },
        ]}
        style={{
          width: 'unset',
          height: '80%',
          overflow: 'hidden',
          boxShadow: 'none',
          border: 'none',
        }}
        loadingText="Loading..."
        sortable
        data={forms}
        resizable
        loading={loading}
        showPagination
        minRows={10}
        defaultPageSize={25}
      />
    </Fragment>
  );
};

export default PatientForms;
