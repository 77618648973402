import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import MaterialIcon from 'material-icons-react';

import styles from './ShiftDetails.module.css';
import { getImage } from '../../api';

// Styles =====
const PhotoIconBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2px;

  cursor: pointer;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.aveannaPrimary};

  :hover {
    background-color: ${({ theme }) => theme.colors.aveannaFadedBlue};
  }
`;

const PhotoValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PhotoImagesContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

const ClickableImage = styled.img`
  height: 100px;
  width: 100px;
  margin: 6px;
  cursor: pointer;
  box-sizing: border-box;

  :hover {
    border: ${({ theme }) => `2px solid ${theme.colors.aveannaPrimary}`};
  }
`;

const rotationKeyFrames = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const RotationAnimationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${rotationKeyFrames} 1500ms infinite linear;
`;

const INFO_TEXT = `Click an Icon to View the Associated Image`;

// Components =====
export const ImageActivityValues = ({ images }: any) => {
  return (
    <PhotoValuesContainer>
      <p className={styles.smallItalicIconText}>{INFO_TEXT}</p>
      <PhotoImagesContainer>
        {images &&
          images.map((tempImage: number) => {
            return <ImageDisplay key={`image-${tempImage}`} imageID={tempImage} />;
          })}
      </PhotoImagesContainer>
    </PhotoValuesContainer>
  );
};

export const ImageDisplay = ({ imageID }: any) => {
  const [imageObjectURL, setImageObjectURL] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onGetImage = async () => {
    setIsLoading(true);

    const tempImageBlob = await getImage(imageID);
    const tempImageURL = URL.createObjectURL(tempImageBlob);
    setImageObjectURL(tempImageURL);
    window.open(tempImageURL, '_blank');

    setIsLoading(false);
  };

  const onOpenImage = () => {
    if (imageObjectURL) {
      window.open(imageObjectURL, '_blank');
    }
  };

  useEffect(() => {
    // Revoke the URL if the component is unmounted
    return () => {
      if (imageObjectURL) {
        URL.revokeObjectURL(imageObjectURL);
      }
    };
  }, [imageObjectURL]);

  return imageObjectURL ? (
    <ClickableImage onClick={onOpenImage} src={imageObjectURL} />
  ) : (
    <PhotoIconBackground onClick={onGetImage}>
      {isLoading ? (
        <RotationAnimationWrapper>
          <MaterialIcon key={`loadingIcon-${imageID}`} icon="autorenew" color="white" />
        </RotationAnimationWrapper>
      ) : (
        <MaterialIcon key={`photoIcon-${imageID}`} icon="photo" color="white" />
      )}
    </PhotoIconBackground>
  );
};
