// Welcome to the AideShiftSearchContainer Component.
// import {AideShiftSearchContainer} './';

// This component can be seen utilized in the <<AllShifts Component>>.
// <AideShiftSearchContainer> will fill, as a row, whatever container it is in.

// <AideShiftSearchContainer> requires the following props:
//      - setIsLoading = setState(pageLoading), which triggers its parent's loading state
//      - setReviewCollection = setState(reviewCollection), which updates its parent's selection of reviews

import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { SearchActions } from '../../actions';
import { ISearchState } from '../../searchReducer';
import { AideShiftSearchComponent, ISetterAction } from './AideShiftSearch';
import { search } from '../../containers/AidesAllShifts';

type IAideShiftSearchProps = {
  setIsLoading?: (isLoading: boolean) => void;
  setReviews: (reviews: any) => void;
  setError?: (error: boolean) => void;
  SearchActions: any;
  searchParams: ISearchState;
};

type IAideShiftSearchState = {
  startDate: Date;
  endDate: Date;
  term: string;
  isDefaultState: boolean;
};

class AideShiftSearch extends Component<IAideShiftSearchProps, IAideShiftSearchState> {
  constructor(props: IAideShiftSearchProps) {
    super(props);
    this.setSearchParameterAndRequest = this.setSearchParameterAndRequest.bind(this);
    this.requestSearch = this.requestSearch.bind(this);
  }

  private async setSearchParameterAndRequest(
    setParams: ISetterAction[],
    shouldRequest: boolean = true
  ) {
    for (const { setterAction, newValue } of setParams) {
      await setterAction(newValue);
    }

    if (shouldRequest) {
      await this.requestSearch();
    }
  }

  private requestSearch() {
    const { setIsLoading, setError, setReviews } = this.props;
    const { searchStartDate, searchEndDate, searchTerm } = this.props.searchParams;
    search(searchStartDate, searchEndDate, searchTerm, setIsLoading, setError, setReviews);
  }

  public render() {
    return (
      <AideShiftSearchComponent
        props={{
          searchParams: this.props.searchParams,
        }}
        actions={{
          SearchActions: this.props.SearchActions,
          requestSearch: this.requestSearch,
          setSearchParameterAndRequest: this.setSearchParameterAndRequest,
        }}
      />
    );
  }
}

const mapStateToProps = ({ searchReducer }: any) => {
  return {
    searchParams: searchReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    SearchActions: {
      setSearchStartDate: (searchStartDate: Date) => {
        dispatch(SearchActions.setSearchStartDate(searchStartDate));
      },
      setSearchEndDate: (searchEndDate: Date) => {
        dispatch(SearchActions.setSearchEndDate(searchEndDate));
      },
      setSearchTerm: (searchTerm: string) => {
        dispatch(SearchActions.setSearchTerm(searchTerm));
      },
      setDefaultSearchParameters: (isDefault: boolean) => {
        dispatch(SearchActions.setDefaultSearchParameters(isDefault));
      },
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AideShiftSearch);
