import React from 'react';
import MaterialIcon from 'material-icons-react';

import { theme as aveannaTheme } from '../../common';
import { IPasswordChip } from '../interfaces';
import { StyledChipContainer, StyledChipIcon, StyledChipText } from './styles';

export const PasswordGuidelines = ({ guidelines }: { guidelines: IPasswordChip[] }) => {
  return (
    <div style={{ marginBottom: 12 }}>
      {guidelines &&
        guidelines.map((tempGuideline: IPasswordChip, index: number) => {
          return (
            <PasswordGuideChip
              key={index}
              message={tempGuideline.message}
              isSatisfied={tempGuideline.isSatisfied}
            />
          );
        })}
    </div>
  );
};

const getIcon = (isSatisfied?: boolean) => {
  if (isSatisfied === undefined) {
    return 'error_outline';
  }

  return isSatisfied ? 'check_circle' : 'cancel';
};

const getColor = (isSatisfied?: boolean) => {
  if (isSatisfied === undefined) {
    return aveannaTheme.colors.tooltipInfoText;
  }

  return isSatisfied
    ? aveannaTheme.colors.tooltipSuccessText
    : aveannaTheme.colors.tooltipErrorText;
};

const PasswordGuideChip = ({ message, isSatisfied, key }: IPasswordChip) => {
  const tempIcon = getIcon(isSatisfied);
  const tempColor = getColor(isSatisfied);
  return (
    <StyledChipContainer isSatisfied={isSatisfied} key={key}>
      <StyledChipIcon>
        <MaterialIcon icon={tempIcon} size="tiny" color={tempColor} key={key} />
      </StyledChipIcon>
      <StyledChipText isSatisfied={isSatisfied} color={tempColor}>
        {message}
      </StyledChipText>
    </StyledChipContainer>
  );
};
