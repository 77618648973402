import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { ReactNode } from 'react';
import { ICaregiverShiftsData } from '../../../../../common/interfaces';
import { HtmlTooltip } from '../../HtmlToolTip/HtmlToolTip';

export const NoteInColumnRenderer = ({
  value,
}: GridRenderCellParams<ICaregiverShiftsData>): ReactNode => {
  return Render(value && value.clockInNote);
};

export const NoteOutColumnRenderer = ({
  value,
}: GridRenderCellParams<ICaregiverShiftsData>): ReactNode => {
  return Render(value && value.clockOutNote);
};

const Render = (note: string | null | undefined): ReactNode => {
  if (!note) {
    return <span>No</span>;
  }
  return (
    <span>
      <HtmlTooltip title={note} children={<span>Yes</span>} />
    </span>
  );
};
