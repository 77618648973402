import React from 'react';
import Select from 'react-select';

import MaterialIcon from 'material-icons-react';

import { theme as aveannaTheme } from '../../common';
import { ClearReasonButton } from '../ShiftPanel/styles';

interface IProps {
  isMultiselect?: boolean;
  reason: any;
  onRemove?: () => void;
  setRejectionReason: (value: any) => void;
  rejectionReasonOptions: any[];
}

export const RejectionReasonDropDown = ({
  isMultiselect = true,
  reason,
  onRemove,
  setRejectionReason,
  rejectionReasonOptions,
}: IProps) => {
  // This is how you style the select — https://react-select.com/styles
  const tempSelectStyles = {
    container: (styles: any) => ({ ...styles, flex: 1 }),
  };

  return (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', marginBottom: 6 }}>
      <Select
        styles={tempSelectStyles}
        options={rejectionReasonOptions}
        value={reason}
        onChange={setRejectionReason}
        isDisabled={reason.isDisabled}
      />
      {isMultiselect && (
        <ClearReasonButton onClick={onRemove}>
          <MaterialIcon icon="clear" color={aveannaTheme.colors.footerButtonRed} />
        </ClearReasonButton>
      )}
    </div>
  );
};
