import { GridCellParams, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { ICaregiverShiftsData } from '../../../../common/interfaces';
import { NoteInColumnRenderer } from './Renderers/NoteColumnRenderer';

const clockInNotesColumn: GridColDef = {
  field: 'clockInNote',
  headerName: 'Clock In',
  maxWidth: 110,
  valueGetter: ({ row }: { row: ICaregiverShiftsData }) => row,
  valueFormatter: ({ value }: GridValueFormatterParams<ICaregiverShiftsData>) => {
    return value && value.clockInNote ? 'Yes' : 'No';
  },
  cellClassName: (params: GridCellParams<ICaregiverShiftsData>) => {
    return params.value && params.value.clockInNote ? 'cell warning' : 'cell';
  },
  renderCell: NoteInColumnRenderer,
};

export default clockInNotesColumn;
