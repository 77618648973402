import React from 'react';
import styled from 'styled-components';

import AccountCircle from '../../assets/account-circle.png';

const ListItemWrapper = styled.div`
  display: flex;
  min-height: 50px;
  max-height: 70px;
`;

const InfoAndControlWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

export const NurseCardDisplay = ({
  nurse: { imageUri, givenName, familyName, skillLevel },
}: any) => {
  return (
    <ListItemWrapper>
      <img
        src={imageUri}
        style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }}
        onError={(e: any) => {
          e.target.onerror = null;
          e.target.src = AccountCircle;
        }}
      />
      <InfoAndControlWrapper>
        <div>
          <div style={{ color: 'rgba(0,0,0,0.9)' }}>{`${givenName} ${familyName}`}</div>
          <div style={{ color: 'rgba(0,0,0,0.6)' }}>{`Level ${skillLevel}`}</div>
        </div>
      </InfoAndControlWrapper>
    </ListItemWrapper>
  );
};
