import { connect } from 'react-redux';
import { notificationTypes } from '@aveanna/client-socket';

import {
  setShiftNotification as setShiftNotificationAction,
  ISetNotificationArguments,
} from '../actions';
import { NotificationStateKeys } from '../constants';
import { useSocketConnection } from '../../api';

interface ISocketEventData {
  channels: string[];
  nameSpace: string;
  notificationType: string;
  userIds: number[];
  userLocationIds: number[];
}

const NotificationReceiveContainer = ({ user, setShiftNotification }: any) => {
  const handleSocketEvent = (socketEvent: ISocketEventData) => {
    if (socketEvent && socketEvent.notificationType) {
      // Check if the current users's location ID is in the userLocationIds list on the socket,
      // if not, then do not continue, as this socket event is not for this location
      if (!socketEvent.userLocationIds.some((tempId: number) => tempId === user.locationId)) {
        console.log(
          `Socket event received, but it's not for this current user's location.`,
          socketEvent
        );
        return;
      }

      switch (socketEvent.notificationType) {
        case notificationTypes.ShiftRequested:
          setShiftNotification({ [NotificationStateKeys.IS_SENT_SHIFT_NOTIFIED]: true });
          break;
        case notificationTypes.ShiftPending:
          setShiftNotification({ [NotificationStateKeys.IS_APPLIED_SHIFT_NOTIFIED]: true });
          break;
        case notificationTypes.ShiftAwarded:
          setShiftNotification({ [NotificationStateKeys.IS_AWARDED_SHIFT_NOTIFIED]: true });
          break;
        case notificationTypes.ShiftScheduled:
          setShiftNotification({ [NotificationStateKeys.IS_SCHEDULED_SHIFT_NOTIFIED]: true });
          break;
        default:
          break;
      }
    }
  };

  useSocketConnection(
    {
      eventName: notificationTypes.ShiftRequested,
      eventHandler: handleSocketEvent,
    },
    !!user
  );

  useSocketConnection(
    {
      eventName: notificationTypes.ShiftPending,
      eventHandler: handleSocketEvent,
    },
    !!user
  );

  useSocketConnection(
    {
      eventName: notificationTypes.ShiftAwarded,
      eventHandler: handleSocketEvent,
    },
    !!user
  );

  useSocketConnection(
    {
      eventName: notificationTypes.ShiftScheduled,
      eventHandler: handleSocketEvent,
    },
    !!user
  );

  return null;
};

const mapStateToProps = ({ user, notifications }: any) => {
  return {
    user: user.user,
    notifications,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setShiftNotification: (eventInput: ISetNotificationArguments) =>
      dispatch(setShiftNotificationAction(eventInput)),
  };
};

export const NotificationReceiver = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationReceiveContainer);
