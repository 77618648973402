import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import moment, { Moment } from 'moment';

// @ts-ignore
import Sidebar from 'react-sidebar';
// @ts-ignore — no types provided
import { useToasts } from 'react-toast-notifications';

import { StyledCheckbox, theme } from '../../common';
import { getAllRejections, getDvrByClockInOutReviewId, saveClockInOut } from '../../api';
import { Table } from '../components/Table';
import styles from './AidesRejectedShifts.module.css';
import StyledDateRangePicker from '../../common/StyledDateRangePicker';
import { ShiftSidebar } from '../ShiftPanel/ShiftSidebar';
import {
  setCurrentShift as setCurrentShiftAction,
  clearCurrentShift as clearCurrentShiftAction,
} from '../actions';
import { filterShiftsByDate } from '../../utils';
import { Actions } from './Actions';
import { DVRActionComponent, zipAllDvrs } from '../ShiftDetails/ShiftDetailsPrinter';
import { TableActionButton } from '../../Shifts/components/Table';
import {
  getOnlyEvvShiftFilter,
  isEvvSource,
  ONLY_EVV_SHIFT_FILTER,
  setOnlyEvvShiftFilter,
} from '../../utils/app';

interface ITableData {
  shift: any;
  fullName: string;
  avatar: string;
  pending: number;
  rejected: number;
  id: number;
  shiftDate: any;
  patientName: string;
  reviewedBy: string;
  rejectedDate: any;
  reason: any;
  reasonCount: number;
}

interface IProps extends RouteComponentProps {
  setCurrentShift: (shift: any) => void;
  clearCurrentShift: () => void;
}

type IState = {
  review: any;
  shift: any;
  error: boolean;
  loading: boolean;
  tableDataList: any;
  filteredList: any;
  originalAidesList: any;
  rejectedList: any;
  datePickerRange: any;
  startDate: any;
  endDate: any;
  focusedInput: any;
  isShiftPanelOpen: boolean;
  hydratedShift: any;
  selected: any;
  printAllLoading: boolean;
  onlyEvvShifts: boolean;
};

const ActionsWithToast = ({ shift, handleOnClickReview }: any) => {
  const { addToast } = useToasts();
  return (
    <Actions
      menuItems={[
        {
          name: 'Review',
          action: () => {
            handleOnClickReview(shift);
          },
        },
        {
          name: 'Print DVR',
          component: <DVRActionComponent shift={shift} />,
        },
      ]}
    />
  );
};

class AidesRejectedShifts extends Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.filterEvvShifts = this.filterEvvShifts.bind(this);
  }

  public state: IState = {
    review: {},
    shift: {},
    error: false,
    loading: true,
    tableDataList: [],
    filteredList: [],
    originalAidesList: [],
    rejectedList: [],
    datePickerRange: new Date(),
    startDate: moment(new Date(), 'MM-DD-YYYY').subtract(29, 'days'),
    endDate: moment(new Date(), 'MM-DD-YYYY'),
    focusedInput: '',
    isShiftPanelOpen: false,
    hydratedShift: null as any,
    selected: {},
    printAllLoading: false,
    onlyEvvShifts: getOnlyEvvShiftFilter(),
  };

  public async componentDidMount() {
    await this.getData();
  }

  private getData = async () => {
    this.setState({ loading: true });
    await this.getAides();
    this.getTableData(this.state.originalAidesList);
    this.setState({ loading: false });
  };

  private handleOnClickReview = async (shift: any) => {
    const { setCurrentShift } = this.props;
    const htmlResponse = await getDvrByClockInOutReviewId(shift.id);
    const hydratedShift = htmlResponse.body;

    setCurrentShift(hydratedShift);
    this.setState({ isShiftPanelOpen: true, hydratedShift, review: shift });
  };

  private handleOnClickCloseReview = () => {
    const { clearCurrentShift } = this.props;

    this.setState({ isShiftPanelOpen: false });
    clearCurrentShift();
  };

  private async getAides() {
    try {
      const aidesList = await getAllRejections();

      this.setState({ originalAidesList: aidesList });
    } catch (error) {
      console.error(error);
      this.setState({ error: true });
    }
  }

  private async onChangeDateRangePicker(newStartDate: Moment, newEndDate: Moment) {
    await this.setState({ startDate: newStartDate, endDate: newEndDate });
    const filterShifts = await filterShiftsByDate(
      this.state.originalAidesList,
      this.state.startDate,
      this.state.endDate
    );
    this.getTableData(filterShifts);
  }
  private getColumns() {
    return [
      {
        Header: (
          <StyledCheckbox
            checked={
              Object.keys(this.state.selected).length > 0 &&
              Object.keys(this.state.selected).length === this.state.filteredList.length
            }
            onClick={() => {
              if (Object.keys(this.state.selected).length === this.state.filteredList.length) {
                this.setState({ selected: {} });
              } else {
                const newSelected: any = {};
                this.state.filteredList.map((data: any) => (newSelected[data.id] = data.shift));
                this.setState({
                  selected: newSelected,
                });
              }
            }}
          />
        ),
        minWidth: 100,
        accessor: 'checkbox',
        sortable: false,
        filterable: false,
        style: {
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        },
        Cell: (row: any) => {
          return (
            <StyledCheckbox
              checked={this.state.selected[row.original.id]}
              onClick={() => {
                const selected = this.state.selected;
                selected[row.original.id]
                  ? delete selected[row.original.id]
                  : (selected[row.original.id] = row.original.shift);
                this.setState({ selected });
              }}
            />
          );
        },
      },
      {
        Header: 'SHIFT DATE',
        accessor: 'shiftDate',
        minWidth: 100,
        Cell: (row: any) => {
          return (
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div>{moment(row.original.shiftDate).format('MM-DD-YYYY')}</div>
            </div>
          );
        },
      },
      {
        Header: 'CAREGIVER',
        accessor: 'aide',
        minWidth: 100,
        Cell: (row: any) => {
          return (
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div>{row.original.fullName}</div>
            </div>
          );
        },
      },
      {
        Header: 'PATIENT NAME',
        accessor: 'patientName',
        minWidth: 100,
        Cell: (row: any) => {
          return (
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div>{row.original.patientName}</div>
            </div>
          );
        },
      },
      {
        Header: 'REVIEWED BY',
        accessor: 'reviewedBy',
        minWidth: 100,
        Cell: (row: any) => {
          return (
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div>{row.original.reviewedBy}</div>
            </div>
          );
        },
      },
      {
        Header: 'UNDER REVIEW DATE',
        accessor: 'underReview',
        minWidth: 100,
        Cell: (row: any) => {
          return (
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div>{moment(row.original.rejectedDate).format('MM-DD-YYYY')}</div>
            </div>
          );
        },
      },
      {
        Header: 'REASON',
        accessor: 'reason',
        minWidth: 100,
        Cell: (row: any) => {
          return (
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <React.Fragment>
                <div>{`${row.original.reason} (${row.original.reasonCount})`}</div>
                {/* <div className={styles.enable_tooltip}>
                <Tooltip
                  zIndex={300}
                  color="black"
                  background="white"
                  placement="bottom"
                  content={
                    <React.Fragment>
                      <div className={styles.tooltip}>
                        <div className={styles.tooltip_header}>Rejection Reason</div>
                      </div>
                    </React.Fragment>
                  }
                >
                  <div className={`${styles.tooltip_icon} ${styles.sNotesCorrection}`}>
                    <MaterialIcon icon="info" color="rgb(90, 93, 133)" />
                  </div>
                </Tooltip>
                  </div> */}
              </React.Fragment>
            </div>
          );
        },
      },
      {
        Header: 'ACTIONS',
        accessor: 'actions',
        minWidth: 100,
        Cell: (row: any) => {
          const data: ITableData = row.original;
          return (
            <ActionsWithToast
              shift={row.original.shift}
              handleOnClickReview={this.handleOnClickReview}
            />
            // <React.Fragment>
            //   <div>
            //     <button
            //       className={styles.SA_Button}
            //       onClick={() => this.handleOnClickReview(row.original.shift)}
            //     >
            //       Review
            //     </button>
            //   </div>
            // </React.Fragment>
          );
        },
      },
    ];
  }

  private onFocusChange(focusedInput: any) {
    this.setState({ focusedInput });
  }

  private filterEvvShifts(event?: any) {
    const onlyEvvShifts = event ? event.target.checked : this.state.onlyEvvShifts;
    if (event) {
      this.setState({
        onlyEvvShifts: event.target.checked,
      });
      setOnlyEvvShiftFilter(event.target.checked);
    }

    if (onlyEvvShifts) {
      const filteredList = this.state.tableDataList.filter((aide: any) => {
        return isEvvSource(aide.shift);
      });
      this.setState({
        filteredList: filteredList,
      });
    } else {
      this.setState({
        filteredList: this.state.tableDataList,
      });
    }
  }

  private getTableData(aidesList: []) {
    const newTableDateList: any = [];
    let tableData = {};
    aidesList.map((obj: any) => {
      const reasonCodes: any = [];
      obj.clockInOutRejectionReasons.map((item: any, index: number) => {
        if (index === obj.clockInOutRejectionReasons.length - 1) {
          reasonCodes.push(item.reason.code);
        }
      });
      tableData = {
        shift: obj,
        fullName: obj.nurseProfile.user.givenName + ' ' + obj.nurseProfile.user.familyName,
        id: obj.id,
        shiftDate: obj.adjustedClockInTimeStamp,
        patientName: obj.patient.givenName + ' ' + obj.patient.familyName,
        reviewedBy: obj.modifier.givenName + ' ' + obj.modifier.familyName,
        rejectedDate: obj.modifiedOn,
        reason: reasonCodes,
        reasonCount: obj.clockInOutRejectionReasons.length,
      };
      newTableDateList.push(tableData);
    });

    this.setState(
      {
        tableDataList: newTableDateList,
        filteredList: newTableDateList,
        error: false,
        loading: false,
      },
      this.filterEvvShifts
    );
  }

  private handleSaveReview = async (review: any) => {
    await saveClockInOut(review);
    this.setState({ isShiftPanelOpen: false, hydratedShift: null });
  };

  public render() {
    return (
      <div className={styles.SA_Parent}>
        <Sidebar
          sidebar={
            <ShiftSidebar
              onSaveNew={() => {}}
              onSave={(review: any) => this.handleSaveReview(review)}
              review={this.state.review}
              dvr={this.state.hydratedShift}
              onClose={this.handleOnClickCloseReview}
              refreshData={this.getData}
            />
          }
          pullRight={true}
          open={this.state.isShiftPanelOpen}
          styles={{
            sidebar: {
              display: 'flex',
              background: 'white',
              zIndex: 6,
            },
            overlay: { zIndex: 5 },
          }}
        >
          <div
            style={{
              flex: 1,
              padding: 24,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{}}>
              <div style={{ color: theme.colors.defaultFontColor, fontSize: theme.fontSizeLg }}>
                Shifts Under Review
              </div>
            </div>
            <div style={{ zIndex: 1 }}>
              <StyledDateRangePicker
                pastLimit={29}
                futureLimit={1}
                startDate={this.state.startDate}
                startDateId="your_unique_start_date_id"
                endDate={this.state.endDate}
                endDateId="your_unique_end_date_id"
                onDatesChange={(value1: any, value2: any) =>
                  this.onChangeDateRangePicker(value1, value2)
                }
                focusedInput={this.state.focusedInput}
                onFocusChange={(value: any) => this.onFocusChange(value)}
                onClose={(value1: any, value2: any) => this.onChangeDateRangePicker(value1, value2)}
              />
            </div>
            <div>
              <TableActionButton
                onClick={async () => {
                  this.setState({ printAllLoading: true });
                  await zipAllDvrs(Object.values(this.state.selected));
                  this.setState({ printAllLoading: false });
                }}
              >
                {this.state.printAllLoading ? 'Loading...' : 'Print Selected Shifts'}
              </TableActionButton>
            </div>
          </div>
          <label>
            <input
              type="checkbox"
              checked={this.state.onlyEvvShifts}
              onChange={this.filterEvvShifts}
            />
            {ONLY_EVV_SHIFT_FILTER}
          </label>
          <Table
            defaultPageSize={100}
            minRows={10}
            showPagination={true}
            data={this.state.filteredList}
            columns={this.getColumns()}
            resizable={true}
            loading={this.state.loading}
            loadingText={this.state.error ? 'Error fetching data. Retrying...' : 'Loading...'}
            noDataText={this.state.error ? '' : 'No results found.'}
            style={{ width: 'unset', height: '80%', boxShadow: 'none' }}
            sortable={true}
          />
        </Sidebar>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCurrentShift: (shift: any) => dispatch(setCurrentShiftAction(shift)),
    clearCurrentShift: () => dispatch(clearCurrentShiftAction()),
  };
};

export default connect(null, mapDispatchToProps)(AidesRejectedShifts);
