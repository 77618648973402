import React, { useState } from 'react';
import { theme as aveannaTheme } from '../../common';
import {
  ConfirmSubPanelContainer,
  ConfirmButtonRow,
  ConfirmMessageContainer,
  CofirmationErrorMessage,
} from '../ShiftPanel/styles';
import { ShiftFooterButton, ShiftFooterTextButton } from '../ShiftDetails/ShiftFooterButtons';
import styled from 'styled-components';
import { getErrorMessage, useDisplaySuccess } from '../../common/useToastNotifications';

interface IProps {
  message: string;
  toastSuccessMessage: string;
  onBack: () => void;
  onClose: () => void;
  onConfirm: () => void;
}

export const AddNewShiftConfirmation = ({
  message,
  onBack,
  onClose,
  onConfirm,
  toastSuccessMessage,
}: IProps) => {
  const displaySuccess = useDisplaySuccess();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const confirm = async () => {
    setIsLoading(true);

    try {
      await onConfirm();
      displaySuccess(toastSuccessMessage);
      onClose();
    } catch (error) {
      setError(!!getErrorMessage(error));
      setIsLoading(false);
    }
  };

  const isDisabled = () => {
    if (isLoading) {
      return true;
    }
  };

  return (
    <SubPanelContainer>
      <ConfirmSubPanelContainer>
        {error && <CofirmationErrorMessage>{error}</CofirmationErrorMessage>}
        <ConfirmMessageContainer>{message}</ConfirmMessageContainer>
        <ConfirmButtonRow>
          <ShiftFooterButton
            onClick={confirm}
            buttonColor={aveannaTheme.colors.footerButtonGreen}
            disabled={isDisabled()}
          >
            {!isDisabled() ? 'Confirm and Add' : 'Processing...'}
          </ShiftFooterButton>
          <ShiftFooterTextButton onClick={onBack} disabled={isDisabled()}>
            Cancel
          </ShiftFooterTextButton>
        </ConfirmButtonRow>
      </ConfirmSubPanelContainer>
    </SubPanelContainer>
  );
};

const SubPanelContainer = styled.div`
  overflow-y: auto;
  padding: 1rem;
  font-size: 1.2rem;
  line-height: 1.5rem;
  max-width: 600px;
`;
