import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ROUTE_PARAMS } from '../../routing/routing.constants';
import {
  NotificationStateKeys,
  setShiftNotification as setShiftNotificationAction,
} from '../../notifications';
import { setCurrentTab as setCurrentTabAction } from '../actions';

export enum TabNames {
  OpenShifts = 'Open Shifts',
  SentShifts = 'Sent Shifts',
  AppliedShifts = 'Applied Shifts',
  AwardedShifts = 'Awarded Shifts',
  ScheduledShifts = 'Scheduled Shifts',
  OrientRequests = 'Orient Requests',
}

export const tabsList = [
  { name: TabNames.OpenShifts, to: ROUTE_PARAMS.OPEN },
  {
    name: TabNames.SentShifts,
    to: ROUTE_PARAMS.SENT,
    stateKey: NotificationStateKeys.IS_SENT_SHIFT_NOTIFIED,
  },
  {
    name: TabNames.AppliedShifts,
    to: ROUTE_PARAMS.APPLIED,
    stateKey: NotificationStateKeys.IS_APPLIED_SHIFT_NOTIFIED,
  },
  {
    name: TabNames.AwardedShifts,
    to: ROUTE_PARAMS.AWARDED,
    stateKey: NotificationStateKeys.IS_AWARDED_SHIFT_NOTIFIED,
  },
  {
    name: TabNames.ScheduledShifts,
    to: ROUTE_PARAMS.SCHEDULED,
    stateKey: NotificationStateKeys.IS_SCHEDULED_SHIFT_NOTIFIED,
  },
  {
    name: TabNames.OrientRequests,
    to: ROUTE_PARAMS.ORIENT_REQUESTS,
    stateKey: NotificationStateKeys.IS_ORIENTATION_REQUEST_NOFITIED,
  },
];

interface ITabProps {
  to: string | ROUTE_PARAMS;
  handleClick: () => void;
  active?: boolean;
  rest?: any;
  name?: TabNames | string;
  stateKey?: string | NotificationStateKeys | null;
  notifications?: any;
}

const Tab: React.SFC<ITabProps> = ({
  active,
  handleClick,
  to,
  notifications,
  stateKey = null,
  ...rest
}) => {
  let isNotified;

  if (notifications && stateKey) {
    isNotified = notifications[stateKey];
  }

  return (
    <StyledTab
      onClick={() => handleClick()}
      data-active={active}
      data-notified={isNotified}
      to={to}
      {...rest}
    />
  );
};

// TAB BAR
export const ConnectedTabBar = (props: any) => {
  const { setCurrentTab, setShiftNotification, notifications, match } = props;
  const tabMap = tabsList.map(({ name, stateKey, to }: any) => (
    <Tab
      name={name}
      key={name}
      to={to}
      handleClick={() => {
        setCurrentTab(name);
        setShiftNotification({ [stateKey]: false });
      }}
      stateKey={stateKey}
      notifications={notifications}
      active={to === match.params.tabName ? true : false}
    >
      {name}
    </Tab>
  ));
  return <StyledTabBar>{tabMap}</StyledTabBar>;
};

const mapStateToProps = ({ currentShiftTab, notifications }: any) => ({
  currentShiftTab,
  notifications,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    { setCurrentTab: setCurrentTabAction, setShiftNotification: setShiftNotificationAction },
    dispatch
  );

export const TabBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ConnectedTabBar)) as any;

// STYLES
const StyledTab: any = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
  font-family: sans-serif;
  font-weight: 600;
  font-size: 14px;
  height: 100%;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  background-color: white;

  ${(props: any) =>
    props['data-notified'] &&
    css`
      &::before {
        content: '⬤';
        color: red;
        padding-left: 6px;
        padding-right: 6px;
      }
    `}

  &:hover {
    color: rgba(0, 0, 0, 0.8);
  }

  ${(props: any) =>
    props['data-active'] &&
    css`
      color: white;
      background-color: #0075c9;
      border-bottom: 2px solid #0075c9;

      &:hover {
        color: white;
      }
    `}
`;

export const StyledTabBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  border-bottom: 2px solid lightgray;
  background-color: #f5f6fa;
`;
