import React from 'react';
import MaterialIcon from 'material-icons-react';
import PatientInformation from './PatientInformation';
import ClockInInformation from './ClockInInformation';
import { IClockInOut } from '../PatientInterfaces';

interface IProps {
  clockInOut: IClockInOut;
  onClose: () => void;
}

const ShiftSchedulerContainer = (props: IProps) => {
  const { clockInOut, onClose } = props;
  return (
    <div
      style={{
        position: 'relative',
        overflow: '',
        justifyContent: 'space-between',
        maxWidth: '40vw',
        minWidth: '600px',
        fontSize: '1.2rem',
      }}
    >
      <div
        style={{
          justifyContent: 'flex-start',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          padding: '20px',
          flexDirection: 'row',
        }}
        onClick={() => {
          onClose();
        }}
      >
        <MaterialIcon icon={'close'} key={'close'} />
        <div
          style={{
            padding: 5,
            fontSize: 12,
          }}
        >
          CLOSE RECONCILE TAB
        </div>
      </div>
      <PatientInformation patient={clockInOut.patient} />
      <ClockInInformation clockInOut={clockInOut} />
    </div>
  );
};

export default ShiftSchedulerContainer;
