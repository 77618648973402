import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { ROUTES } from '../routing/routing.constants';
import { FeatureFlags } from '../utils';
import { interfaces, SideNav } from '../common';
import { AidesAllShifts } from '../Aides/containers';
import AidesQAShifts from './AidesQAShifts';

const QANurseSideNavConfig: interfaces.ISideNavRouteConfig[] = [
  {
    title: 'All Shifts',
    route: ROUTES.ALL_SHIFTS,
    icon: 'list_alt',
  },
  {
    title: 'QA Shifts',
    route: ROUTES.QA_SHIFTS,
    icon: 'check',
  },
];

export const QANurseRouter = ({ match }: RouteComponentProps) => {
  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <SideNav routeConfig={QANurseSideNavConfig} />
      <div style={{ flex: 1, height: `100vh` }}>
        <Switch>
          <Route path={`${ROUTES.QA_SHIFTS}`} component={AidesQAShifts} />
          <Route path={`${ROUTES.ALL_SHIFTS}`} component={AidesAllShifts} />
        </Switch>
      </div>
    </div>
  );
};
