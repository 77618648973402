import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import MaterialIcon from 'material-icons-react';
import moment, { Moment } from 'moment';

// @ts-ignore error: TS7016
import Sidebar from 'react-sidebar';
import { getDvrByClockInOutReviewId, saveClockInOut } from '../api';
import { theme } from '../common';
import { Table, TableActionButton } from '../Shifts/components/Table';
import { ShiftSidebar } from '../Aides/ShiftPanel/ShiftSidebar';
import styles from '../Aides/containers/AidesRejectedShifts.module.css';
import ReusableTooltip, { GetSourceAlert } from '../common/ReusableTooltip';
import { filterShiftsByDate, StatusType } from '../utils';
import {
  setCurrentShift as setCurrentShiftAction,
  clearCurrentShift as clearCurrentShiftAction,
  setQAShifts,
} from '../Aides/actions';
import {
  getOnlyEvvShiftFilter,
  isEvvSource,
  ONLY_EVV_SHIFT_FILTER,
  setOnlyEvvShiftFilter,
} from '../utils/app';
import StyledDateRangePicker from '../common/StyledDateRangePicker';

interface ITableData {
  shiftId: number;
  shiftDate: string;
  aideFullName: string;
  avatar: string;
  patientName: string;
  reviewedBy: string;
}

type Props = RouteComponentProps;

interface IProps extends RouteComponentProps {
  setCurrentShift: (shift: any) => void;
  clearCurrentShift: () => void;
  setQAShifts: () => {};
  qaShifts: [];
}

type IState = {
  isShiftPanelOpen: boolean;
  error: boolean;
  loading: boolean;
  allShifts: any;
  filteredShifts: any;
  hydratedShift: any;
  review: any;
  qaShifts: any;
  startDate: Moment;
  endDate: Moment;
  focusedInput: any;
  onlyEvvShifts: boolean;
  columns: any[];
};

class AidesQAShifts extends Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.filterEvvShifts = this.filterEvvShifts.bind(this);
  }

  public state: IState = {
    isShiftPanelOpen: false,
    error: false,
    loading: true,
    allShifts: [],
    filteredShifts: [],
    hydratedShift: null as any,
    review: {},
    qaShifts: [],
    startDate: moment().startOf('day').add(-30, 'd'),
    endDate: moment().endOf('day'),
    focusedInput: '',
    onlyEvvShifts: getOnlyEvvShiftFilter(),
    columns: [
      {
        Header: 'SHIFT DATE',
        accessor: 'date',
        minWidth: 100,
        Cell: (row: any) => {
          return <div>{moment(row.original.shiftDate).format('MM-DD-YYYY')}</div>;
        },
      },
      {
        Header: 'CAREGIVER',
        accessor: 'aide',
        minWidth: 100,
        Cell: (row: any) => {
          return (
            <div>
              <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                <img
                  src={row.original.avatar}
                  height="28"
                  width="28"
                  style={{ borderRadius: 50, marginRight: 12 }}
                />
              </div>
              <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                {row.original.aideFullName}
              </div>
            </div>
          );
        },
      },
      {
        Header: 'PATIENT NAME',
        accessor: 'patientName',
        minWidth: 100,
      },
      {
        Header: 'REVIEWED BY',
        accessor: 'reviewedBy',
        minWidth: 100,
      },
      {
        Header: 'RANDOM',
        accessor: 'random',
        minWidth: 50,
        Cell: (row: any) => {
          return (
            <div>
              {row.original.systemQA ? (
                <MaterialIcon icon="check" color="maroon" size="small" />
              ) : (
                ''
              )}
            </div>
          );
        },
      },
      {
        Header: 'ALERTS',
        accessor: 'alerts',
        minWidth: 100,
        style: { overflow: 'visible' },
        Cell: (row: any) => {
          const timeAlerts = row.original.timeAlerts;
          const locationAlerts = row.original.locationAlerts;
          const overlapAlerts = row.original.overlapAlerts;
          const officeAlerts = row.original.officeAlerts;

          const timeAlertsExist = timeAlerts && timeAlerts.length > 0;
          const locationAlertsExist =
            locationAlerts &&
            ((locationAlerts.clockInAlert && locationAlerts.clockInAlert.length > 0) ||
              (locationAlerts.clockOutAlert && locationAlerts.clockOutAlert.length > 0));
          const overlapAlertsExist = overlapAlerts && overlapAlerts.length > 0;
          const officeAlertsExist = officeAlerts && officeAlerts.length > 0;
          const alertsExist =
            timeAlertsExist || locationAlertsExist || overlapAlertsExist || officeAlertsExist;

          return (
            <>
              {alertsExist ? null : <MaterialIcon icon="check" color="green" size="small" />}
              <ReusableTooltip
                zIndex={300}
                fontColor={'black'}
                backgroundColor={'white'}
                placement={'bottom'}
                data={timeAlerts}
                tooltipHeader={'Time Issues'}
                iconName={'access_time'}
                iconColor={'maroon'}
                autoWidth={true}
              />

              <ReusableTooltip
                zIndex={300}
                fontColor={'black'}
                backgroundColor={'white'}
                placement={'bottom'}
                data={locationAlerts}
                tooltipHeader={'Location Issues'}
                iconName={'room'}
                iconColor={'maroon'}
                autoWidth={true}
              />

              <ReusableTooltip
                zIndex={300}
                fontColor={'black'}
                backgroundColor={'white'}
                placement={'bottom'}
                data={overlapAlerts}
                tooltipHeader={'Overlap Issues'}
                iconName={'settings_overscan'}
                iconColor={'maroon'}
                autoWidth={true}
              />

              <ReusableTooltip
                zIndex={300}
                fontColor={'black'}
                backgroundColor={'white'}
                placement={'bottom'}
                data={officeAlerts}
                tooltipHeader={'Office Issues'}
                iconName={'location_city'}
                iconColor={'maroon'}
                autoWidth={true}
              />

              {GetSourceAlert('Clock In Source', row.original.clockInSource)}
              {GetSourceAlert('Clock Out Source', row.original.clockOutSource)}
            </>
          );
        },
      },
      {
        Header: 'STATUS',
        accessor: 'status',
        sortMethod: (a: any, b: any) => {
          return a.iconName > b.iconName ? 1 : a.iconName < b.iconName ? -1 : 0;
        },
        minWidth: 100,
        Cell: (row: any) => {
          const data: ITableData = row.original;

          // default value for StatusType.PENDING
          let iconText = 'Pending';
          let iconName = 'error';
          let iconColor = 'darkorange';

          if (row.original.status === StatusType.APPROVED) {
            iconName = 'check_circle';
            iconText = 'Approved';
            iconColor = 'green';
          } else if (row.original.status === StatusType.REJECTED) {
            iconText = 'Reviewing';
            iconName = 'cancel';
            iconColor = 'maroon';
          }
          return (
            <div style={{ fontSize: '0.9rem', fontWeight: 700 }}>
              <div>
                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                  <MaterialIcon key={iconName} icon={iconName} color={iconColor} size="small" />
                </div>
                <div
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    paddingLeft: 10,
                    color: iconColor,
                  }}
                >
                  {iconText}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'ACTIONS',
        accessor: 'actions',
        minWidth: 100,
        Cell: (row: any) => {
          const data: ITableData = row.original;
          return (
            <TableActionButton
              onClick={() => {
                this.handleOnClickReview(data);
              }}
              style={{
                cursor: 'pointer',
                margin: '0.5rem 1rem 0.5rem 1rem',
                padding: '0.5rem 1rem 0.5rem 1rem',
                pointerEvents: 'auto',
                fontSize: '0.9rem',
                fontWeight: 700,
                color: 'white',
                backgroundColor: '#0075c9',
                borderWidth: 0,
                borderRadius: 6,
              }}
            >
              Review
            </TableActionButton>
          );
        },
      },
    ],
  };

  public async componentDidMount() {
    await this.props.setQAShifts();
    this.getAides();
  }

  public componentDidUpdate(prevProps: IProps) {
    // if (prevProps.qaShifts !== null && undefined && this.props.qaShifts !== null && undefined ) {
    //   if (prevProps.qaShifts.length !== this.props.qaShifts.length) {
    //     this.getAides();
    //   }
    // }
    if (prevProps.qaShifts) {
      if (prevProps.qaShifts.length !== this.props.qaShifts.length) {
        this.getAides();
      }
    }
  }

  private async getAides() {
    try {
      const allShifts = this.props.qaShifts;
      allShifts ? this.getTableData(allShifts) : this.setState({ error: false, loading: false });
    } catch (error) {
      console.error(error);
      this.setState({ error: true });
    }
  }

  private filterEvvShifts(event?: any) {
    const onlyEvvShifts = event ? event.target.checked : this.state.onlyEvvShifts;
    if (event) {
      this.setState({
        onlyEvvShifts: event.target.checked,
      });
      setOnlyEvvShiftFilter(event.target.checked);
    }

    if (onlyEvvShifts) {
      const filteredList = this.state.allShifts.filter((aide: any) => {
        return isEvvSource(aide);
      });
      this.setState({
        filteredShifts: filteredList,
      });
    } else {
      this.setState({
        filteredShifts: this.state.allShifts,
      });
    }
  }

  private onFocusChange(focusedInput: any) {
    this.setState({ focusedInput });
  }

  private async onChangeDateRangePicker(newStartDate: Moment, newEndDate: Moment) {
    await this.setState({ startDate: newStartDate, endDate: newEndDate });
    const filteredShifts = await filterShiftsByDate(
      this.props.qaShifts,
      this.state.startDate,
      this.state.endDate
    );
    this.getTableData(filteredShifts);
  }

  private getTableData(allShifts: []) {
    let tableData: ITableData[] = [];
    allShifts.map((obj: any) => {
      const id = obj.id.toString();
      const aideFullName = obj.nurseProfile.user.givenName + ' ' + obj.nurseProfile.user.familyName;
      tableData.push({
        shiftId: id,
        shiftDate: obj.adjustedClockInTimeStamp,
        aideFullName,
        avatar: obj.nurseProfile.user.imageUri,
        patientName: obj.patient.name,
        reviewedBy: obj.modifier.givenName + ' ' + obj.modifier.familyName,
        ...obj,
      });
    });

    tableData = tableData.reverse();

    this.setState(
      {
        allShifts: tableData,
        filteredShifts: tableData,
        loading: false,
      },
      this.filterEvvShifts
    );
  }

  private handleOnClickReview = async (shift: any) => {
    const { setCurrentShift } = this.props;
    const htmlResponse = await getDvrByClockInOutReviewId(shift.id);
    const hydratedShift = htmlResponse.body;

    setCurrentShift(hydratedShift);
    this.setState({ isShiftPanelOpen: true, hydratedShift, review: shift });
  };

  private async handleSaveReview(review: any) {
    await saveClockInOut(review);
    this.setState({ isShiftPanelOpen: false, hydratedShift: null });
  }

  public render() {
    return (
      <div className={styles.SA_Parent}>
        <Sidebar
          sidebar={
            <ShiftSidebar
              dvr={this.state.hydratedShift}
              onClose={() => this.setState({ isShiftPanelOpen: false })}
              onSaveNew={() => {}}
              onSave={(review: any) => this.handleSaveReview(review)}
              review={this.state.review}
            />
          }
          pullRight={true}
          open={this.state.isShiftPanelOpen}
          styles={{
            sidebar: {
              display: 'flex',
              background: 'white',
              zIndex: 6,
            },
            overlay: { zIndex: 5 },
          }}
        >
          <div style={{ padding: 24, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ justifyContent: 'space-evenly' }}>
              <div style={{ color: theme.colors.defaultFontColor, fontSize: theme.fontSizeLg }}>
                QA Shifts
              </div>
            </div>
            <div style={{ zIndex: 2, position: 'relative', display: 'flex', marginLeft: 30 }}>
              <StyledDateRangePicker
                pastLimit={30}
                futureLimit={1}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onDatesChange={(startDate: any, endDate: any) =>
                  this.onChangeDateRangePicker(startDate, endDate)
                }
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput: any) => this.onFocusChange(focusedInput)}
                onClose={(startDate: any, endDate: any) =>
                  this.onChangeDateRangePicker(startDate, endDate)
                }
              />
            </div>
          </div>

          <label>
            <input
              type="checkbox"
              checked={this.state.onlyEvvShifts}
              onChange={this.filterEvvShifts}
            />
            {ONLY_EVV_SHIFT_FILTER}
          </label>
          <Table
            defaultPageSize={100}
            minRows={10}
            showPagination={true}
            data={this.state.filteredShifts}
            columns={this.state.columns}
            resizable={true}
            loading={this.state.loading}
            loadingText="Loading..."
            noDataText={this.state.error ? 'Error fetching data.' : 'No rows found'}
            sortable={true}
            style={{
              width: 'unset',
              height: '90%',
              overflow: 'hidden',
              boxShadow: 'none',
              border: 'none',
            }}
          />
        </Sidebar>
      </div>
    );
  }
}

const mapStateToProps = ({ aides }: any) => {
  return {
    qaShifts: aides.qaShifts,
    currentEditedShift: aides.currentEditedShift,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCurrentShift: (shift: any) => dispatch(setCurrentShiftAction(shift)),
    clearCurrentShift: () => dispatch(clearCurrentShiftAction()),
    setQAShifts: () => dispatch(setQAShifts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AidesQAShifts);
