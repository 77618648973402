import { checkForErrors } from '../utils';
import { interfaces } from '../common';
import { get, post } from './apiCallHelper';

export const getAllMessages = async () => {
  const messageResponse = await get('api/Messaging/Messages');

  checkForErrors(messageResponse);
  return messageResponse.body;
};

export const getNewChannelMessages = async ({
  channelId,
  lastMessageId,
}: {
  channelId: number;
  lastMessageId: number;
}) => {
  const messageResponse = await get(
    `api/Messaging/v2/NewChannelMessages/${channelId}/?lastMessageId=${lastMessageId}`
  );
  checkForErrors(messageResponse);
  return messageResponse.body;
};

export const sendMessage = async ({ text, channelId, sentById }: interfaces.ISendMessageParams) => {
  const tempBody = { body: text, channelId, sentById };
  const messageResponse = await post('api/Messaging/Send', { body: tempBody });
  checkForErrors(messageResponse);
  return messageResponse.body;
};

export const sendMassMessage = async ({
  text,
  channelId,
  sentById,
  locationId,
}: interfaces.ISendMassMessageParams) => {
  const tempBody = { body: text, channelId, sentById };
  const messageResponse = await post(`api/Messaging/MassSend/${locationId}`, {
    body: tempBody,
  });

  checkForErrors(messageResponse);
  return messageResponse.body;
};

export const createGroupMessage = async ({
  nurseTitle,
  schedulerTitle,
  userIds,
}: {
  nurseTitle: string;
  schedulerTitle: string;
  userIds: [number];
}) => {
  const res = await post(`/api/Messaging/CreateNewChannel`, {
    body: {
      nurseTitle,
      schedulerTitle,
      userIds,
    },
  });
  checkForErrors(res);
  return res;
};
