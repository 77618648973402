import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useSelector } from 'react-redux';
import { ILocation } from '../../Shifts/interfaces';
import { ILocationOption } from '../../common/interfaces';
import { ToastAppearance } from '../../utils';
import Select from 'react-select';
import { formatLocationForDisplay } from '../locationColumnUtility';

export const LocationEditorComponent = forwardRef((props: any, ref) => {
  const { addToast } = useToasts();

  const locations = useSelector<any, ILocation[]>(({ admin }: any) => admin.locations);
  const originalLocationOption = toLocationOption(locations.find((x) => x.id === props.locationId));
  const [currentLocationOption, setCurrentLocationOption] = useState<ILocationOption | null>(
    originalLocationOption
  );

  const updateLocation = async (newLocation: ILocationOption | null) => {
    try {
      if (
        !newLocation ||
        (originalLocationOption && newLocation.value === originalLocationOption.value)
      ) {
        return;
      }

      await props.updateLocation(newLocation.value);
      addToast(
        originalLocationOption
          ? `Updated Location from ${originalLocationOption.label} to ${newLocation.label}`
          : `'Updated Location to ${newLocation.label}`,
        {
          appearance: ToastAppearance.SUCCESS,
          pauseOnHover: true,
          autoDismiss: true,
          autoDismissTimeout: 10000,
        }
      );
    } catch (error) {
      console.error(error);
      addToast((error as Error).message, {
        appearance: ToastAppearance.WARNING,
        pauseOnHover: true,
        autoDismiss: true,
        autoDismissTimeout: 10000,
      });
      props.node.setDataValue(
        props.backingFieldName,
        originalLocationOption ? originalLocationOption.value : null
      );
    }
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        updateLocation(currentLocationOption);
        return currentLocationOption ? currentLocationOption.value : null;
      },
      isPopup: () => true,
    };
  });

  // Renders dropdown options
  const options = locations.map((location) => ({
    value: location.id,
    label: formatLocationForDisplay(location),
  }));

  const handleSelectChange = (e: any) => {
    setCurrentLocationOption(e);
  };

  return (
    <form
      name="locationForm"
      style={{
        width: props.node.columnApi.getColumn(props.backingFieldName)?.actualWidth ?? 150,
      }}
    >
      <Select
        options={options}
        defaultValue={currentLocationOption}
        onChange={handleSelectChange}
        name="location"
        inputId="location"
      />
    </form>
  );
});

const toLocationOption = (location: ILocation | undefined): ILocationOption | null => {
  if (!location) {
    return null;
  }
  return {
    value: location.id,
    label: formatLocationForDisplay(location),
  };
};
