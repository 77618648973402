import React from 'react';
import { getCurrentReleaseChannelFlag } from './app';
import { Environment } from './constants';
import { getReleaseEnvironmentName } from '../utils';

type Props = {};

class EnvironmentName extends React.Component<Props, {}> {
  // display env name only in QA and RC
  private checkEnvironment(channel: string) {
    return (
      channel.toLowerCase().includes(Environment.QA) ||
      channel.toLowerCase().includes(Environment.RC)
    );
  }

  public render() {
    const channel = getCurrentReleaseChannelFlag();
    return (
      <>
        {this.checkEnvironment(channel) && (
          <div
            style={{
              zIndex: 999,
              position: 'absolute',
              top: 10,
              right: 400,
              fontFamily: 'Lobster',
              fontSize: 40,
              color: 'red',
              fontWeight: 'bold',
            }}
          >
            {getReleaseEnvironmentName().toUpperCase()}
          </div>
        )}
      </>
    );
  }
}

export default EnvironmentName;
