import { getBuildNumber, isProduction } from './app';

export const isCacheOutdated = async () => {
  const manifestHeaders = new Headers();
  manifestHeaders.append('pragma', 'no-cache');
  manifestHeaders.append('cache-control', 'no-cache');

  const requestInit = {
    method: 'GET',
    headers: manifestHeaders,
  };

  const tempManifest = await fetch('/manifest.json', requestInit);

  if (tempManifest) {
    const tempManifestJSON = await tempManifest.json();
    let tempIsCacheOutdated = false;
    if (tempManifestJSON.version && getBuildNumber() !== tempManifestJSON.version) {
      tempIsCacheOutdated = true;
    }

    console.log(
      `js cached build: ${getBuildNumber()} / manifest build: ${tempManifestJSON.version}`
    );
    if (
      isProduction() && // comment/uncomment to test tempIsCacheOutdated
      tempIsCacheOutdated
    ) {
      return true;
    } else {
      return false;
    }
  }
};
