import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
  CaregiverShiftUpdate,
  ICaregiverShiftsData,
  IReasonCode,
} from '../../../common/interfaces';
import ReasonCodeSelection from '../ReasonCodeSelection/ReasonCodeSelection';
import {
  EVV_REJECT,
  ReasonCodeTypes,
  ShiftReviewActions,
  StatusType,
  ToastAppearance,
} from '../../../utils/constants';
import MomentUtils from '@date-io/moment';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { formatDateTime } from '../DataHelpers';
import { ShiftEditReview } from '../ShiftEditReview/ShiftEditReview';
import { RejectionSection } from '../../../Aides/ShiftDetails/ShiftDetailsRejectionSection';
import { updateCaregiverShift } from '../../../api';
import { ReviewPeopleInfo } from '../ReviewPeopleInfo/ReviewPeopleInfo';
import { sendAnalyticsEvent } from '../../../analytics';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useToasts } from 'react-toast-notifications';
import { CaregiverShiftsRejectionReason } from '../../../common/types';
import { useSelector } from 'react-redux';
import { getDefaultToastConfig } from '../../../utils';

type Props = {
  shiftData: ICaregiverShiftsData;
  onShiftUpdated: (shiftData: ICaregiverShiftsData) => void;
};

type OriginalRejectionReasons = {
  value: number | null;
  label: string;
};

type ExpandedCaregiverShiftsRejectionReason = OriginalRejectionReasons &
  Partial<CaregiverShiftsRejectionReason>;

type Id = number | null;

const formatDate = (date: string): string => formatDateTime(date, 'LLL') || '';

export function ShiftEditModal({ shiftData, onShiftUpdated }: Props) {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxHeight: '90vh',
        overflowY: 'auto',
      },
    })
  );

  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInChangeReview, setIsInChangeReview] = useState(false);
  const [clockInTimeCorrectionId, setClockInTimeCorrectionId] = useState<Id>(
    shiftData.correctionIds ? shiftData.correctionIds.clockInTimeCorrectionId : null
  );
  const [clockOutTimeCorrectionId, setClockOutTimeCorrectionId] = useState<Id>(
    shiftData.correctionIds ? shiftData.correctionIds.clockOutTimeCorrectionId : null
  );
  const [clockInLocationCorrectionId, setClockInLocationCorrectionId] = useState<Id>(
    shiftData.correctionIds ? shiftData.correctionIds.clockInLocationCorrectionId : null
  );
  const [clockOutLocationCorrectionId, setClockOutLocationCorrectionId] = useState<Id>(
    shiftData.correctionIds ? shiftData.correctionIds.clockOutLocationCorrectionId : null
  );

  const [clockInDateTime, setClockInDateTime] = useState(
    formatDate(shiftData.adjustedClockInTimeStamp)
  );
  const [clockOutDateTime, setClockOutDateTime] = useState(
    formatDate(shiftData.adjustedClockOutTimeStamp)
  );
  const [rejectionReasons, setRejectionReasons] = useState<
    ExpandedCaregiverShiftsRejectionReason[]
  >([{ value: null, label: 'Please select a reason...', clockInOutId: shiftData.id }]);

  const aggregatedReasonCodes = useSelector(({ appData }: any) => ({
    allReasonCodes: appData.allReasonCodes,
    rejectionRejectOptions: appData.rejectionRejectOptions,
    rejectionOverrideOptions: appData.rejectionOverrideOptions,
  }));

  useEffect(() => {
    if (shiftData.clockInOutRejectionReasons && shiftData.clockInOutRejectionReasons.length) {
      setRejectionReasons(
        shiftData.clockInOutRejectionReasons.map((x) => ({
          ...x,
          value: x.reasonId,
          label: `${x.reasonCode}: ${x.reasonText}`,
        }))
      );
    }
  }, [shiftData.clockInOutRejectionReasons]);

  const { addToast } = useToasts();

  const resetState = () => {
    setClockInTimeCorrectionId(
      shiftData.correctionIds ? shiftData.correctionIds.clockInTimeCorrectionId : null
    );
    setClockOutTimeCorrectionId(
      shiftData.correctionIds ? shiftData.correctionIds.clockOutTimeCorrectionId : null
    );
    setClockInLocationCorrectionId(
      shiftData.correctionIds ? shiftData.correctionIds.clockInLocationCorrectionId : null
    );
    setClockOutLocationCorrectionId(
      shiftData.correctionIds ? shiftData.correctionIds.clockOutLocationCorrectionId : null
    );

    setClockInDateTime(formatDate(shiftData.adjustedClockInTimeStamp));
    setClockOutDateTime(formatDate(shiftData.adjustedClockOutTimeStamp));
    setIsInChangeReview(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    resetState();
    setIsModalOpen(false);
  };

  function getReasonCodeSelection(reasonCode: ReasonCodeTypes, setMethod: (newValue: Id) => void) {
    const id = getId(reasonCode);

    const relatedReasonCodes = aggregatedReasonCodes.allReasonCodes
      ? aggregatedReasonCodes.allReasonCodes.filter((x: IReasonCode) => x.name === reasonCode)
      : [];

    const modifiedCallback = (newId: Id): void => {
      const eventValue = newId === null ? undefined : newId;
      sendAnalyticsEvent('CaregiverShifts', `Modal ${reasonCode}`, { eventValue });
      setMethod(newId);
    };

    return (
      <div>
        <ReasonCodeSelection
          id={id}
          relatedReasonCodes={relatedReasonCodes}
          onReasonCodeChange={modifiedCallback}
        />
      </div>
    );
  }

  function rejectButtonStyle(buttonText: string) {
    if (buttonText === ShiftReviewActions.REJECT) {
      return {
        backgroundColor: 'red',
      };
    }
    return {
      display: 'inherit',
    };
  }

  const getId = (reason: ReasonCodeTypes): number | null => {
    switch (reason) {
      case ReasonCodeTypes.TIME_IN:
        return shiftData.correctionIds ? shiftData.correctionIds.clockInTimeCorrectionId : null;
      case ReasonCodeTypes.TIME_OUT:
        return shiftData.correctionIds ? shiftData.correctionIds.clockOutTimeCorrectionId : null;
      case ReasonCodeTypes.LOCATION_IN:
        return shiftData.correctionIds ? shiftData.correctionIds.clockInLocationCorrectionId : null;
      default:
        return shiftData.correctionIds
          ? shiftData.correctionIds.clockOutLocationCorrectionId
          : null;
    }
  };

  function submitButton(buttonText: string, onClick: () => void | Promise<void>) {
    const modifiedCallback = async () => {
      sendAnalyticsEvent('CaregiverShifts', `Modal ${buttonText} clicked`);
      await onClick();
    };
    return (
      <button
        onClick={modifiedCallback}
        className="actionButton actionButton-l"
        style={rejectButtonStyle(buttonText)}
      >
        {buttonText}
      </button>
    );
  }

  const handleClockInTimeChange = (newDateValue: MaterialUiPickersDate) => {
    sendAnalyticsEvent('CaregiverShifts', 'Modal Clock In Time Change', {
      eventValue: newDateValue ? newDateValue.unix() : undefined,
    });

    const newValue = moment(newDateValue).format();
    setClockInDateTime(newValue);
  };

  const handleClockOutTimeChange = (newDateValue: MaterialUiPickersDate) => {
    sendAnalyticsEvent('CaregiverShifts', 'Modal Clock Out Time Change', {
      eventValue: newDateValue ? newDateValue.unix() : undefined,
    });

    const newValue = moment(newDateValue).format();
    setClockOutDateTime(newValue);
  };

  function editShift() {
    return (
      <div>
        <div className="editModalSections">
          Select a reason code to edit clock in time:
          <div className="inputSection">
            {getReasonCodeSelection(ReasonCodeTypes.TIME_IN, setClockInTimeCorrectionId)}
            {clockInTimeCorrectionId && (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker value={clockInDateTime} onChange={handleClockInTimeChange} />
              </MuiPickersUtilsProvider>
            )}
          </div>
        </div>
        <div className="editModalSections">
          Select a reason code to edit clock out time:
          <div className="inputSection">
            {getReasonCodeSelection(ReasonCodeTypes.TIME_OUT, setClockOutTimeCorrectionId)}
            {clockOutTimeCorrectionId && (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker value={clockOutDateTime} onChange={handleClockOutTimeChange} />
              </MuiPickersUtilsProvider>
            )}
          </div>
        </div>
        <div className="editModalSections">
          Select a reason code for clock in GPS corrections:
          <div className="inputSection">
            {getReasonCodeSelection(ReasonCodeTypes.LOCATION_IN, setClockInLocationCorrectionId)}
          </div>
        </div>
        <div className="editModalSections">
          Select a reason code for clock out GPS corrections:
          <div className="inputSection">
            {getReasonCodeSelection(ReasonCodeTypes.LOCATION_OUT, setClockOutLocationCorrectionId)}
          </div>
        </div>
        <div className="editModalSections">
          <div>Clock in notes: (notes)</div>
          <div>Clock out notes: (notes)</div>
        </div>
        <div>
          {(clockInTimeCorrectionId ||
            clockOutTimeCorrectionId ||
            clockInLocationCorrectionId ||
            clockOutLocationCorrectionId) &&
            submitButton(ShiftReviewActions.REVIEW, () => setIsInChangeReview(true))}
          {submitButton(ShiftReviewActions.REJECT, rejectShift)}
        </div>
      </div>
    );
  }

  const rejectShift = async (): Promise<void> => {
    try {
      sendAnalyticsEvent('CaregiverShifts', 'Shift rejected');
      const update: CaregiverShiftUpdate = {
        clockInOutId: shiftData.id,
        status: StatusType.REJECTED,
        clockInOutRejectionReasons: [{ reasonId: EVV_REJECT, clockInOutId: shiftData.id }],
      };
      const result = await updateCaregiverShift(update);
      onShiftUpdated(result);
      // The use of the error class is to utilize the red color to indicate
      // the shift was rejected.
      addToast(
        `You've successfully rejected the shift`,
        getDefaultToastConfig(ToastAppearance.ERROR)
      );
    } catch (error) {
      console.log('Reject shift error ', (error as any).message);
      addToast('Error rejecting shift', getDefaultToastConfig(ToastAppearance.ERROR));
    }
    setIsModalOpen(false);
  };

  const submitShiftHandler = async () => {
    try {
      sendAnalyticsEvent('CaregiverShifts', 'Shift under review');

      const correctionIds = {
        clockInTimeCorrectionId: clockInTimeCorrectionId,
        clockOutTimeCorrectionId: clockOutTimeCorrectionId,
        clockInLocationCorrectionId: clockInLocationCorrectionId,
        clockOutLocationCorrectionId: clockOutLocationCorrectionId,
      };

      const update: CaregiverShiftUpdate = {
        clockInOutId: shiftData.id,
        correctionIds: correctionIds,
        status: StatusType.REJECTED,
        adjustedClockInTimeStamp: clockInDateTime,
        adjustedClockOutTimeStamp: clockOutDateTime,
        clockInOutRejectionReasons: rejectionReasons
          .filter((x) => x.clockInOutId !== undefined && x.reasonId !== undefined)
          .map((x) => ({
            id: x.id,
            clockInOutId: x.clockInOutId as number,
            reasonId: x.reasonId as number,
          })),
      };
      const result = await updateCaregiverShift(update);
      onShiftUpdated(result);
      addToast(`Shift placed under review!`, getDefaultToastConfig(ToastAppearance.INFO));
    } catch (error) {
      console.log('Review shift error ', (error as any).message);
      addToast('Error placing shift under review', getDefaultToastConfig(ToastAppearance.ERROR));
    }
    setIsModalOpen(false);
  };

  const updateRejectionReasons = (index: number, value: any) => {
    rejectionReasons[index].value = value.value;
    rejectionReasons[index].reasonId = value.value;
    rejectionReasons[index].clockInOutId = shiftData.id;
    rejectionReasons[index].label = value.label;
    sendAnalyticsEvent('CaregiverShifts', 'Modal Rejection Reason Updated', {
      eventLabel: rejectionReasons[index].label,
      eventValue:
        rejectionReasons[index].value === null
          ? undefined
          : (rejectionReasons[index].value as number),
    });
    setRejectionReasons([...rejectionReasons]);
  };

  const addRejectionReason = () => {
    sendAnalyticsEvent('CaregiverShifts', 'Modal Rejection Reason Added');
    setRejectionReasons([...rejectionReasons, { value: null, label: 'Please select a reason...' }]);
  };

  const removeRejectionReason = (indexToRemove: number) => {
    const { label, value } = rejectionReasons[indexToRemove];

    sendAnalyticsEvent('CaregiverShifts', 'Modal Rejection Reason Removed', {
      eventLabel: label,
      eventValue: value === null ? undefined : (value as number),
    });

    setRejectionReasons([
      ...rejectionReasons.filter(
        (tempReason: any, tempIndex: number) => tempIndex !== indexToRemove
      ),
    ]);
  };

  return (
    <span>
      <button type="button" onClick={handleOpen} className="actionButton">
        {ShiftReviewActions.REVIEW_REJECT}
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isModalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 175,
        }}
      >
        <Fade in={isModalOpen}>
          <div className={classes.paper}>
            <div className="row">
              <div>
                <h2 id="transition-modal-title">{ShiftReviewActions.REVIEW_REJECT} Shift</h2>
              </div>
              <div>
                <ReviewPeopleInfo
                  caregiverName={`${shiftData.nursesGivenName} ${shiftData.nursesFamilyName}`}
                  caregiverWorkDayId={shiftData.nursesWorkdayId}
                  patientName={`${shiftData.patientsGivenName} ${shiftData.patientsFamilyName}`}
                  patientSearchField={shiftData.patientsSearchField}
                ></ReviewPeopleInfo>
              </div>
            </div>
            {isInChangeReview ? (
              <>
                <ShiftEditReview
                  before={{
                    ...shiftData.correctionIds,
                    adjustedClockInTimeStamp: shiftData.adjustedClockInTimeStamp,
                    adjustedClockOutTimeStamp: shiftData.adjustedClockOutTimeStamp,
                  }}
                  after={{
                    clockInTimeCorrectionId: clockInTimeCorrectionId,
                    clockOutTimeCorrectionId: clockOutTimeCorrectionId,
                    clockInLocationCorrectionId: clockInLocationCorrectionId,
                    clockOutLocationCorrectionId: clockOutLocationCorrectionId,
                    adjustedClockInTimeStamp: clockInDateTime,
                    adjustedClockOutTimeStamp: clockOutDateTime,
                  }}
                />
                <RejectionSection
                  rejectionReasons={rejectionReasons}
                  setRejectionReasons={updateRejectionReasons}
                  addRejectionReason={addRejectionReason}
                  removeRejectionReason={removeRejectionReason}
                  rejectionReasonsOptions={aggregatedReasonCodes.rejectionRejectOptions}
                  isApproval={false}
                />
                <div>
                  {(clockInTimeCorrectionId ||
                    clockOutTimeCorrectionId ||
                    clockInLocationCorrectionId ||
                    clockOutLocationCorrectionId) &&
                    rejectionReasons &&
                    rejectionReasons.length &&
                    rejectionReasons[0].value &&
                    submitButton(ShiftReviewActions.SUBMIT_REVIEW, submitShiftHandler)}
                  {submitButton(ShiftReviewActions.REJECT, rejectShift)}
                </div>
              </>
            ) : (
              editShift()
            )}
          </div>
        </Fade>
      </Modal>
    </span>
  );
}

export default ShiftEditModal;
