import { REHYDRATE } from 'redux-persist';
import { types } from './constants';
import { generateStateFromExistingJSON } from './ShiftDetails/DVRForm';

interface IAidesState {
  isFetching: boolean;
  allShifts: any;
  currentShift: any;
  currentDVRState: any;
  currentEditedShift: any;
  currentEditedDVRState: any;
  isShiftEdited: boolean;
  error?: string | null;
  qaShifts: any | null;
}

const aidesInitialState: IAidesState = {
  // ui state
  isFetching: false,
  error: null,

  // data
  allShifts: null,
  currentShift: null,
  currentDVRState: null,
  currentEditedShift: null,
  currentEditedDVRState: null,
  qaShifts: null,
  isShiftEdited: false,
};

export const aidesReducer = (
  state = aidesInitialState,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case types.SET_CURRENT_SHIFT:
      return {
        ...state,
        currentShift: { ...payload.shift },
        currentEditedShift: { ...payload.shift },
        currentDVRState: generateStateFromExistingJSON(
          payload.shift.form && payload.shift.form.jsonData
        ),
        currentEditedDVRState: generateStateFromExistingJSON(
          payload.shift.form && payload.shift.form.jsonData
        ),
      };
    case types.SET_SAVE_CURRENT_SHIFT:
      return {
        ...state,
        currentShift: { ...payload.shift },
        currentEditedShift: { ...payload.shift },
      };
    case types.SET_CURRENT_CLOCK_IN_OUT:
      return {
        ...state,
      };
    case types.SET_CURRENT_EDITED_SHIFT:
      return {
        ...state,
        ...updateCurrentEditedShift(state.currentShift, payload.editedShift),
      };
    case types.REVERT_SHIFT_EDITS:
      return {
        ...state,
        currentEditedShift: state.currentShift ? { ...state.currentShift } : null,
        currentEditedDVRState: state.currentDVRState ? { ...state.currentDVRState } : null,
      };
    case types.SET_EDITED_DVR:
      return {
        ...state,
        isShiftEdited: true,
        currentEditedDVRState: { ...payload.editedDVR },
      };
    case types.CLEAR_CURRENT_SHIFT:
      return {
        isShiftEdited: false,
        currentShift: null,
        currentEditedShift: null,
        currentDVRState: null,
        currentEditedDVRState: null,
      };
    case types.SET_QA_SHIFTS:
      return {
        ...state,
        qaShifts: payload.qaShifts,
      };
    case REHYDRATE:
      return {
        ...state,
        currentShift: null,
        currentDVRState: null,
        currentEditedShift: null,
        currentEditedDVRState: null,
        isShiftEdited: false,
      };
    default:
      return state;
  }
};

const updateCurrentEditedShift = (currentShift: any, editedShift: any) => {
  const tempCurrentShift = JSON.stringify(currentShift);
  const tempEditedShift = JSON.stringify(editedShift);
  const tempIsEdited = tempCurrentShift === tempEditedShift ? false : true;

  return {
    isShiftEdited: tempIsEdited,
    currentEditedShift: { ...editedShift },
  };
};
