// A shift/request progresses in this order
// 0 = Available             <comes from EMR
// 4 = Requested          <set by Scheduler for a Nurse
// 2 = Pending              <set by Nurse
// 5 = Awarded             <set by Scheduler for a Nurse
// 1 = Scheduled or      <set by Nurse sent to EMR, also comes from EMR
// 3 = Rejected             <set by API on other requests when Nurse sets Scheduled

export enum shiftStatusCode {
  available = 0,
  requested = 4,
  pending = 2,
  awarded = 5,
  scheduled = 1,
  rejected = 3,
}

// oriented
// 0 = NotApplicable
// 1 = No
// 2 = Yes

export enum nurseOrientedCode {
  isOriented = 2,
  isNotOriented = 1,
  isNotApplicable = 0,
}

export enum FilterNames {
  'Before Date' = 'Before Date',
  'After Date' = 'After Date',
  'Patient Name' = 'Patient Name',
  'Patient ID' = 'Patient ID',
  'Min Skill Level' = 'Min Skill Level',
  'Max Skill Level' = 'Max Skill Level',
  'Location' = 'Location',
  'Primary Diagnosis' = 'Primary Diagnosis',
}

export enum tabFilterNames {
  openShiftsFilters = 'openShiftsFilters',
  sentShiftsFilters = 'sentShiftsFilters',
  appliedShiftsFilters = 'appliedShiftsFilters',
  awardedShiftsFilters = 'awardedShiftsFilters',
  confirmedShiftsFilters = 'confirmedShiftsFilters',
  orientRequestsFilters = 'orientRequestsFilters',
}
