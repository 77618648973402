import React, { Component, Fragment } from 'react';
import moment from 'moment';
import {
  CardContainer,
  CardContent,
  ContentText,
  Bold,
  StartEndHours,
  CardBottomButton,
} from '../components/Cards';

import { Loading, Error, NoneFound } from '../../common/';

import { getAllShifts } from '../../api';
import { API_RETRY_TIMEOUT } from '../../utils';
import { AvailableNurseListModal } from './AvailableNurseListModal';

export class OpenShiftCards extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { selectedShift: null, shifts: [], loading: true, error: false };
  }

  private retryTimeout: any;

  public componentDidMount = async () => {
    this.getShiftData();
  };

  public componentWillUnmount() {
    clearTimeout(this.retryTimeout);
  }

  public retryGetData = async () => {
    this.setState({ error: true });
    this.retryTimeout = setTimeout(this.getShiftData, API_RETRY_TIMEOUT);
  };

  public getShiftData = async () => {
    this.setState({ loading: true });
    try {
      const tempAllShifts = await getAllShifts();
      this.setState({ shifts: tempAllShifts, loading: false, error: false });
    } catch (error) {
      console.log('error — ', error);
      this.setState({ error: true, loading: false });
      this.retryGetData();
    }
  };

  public renderModal() {
    const closeModalFunction = () => this.setState({ openModal: false }, () => this.getShiftData());

    return (
      <AvailableNurseListModal shift={this.state.selectedShift} closeModal={closeModalFunction} />
    );
  }

  public render() {
    const shifts = this.state.shifts.map((shift: any) => (
      <div style={{ margin: '15px', width: '280px' }} key={`${shift.patient.id} ${shift.start}`}>
        <OpenShiftCard
          shift={shift}
          onButtonClick={() =>
            this.setState({ selectedShift: shift }, () => this.setState({ openModal: true }))
          }
        />
      </div>
    ));

    return (
      <Fragment>
        {this.state.error && <Error />}
        {!this.state.error && !this.state.loading && this.state.shifts.length === 0 && (
          <NoneFound />
        )}
        {!this.state.error && this.state.loading ? (
          <Loading />
        ) : (
          <Fragment>
            {this.state.openModal && this.renderModal()}
            {shifts}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const OpenShiftCard = ({
  shift,
  shift: {
    start,
    end,
    patient: { name, skillLevel, sex, age, weight, primaryDiagnosis, address },
    // status,
    hours,
  },
  onButtonClick,
}: any) => {
  const splitAddress = address.split(',');
  const streetCity = splitAddress[0].split(' ');
  const street = streetCity.slice(0, -1).join(' ') || '';
  const city = streetCity.pop() || '';
  const stateZip = splitAddress[1] || '';
  return (
    <CardContainer style={{ maxHeight: '50vh' }}>
      <CardContent>
        <ContentText style={{ fontSize: '20px', lineHeight: '24px' }}>
          <Bold>
            {name} - {moment(start).format('MMM DD')}
          </Bold>
        </ContentText>
        <ContentText>Skill Level: {skillLevel}</ContentText>
        <StartEndHours start={start} end={end} hours={hours} />
        <ContentText>{`${street}`}</ContentText>
        <ContentText>{`${city}, ${stateZip}`}</ContentText>
        <ContentText>
          <Bold>Age:</Bold> {age} &nbsp;<Bold>Sex:</Bold> {sex} &nbsp;<Bold>Weight:</Bold> {weight}
          lbs
        </ContentText>
        <ContentText>
          <Bold>Primary Diagnosis:</Bold> {primaryDiagnosis}
        </ContentText>
      </CardContent>
      <CardBottomButton onClick={onButtonClick}>Check Availability</CardBottomButton>
    </CardContainer>
  );
};
