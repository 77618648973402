import React, { Component, Fragment } from 'react';
import moment from 'moment';

import { API_RETRY_TIMEOUT } from '../../utils';
import { getAwardedShifts, removeAwardedNurseShift, remindNursesForShift } from '../../api';

import {
  CardContainer,
  CardContent,
  ContentText,
  Bold,
  StartEndHours,
  CardBottomButton,
} from '../components/Cards';
import { Loading, Error, NoneFound } from '../../common/';
import { CardToastWrapper } from '../components/ModalBody';
import { NurseCardDisplay } from '../../Nurses/Components/NurseCardDisplay';
import { AppliedNurseListModal } from './AppliedNurseListModal';

import { shiftStatusCode } from '../enums';

enum ToastText {
  reassign = 'Removed - Please Reassign Shift',
  remind = 'Reminder Sent',
  failed = 'Something Went Wrong',
}

export class AwardedShiftCards extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedShift: null,
      shifts: [],
      showToast: false,
      toastText: 'default',
      toastCardShiftKey: null,
      openModal: false,
      loading: true,
      error: false,
    };
  }

  private retryTimeout: any;

  public componentWillMount = async () => {
    this.getShiftData();
  };

  public componentWillUnmount() {
    clearTimeout(this.retryTimeout);
  }

  public getShiftData = async () => {
    this.setState({ loading: true });

    try {
      const tempAwardedShifts = await getAwardedShifts();
      this.setState({ shifts: tempAwardedShifts, loading: false, error: false });
    } catch (error) {
      console.log('error — ', error);
      this.setState({ loading: false, error: true });
      this.retryGetData();
    }
  };

  public retryGetData = async () => {
    this.retryTimeout = setTimeout(this.getShiftData, API_RETRY_TIMEOUT);
  };

  public handleReassignShift = async (shift: any) => {
    const awardedApplicant = shift.applications.find((application: any) => {
      return application.status === shiftStatusCode.awarded;
    });

    if (awardedApplicant) {
      const res = await removeAwardedNurseShift(shift.key, awardedApplicant.applicant.id);

      if (res.status === 200) {
        this.setState({
          showToast: true,
          toastText: ToastText.reassign,
          toastCardShiftKey: shift.key,
        });
        setTimeout(() => this.setState({ openModal: true, showToast: false }), 1500);
      } else {
        this.setState({
          showToast: true,
          toastText: ToastText.failed,
          toastCardShiftKey: shift.key,
        });
        setTimeout(() => this.setState({ openModal: false, showToast: false }), 1500);
      }
    }
  };

  public handleRemindShift = async (shiftKey: any) => {
    const res = await remindNursesForShift(shiftKey);

    if (res.status === 204) {
      this.setState({ showToast: true, toastText: ToastText.remind, toastCardShiftKey: shiftKey });
      setTimeout(() => this.setState({ showToast: false }), 1500);
    } else {
      this.setState({ showToast: true, toastText: ToastText.failed, toastCardShiftKey: shiftKey });
    }
  };

  public renderModal() {
    const closeModalFunction = () => this.setState({ openModal: false }, () => this.getShiftData());

    return (
      <AppliedNurseListModal shift={this.state.selectedShift} closeModal={closeModalFunction} />
    );
  }

  public renderToast = () => {
    if (this.state.showToast) {
      return (
        <CardToastWrapper failed={this.state.toastText === ToastText.failed}>
          {this.state.toastText}
        </CardToastWrapper>
      );
    }
  };

  public render() {
    const shifts = this.state.shifts.map((shift: any) => {
      const showThisToast =
        this.state.showToast && this.state.toastCardShiftKey === shift.key ? true : false;
      return (
        <div style={{ margin: '15px', width: '280px' }} key={`${shift.patient.id} ${shift.start}`}>
          <AwardedShiftCard
            shift={shift}
            onReassignClick={() =>
              this.setState({ selectedShift: shift }, () => this.handleReassignShift(shift))
            }
            onRemindClick={() =>
              this.setState({ selectedShift: shift }, () => this.handleRemindShift(shift.key))
            }
            showToast={showThisToast}
            toastText={this.state.toastText}
          />
        </div>
      );
    });

    return (
      <Fragment>
        {this.state.error && <Error />}
        {!this.state.error && !this.state.loading && this.state.shifts.length === 0 && (
          <NoneFound />
        )}
        {!this.state.error && this.state.loading ? (
          <Loading />
        ) : (
          <Fragment>
            {this.state.openModal && this.renderModal()}
            {shifts}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export const AwardedShiftCard = ({
  shift: {
    start,
    end,
    patient: { name, address },
    // status,
    hours,
    applications,
  },
  onReassignClick,
  onRemindClick,
  showToast,
  toastText,
}: any) => {
  let applicant;
  try {
    applicant = applications.find((application: any) => application.status === 5).applicant;
  } catch (e) {
    return <div />;
  }
  const { imageUri, givenName, familyName } = applicant.user;
  const nurse = { skillLevel: applicant.skillLevel, imageUri, givenName, familyName };
  return (
    <CardContainer style={{ maxHeight: '300px', borderLeftColor: '#FFBE00' }}>
      <CardContent>
        <ContentText style={{ fontSize: '20px', lineHeight: '24px' }}>
          <Bold>
            {name} - {moment(start).format('MMM DD')}
          </Bold>
        </ContentText>
        <StartEndHours start={start} end={end} hours={hours} />
        <ContentText>{address}</ContentText>
        <ContentText>Awarded to</ContentText>
        <NurseCardDisplay key={applicant.userId + applicant.externalId} nurse={nurse} />
      </CardContent>
      {showToast ? (
        <CardToastWrapper failed={toastText === ToastText.failed}>{toastText}</CardToastWrapper>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <CardBottomButton onClick={onReassignClick}>Reassign</CardBottomButton>
          <CardBottomButton onClick={onRemindClick}>Remind</CardBottomButton>
        </div>
      )}
    </CardContainer>
  );
};
