import { api, userLogin, getSocketConnection } from '../api';
import { interfaces } from '../common';
import { types } from './constants';
import { IUserSignInInput, ISetUserInput } from './interfaces';
import { isCookieAuthEnabled } from '../utils/app';
import { getErrorMessage } from '../common/useToastNotifications';

export const login = ({ email, password, isRemembered }: IUserSignInInput) => {
  return async (dispatch: any) => {
    dispatch(requestLogin());

    return new Promise<void>(async (resolve, reject) => {
      try {
        const userResponse = await userLogin({ email, password, isRemembered });

        dispatch(loginSuccess(userResponse));
        resolve();
      } catch (error) {
        console.log('error — ', getErrorMessage(error));
        dispatch(loginFailure(error));
        reject();
      }
    });
  };
};

const requestLogin = () => {
  return {
    type: types.REQUEST_LOGIN,
  };
};

const loginSuccess = (user: any) => {
  return {
    type: types.LOGIN_SUCCESS,
    payload: { user: user.user, token: user.token },
  };
};

const loginFailure = (error: any) => {
  return {
    type: types.LOGIN_FAILURE,
    payload: { error },
  };
};

export const setUser = ({ user, token }: ISetUserInput) => {
  return async (dispatch: any) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await setUpAppWithUserCredentials(user, token);
      } catch (e) {
        reject();
      }
      dispatch(setUserState(user, token));
      resolve();
    });
  };
};

export const setUpAppWithUserCredentials = async (user: interfaces.IUser, token: string) => {
  try {
    await getSocketConnection({ ...user, token });
  } catch (error) {
    console.log(`error with creating socket: ${error}`);
    throw error;
  }

  if (!isCookieAuthEnabled()) {
    await api.jwt(token);
  }
};

const setUserState = (user: any, token: string) => {
  return {
    type: types.SET_USER,
    payload: { user, token },
  };
};

export const clearUser = (): { type: string } => {
  return {
    type: types.CLEAR_USER,
  };
};

export const setAppFeatureFlags = (featureFlags: interfaces.IFeatureFlags) => {
  return {
    type: types.SET_APP_FEATURE_FLAGS,
    payload: { featureFlags },
  };
};

export const setUserLocations = (locations: interfaces.ILocation) => {
  return {
    type: types.SET_LOCATIONS,
    payload: { locations },
  };
};
