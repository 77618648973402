import React, { useState, FormEvent, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

// @ts-ignore — no types provided
import { useToasts } from 'react-toast-notifications';

import { changePassword } from '../../api';
import { isPasswordValid, ToastAppearance } from '../../utils';
import {
  StyledInputGroup,
  StyledInputLabel,
  StyledSubmitButton,
  StyledPasswordInput,
  StyledErrorDisplay,
} from '../../common';
import { PasswordFormLayout, PasswordGuidelines } from '../components';
import { successTimeout, staticGuidelines, passwordRequirementsMessage } from '../constants';
import { IPasswordChip } from '../interfaces';
import { ROUTE_PARAMS, ROUTES } from '../../routing';
import { getErrorMessage } from '../../common/useToastNotifications';

interface IRouteParams {
  status?: string;
}

type Props = {} & RouteComponentProps<IRouteParams>;

export const ChangePassword = ({ history, match }: Props) => {
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errors, setErrors] = useState<any>(null);
  const [networkError, setNetworkError] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { addToast } = useToasts();

  useEffect(() => {
    if (newPassword === '') {
      setIsValid(false);
      return;
    }

    if (!isPasswordValid(newPassword)) {
      setErrors({ ...errors, newPassword: 'Does not meet password requirements' });
      setIsValid(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrors({ confirmPassword: 'New password and confirm password must match.' });
      setIsValid(false);
      return;
    }

    setErrors({ ...errors, confirmPassword: null, newPassword: null });
    setIsValid(true);
  }, [newPassword, confirmPassword, isValid]);

  const submitForm = async (event?: FormEvent<HTMLInputElement>) => {
    setIsSubmitting(true);
    setNetworkError('');

    if (event) {
      event.preventDefault();
    }
    setErrors(null);

    try {
      await changePassword({ oldPassword, newPassword });
      onSuccess();
    } catch (error) {
      setNetworkError(getErrorMessage(error));
    }

    setIsSubmitting(false);
  };

  const onSuccess = () => {
    addToast(`You've successfully updated your password`, {
      appearance: ToastAppearance.SUCCESS,
      autoDismiss: true,
    });

    if (isExpired) {
      history.replace(`${ROUTES.SHIFTS}/${ROUTE_PARAMS.OPEN}`);
    } else {
      history.goBack();
    }
  };

  const isExpired =
    match.params && match.params.status && match.params.status === ROUTE_PARAMS.EXPIRED
      ? true
      : false;

  const tempPasswordGuidelines: IPasswordChip[] = [
    {
      key: 'requirement',
      message: passwordRequirementsMessage,
      isSatisfied: isPasswordValid(newPassword),
    },
    {
      key: 'newPasswordMatch',
      message: 'New Password and Confirm Password must match',
      isSatisfied: newPassword !== '' && newPassword === confirmPassword,
    },
    ...staticGuidelines,
  ];

  return (
    <PasswordFormLayout>
      <h2>Update Password</h2>
      {isExpired && (
        <div style={{ marginBottom: 12 }}>
          <p>Your password has expired. Kindly set a new one to continue using the app.</p>
          <p>As always, thank you for your service.</p>
        </div>
      )}
      <StyledInputGroup style={{ marginTop: 6 }}>
        <StyledInputLabel>Current Password</StyledInputLabel>
        <StyledPasswordInput
          placeholder="Current Password"
          name="oldPassword"
          errors={errors}
          value={oldPassword}
          onChange={(event: any) => {
            setOldPassword(event.target.value);
          }}
        />
      </StyledInputGroup>
      <StyledInputGroup style={{ marginTop: 6 }}>
        <StyledInputLabel>New Password</StyledInputLabel>
        <StyledPasswordInput
          placeholder="New Password"
          name="newPassword"
          value={newPassword}
          errors={errors}
          onChange={(event: any) => {
            setNewPassword(event.target.value);
          }}
        />
        <StyledPasswordInput
          placeholder="Confirm Password"
          name="confirmPassword"
          value={confirmPassword}
          errors={errors}
          onChange={(event: any) => {
            setConfirmPassword(event.target.value);
          }}
        />
      </StyledInputGroup>
      <StyledErrorDisplay errorMessage={networkError} />
      <PasswordGuidelines guidelines={tempPasswordGuidelines} />
      <StyledSubmitButton
        type="submit"
        value="UPDATE"
        onClick={submitForm}
        disabled={!isValid || isSubmitting}
      />
    </PasswordFormLayout>
  );
};
