import React from 'react';
import styled, { keyframes } from 'styled-components';

const loading = keyframes`
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1, 2.2);
    }
    40% {
      transform: scale(1);
    }
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  max-height: 30px;
`;

const LoadingBar = styled.div`
  background-color: #0075c9;
  width: 6px;
  min-height: 20px;
  height: 100%;
  margin: 0 1px 0 1px;
  border-radius: 3px;
  animation-name: ${loading};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  &:nth-child(1) {
    animation-delay: 0;
  }

  &:nth-child(2) {
    animation-delay: 0.09s;
  }

  &:nth-child(3) {
    animation-delay: 0.18s;
  }

  &:nth-child(4) {
    animation-delay: 0.27s;
  }

  &:nth-child(5) {
    animation-delay: 0.36s;
  }
`;

export const Loading = (props: any) => (
  <LoadingWrapper {...props}>
    <LoadingBar />
    <LoadingBar />
    <LoadingBar />
    <LoadingBar />
    <LoadingBar />
  </LoadingWrapper>
);

export const Error = (props: any) => (
  <div style={{ color: 'gray', fontSize: '20px', margin: '20px' }}>
    {props.errorMessage ? props.errorMessage : 'Error Fetching Shifts, Retrying...'}
  </div>
);

const StyledNoneFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.7);
  margin: 8px 0 8px 0;
`;

export const NoneFound = () => <StyledNoneFound>None Found</StyledNoneFound>;
