const CLEAR_CURRENT_SHIFT = 'CLEAR_CURRENT_SHIFT';
const REVERT_SHIFT_EDITS = 'REVERT_SHIFT_EDITS';
const SET_ALL_SHIFTS = 'SET_ALL_SHIFTS';
const SET_CURRENT_CLOCK_IN_OUT = 'SET_CLOCK_IN_OUT';
const SET_CURRENT_EDITED_SHIFT = 'SET_CURRENT_EDITED_SHIFT';
const SET_CURRENT_SHIFT = 'SET_SHIFT';
const SET_DEFAULT_SEARCH_PARAMETERS = 'SET_DEFAULT_SEARCH_PARAMETERS';
const SET_EDITED_DVR = 'SET_EDITED_DVR';
const SET_QA_SHIFTS = 'SET_QA_SHIFTS';
const SET_SAVE_CURRENT_SHIFT = 'SET_SAVE_CURRENT_SHIFT';
const SET_SEARCH_END_DATE = 'SET_SEARCH_END_DATE';
const SET_SEARCH_START_DATE = 'SET_SEARCH_START_DATE';
const SET_SEARCH_TERM = 'SET_SEARCH_TERM';

export const types = {
  CLEAR_CURRENT_SHIFT,
  REVERT_SHIFT_EDITS,
  SET_ALL_SHIFTS,
  SET_CURRENT_CLOCK_IN_OUT,
  SET_CURRENT_EDITED_SHIFT,
  SET_CURRENT_SHIFT,
  SET_DEFAULT_SEARCH_PARAMETERS,
  SET_EDITED_DVR,
  SET_QA_SHIFTS,
  SET_SAVE_CURRENT_SHIFT,
  SET_SEARCH_END_DATE,
  SET_SEARCH_START_DATE,
  SET_SEARCH_TERM,
};

export enum StatusType {
  PENDING = 0,
  APPROVED = 1,
  REJECTED = 2,
}
