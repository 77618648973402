import React from 'react';
import styled, { css } from 'styled-components';

interface IShiftFooterButtonProps {
  onClick?: any;
  buttonColor?: string;
  children?: any;
  disabled?: boolean;
  style?: any;
}

const StyledFooterButton: React.SFC<IShiftFooterButtonProps> = (props) => {
  const { children, buttonColor, ...rest } = props;
  return <button {...rest}>{props.children}</button>;
};

export const ShiftFooterButton = styled(StyledFooterButton)`
  cursor: pointer;
  border-radius: 6px;
  margin-right: 12px;
  padding: 12px 24px;
  border: none;
  background-color: ${({ theme, buttonColor }) =>
    buttonColor ? buttonColor : theme.colors.footerButtonGreen};
  font-size: ${({ theme }) => theme.fontSizeMd};
  color: ${({ theme }) => theme.colors.white};

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background-color: grey;
    `};
`;

export const ShiftFooterTextButton = styled.button`
  background-color: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizeMd};
`;
