import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { updateNurseExternalSource } from '../../../api/admin';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { getDefaultToastConfig, ToastAppearance } from '../../../utils';
import { IExternalSource } from '../../../common/interfaces';

export const ExternalSourceEditor = forwardRef((props: any, ref) => {
  const { node } = props;
  const originalExternalSource = props.data.nurseExternalSource;
  const [externalSource, setExternalSource] = useState(originalExternalSource);
  const externalSources = useSelector(({ admin }: any) => admin.externalSources);

  const { addToast } = useToasts();

  const updateExternalSource = async (nurseId: number, newExternalSource: string) => {
    if (newExternalSource !== originalExternalSource) {
      try {
        await updateNurseExternalSource(nurseId, newExternalSource);
        addToast(
          originalExternalSource !== null
            ? `External Source updated from ${originalExternalSource} to ${newExternalSource}`
            : `External Source updated to ${newExternalSource}`,
          getDefaultToastConfig(ToastAppearance.SUCCESS)
        );
      } catch (error) {
        addToast((error as Error).message, getDefaultToastConfig(ToastAppearance.WARNING));
        node.setDataValue('nurseExternalSource', originalExternalSource);
      }
    }
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        if (!externalSource) {
          return null;
        }
        updateExternalSource(props.data.nurseId, externalSource);

        return externalSource;
      },
    };
  });

  const getDropdownOptions = (): JSX.Element[] =>
    externalSources.map((externalSource: IExternalSource, index: number) => {
      return (
        <option value={externalSource.name} key={index}>
          {externalSource.name}
        </option>
      );
    });

  return (
    <select
      value={externalSource}
      onChange={(e) => {
        setExternalSource(e.target.value);
      }}
    >
      {!externalSource && <option value="">Select one...</option>}
      {getDropdownOptions()}
    </select>
  );
});

export default ExternalSourceEditor;
