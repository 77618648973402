import React, { Component } from 'react';

import MaterialIcon from 'material-icons-react';

import { StyledChannel, StyledIndicator } from './StyledChannel';
import { IChannel, IMessage, IGetNewMessagesParams } from '../interfaces';
import { getChatChannelMessageFromParticipants } from '../../utils';

import DefaultAveannaLogo from '../../assets/aveanna-user-logo.png';

interface IProps {
  isActive: boolean;
  setActiveChannel: (id: number) => void;
  getNewMessages: ({ channelId, lastMessageId }: IGetNewMessagesParams) => void;
  channel: IChannel;
  user: any;
}

const messageRefreshRate = 2000;

class Channel extends Component<IProps> {
  private pollMessages: any = null;

  public componentWillUnmount() {
    clearInterval(this.pollMessages);
  }

  public componentDidUpdate() {
    const { isActive, channel } = this.props;
    if (isActive) {
      const tempLastMessage = this.getLastMessage(channel);
      if (tempLastMessage) {
        this.initPollForMessages(channel.id, tempLastMessage.id);
      }
    } else {
      clearInterval(this.pollMessages);
    }
  }

  private initPollForMessages = (channelId: number, lastMessageId: number) => {
    const { getNewMessages } = this.props;
    clearInterval(this.pollMessages);
    this.pollMessages = setInterval(() => {
      getNewMessages({ channelId, lastMessageId });
    }, messageRefreshRate);
  };

  private getLastMessage = (channel: IChannel): IMessage | null => {
    if (channel.messages) {
      return channel.messages[channel.messages.length - 1];
    }
    return null;
  };

  private getChatChannelImage = (): string => {
    const {
      user: currentUser,
      channel: { participants },
    } = this.props;

    return getChatChannelMessageFromParticipants(participants);
  };

  public render() {
    const { channel, setActiveChannel, isActive } = this.props;
    const tempImageURL = this.getChatChannelImage();
    return (
      <StyledChannel
        id={`channel-${channel.id}`}
        onClick={() => {
          setActiveChannel(channel.id);
        }}
        isActive={isActive}
      >
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div>
            <StyledIndicator isActive={channel.isUnread} />
          </div>
          <div
            style={{
              display: 'block',
              height: '40px',
              width: '40px',
            }}
          >
            <img
              src={tempImageURL === '' ? DefaultAveannaLogo : tempImageURL}
              style={{ display: 'block', height: '40px', width: '40px', borderRadius: '20px' }}
            />
          </div>
          <span style={{ marginLeft: '6px' }}>{`${channel.title}`}</span>
          <span style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            <MaterialIcon color="white" size="small" icon="chevron_right" />
          </span>
        </div>
      </StyledChannel>
    );
  }
}

export default Channel;
