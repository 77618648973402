import styled from 'styled-components';

// For typical anchors (will remount the app) unless you use onClick for routing
export const StyledToastAnchor: any = styled.a`
  cursor: pointer;
  border-bottom-right-radius: 4px;
  color: #0075c9;
  border: none;
  font-size: 14px;
  text-decoration: underline;
`;
