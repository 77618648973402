import React from 'react';
import styled, { css } from 'styled-components';

import { skillLevelColors } from '../constants';
import { IPatient } from '../../Shifts/interfaces';

type Props = {
  skillLevel: number;
  patient: IPatient;
  isSelectedPatient: boolean;
  onSelectPatient: (patient: IPatient) => void;
  lat: number;
  lng: number;
};

export class PatientMapMarker extends React.PureComponent<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const Marker = styled.div`
      width: 10px;
      height: 10px;
      background-color: ${skillLevelColors[this.props.skillLevel]};
      border-radius: 3px;
      position: absolute;
      top: -5px;
      left: -5px;
      ${this.props.isSelectedPatient &&
      css`
        width: 30px;
        height: 30px;
        top: -15px;
        left: -15px;
      `}
    `;

    return (
      <Marker
        onClick={() => {
          this.props.onSelectPatient(this.props.patient);
        }}
      />
    );
  }
}
