import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import MaterialIcon from 'material-icons-react';

import { sendMassMessage as sendMassMessageAction } from '../actions';
import { ModalWrapper, interfaces, device } from '../../common';
import { MessageInput, MessageConfirmModal } from '../components';

interface IProps {
  sendMassMessage: (massMessageInput: interfaces.ISendMassMessageParams) => void;
  user: interfaces.IUser;
}

const MessageAll = ({ sendMassMessage, user }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalActive, setModalActive] = useState(false);
  const [messageText, setMessageText] = useState('');

  const sendMessage = async () => {
    try {
      await sendMassMessage({
        text: messageText,
        channelId: 1,
        sentById: user.id,
        locationId: user.locationId,
      });
      setIsOpen(false);
      setModalActive(false);
    } catch (error) {
      console.log('error — ', error);
    }
  };

  const openConfirmModal = ({ text }: { text: string }) => {
    setMessageText(text);
    setModalActive(true);
  };

  return (
    <MessageAllWrapper>
      <MessageAllButton
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <MaterialIcon color="maroon" size="medium" icon="announcement" />
      </MessageAllButton>
      {isOpen && (
        <MessageInputWrapper>
          <AllMessageHeader>Send message to all Caregivers</AllMessageHeader>
          <MessageInput sendMessage={openConfirmModal} />
        </MessageInputWrapper>
      )}
      {isModalActive && (
        <ModalWrapper
          closeModal={() => {
            setModalActive(false);
          }}
        >
          <MessageConfirmModal
            messageText={messageText}
            onConfirm={sendMessage}
            onClose={() => setModalActive(false)}
          />
        </ModalWrapper>
      )}
    </MessageAllWrapper>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    sendMassMessage: (massMessageInput: interfaces.ISendMassMessageParams) =>
      dispatch(sendMassMessageAction({ ...massMessageInput })),
  };
};

export default connect(null, mapDispatchToProps)(MessageAll);

// STYLES
const MessageAllWrapper = styled.div`
  position: relative;
  margin-right: 24px;

  @media ${device.tablet} {
    margin-right: 48px;
  }
`;

const MessageAllButton = styled.div`
  cursor: pointer;
`;

const MessageInputWrapper = styled.div`
  position: fixed;
  top: 75px;
  left: 0px;
  right: 0px;
  padding-bottom: 12px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.defaultBoxShadow};

  @media ${device.tablet} {
    position: absolute;
    top: 64px;
    right: 0px;
    left: auto;
    width: 400px;
    border-radius: 4px;
    background-color: white;
    box-shadow: ${({ theme }) => theme.defaultBoxShadow};
    padding-bottom: 4px;
  }
`;

const AllMessageHeader = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  display: flex;
  justify-content: center;
  background-color: maroon;

  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  padding: 24px;
  margin-bottom: 24px;

  @media ${device.tablet} {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
`;
