import { GridCellParams, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { ICaregiverShiftsData } from '../../../../common/interfaces';
import { StatusType } from '../../../../utils';
import { StatusTypeStrings } from '../../../../utils/constants';
import { isRejectedShift } from '../../DataHelpers';

const statusColumn: GridColDef = {
  field: 'status',
  headerName: 'Status',
  valueGetter: ({ row }: { row: ICaregiverShiftsData }) => row,
  valueFormatter: ({ value }: GridValueFormatterParams<ICaregiverShiftsData>) => {
    switch (value.status) {
      case StatusType.PENDING:
        return StatusTypeStrings.PENDING;
      case StatusType.APPROVED:
        return StatusTypeStrings.APPROVED;
      case StatusType.REJECTED:
        return isRejectedShift(value) ? StatusTypeStrings.REJECTED : StatusTypeStrings.REVIEW;
      default:
        return StatusTypeStrings.PENDING;
    }
  },
  cellClassName: (params: GridCellParams<string>) => {
    switch (params.formattedValue) {
      case StatusTypeStrings.PENDING:
        return 'status pending';
      case StatusTypeStrings.APPROVED:
        return 'status approved';
      case StatusTypeStrings.REJECTED:
        return 'status rejected';
      case StatusTypeStrings.REVIEW:
        return 'status review';
      default:
        return 'status pending';
    }
  },
};

export default statusColumn;
