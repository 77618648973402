export const types = {
  REQUEST_MESSAGES: 'REQUEST_MESSAGES',
  GET_MESSAGES_SUCCESS: 'GET_MESSAGES_SUCCESS',
  GET_MESSAGES_FAILURE: 'GET_MESSAGES_FAILURE',
  SET_ACTIVE_CHANNEL: 'SET_ACTIVE_CHANNEL',
  CLEAR_ACTIVE_CHANNEL: 'CLEAR_ACTIVE_CHANNEL',
  ADD_NEW_CHANNEL: 'ADD_NEW_CHANNEL',
  REQUEST_NEW_CHANNEL_MESSAGES: 'REQUEST_NEW_CHANNEL_MESSAGES',
  GET_NEW_CHANNEL_MESSAGES_SUCCESS: 'GET_NEW_CHANNEL_MESSAGES_SUCCESS',
  GET_NEW_CHANNEL_MESSAGES_FAILURE: 'GET_NEW_CHANNEL_MESSAGES_FAILURE',
  CHECK_MESSAGES_SUCCESS: 'CHECK_MESSAGES_SUCCESS',
  CHECK_MESSAGES_FAILURE: 'CHECK_MESSAGES_FAILURE',
  REQUEST_SEND_MESSAGE: 'REQUEST_SEND_MESSAGE',
  SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAILURE: 'SEND_MESSAGE_FAILURE',
  REQUEST_SEND_MASS_MESSAGE: 'REQUEST_SEND_MASS_MESSAGE',
  SEND_MASS_MESSAGE_SUCCESS: 'SEND_MASS_MESSAGE_SUCCESS',
  SEND_MASS_MESSAGE_FAILURE: 'SEND_MASS_MESSAGE_FAILURE',
};
