import React, { Component, Fragment } from 'react';
import moment from 'moment';

import { getAppliedShifts } from '../../api';
import { API_RETRY_TIMEOUT } from '../../utils';

import {
  CardContainer,
  CardContent,
  ContentText,
  Bold,
  StartEndHours,
  CardBottomButton,
} from '../components/Cards';
import { Loading, Error, NoneFound } from '../../common/';
import { AppliedNurseListModal } from './AppliedNurseListModal';

export class AppliedShiftCards extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { selectedShift: null, shifts: [], loading: true, error: false };
  }

  private retryTimeout: any;

  public componentDidMount = async () => {
    this.getShiftData();
  };

  public componentWillUnmount() {
    clearTimeout(this.retryTimeout);
  }

  public getShiftData = async () => {
    this.setState({ loading: true });
    try {
      const tempAppliedShifts = await getAppliedShifts();
      this.setState({ shifts: tempAppliedShifts, loading: false, error: false });
    } catch (error) {
      console.log('error — ', error);
      this.setState({ loading: false, error: true });
      this.retryGetData();
    }
  };

  public retryGetData = async () => {
    this.retryTimeout = setTimeout(this.getShiftData, API_RETRY_TIMEOUT);
  };

  public renderModal() {
    const closeModalFunction = () => this.setState({ openModal: false }, () => this.getShiftData());

    return (
      <AppliedNurseListModal shift={this.state.selectedShift} closeModal={closeModalFunction} />
    );
  }

  public render() {
    const shifts = this.state.shifts.map((shift: any) => (
      <div style={{ margin: '15px', width: '280px' }} key={`${shift.patient.id} ${shift.start}`}>
        <AppliedShiftCard
          shift={shift}
          onButtonClick={() =>
            this.setState({ selectedShift: shift }, () => this.setState({ openModal: true }))
          }
        />
      </div>
    ));

    return (
      <Fragment>
        {this.state.error && <Error />}
        {!this.state.error && !this.state.loading && this.state.shifts.length === 0 && (
          <NoneFound />
        )}
        {!this.state.error && this.state.loading ? (
          <Loading />
        ) : (
          <Fragment>
            {this.state.openModal && this.renderModal()}
            {shifts}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const AppliedShiftCard = ({
  shift: {
    start,
    end,
    patient: { name, address },
    hours,
  },
  onButtonClick,
}: any) => {
  return (
    <CardContainer style={{ maxHeight: '250px', borderLeftColor: '#9399E6' }}>
      <CardContent>
        <ContentText style={{ fontSize: '20px', lineHeight: '24px' }}>
          <Bold>
            {name} - {moment(start).format('MMM DD')}
          </Bold>
        </ContentText>
        <StartEndHours start={start} end={end} hours={hours} />
        <ContentText>{address}</ContentText>
      </CardContent>
      <CardBottomButton onClick={onButtonClick}>View Applicants</CardBottomButton>
    </CardContainer>
  );
};
