import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Switch } from '@material-ui/core';
import { updatePatientDischargedStatus, updatePatientOfficeId } from '../../../api/patient';
import { IFloatingFilterParams } from 'ag-grid-community';
import { useToasts } from 'react-toast-notifications';
import { ToastAppearance } from '../../../utils';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const StatusToggleEditor = forwardRef((props: any, ref) => {
  const [isDischarged, setIsDischarged] = useState<boolean>(props.data.isDischarged);
  const isDischargedString = isDischarged ? 'Discharged' : 'Active';
  const { addToast } = useToasts();

  useEffect(() => {
    props.data.isDischarged = isDischarged;
  }, [isDischarged]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        async function fetchData() {
          try {
            const response = await updatePatientDischargedStatus(props.data.id, isDischarged);
            setIsDischarged(response.isDischarged);
            addToast(`Patient status successfully updated`, {
              appearance: ToastAppearance.SUCCESS,
              autoDismiss: true,
            });
          } catch (error: any) {
            console.log('Error updating patient status ', error.message);
            addToast('Error updating patient status ', {
              appearance: ToastAppearance.WARNING,
              autoDismiss: true,
            });
          }
        }
        fetchData().then();
      },
    };
  });

  return (
    <div id="status-toggle" data-testid="test-status-div">
      <Switch
        id="status-switch"
        data-testid="test-status-switch"
        {...label}
        defaultChecked={!isDischarged}
        onChange={async () => {
          setIsDischarged(!isDischarged);
        }}
      />
      <span data-testid="test-status-text">{isDischargedString}</span>
    </div>
  );
});

export const StatusFilter = forwardRef((props: IFloatingFilterParams, ref) => {
  const inputRef = useRef<any>();
  const params = props.filterParams.context();

  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged(parentModel: any) {
        if (!parentModel) {
          inputRef.current.value = '';
        } else {
          inputRef.current.value = parentModel.filter;
        }
      },
    };
  });

  const onInputBoxChanged = (input: any) => {
    props.parentFilterInstance((instance: any) => {
      if (input.target.value === '') {
        instance.onFloatingFilterChanged(null, null);
      }
      instance.onFloatingFilterChanged('equals', input.target.value);
    });
  };

  function dropdownOptions() {
    return params.map((option: any, index: number) => {
      return (
        <option key={index} value={option.value || ''}>
          {option.option || option}
        </option>
      );
    });
  }

  return (
    <div>
      <select ref={inputRef} onInput={onInputBoxChanged}>
        {dropdownOptions()}
      </select>
    </div>
  );
});
