import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { getDefaultToastConfig, StatusType, ToastAppearance } from '../../../utils';
import { Fade } from '@material-ui/core';
import { sendAnalyticsEvent } from '../../../analytics';
import CaregiverShiftsDataGrid from '../CaregiverShiftsDataGrid/CaregiverShiftsDataGrid';
import DateRangePicker from '../DateRangePicker/DateRangePicker';
import moment, { Moment } from 'moment';
import { getCaregiverShiftsData } from '../../../api/careGiverShifts';
import { ICaregiverShiftsData } from '../../../common/interfaces';
import { useToasts } from 'react-toast-notifications';

export type TabInfo = {
  status: StatusType;
  header: string;
  isQA: boolean;
  isRejected: boolean;
  hasActions: boolean;
};

export const tabInfo: readonly TabInfo[] = [
  {
    status: StatusType.PENDING,
    header: 'Pending Shifts',
    isQA: false,
    isRejected: false,
    hasActions: true,
  },
  {
    status: StatusType.REJECTED,
    header: 'Under Review Shifts',
    isQA: false,
    isRejected: false,
    hasActions: true,
  },
  {
    status: StatusType.APPROVED,
    header: 'Approved Shifts',
    isQA: false,
    isRejected: false,
    hasActions: false,
  },
  {
    status: StatusType.REJECTED,
    header: 'Rejected Shifts',
    isQA: false,
    isRejected: true,
    hasActions: true,
  },
  {
    status: StatusType.QA_SHIFTS,
    header: 'QA Review Shifts',
    isQA: true,
    isRejected: false,
    hasActions: true,
  },
  {
    status: StatusType.ALL_SHIFTS,
    header: 'All Shifts',
    isQA: false,
    isRejected: false,
    hasActions: true,
  },
] as const;

type DateButton = 'Yesterday' | 'This Week' | 'Last Week';

const TabBar = () => {
  const [currentTab, setCurrentTab] = useState(tabInfo[0]);
  const [areTabsDirty, setAreTabsDirty] = useState(false);
  const [startDate, setStartDate] = useState<Moment>(moment().startOf('day').add(-14, 'd'));
  const [endDate, setEndDate] = useState<Moment>(moment().endOf('day'));
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [rows, setRows] = useState<ICaregiverShiftsData[]>([]);
  const [selectedButton, setSelectedButton] = useState<DateButton | null>(null);

  useEffect(() => {
    loadGridData();
  }, []);

  useEffect(() => {
    if (currentTab) {
      const eventLabel = currentTab ? currentTab.header : undefined;
      sendAnalyticsEvent('CaregiverShifts', 'Tab Change', {
        eventLabel,
        isEventNonInteractive: !areTabsDirty,
      });
    }
  }, [currentTab]);

  const { addToast } = useToasts();

  const tabLabels = () => {
    return tabInfo.map((x, index) => <Tab label={x.header} {...a11yProps(index)} />);
  };

  const a11yProps = (index: any) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      key: index,
    };
  };

  async function loadGridData(start: Moment = startDate, end: Moment = endDate): Promise<void> {
    try {
      setIsDataLoading(true);
      const updatedRows = await getCaregiverShiftsData(start, end);
      if (updatedRows) {
        setRows(updatedRows);
      } else {
        setRows([]);
      }
    } catch (error) {
      console.error('Error attempting to load grid data: ', error);
      addToast('Unable to load grid data', getDefaultToastConfig(ToastAppearance.WARNING));
    } finally {
      setIsDataLoading(false);
    }
  }

  const onShiftUpdated = (shiftData: ICaregiverShiftsData): void => {
    setRows([...rows.filter((x) => x.id !== shiftData.id), shiftData]);
  };

  const pickerChanged = async (newStartDate: Moment, newEndDate: Moment): Promise<void> => {
    sendAnalyticsEvent('CaregiverShifts', 'Date Range Changed');
    setSelectedButton(null);
    setStartDate(newStartDate.startOf('day'));
    setEndDate(newEndDate.endOf('day'));
    await loadGridData(newStartDate.startOf('day'), newEndDate.endOf('day'));
  };

  const pickerClosed = async (): Promise<void> => {
    await loadGridData(startDate, endDate);
  };

  const refresh = async (): Promise<void> => {
    sendAnalyticsEvent('CaregiverShifts', 'Refresh Button Clicked');
    await loadGridData(startDate, endDate);
  };

  const viewYesterday = async (): Promise<void> => {
    sendAnalyticsEvent('CaregiverShifts', 'Yesterday Button Clicked');
    setSelectedButton('Yesterday');
    const yesterday = moment().add(-1, 'day').startOf('day');
    const endOfDay = yesterday.clone().endOf('day');
    await viewRange(yesterday, endOfDay);
  };

  const viewLastWeek = async (): Promise<void> => {
    sendAnalyticsEvent('CaregiverShifts', 'Last Week Button Clicked');
    setSelectedButton('Last Week');
    const lastSunday = moment().add(-7, 'day').startOf('week');
    const lastSaturday = lastSunday.clone().add(6, 'day').endOf('day');
    await viewRange(lastSunday, lastSaturday);
  };

  const viewThisWeek = async (): Promise<void> => {
    sendAnalyticsEvent('CaregiverShifts', 'This Week Button Clicked');
    setSelectedButton('This Week');
    const sunday = moment().startOf('week');
    const saturday = sunday.clone().add(6, 'day').endOf('day');
    await viewRange(sunday, saturday);
  };

  const viewRange = async (start: Moment, end: Moment): Promise<void> => {
    setStartDate(start);
    setEndDate(end);
    await loadGridData(start, end);
  };

  const tabContents = () => {
    return (
      <TabPanel>
        <Fade in={true} mountOnEnter unmountOnExit timeout={300}>
          <div className="tabPanel">
            <div>
              <div className="tableActions"></div>
              <DateRangePicker
                className="inputTextBox wholeTableButton"
                startDate={startDate}
                endDate={endDate}
                onChange={pickerChanged}
                onCalendarClose={pickerClosed}
              />
              <button className="actionButton wholeTableButton" onClick={refresh}>
                Refresh
              </button>
              <button
                className="actionButton wholeTableButton"
                disabled={selectedButton === 'Yesterday'}
                onClick={viewYesterday}
              >
                Yesterday
              </button>
              <button
                className="actionButton wholeTableButton"
                disabled={selectedButton === 'This Week'}
                onClick={viewThisWeek}
              >
                This Week
              </button>
              <button
                className="actionButton wholeTableButton"
                disabled={selectedButton === 'Last Week'}
                onClick={viewLastWeek}
              >
                Last Week
              </button>
            </div>

            <CaregiverShiftsDataGrid
              isDataLoading={isDataLoading}
              rows={rows}
              currentTab={currentTab}
              onShiftUpdated={onShiftUpdated}
            />
          </div>
        </Fade>
      </TabPanel>
    );
  };

  const TabPanel = ({ children }: { children: React.ReactNode }) => {
    return (
      <div role="tabpanel" id={`simple-tabpanel`} aria-labelledby={`simple-tab`}>
        {<Box p={3}>{children}</Box>}
      </div>
    );
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setAreTabsDirty(true);
    setCurrentTab(tabInfo[newValue]);
  };

  return (
    <div>
      <h2>{currentTab.header}</h2>
      <div className="tabContainer" data-test-id="shift-tab-container">
        <AppBar position="static">
          <Tabs
            value={tabInfo.indexOf(currentTab)}
            onChange={handleChange}
            aria-label="shift-tabs"
            data-test-id="shift-tab-status"
          >
            {tabLabels()}
          </Tabs>
        </AppBar>
        {tabContents()}
      </div>
    </div>
  );
};

export default TabBar;
