import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { IShiftCard } from '../interfaces';

import { NurseCardDisplay } from '../../Nurses/Components/NurseCardDisplay';

export const GradientCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: linear-gradient(90deg, #6ecde0, #0075c9);
  background-size: cover;
  background-repeat: no-repeat;
`;

export const CardContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  border-left-width: 5px;
  border-left-color: #0075c9;
  border-left-style: solid;
  color: rgba(0, 0, 0, 0.6);

  :last-child {
    margin-bottom: 12px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 10px 10px 5px 10px;
  margin: 10px 10px 0 10px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.6);
  background-color: white;
`;

export const ContentText = styled.span`
  margin-top: 3px;
  margin-bottom: 3px;
  overflow-wrap: normal;
  max-width: 350px;
`;

export const Bold = styled.span`
  font-weight: 600;
`;

export const CardBottomButton = styled.button`
  cursor: pointer;
  margin-top: auto;
  display: block;
  border-bottom-right-radius: 4px;
  background-color: white;
  color: #0075c9;
  border: none;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.3;
  height: 45px;
`;

export enum CardButtonTextByTabType {
  OpenShifts = 'Check Availability',
  SentShifts = 'View Selected Caregivers',
  AppliedShifts = 'View Applicants',
  AwardedShifts = 'View Awarded Caregiver',
}

export const StartEndHours = ({ start, end, hours }: any) => (
  <ContentText>
    {moment(start).format('LT').toLowerCase()} - {moment(end).format('LT').toLowerCase()} &nbsp;{' '}
    {hours} Hours
  </ContentText>
);

export const OpenShiftCard = ({
  shift,
  shift: {
    start,
    end,
    patient: { name, skillLevel, sex, age, weight, primaryDiagnosis, address },
    // status,
    hours,
  },
  buttonText,
  onButtonClick,
}: IShiftCard) => {
  const splitAddress = address.split(',');
  const streetCity = splitAddress[0].split(' ');
  const street = streetCity.slice(0, -1).join(' ') || '';
  const city = streetCity.pop() || '';
  const stateZip = splitAddress[1] || '';
  return (
    <CardContainer style={{ maxHeight: '50vh' }}>
      <CardContent>
        <ContentText style={{ fontSize: '20px', lineHeight: '24px' }}>
          <Bold>
            {name} - {moment(start).format('MMM DD')}
          </Bold>
        </ContentText>
        <ContentText>Skill Level: {skillLevel}</ContentText>
        <StartEndHours start={start} end={end} hours={hours} />
        <ContentText>{`${street}`}</ContentText>
        <ContentText>{`${city}, ${stateZip}`}</ContentText>
        <ContentText>
          <Bold>Age:</Bold> {age} &nbsp;<Bold>Sex:</Bold> {sex} &nbsp;<Bold>Weight:</Bold> {weight}
          lbs
        </ContentText>
        <ContentText>
          <Bold>Primary Diagnosis:</Bold> {primaryDiagnosis}
        </ContentText>
      </CardContent>
      <CardBottomButton onClick={onButtonClick}>{buttonText}</CardBottomButton>
    </CardContainer>
  );
};

export const SentShiftCard = ({
  shift: {
    start,
    end,
    patient: { name, skillLevel, sex, age, weight, primaryDiagnosis, address },
    // status,
    hours,
  },
  buttonText,
  onButtonClick,
}: IShiftCard) => {
  return (
    <CardContainer style={{ maxHeight: '250px', borderLeftColor: '#6ECDE0' }}>
      <CardContent>
        <ContentText style={{ fontSize: '20px', lineHeight: '24px' }}>
          <Bold>
            {name} - {moment(start).format('MMM DD')}
          </Bold>
        </ContentText>
        <StartEndHours start={start} end={end} hours={hours} />
        <ContentText>{address}</ContentText>
      </CardContent>
      <CardBottomButton onClick={onButtonClick}>{buttonText}</CardBottomButton>
    </CardContainer>
  );
};

export const AppliedShiftCard = ({
  shift,
  shift: {
    start,
    end,
    patient: { name, skillLevel, sex, age, weight, primaryDiagnosis, address },
    // status,
    hours,
  },
  buttonText,
  onButtonClick,
}: IShiftCard) => {
  return (
    <CardContainer style={{ maxHeight: '250px', borderLeftColor: '#9399E6' }}>
      <CardContent>
        <ContentText style={{ fontSize: '20px', lineHeight: '24px' }}>
          <Bold>
            {name} - {moment(start).format('MMM DD')}
          </Bold>
        </ContentText>
        <StartEndHours start={start} end={end} hours={hours} />
        <ContentText>{address}</ContentText>
      </CardContent>
      <CardBottomButton onClick={onButtonClick}>{buttonText}</CardBottomButton>
    </CardContainer>
  );
};

export const AwardedShiftCard = ({
  shift,
  shift: {
    start,
    end,
    patient: { name, skillLevel, sex, age, weight, primaryDiagnosis, address },
    // status,
    hours,
    applications,
  },
  buttonText,
  onButtonClick,
}: any) => {
  let applicant;
  try {
    applicant = applications.find((application: any) => application.status === 5).applicant;
  } catch (e) {
    return <div />;
  }
  const { imageUri, givenName, familyName } = applicant.user;
  const nurse = { skillLevel: applicant.skillLevel, imageUri, givenName, familyName };
  return (
    <CardContainer style={{ maxHeight: '300px', borderLeftColor: '#FFBE00' }}>
      <CardContent>
        <ContentText style={{ fontSize: '20px', lineHeight: '24px' }}>
          <Bold>
            {name} - {moment(start).format('MMM DD')}
          </Bold>
        </ContentText>
        <StartEndHours start={start} end={end} hours={hours} />
        <ContentText>{address}</ContentText>
        <ContentText>Awarded to</ContentText>
        <NurseCardDisplay key={applicant.userId + applicant.externalId} nurse={nurse} />
      </CardContent>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <CardBottomButton onClick={onButtonClick}>{buttonText}</CardBottomButton>
      </div>
    </CardContainer>
  );
};
