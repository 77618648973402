import { IUser } from '../common/interfaces';
import { ROUTE_PARAMS, ROUTES } from './routing.constants';
import { UserRoles } from '../utils/userRoles';

let roles: UserRoles;

// Order of importance
// EVV Scheduler
// CGM Scheduler
// Admin
// QA
export const handleLandingPageRoutes = (user: IUser) => {
  roles = new UserRoles(user);
  if (roles.isEvvScheduler) {
    return ROUTES.AIDES;
  } else if (roles.isScheduler) {
    return `${ROUTES.SHIFTS}/${ROUTE_PARAMS.OPEN}`;
  } else if (roles.isAdmin) {
    return ROUTES.ADMIN;
  } else {
    return `${ROUTES.SHIFTS}/${ROUTE_PARAMS.OPEN}`; // Default, show shifts
  }
};

export const userCanNavigateToCaregiverShifts = (user: IUser, featureFlags: any) => {
  roles = new UserRoles(user);
  return (
    (roles.isEvvScheduler || roles.isQaNurse) &&
    featureFlags.caregiverShifts &&
    featureFlags.costCenterLocationsEnabled
  );
};

export const userCanNavigateToAdminPortal = (user: IUser, featureFlags: any) => {
  roles = new UserRoles(user);
  return roles.isAdmin && featureFlags.featureAdminPortal;
};

export const userCanNavigateToPatients = (user: IUser) => {
  roles = new UserRoles(user);
  return roles.isEvvScheduler || roles.isEvvNurse;
};

export const userCanNavigateToDashboard = (user: IUser) => {
  roles = new UserRoles(user);
  return roles.isAdmin;
};

export const userCanNavigateToAides = (user: IUser) => {
  roles = new UserRoles(user);
  return roles.isEvvScheduler || roles.isEvvNurse;
};

export const userCanNavigateToAdmin = (user: IUser) => {
  roles = new UserRoles(user);
  return roles.isAdmin;
};
