import React from 'react';
import Select from 'react-select';

import { interfaces } from '../../common';

interface IProps {
  value: any;
  options: interfaces.IDropdownOption[];
  onChange: any;
}

export const LocationDropdown = ({ value, options, onChange }: IProps) => {
  return (
    <div style={{ marginBottom: 12 }}>
      <Select
        options={options}
        value={value}
        onChange={onChange}
        isDisabled={false}
        id="location-select-dropdown"
      />
    </div>
  );
};
