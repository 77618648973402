import React, { useEffect, useState } from 'react';
import MaterialIcon from 'material-icons-react';
import PatientInformation from './PatientInformation';
import ClockInMatcher from './ClockInMatcher';
import ShiftInformation from './ShiftInformation';
import { getUnmatchedClockInOutsForPatient } from '../../api/clockInOut';
import { matchClockInOutToShift } from '../../api/shifts';
import { IClockInOut, IGenericShift } from '../PatientInterfaces';
import { ShiftFooterButton } from '../../Aides/ShiftDetails/ShiftFooterButtons';
import moment, { Moment } from 'moment';
import {
  ShiftPanelBody,
  ShiftPanelHeader,
  ShiftPanelHeaderLeft,
  ShiftPanelHeaderText,
} from '../../Aides/ShiftPanel/ShiftSidebar';
import StyledDateRangePicker from '../../common/StyledDateRangePicker';
import styles from '../../Aides/ShiftDetails/ShiftDetails.module.css';

interface IProps {
  shift: IGenericShift;
  onClose: (update?: boolean) => void;
}
const ReconcileContainer = (props: IProps) => {
  const { shift, onClose } = props;

  const [clockInOuts, setClockInOuts] = useState<IClockInOut[]>([]);
  const [selectedClockInOut, setSelectedClockInOut] = useState<IClockInOut | null>(null);
  const [focusedInput, setFocusedInput] = useState<any>('');
  const [searchStartDate, setSearchStartDate] = useState(
    moment.utc(shift.start).subtract(5, 'days')
  );
  const [searchEndDate, setSearchEndDate] = useState(moment.utc(shift.start));
  const [loading, setLoading] = useState(true);

  const changeDates = (startDate: Moment, endDate: Moment | null) => {
    if (startDate !== null) {
      setSearchStartDate(startDate);
    }
    if (endDate !== null) {
      setSearchEndDate(endDate);
    }
  };

  useEffect(() => {
    async function effect() {
      setClockInOuts(
        await getUnmatchedClockInOutsForPatient(shift.patient.id, searchStartDate, searchEndDate)
      );
      setLoading(false);
    }
    effect();
  }, [searchStartDate, searchEndDate]);

  const reconcile = async () => {
    if (selectedClockInOut) {
      await matchClockInOutToShift(selectedClockInOut.id, {
        id: shift.id,
        externalId: shift.externalId,
        externalSource: shift.externalSource,
        status: shift.status,
        start: shift.start,
        end: shift.end,
      });
      onClose(true);
    }
  };
  return (
    <div
      style={{
        position: 'relative',
        overflowY: 'auto',
        maxWidth: '40vw',
        minWidth: 1100,
        paddingBottom: '6px',
        fontSize: '1rem',
        background: 'white',
        color: 'black',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <ShiftPanelHeader>
        <ShiftPanelHeaderLeft
          onClick={() => {
            onClose();
          }}
        >
          <MaterialIcon icon={'close'} key={'close'} />
          <ShiftPanelHeaderText>CLOSE RECONCILE TAB</ShiftPanelHeaderText>
        </ShiftPanelHeaderLeft>
      </ShiftPanelHeader>
      <ShiftPanelBody>
        <PatientInformation patient={shift.patient} />
        <ShiftInformation shift={shift} />
        <div style={{ padding: '0.5rem 1.5rem' }}>
          <div className={styles.SP_Body}>
            <div className={styles.GeneralInformation}>
              <div className={styles.GeneralInformation_row}>
                <div className={styles.GeneralInformation_row__label}>Search Range</div>
                <div className={styles.GeneralInformation_row__content}>
                  <div style={{ zIndex: 3 }}>
                    <StyledDateRangePicker
                      futureLimit={1}
                      startDate={moment(searchStartDate)}
                      startDateId={'searchStartDate'}
                      endDate={moment(searchEndDate)}
                      endDateId={'searchEndDate'}
                      onDatesChange={(value1: any, value2: any) => changeDates(value1, value2)}
                      focusedInput={focusedInput}
                      onFocusChange={(value: any) => setFocusedInput(value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ClockInMatcher
          clockInOuts={clockInOuts}
          selectedClockInOut={selectedClockInOut}
          selectClockInOut={setSelectedClockInOut}
          loading={loading}
        />
        <div style={{ padding: '0.5rem 1.5rem' }}>
          <ShiftFooterButton onClick={reconcile} disabled={selectedClockInOut === null}>
            RECONCILE
          </ShiftFooterButton>
        </div>
      </ShiftPanelBody>
    </div>
  );
};

export default ReconcileContainer;
