import React, { ButtonHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { device } from './device/deviceSizes';
import { Loading } from '../common';

type IButtonProps = {
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  isLoading?: boolean;
  style?: any;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.SFC<IButtonProps> = (props) => {
  const { primary, secondary, tertiary, children, isLoading, style, ...rest } = props;
  return (
    <button {...rest} style={{ ...style }}>
      {isLoading ? 'Loading...' : children}
    </button>
  );
};

export const StyledButton = styled(Button)`
  width: 124px;
  padding: 6px;
  color: white;
  background-color: #0075c9;
  border-width: 0px;
  border-radius: 6px;
  cursor: pointer;

  ${(props) =>
    props.primary &&
    css`
      background-color: #0075c9;
    `}

  ${(props) =>
    props.secondary &&
    css`
      color: ${props.theme.colors.aveannaDarkBlueHex};
      border-width: 1px;
      border-color: ${props.theme.colors.aveannaDarkBlueHex};
      background-color: transparent;
    `}

  ${(props) =>
    props.tertiary &&
    css`
      color: ${props.theme.colors.aveannaDarkBlueHex};
      background-color: transparent;
    `}

  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.colors.white};
      background-color: ${props.theme.colors.aveannaGray};
    `}

  ${(props) =>
    props.isLoading &&
    css`
      color: ${props.theme.colors.white};
      background-color: ${props.theme.colors.aveannaGray};
    `}
`;

export const StyledSubmitButton = styled.input`
  flex-shrink: 0;
  width: 124px;
  padding: 18px 6px;
  color: white;
  font-size: ${({ theme }) => theme.fontSizeSm};
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.aveannaDarkBlueGray : theme.colors.aveannaDarkBlue};
  border-width: 0px;
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  @media ${device.mobileL} {
    width: 124px;
  }
`;

export const StyledTransparentButton = styled(Button)`
  flex-shrink: 0;
  width: fit-content;
  padding: 3px 3px;
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.aveannaDarkBlueGray : theme.colors.aveannaDarkBlue};
  :hover {
    color: ${({ theme }) => theme.colors.aveannaLightBlue};
  }
  background-color: transparent;
  border-width: 0px;
  border-radius: 0px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  @media ${device.mobileL} {
    width: fit-content;
  }
`;

// For typical anchors (will remount the app)
export const FlatStyledAnchor: any = styled.a`
  cursor: pointer;
  display: block;
  border-bottom-right-radius: 4px;
  background-color: white;
  color: #0075c9;
  border: none;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.3;
  text-decoration: none;
  text-align: center;
`;

// For actual router link
export const FlatStyledLink: any = styled(Link)`
  cursor: pointer;
  display: block;
  border-bottom-right-radius: 4px;
  background-color: white;
  color: #0075c9;
  border: none;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.3;
  text-decoration: none;
  text-align: center;
`;

const StyledFooterButton = ({ ...props }) => {
  const { children, buttonColor, ...rest } = props;
  return <button {...rest}>{props.children}</button>;
};

export const ApprovalButton = styled(StyledFooterButton)`
  cursor: pointer;
  border-radius: 6px;
  padding: 12px 24px;
  border: none;
  background-color: ${({ theme, buttonColor }) =>
    buttonColor ? buttonColor : theme.colors.footerButtonGreen};
  font-size: ${({ theme }) => theme.fontSizeMd};
  color: ${({ theme }) => theme.colors.white};

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background-color: grey;
    `};
`;
