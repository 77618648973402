import React from 'react';
import MaterialIcon from 'material-icons-react';
import {
  ShiftPanelBody,
  ShiftPanelHeader,
  ShiftPanelHeaderLeft,
  ShiftPanelHeaderText,
  ShiftPanelHeading,
  ShiftPanelText,
} from './ShiftSidebar';
import { StatusType } from '../../utils';

type Props = {
  dvr: any;
  onClickHeader: () => void;
};

type State = {
  createdDate: string;
  reviewerFullName: string;
  unNestedArray: any[];
};

const status = {
  VALUE_CREATED: 'Created',
  VALUE_UPDATED: 'Updated',
  VALUE_DELETED: 'Deleted',
  VALUE_UNCHANGED: 'Unchanged',
};

class ShiftHistory extends React.Component<Props, State> {
  private unNestedArray: any[] = [];
  public state: State = {
    createdDate: '',
    reviewerFullName: '',
    unNestedArray: [],
  };

  public constructor(props: Props) {
    super(props);
  }

  public componentDidMount() {
    if (this.props.dvr.clockInOut.clockInOutAuditLogs) {
      this.props.dvr.clockInOut.clockInOutAuditLogs.map((log: any) => {
        const previous = JSON.parse(log.previous);
        const current = JSON.parse(log.current);
        const createdDate = log.modifiedOn;
        const reviewerFullName = log.modifier.givenName + ' ' + log.modifier.familyName;
        this.setState({ createdDate, reviewerFullName });
        this.mapping(previous, current, createdDate, reviewerFullName);
        this.setState({ unNestedArray: this.unNestedArray });
      });
    }
  }

  private mapping(obj1: any, obj2: any, createdDate: string, reviewerFullName: string) {
    if (this.isFunction(obj1) || this.isFunction(obj2)) {
      throw Error('Invalid argument. Function given, object expected.');
    }
    if (this.isValue(obj1) || this.isValue(obj2)) {
      return {
        type: this.compareValues(obj1, obj2),
        data: obj1 === undefined ? obj2 : obj1,
      };
    }

    const diff: any = {};

    for (const key in obj1) {
      if (this.isFunction(obj1[key])) {
        continue;
      }
      let value2;
      if (obj2[key] !== undefined) {
        value2 = obj2[key];
      }
      diff[key] = this.mapping(obj1[key], value2, createdDate, reviewerFullName);
      if (diff[key].type && key !== '0') {
        this.unNestedArray.push({
          key,
          status: diff[key].type,
          createdDate,
          reviewerFullName,
          value: diff[key].data,
          value2,
        });
      } else if (this.isObject(diff[key])) {
        if (diff[key][0]) {
          this.unNestedArray.push({
            key,
            status: diff[key][0].type,
            createdDate,
            reviewerFullName,
            value: diff[key].data,
            value2,
          });
        }
      }
    }

    for (const key in obj2) {
      if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
        continue;
      }

      // Unreachable code?
      diff[key] = this.mapping(undefined, obj2[key], createdDate, reviewerFullName);

      if (diff[key].type && key !== '0') {
        this.unNestedArray.push({
          key,
          status: diff[key].type,
          createdDate,
          reviewerFullName,
          value: diff[key].data,
        });
      } else if (this.isObject(diff[key])) {
        if (diff[key][0]) {
          this.unNestedArray.push({
            key,
            status: diff[key][0].type,
            createdDate,
            reviewerFullName,
            value: diff[key].data,
          });
        }
      }
    }

    return diff;
  }

  private compareValues(value1: any, value2: any) {
    if (value1 === value2) {
      return status.VALUE_UNCHANGED;
    }
    if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
      return status.VALUE_UNCHANGED;
    }
    if (value1 === undefined) {
      return status.VALUE_CREATED;
    }
    if (value2 === undefined) {
      return status.VALUE_DELETED;
    }
    return status.VALUE_UPDATED;
  }

  private isFunction(x: any) {
    return Object.prototype.toString.call(x) === '[object Function]';
  }

  private isArray(x: any) {
    return Object.prototype.toString.call(x) === '[object Array]';
  }

  private isDate(x: any) {
    return Object.prototype.toString.call(x) === '[object Date]';
  }

  private isObject(x: any) {
    return Object.prototype.toString.call(x) === '[object Object]';
  }

  private isValue(x: any) {
    return !this.isObject(x) && !this.isArray(x);
  }

  private translateMessage(status: string, key: string, value: any, value2: any) {
    console.log('status, key, value, value2', status, key, value, value2);

    if (key === 'Status') {
      return (
        <div>
          <b>{`Updated Status:`}</b> {StatusType[value2]}
        </div>
      );
    } else if (key === 'Created') {
      return (
        <div>
          <b>{status}</b> {key}
          <div>
            <b style={{ marginRight: '1.7em' }}>New </b>
            {value && value.ReasonId}
          </div>
        </div>
      );
    } else if ((value && this.isArray(value)) || (value2 && this.isArray(value2))) {
      const val =
        value && value.length > 0
          ? value.map((x: any) => {
              return <span key={x.ReasonId}>{x.ReasonId}</span>;
            })
          : null;
      const val2 =
        value2 && value2.length > 0
          ? value2.map((x: any) => {
              return <span key={x.ReasonId}>{x.ReasonId}</span>;
            })
          : null;
      console.log('snarf', val, val2);
      return (
        <div>
          <b>{status}</b> {key}
          <div>
            <b style={{ marginRight: '1.7em' }}>from</b>
            {val}
          </div>
          <div>
            <b style={{ marginRight: '1.7em' }}>to</b>
            {val2}
          </div>
        </div>
      );
    } else if (
      (value !== null && typeof value === typeof {}) ||
      (value2 !== null && typeof value2 === typeof {})
    ) {
      return (
        <div>
          <b>{status}</b> {key}
          <div>
            <b style={{ marginRight: '1.7em' }}>{'From'}</b>
            {value && value.ReasonId}
          </div>
          <div>
            <b style={{ marginRight: '2.7em' }}>{'To '}</b>
            {value2 && value2.ReasonId}
          </div>
        </div>
      );
    }

    return (
      <div>
        <b>{status}</b> {key}
        <div>
          <b style={{ marginRight: '1.7em' }}>{'From'}</b>
          {value}
        </div>
        <div>
          <b style={{ marginRight: '2.7em' }}>{'To '}</b>
          {value2}
        </div>
      </div>
    );
  }

  public render() {
    const { onClickHeader } = this.props;
    return (
      <>
        <ShiftPanelHeader>
          <ShiftPanelHeaderLeft onClick={onClickHeader}>
            <MaterialIcon icon={'chevron_left'} key={'chevron_left'} />
            <ShiftPanelHeaderText>BACK TO SHIFT DETAILS</ShiftPanelHeaderText>
          </ShiftPanelHeaderLeft>
        </ShiftPanelHeader>
        <ShiftPanelBody>
          <ShiftPanelHeading>Edit History</ShiftPanelHeading>
          {this.state.unNestedArray.length > 0 &&
            this.state.unNestedArray.map((each: any, index: number) => {
              if (each.status && each.status !== status.VALUE_UNCHANGED) {
                if (each.key === 'Modifier' || each.key === 'ModifiedOn') {
                  return;
                }
                return (
                  <div
                    key={index}
                    style={{ flex: 1, padding: '1em', borderTop: '1px solid #505980' }}
                  >
                    <div>
                      <ShiftPanelText style={{ lineHeight: 2, paddingBottom: '1em' }}>
                        <div style={{ fontWeight: 'bold' }}>{each.reviewerFullName}</div>
                      </ShiftPanelText>
                    </div>
                    <div style={{ paddingRight: 40, paddingBottom: '1em' }}>
                      <b style={{ fontWeight: 'bold', paddingRight: '2em' }}>Time</b>
                      <div style={{ lineHeight: 2, display: 'inline-block' }}>
                        {new Date(each.createdDate).toString().split('GMT')[0]}
                        {/* {new Date(each.createdDate).toString()} */}
                      </div>
                    </div>
                    <div>
                      <ShiftPanelText style={{ lineHeight: 2 }}>
                        <div>
                          {this.translateMessage(each.status, each.key, each.value, each.value2)}
                        </div>
                      </ShiftPanelText>
                    </div>
                  </div>
                );
              }
            })}
          {this.state.unNestedArray.length === 0 && <div>There is no edit history to show.</div>}
        </ShiftPanelBody>
      </>
    );
  }
}

export default ShiftHistory;
