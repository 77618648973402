import { api, parseApiResponse } from './api';
import { get, put } from './apiCallHelper';

export const getAllPatients = async () => {
  const response = await get(`/api/Patient`);
  return parseApiResponse(response);
};

export const getAllPatientsForLocation = async () => {
  const response = await get(`/api/Patient/Location`);
  return parseApiResponse(response);
};

export const updatePatientOfficeId = async (patientId: number, newOfficeLocationId: number) => {
  // tslint:disable-next-line:max-line-length
  const response = await put(
    `/api/Patient/UpdateOfficeLocation/?patientId=${patientId}&newOfficeLocationId=${newOfficeLocationId}`
  );
  return parseApiResponse(response);
};

export const updatePatientDischargedStatus = async (
  patientId: number,
  newDischargedStatus: boolean
) => {
  // tslint:disable-next-line:max-line-length
  const response = await put(
    `/api/Patient/UpdateDischargedStatus/?patientId=${patientId}&newDischargedStatus=${newDischargedStatus}`
  );
  return parseApiResponse(response);
};

export const getPatients = async () => {
  const response = await get(`/api/Patient/AllPatients`);
  return parseApiResponse(response);
};

export const getAllPatientsForLocationWithUnprocessedShifts = async () => {
  const response = await get(`/api/Patient/Location/Unprocessed`);
  return parseApiResponse(response);
};

export const getPatient = async (patientId: number | string) => {
  const response = await get(`/api/Patient/${patientId}`);
  return parseApiResponse(response);
};

export const getPatientForms = async (
  patientId: number | string,
  includeAllNonActiveForms: boolean = true
) => {
  const response = await get(
    `/api/Form/Templates/?patientId=${patientId}&includeAll=${includeAllNonActiveForms}`
  );
  return parseApiResponse(response);
};
