export const skillLevelColors: { [index: number]: string } = {
  0: '#4EB1FF',
  1: '#3497FD',
  2: '#5773FF',
  3: '#665EFF',
  4: '#8754F8',
  5: '#A74AF0',
  6: '#C840E9',
};

export const skillLevelOptions = [
  { value: 'all', label: 'Select All' },
  { value: 0, label: 'Level 0' },
  { value: 1, label: 'Level 1' },
  { value: 2, label: 'Level 2' },
  { value: 3, label: 'Level 3' },
  { value: 4, label: 'Level 4' },
  { value: 5, label: 'Level 5' },
  { value: 6, label: 'Level 6' },
];

export const DistanceOptions: { [index: number]: { value: number; distance: number } } = {
  1: {
    value: 1,
    distance: 1,
  },
  2: {
    value: 2,
    distance: 5,
  },
  3: {
    value: 3,
    distance: 10,
  },
  4: {
    value: 4,
    distance: 30,
  },
  5: {
    value: 5,
    distance: 50,
  },
};
