import React, { Component } from 'react';
import { FeatureFlags } from '../../utils/app';

import Close from '../../assets/close.png';
import LeftArrow from '../../assets/left-arrow.png';

import {
  getAwardedNursesByShift,
  cancelConfirmedShift,
  cancellationReasons,
} from '../../api/shifts';
import { IShift } from '../interfaces';

import { ModalWrapper, Loading, interfaces } from '../../common';
import { NurseListItem } from '../../Nurses/';

import {
  ContentWrapper,
  TitleWrapper,
  Title,
  ListItemWrapper,
  MobileCloseModalButton,
  CloseModalButton,
  ShiftInfoHeader,
  FooterWrapper,
  ToastWrapper,
  FooterButton,
  SubHeader,
  SubHeaderText,
} from '../components/ModalBody';

enum ToastText {
  reassign = 'Canceled, please reassign shift',
  failed = 'Something Went Wrong',
}

interface IState {
  selectedNurseId: number | null;
  nurses: interfaces.INurse[];
  showToast: boolean;
  toastText: ToastText;
  cancellationReasonCode: any;
  isSubmitting: boolean;
}

interface IProps {
  shift: IShift;
  closeModal: () => void;
}

export class ScheduledNurseListModal extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedNurseId: null,
      nurses: [],
      showToast: false,
      toastText: ToastText.reassign,
      cancellationReasonCode: 0,
      isSubmitting: false,
    };
  }

  public componentWillMount = async () => {
    const res = await getAwardedNursesByShift(this.props.shift.key);

    if (res.status === 200) {
      this.setState({ nurses: res.body });
    }
  };

  public renderAppliedNurseList = () => {
    const { nurses, selectedNurseId } = this.state;
    if (nurses.length > 0) {
      return nurses.map((nurse: interfaces.INurse) => {
        const checked = selectedNurseId === nurse.id ? true : false;
        return (
          <NurseListItem
            key={nurse.userId + nurse.externalId}
            nurse={nurse}
            checked={checked}
            handleClick={() => {
              return;
            }}
          />
        );
      });
    } else {
      return <Loading />;
    }
  };

  public renderAdjustAwardedShift = () => {
    const { showToast, cancellationReasonCode, isSubmitting } = this.state;
    if (!showToast && cancellationReasonCode) {
      return (
        <FooterWrapper>
          <FooterButton
            style={{ backgroundColor: 'white', color: '#0075c9' }}
            onClick={() => this.props.closeModal()}
          >
            No
          </FooterButton>
          <FooterButton onClick={() => this.handleCancelShift()} disabled={isSubmitting}>
            Yes, Cancel Shift
          </FooterButton>
        </FooterWrapper>
      );
    }
  };

  public renderToast = () => {
    const { showToast, toastText } = this.state;
    if (showToast) {
      return <ToastWrapper failed={toastText === ToastText.failed}>{toastText}</ToastWrapper>;
    }
  };

  public handleCancelShift = async () => {
    this.setState({ showToast: true, isSubmitting: true });
    try {
      const res = await cancelConfirmedShift(
        this.props.shift.key,
        this.state.nurses[0].id,
        this.state.cancellationReasonCode
      );

      if (res.status === 200) {
        this.setState({ showToast: true, toastText: ToastText.reassign, isSubmitting: false });
        setTimeout(() => this.props.closeModal(), 1500);
      } else {
        throw new Error(
          `Something went wrong with cancelling the shift, status code: ${res.status}`
        );
      }
    } catch (error) {
      this.setState({ showToast: true, toastText: ToastText.failed, isSubmitting: false });
      setTimeout(() => this.props.closeModal(), 1500);
    }
  };

  public render() {
    const { FEATURE_CANCEL_CONFIRMED_SHIFT } = FeatureFlags;
    const { closeModal, shift } = this.props;
    const { cancellationReasonCode } = this.state;
    return (
      <ModalWrapper closeModal={closeModal}>
        <ContentWrapper>
          <TitleWrapper>
            <MobileCloseModalButton onClick={() => closeModal()}>
              <img src={LeftArrow} />
            </MobileCloseModalButton>
            <Title>Confirmed Shift</Title>
            <CloseModalButton onClick={() => closeModal()}>
              <img src={Close} />
            </CloseModalButton>
          </TitleWrapper>
          <ShiftInfoHeader {...shift} />
          <ListItemWrapper>
            <SubHeader>
              <SubHeaderText>Caregiver</SubHeaderText>
            </SubHeader>
            {this.renderAppliedNurseList()}
          </ListItemWrapper>
          {FEATURE_CANCEL_CONFIRMED_SHIFT === 'true' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <SubHeaderText>Reason For Canceling Shift</SubHeaderText>
              <select
                value={cancellationReasonCode}
                style={{ height: '25px', maxWidth: '80%', border: '1px solid lightgray' }}
                onChange={({ target: { value } }) => {
                  this.setState({ cancellationReasonCode: value });
                }}
              >
                <option value={0} disabled>
                  Select a Reason
                </option>
                {Object.entries(cancellationReasons).map(([key, val]: any) => (
                  <option key={val} value={val}>
                    {`${key.split(/(?=[A-Z])/).join(' ')}`}
                  </option>
                ))}
              </select>
            </div>
          )}
        </ContentWrapper>
        {Boolean(cancellationReasonCode) && (
          <SubHeaderText
            style={{ marginTop: 'auto', alignSelf: 'center', borderBottom: '1px solid black' }}
          >
            Are you sure you want to cancel this shift?
          </SubHeaderText>
        )}
        {this.renderAdjustAwardedShift()}
        {this.renderToast()}
      </ModalWrapper>
    );
  }
}
