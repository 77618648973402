import { GridRenderCellParams } from '@mui/x-data-grid';

import React, { ReactNode } from 'react';
import { getDefaultToastConfig, StatusType, ToastAppearance } from '../../../../../utils';
import { useToasts } from 'react-toast-notifications';
import { CaregiverShiftUpdate, ICaregiverShiftsData } from '../../../../../common/interfaces';
import { sendAnalyticsEvent } from '../../../../../analytics';
import { updateCaregiverShift } from '../../../../../api/careGiverShifts';
import ShiftEditModal from '../../../ShiftEditModal/ShiftEditModal';

type Props = ICaregiverShiftsData & { onShiftUpdated: (shiftData: ICaregiverShiftsData) => void };

const ActionsColumnRenderer = ({ value: shiftData }: GridRenderCellParams<Props>): ReactNode => {
  const { addToast } = useToasts();

  if (!shiftData) {
    return null;
  }

  const approveShift = async (): Promise<void> => {
    try {
      sendAnalyticsEvent('CaregiverShifts', 'Shift approved');
      const update: CaregiverShiftUpdate = {
        clockInOutId: shiftData.id,
        status: StatusType.APPROVED,
      };

      const response = await updateCaregiverShift(update);
      shiftData.onShiftUpdated(response);

      addToast(
        `You've successfully approved the shift`,
        getDefaultToastConfig(ToastAppearance.SUCCESS)
      );
    } catch (error) {
      console.log('Approve shift error ', (error as any).message);
      addToast('Error approving shift', getDefaultToastConfig(ToastAppearance.WARNING));
    }
  };

  const qaReviewShift = async (): Promise<void> => {
    try {
      sendAnalyticsEvent('CaregiverShifts', "Shift QA'd");
      const update: CaregiverShiftUpdate = {
        clockInOutId: shiftData.id,
        isQaComplete: shiftData.isQa,
        isQa: true,
      };

      const response = await updateCaregiverShift(update);
      shiftData.onShiftUpdated(response);

      addToast(`Shift moved to QA!`, getDefaultToastConfig(ToastAppearance.INFO));
    } catch (error) {
      console.log('QA shift error ', (error as any).message);
      addToast('Error placing shift under QA review', getDefaultToastConfig(ToastAppearance.ERROR));
      throw new Error((error as any).message);
    }
  };

  const isApproveButtonDisabled = (value: ICaregiverShiftsData) => {
    const gpsCoordinatesOrCorrectionIdExists =
      (value.clockInDistance != null ||
        (value.correctionIds && value.correctionIds.clockInLocationCorrectionId != null)) &&
      (value.clockOutDistance != null ||
        (value.correctionIds && value.correctionIds.clockOutLocationCorrectionId != null));
    const timeCoordinatesOrCorrectionIdExists =
      (value.adjustedClockInTimeStamp != null ||
        (value.correctionIds && value.correctionIds.clockInTimeCorrectionId != null)) &&
      (value.adjustedClockOutTimeStamp != null ||
        (value.correctionIds && value.correctionIds.clockOutTimeCorrectionId != null));
    return (
      !(gpsCoordinatesOrCorrectionIdExists && timeCoordinatesOrCorrectionIdExists) ||
      value.status === StatusType.APPROVED
    );
  };

  return (
    <span>
      <button
        className="actionButton approveShiftClass"
        onClick={approveShift}
        id="actionApproveShift"
        disabled={isApproveButtonDisabled(shiftData)}
      >
        Approve
      </button>

      <ShiftEditModal onShiftUpdated={shiftData.onShiftUpdated} shiftData={shiftData} />

      <button className="actionButton" onClick={qaReviewShift} id="actionQAReview">
        {shiftData.isQa ? 'QA Complete' : 'QA Review'}
      </button>
    </span>
  );
};

export default ActionsColumnRenderer;
