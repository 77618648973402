import React from 'react';
import styled from 'styled-components';
import { generateVersionStrings } from '../../utils';

export const Version = ({}) => {
  return <StyledVersion>{generateVersionStrings().display}</StyledVersion>;
};

const StyledVersion = styled.div`
  position: absolute;
  z-index: 100;
  right: 24px;
  bottom: 6px;

  font-size: ${({ theme }) => theme.fontSizeXs};
  font-family: monospace;
  color: ${({ theme }) => theme.colors.aveannaFadedBlue};
`;
