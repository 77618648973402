import React from 'react';

import styles from './ShiftEditReview.module.css';

interface IProps {
  title: string;
  previousData: any;
  currentData: any;
}

export const EditedGenericItem = ({ title, previousData, currentData }: IProps) => {
  return JSON.stringify(currentData) !== JSON.stringify(previousData) ? (
    <div className={styles.editGroup}>
      <div className={styles.editGroup}>
        <div className={styles.editTitleContainer}>
          <p className={styles.editTitle}>{title}</p>
        </div>
        <div style={{ flex: 1.5, flexDirection: 'column' }}>
          <p className={styles.editData}>N/A</p>
        </div>
      </div>
      <div className={styles.editGroup}>
        <div className={styles.editTitleContainer}>
          <p className={styles.editTitle}>{title}</p>
        </div>
        <div style={{ flex: 1.5, flexDirection: 'column' }}>
          <p className={styles.editData}>{`${title} was edited`}</p>
        </div>
      </div>
    </div>
  ) : null;
};
