import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { interfaces } from '../../common';
import { LocationActivator } from '../components';
import { setLocationActive } from '../../api';
import { updateLocation } from '../actions';
import useToastNotifications from '../../common/useToastNotifications';

interface ILocationByActive {
  inactiveLocations: interfaces.ILocation[];
  activeLocations: interfaces.ILocation[];
}

const activationInfo = () => (
  <ol style={{ paddingRight: 12, paddingLeft: 16 }}>
    <li>
      A location gets activated immediately, but it may take between 4 - 8 hours for a location to
      get data (users etc.) allocated from another system (EMR).
    </li>
    <li>
      <b>NOTE:</b> Messaging channels may not be setup immediately for AveannaConnect and Scheduler
      Portal. Messaging channels may need to be requested to be created manually by integrations
      (Colin Ruston).
    </li>
  </ol>
);

const deactivationInfo = () => (
  <ol style={{ paddingRight: 12, paddingLeft: 16 }}>
    <li>
      Deactivating a location <i>may not</i> prevent the very next import of data from the other EMR
      system, but it will prevent subsequent updates.
    </li>
  </ol>
);

// COMPONENT
export const LocationManager = () => {
  const locations = useSelector(({ admin }: any) => admin.locations);
  const dispatch = useDispatch();
  const { displaySuccess, displayError } = useToastNotifications();

  const { activeLocations, inactiveLocations }: ILocationByActive = locations.reduce(
    (accumulator: ILocationByActive, currentLocation: interfaces.ILocation) => {
      if (currentLocation) {
        currentLocation.isActive
          ? accumulator.activeLocations.push(currentLocation)
          : accumulator.inactiveLocations.push(currentLocation);

        return accumulator;
      } else {
        return accumulator;
      }
    },
    { activeLocations: [], inactiveLocations: [] }
  );

  const onSetLocationActive = async (id: interfaces.ID, isActive: boolean) => {
    try {
      const tempActivationResponse = await setLocationActive(id, isActive);
      dispatch(updateLocation(tempActivationResponse));
      displaySuccess(`Location successfully ${isActive ? 'ACTIVATED!' : 'DEACTIVATED!'}`);
    } catch (error) {
      displayError(error);
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <LocationActivator
        title="Location Activator"
        info={activationInfo}
        locations={inactiveLocations}
        actionButtonTitle="Activate"
        onActivate={(id: interfaces.ID) => onSetLocationActive(id, true)}
        data-cy={'location-activator'}
      />
      <LocationActivator
        title="Location Deactivator"
        info={deactivationInfo}
        locations={activeLocations}
        actionButtonTitle="Deactivate"
        onActivate={(id: interfaces.ID) => onSetLocationActive(id, false)}
        data-cy={'location-deactivator'}
      />
    </div>
  );
};
