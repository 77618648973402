import { interfaces } from '../common';
import { retrieveImage } from './apiCallHelper';

export const getImage = async (id: interfaces.ID) => {
  const tempImageResponse = await retrieveImage(`api/Form/Download/${id}`);

  const tempBlob = await tempImageResponse.blob();
  return tempBlob;
};

// 🚧 WIP
// We use fetch here to bypass the baseURL (since we're getting the whole thing)
export const getImageByURL = async (URL: string) => {
  console.log(`getting image by URL  — ${URL}`);
  try {
    // const tempImageResponse = await fetch(URL, {
    //   headers: {
    //     ...api.headers,
    //     'Content-Type': 'image/png',
    //     Accept: '*/*',
    //   },
    // });

    const tempImageResponse = await retrieveImage(URL);

    const tempBlob = await tempImageResponse.blob();
    return tempBlob;
  } catch (error) {
    console.log('error — ', error);
  }
};
