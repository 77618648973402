import React from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../common';

import AccountCircle from '../../assets/account-circle.png';

// SINGLE MESSAGE CONTAINER
type StyledMessageProps = {
  isMe: boolean;
  isGrouped?: boolean;
  children?: React.ReactNode;
};

const StyledMessageDiv: React.SFC<StyledMessageProps> = (props) => {
  const { isMe, isGrouped, ...rest } = props;
  return <div {...rest} />;
};

export const StyledMessageContainer = styled(StyledMessageDiv)`
  display: flex;
  flex-shrink: 0;
  max-width: 85%;
  margin-top: 8px;
  word-break: break-word;
  align-self: ${({ isMe }) => (isMe ? 'flex-end' : 'flex-start')};

  ${(props) =>
    props.isGrouped &&
    css`
      margin-top: 2px;
    `};

  @media ${device.laptop} {
    align-self: flex-start;
    max-width: 100%;
  }
`;

export const StyledMessage = styled(StyledMessageDiv)`
  padding: 8px;
  border-radius: 16px;
  border-top-left-radius: ${({ isMe }) => (isMe ? '16px' : '0px')};
  background-color: ${({ isMe, theme }) =>
    isMe ? theme.colors.aveannaDarkBlue : theme.colors.chatGray};
  color: ${({ isMe, theme }) => (isMe ? 'white' : '#333')};

  ${(props) =>
    props.isGrouped &&
    !props.isMe &&
    css`
      border-radius: 16px;
      margin-left: 54px;
    `};

  ${(props) =>
    props.isGrouped &&
    css`
      border-radius: 16px;
      margin-left: 54px;
    `};

  @media ${device.laptop} {
    background-color: transparent;
    padding: 4px;
    color: #333;
  }
`;

// MESSAGE TITLE
interface IMessageTitleProps {
  isMe?: boolean | null;
  children?: React.ReactNode;
}
const MessageTitle: React.SFC<IMessageTitleProps> = (props) => {
  const { isMe, ...rest } = props;
  return <div {...rest} />;
};

export const StyledMessageTitle = styled(MessageTitle)`
  font-size: ${({ theme }) => theme.fontSizeSm};

  ${(props) =>
    props.isMe &&
    css`
      display: none;
    `};

  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fontSizeSm};
    font-weight: 700;
    display: flex;
  }
`;

// MESSAGE TIMESTAMP
export const StyledMessageTimestamp = styled(MessageTitle)`
  font-size: ${({ theme }) => theme.fontSizeXs};
  margin-left: 8px;
  margin-right: 8px;
`;

// MESSAGE HEADER CONTENT
export const StyledMessageHeader = styled(MessageTitle)`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: baseline;

  @media ${device.laptop} {
    justify-content: flex-start;
  }

  ${(props) =>
    props.isMe &&
    css`
      justify-content: flex-end;
    `};
`;

// PROFILE IMAGE
interface IProfileImageProps {
  isMe?: boolean | null;
  src: string;
}
const ProfileImage: React.SFC<IProfileImageProps> = (props) => {
  const { src, isMe, ...rest } = props;
  return (
    <img
      src={src}
      {...rest}
      onError={(e: any) => {
        e.target.onerror = null;
        e.target.src = AccountCircle;
      }}
    />
  );
};

export const StyledProfileImage = styled(ProfileImage)`
  width: 48px;
  height: 48px;
  margin-right: 8px;
  border-radius: 50%;

  ${(props) =>
    props.isMe &&
    css`
      display: none;
    `};

  @media ${device.laptop} {
    display: flex;
  }
`;

// MESSAGE INPUT
export const StyledMessageInputContainer = styled.form`
  display: flex;
  padding: 6px;
  background-color: white;
`;

export const StyledMessageInputBox = styled.input`
  width: 100%;
  padding: 10px;
  outline: none;
`;

export const StyledSendButton = styled.button`
  display: flex;
  border-radius: 6px;
  margin-left: 4px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.aveannaDarkGrey : theme.colors.aveannaDarkBlue};
  cursor: pointer;
  border: 0px;
  transition: 0.1s;

  :hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.aveannaDarkGray : theme.colors.aveannaLightBlue};
  }
`;
