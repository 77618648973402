import { IPatientStatus } from '../common/interfaces';

export enum localStorageKeys {
  USER = 'AS_User',
  TOKEN = 'AS_Token',
  IS_REMEMBERED = 'AS_IsRemembered',
  NOTIFICATIONS = 'AS_Notifications',
  ONLY_EVV_SHIFTS = 'AS_OnlyEvvShifts',
  ACCESS = 'AS_ACCESS',
  REFRESH = 'AS_REFRESH',
  PKCE = 'AS_PKCE',
  AUTH_FLAG = 'AS_AuthFlag',
  COOKIE_FLAG = 'AS_CookieFlag',
  CLIENT_ID = 'AS_ClientId',
  ONELOGIN_ID = 'AS_OneloginId',
  ONELOGIN_URL = 'AS_OneLoginUrl',
  SPLIT_LOGIN_FLAG = 'AS_SplitLoginFlag',
}

export enum Environment {
  PROD = 'prod',
  QA = 'stage',
  RC = 'rc',
}

export enum userTypes {
  NURSE = 1,
  NURSE_ADMIN = 2,
  // ALL_NURSE_TYPES = 3,
  ADMIN = 4,
  AVEANNA_STAFF = 8,
  SCHEDULER = 16,
  EVV_NURSE = 32,
  // EVV_CGM_NURSE = 33,
  EVV_SCHEDULER = 64,
  ADMIN_EVV_SCHEDULER = 68,
  // EVV_CGM_SCHEDULER = 80,
  EVV_SCHEDULER_EVV_NURSE = 96,
  SUPER_USER = 127,
  QA_NURSE = 128,
}

export const userTypeByID: any = {
  [userTypes.NURSE]: 'Nurse',
  [userTypes.NURSE_ADMIN]: 'Nurse Admin',
  // [userTypes.ALL_NURSE_TYPES]: 'Super Nurse',
  [userTypes.ADMIN]: 'Admin',
  [userTypes.AVEANNA_STAFF]: 'Aveanna Staff',
  [userTypes.SCHEDULER]: 'Scheduler',
  [userTypes.EVV_NURSE]: 'EVV Nurse',
  [userTypes.EVV_SCHEDULER]: 'EVV Scheduler',
  [userTypes.ADMIN_EVV_SCHEDULER]: 'Admin & EVV Scheduler',
  [userTypes.EVV_SCHEDULER_EVV_NURSE]: 'EVV Scheduler & EVV Nurse',
  [userTypes.SUPER_USER]: 'Super User',
  [userTypes.QA_NURSE]: 'QA Nurse',
};

export enum PasswordStatus {
  MUST_CHANGE_PASSWORD,
  EXPIRATION_WARNING,
  EXPIRATION_CRITICAL_WARNING,
  OKAY,
}

export enum ToastAppearance {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export enum StatusType {
  PENDING = 0,
  APPROVED = 1,
  REJECTED = 2,
  QA_SHIFTS = 4,
  ALL_SHIFTS = 5,
}

export enum StatusTypeStrings {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REVIEW = 'Review',
  QA_SHIFT = 'QA',
  REJECTED = 'Rejected',
}

export enum ReasonCodeTypes {
  LOCATION_IN = 'EVV Location In Correction',
  LOCATION_OUT = 'EVV Location Out Correction',
  SHIFT_NEW = 'EVV New',
  SHIFT_REJECT = 'EVV Reject',
  SHIFT_REJECT_OVERRIDE = 'EVV Reject Override',
  TIME_IN = 'EVV Time In Correction',
  TIME_OUT = 'EVV Time Out Correction',
}
export const EVV_REJECT = 136;

export enum ShiftReviewActions {
  APPROVE = 'Approve',
  REVIEW = 'Under Review',
  SUBMIT_REVIEW = 'Submit Review',
  REJECT = 'Reject',
  REVIEW_REJECT = 'Review/Edit',
  QA_REVIEW = 'QA Review',
}

export enum TableCategories {
  PATIENTS,
  USERS,
  LOCATIONS,
}

export const PatientIsDischarged: IPatientStatus[] = [
  { value: false, text: 'Active' },
  { value: true, text: 'Discharged' },
];

export const API_RETRY_TIMEOUT = 3000;

export const OPT_OUT_VERBIAGE =
  'I expressly affirm that the mark or symbol below constitutes my electronic signature and shall be of the same legal effect, validity and enforceability as a manually executed signature to the fullest extent permitted by applicable law.\n';
