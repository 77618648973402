import React, { Component, Fragment } from 'react';
import ReactGA from 'react-ga';

import Close from '../../assets/close.png';
import LeftArrow from '../../assets/left-arrow.png';

import { ModalWrapper, Loading, interfaces } from '../../common';
import { NurseListItem, NurseListItemControlType } from '../../Nurses';

import {
  getRequestedNursesByShift,
  removeRequestNurseForShift,
  remindNursesForShift,
  getOrientRequests,
} from '../../api/shifts';

import {
  ContentWrapper,
  TitleWrapper,
  Title,
  ListItemWrapper,
  MobileCloseModalButton,
  CloseModalButton,
  ShiftInfoHeader,
  FooterWrapper,
  ToastWrapper,
  FooterButton,
  SubHeader,
  SubHeaderText,
  SelectAllButton,
  NoNursesFound,
} from '../components/ModalBody';

enum ToastText {
  remove = 'Shift request(s) removed',
  remind = 'Reminder(s) sent',
  failed = 'Something Went Wrong',
}

interface IState {
  selectedNursesIds: number[];
  orientationRequestedNurses: interfaces.INurse[];
  nurses: interfaces.INurse[];
  fetchingOriented: boolean;
  fetchingOther: boolean;
  toastText: string;
  isLoading: boolean;
  showToast: boolean;
}

export class SentNurseListModal extends Component<any, IState> {
  constructor(props: any) {
    super(props);

    const { selectedNursesIds = [] } = this.props;

    this.state = {
      selectedNursesIds,
      orientationRequestedNurses: [],
      nurses: [],
      fetchingOriented: true,
      fetchingOther: true,
      toastText: '',
      showToast: false,
      isLoading: true,
    };

    // https://analytics.google.com/analytics/web/#home/
    // This just needs to be called once since we have no routes in this case.
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  public componentWillMount = async () => {
    try {
      const [requestedNurses, orientedNurses] = await this.getShiftData();
      this.setState({
        isLoading: false,
        nurses: requestedNurses,
        orientationRequestedNurses: orientedNurses,
      });
    } catch (error) {
      this.setState({ isLoading: false, showToast: true, toastText: ToastText.failed });
    }
  };

  private getShiftData = async (): Promise<any> => {
    const { shift } = this.props;
    try {
      const [nursesByShiftResponse, orientationResponse] = await Promise.all([
        getRequestedNursesByShift(shift.key),
        getOrientRequests(shift.patient.officeLocationId),
      ]);

      const tempOrientationRequestedNurses: any[] = await this.getOrientedNurses(
        orientationResponse,
        shift
      );

      return [nursesByShiftResponse, tempOrientationRequestedNurses];
    } catch (error) {
      console.log('error — ', error);
    }
  };

  private getOrientedNurses = async (
    orientationRequestedNurses: interfaces.IOrientationRequest[],
    shift: any
  ) => {
    const tempOrientationRequestedNurses = orientationRequestedNurses.filter(
      (tempOrientationRequest: interfaces.IOrientationRequest) =>
        tempOrientationRequest.shiftKey === shift.key
    );

    return tempOrientationRequestedNurses;
  };

  public addSelectedNurse = (id: number) => {
    this.setState((prevState: any) => {
      prevState.selectedNursesIds.push(id);
      return prevState;
    });
  };

  public removeSelectedNurse = (id: number) => {
    this.setState((prevState: any) => {
      return {
        ...prevState,
        selectedNursesIds: prevState.selectedNursesIds.filter((val: number) => val !== id),
      };
    });
  };

  public toggleSelection = (id: number) => {
    if (this.state.selectedNursesIds.includes(id)) {
      this.removeSelectedNurse(id);
      return;
    }
    this.addSelectedNurse(id);
  };

  public selectAll = () => {
    const orientedNurses = this.state.nurses;

    const allIds = orientedNurses.map((nurse: any) => nurse.id);
    this.setState({ selectedNursesIds: allIds });
  };

  public renderRequestedNurseList = () => {
    const { nurses, selectedNursesIds } = this.state;

    if (nurses.length > 0) {
      return nurses.map((nurse: interfaces.INurse) => {
        const checked = selectedNursesIds.includes(nurse.id) ? true : false;
        return (
          <NurseListItem
            key={nurse.userId + nurse.externalId}
            nurse={nurse}
            controlType={NurseListItemControlType.CHECKBOX}
            checked={checked}
            handleClick={() => this.toggleSelection(nurse.id)}
          />
        );
      });
    } else {
      return <NoNursesFound />;
    }
  };

  private renderOrientationRequests = () => {
    const { orientationRequestedNurses } = this.state;

    if (orientationRequestedNurses && orientationRequestedNurses.length > 0) {
      return orientationRequestedNurses.map((tempOrientationRequest: any) => {
        return (
          <NurseListItem
            key={tempOrientationRequest.id}
            nurse={tempOrientationRequest.nurseProfile}
            controlType={NurseListItemControlType.ORIENTATION_REQUESTED}
            checked={false}
            handleClick={null}
          />
        );
      });
    } else {
      return <NoNursesFound />;
    }
  };

  public renderShiftOptions = () => {
    if (this.state.selectedNursesIds.length > 0 && !this.state.showToast) {
      return (
        <FooterWrapper>
          <FooterButton
            style={{ backgroundColor: 'white', color: '#0075c9' }}
            onClick={() => this.handleRemove()}
          >
            Remove
          </FooterButton>
          <FooterButton onClick={() => this.handleRemind()}>Remind</FooterButton>
        </FooterWrapper>
      );
    }
  };

  public handleRemove = async () => {
    const { closeModal } = this.props;

    const requests = this.state.selectedNursesIds.map(async (nurseId: any) => {
      return await removeRequestNurseForShift(this.props.shift.key, nurseId);
    });

    const responses = await Promise.all(requests);

    if (responses.every((res: any) => res.status === 200)) {
      this.setState({ showToast: true, toastText: ToastText.remove });
      setTimeout(() => closeModal(), 1500);
    } else {
      this.setState({ showToast: true, toastText: ToastText.failed });
      setTimeout(() => closeModal(), 1500);
    }
  };

  public handleRemind = async () => {
    ReactGA.event({
      category: 'Sent Shift',
      action: 'handleRemind on SentNurseListModal.tsx',
      label: 'Sent Shift Remind',
      nonInteraction: false,
    });

    const requests = this.state.selectedNursesIds.map(async () => {
      return await remindNursesForShift(this.props.shift.key);
    });

    const responses = await Promise.all(requests);

    if (responses.every((res: any) => res.status === 204)) {
      this.setState({ showToast: true, toastText: ToastText.remind });
      setTimeout(() => this.props.closeModal(), 1500);
    } else {
      this.setState({ showToast: true, toastText: ToastText.failed });
      setTimeout(() => this.props.closeModal(), 1500);
    }
  };

  public renderToast = () => {
    if (this.state.showToast) {
      return (
        <ToastWrapper failed={this.state.toastText === ToastText.failed}>
          {this.state.toastText}
        </ToastWrapper>
      );
    }
  };

  public render() {
    const { isLoading } = this.state;
    return (
      <ModalWrapper closeModal={this.props.closeModal}>
        <ContentWrapper>
          <TitleWrapper>
            <MobileCloseModalButton onClick={() => this.props.closeModal()}>
              <img src={LeftArrow} />
            </MobileCloseModalButton>
            <Title>Selected Caregivers</Title>
            <CloseModalButton onClick={() => this.props.closeModal()}>
              <img src={Close} />
            </CloseModalButton>
          </TitleWrapper>
          <ShiftInfoHeader {...this.props.shift} />
          <ListItemWrapper>
            {isLoading ? (
              <Loading />
            ) : (
              <Fragment>
                <SubHeader>
                  <SubHeaderText>Caregivers</SubHeaderText>
                  {this.state.nurses.length > 0 && (
                    <SelectAllButton onClick={() => this.selectAll()}>
                      {this.state.nurses.length > 1 ? 'Select All' : 'Select'}
                    </SelectAllButton>
                  )}
                </SubHeader>
                {this.renderRequestedNurseList()}
                <SubHeader>
                  <SubHeaderText>Pending Orientation Requests</SubHeaderText>
                </SubHeader>
                {this.renderOrientationRequests()}
              </Fragment>
            )}
          </ListItemWrapper>
        </ContentWrapper>
        {this.renderShiftOptions()}
        {this.renderToast()}
      </ModalWrapper>
    );
  }
}
