import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import React, { ReactNode } from 'react';
import { ICaregiverShiftsData } from '../../../../../common/interfaces';
import { HtmlTooltip } from '../../HtmlToolTip/HtmlToolTip';
import { blue, green, red } from '@material-ui/core/colors';

const renderLocationAlert = (alertType: string, locationAlert: string | null) => {
  if (!locationAlert) {
    return (
      <HtmlTooltip
        title="Within distance allowed"
        children={
          <i className="material-icons" style={{ color: green.A400 }}>
            check
          </i>
        }
      />
    );
  }

  return (
    <HtmlTooltip
      title={alertType + locationAlert}
      children={
        <i className="material-icons" style={{ color: red.A400 }}>
          public
        </i>
      }
    />
  );
};

const renderTimeAlert = (timeAlert: string | null) => {
  if (!timeAlert) {
    return (
      <HtmlTooltip
        title={'Time within 30 minutes of scheduled.'}
        children={
          <i className="material-icons" style={{ color: green.A400 }}>
            check
          </i>
        }
      />
    );
  }

  return (
    <HtmlTooltip
      title={timeAlert}
      children={
        <i className="material-icons" style={{ color: red.A400 }}>
          alarm
        </i>
      }
    />
  );
};

const renderSourceIcon = (source: string | null, alertType: string) => {
  switch (source) {
    case 'Dynoforms':
      return (
        <HtmlTooltip
          title={alertType + source}
          children={
            <i className="material-icons" style={{ color: blue.A400 }}>
              desktop_mac{' '}
            </i>
          }
        />
      );
    case 'EVV':
      return (
        <HtmlTooltip
          title={alertType + source}
          children={
            <i className="material-icons" style={{ color: blue.A400 }}>
              phone_android{' '}
            </i>
          }
        />
      );
    default:
      return (
        <HtmlTooltip
          title={alertType + source}
          children={
            <i className="material-icons" style={{ color: blue.A400 }}>
              device_unknown{' '}
            </i>
          }
        />
      );
  }
};

const AlertColumnRenderer = ({ value }: GridRenderCellParams<ICaregiverShiftsData>): ReactNode => {
  if (!value) {
    return null;
  }
  return (
    <span>
      {renderLocationAlert('Clock In Alert: ', value.clockInAlert)}
      {renderLocationAlert('Clock Out Alert: ', value.clockOutAlert)}
      {renderTimeAlert(value.timeAlert)}
      {renderSourceIcon(value.clockInSource, 'Clock In Source: ')}
      {renderSourceIcon(value.clockOutSource, 'Clock Out Source: ')}
    </span>
  );
};

export default AlertColumnRenderer;
