import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { interfaces, theme as aveannaTheme } from '../../common';
import AccountCircle from '../../assets/account-circle.png';
import Check from '../../assets/check.png';

export enum NurseListItemControlType {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  ORIENTATION_REQUESTED = 'orientationRequested',
}

const Checkbox = ({ checked, handleClick }: { checked: boolean; handleClick: any }) => (
  <CheckboxWrapper onClick={handleClick} checked={checked}>
    {checked && <img src={Check} />}
  </CheckboxWrapper>
);

const Radio = ({ checked, handleClick }: { checked: boolean; handleClick: any }) => (
  <RadioWrapper onClick={handleClick} checked={checked}>
    {checked && <Dot />}
  </RadioWrapper>
);

const OrientationRequested = ({ checked, handleClick }: { checked: boolean; handleClick: any }) => (
  <OrientRequestedWrapper onClick={handleClick} checked={checked}>
    <OrientationRequestedText>Orientation Request Sent</OrientationRequestedText>
  </OrientRequestedWrapper>
);

const ControlNotFound = ({ checked, handleClick }: { checked: boolean; handleClick: any }) => (
  <div />
);

export const NurseListItem = ({
  nurse: {
    applicationDate,
    skillLevel,
    user: { familyName, givenName, imageUri },
  },
  controlType,
  checked,
  handleClick,
}: {
  nurse: interfaces.INurse & { applicationDate?: any };
  controlType?: string | NurseListItemControlType;
  checked: boolean;
  handleClick: any;
}) => {
  const ControlComponent = getControlTypeComponent(controlType);
  return (
    <ListItemWrapper>
      <img
        src={imageUri}
        style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }}
        onError={(e: any) => {
          e.target.onerror = null;
          e.target.src = AccountCircle;
        }}
      />
      <InfoAndControlWrapper>
        <div>
          <div style={{ color: 'rgba(0,0,0,0.9)' }}>{`${givenName} ${familyName}`}</div>
          <div style={{ color: 'rgba(0,0,0,0.6)' }}>{`Level ${skillLevel}`}</div>
          {applicationDate && (
            <div style={{ color: 'rgba(0,0,0,0.6)' }}>
              Applied: &nbsp;{moment(applicationDate).format('ddd MMM D - h:mm A')}
            </div>
          )}
        </div>
        <ControlWrapper>
          {ControlComponent && <ControlComponent checked={checked} handleClick={handleClick} />}
        </ControlWrapper>
      </InfoAndControlWrapper>
    </ListItemWrapper>
  );
};

export const UserListItem = ({
  user: { familyName, givenName, imageUri },
  controlType,
  checked,
  handleClick,
}: {
  user: any;
  controlType?: NurseListItemControlType;
  checked: boolean;
  handleClick: any;
}) => {
  const ControlComponent = getControlTypeComponent(controlType);
  return (
    <ListItemWrapper>
      <img
        src={imageUri}
        style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }}
        onError={(e: any) => {
          e.target.onerror = null;
          e.target.src = AccountCircle;
        }}
      />
      <InfoAndControlWrapper>
        <div>
          <div style={{ color: 'rgba(0,0,0,0.9)' }}>{`${givenName} ${familyName}`}</div>
        </div>
        <ControlWrapper>
          {ControlComponent && <ControlComponent checked={checked} handleClick={handleClick} />}
        </ControlWrapper>
      </InfoAndControlWrapper>
    </ListItemWrapper>
  );
};

const getControlTypeComponent = (controlType?: NurseListItemControlType | string) => {
  switch (controlType) {
    case NurseListItemControlType.RADIO:
      return Radio;
    case NurseListItemControlType.CHECKBOX:
      return Checkbox;
    case NurseListItemControlType.ORIENTATION_REQUESTED:
      return OrientationRequested;
    default:
      return ControlNotFound;
  }
};

interface INurseListControlProps extends HTMLAttributes<HTMLDivElement> {
  checked: boolean;
}

// STYLES
const OrientationRequestedText = styled.p`
  color: ${aveannaTheme.colors.aveannaDarkBlue};
  font-size: ${aveannaTheme.fontSizeSm};
`;

const OrientRequestedWrapper: React.FC<INurseListControlProps> = styled.div``;

const ListItemWrapper = styled.div`
  display: flex;
  padding: 8px;
  min-height: 50px;
  max-height: 70px;
`;

const InfoAndControlWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  border-bottom: 1px solid lightgray;
`;

const ControlWrapper = styled.div`
  margin-left: auto;
  margin-right: 20px;
`;

const CheckboxWrapper: React.FC<INurseListControlProps> = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 3px;
  border-width: 3px;
  border-style: solid;
  border-color: #0075c9;
  background-color: ${(props: INurseListControlProps) => (props.checked ? '#0075c9' : 'white')};
  box-sizing: border-box;
  cursor: pointer;
`;

const RadioWrapper: React.FC<INurseListControlProps> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: #0075c9;
  background-color: white;
  box-sizing: border-box;
  cursor: pointer;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #0075c9;
`;
