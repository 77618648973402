/**
 * Every function in this file should return a string and should only be used for rendering purposes.
 */

export const formatPhoneNumber = (data: any) => {
  // Strips data of non-numeric characters, then formats the number using 7, 10, and 11 digit phone number formats.
  // X (XXX) XXX-XXXX
  if (typeof data !== 'string' && typeof data !== 'number') {
    console.log(
      `ERROR: Phone number should be of type (string) or (number), but received ${typeof data} ${JSON.stringify(
        data
      )}`
    );
    return 'Phone # Unavailable';
  }

  // Strip all non-numeric characters.
  const numbers = `${data}`.replace(/\D+/g, '');

  // Get 7 digit number.
  const length = numbers.length;
  const phoneNumber = `${numbers.substr(length - 7, 3)}-${numbers.substr(length - 4)}`;

  switch (length) {
    // Base number only
    case 7:
      return phoneNumber;
    // With area code
    case 10:
      return `(${numbers.substr(0, 3)}) ${phoneNumber}`;
    // With country code
    case 11:
      return `${numbers.substr(0, 1)} (${numbers.substr(1, 3)}) ${phoneNumber}`;
    default:
      console.log(`Unhandled phone number length: ${data}`);
      return data;
  }
};
