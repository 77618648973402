import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import MaterialIcon from 'material-icons-react';
// @ts-ignore error: TS7016
import Sidebar from 'react-sidebar';

import { StyledCheckbox, theme } from '../../common';
import { Table, TableActionButton } from '../../Shifts/components/Table';
import styles from '../../Aides/containers/AidesRejectedShifts.module.css';
import ReusableTooltip, { GetSourceAlert } from '../../common/ReusableTooltip';
import { StatusType } from '../../utils';
import ReconcileContainer from '../PatientDetails/ReconcileContainer';
import { ShiftSidebar } from '../../Aides/ShiftPanel/ShiftSidebar';
import { getDvrByClockInOutReviewId, saveClockInOut } from '../../api';
import {
  setCurrentShift as setCurrentShiftAction,
  clearCurrentShift as clearCurrentShiftAction,
} from '../../Aides/actions';
import ShiftSchedulerContainer from '../PatientDetails/ShiftSchedulerContainer';
import PatientShiftSearch from '../components/PatientsShiftSearch/PatientShiftSearchContainer';
import { getShiftsForLocation } from '../../api/shifts';
import { IClockInOut, IGenericShift } from '../PatientInterfaces';
import { isEvvSource, ONLY_EVV_SHIFT_FILTER } from '../../utils/app';

const getClockInOutAlerts = (clockInOut: IClockInOut): JSX.Element | null => {
  return clockInOut !== null ? (
    <>
      {clockInOut.timeAlert !== null &&
      !clockInOut.locationAlerts.clockInAlert &&
      !clockInOut.locationAlerts.clockOutAlert &&
      clockInOut.overlapAlerts.length === 0 &&
      clockInOut.officeAlerts.length === 0 ? (
        <MaterialIcon icon="check" color="green" size="small" />
      ) : null}
      <ReusableTooltip
        zIndex={300}
        fontColor={'black'}
        backgroundColor={'white'}
        placement={'bottom'}
        data={clockInOut.timeAlert}
        tooltipHeader={'Time Issues'}
        iconName={'access_time'}
        iconColor={'maroon'}
        autoWidth={true}
      />

      <ReusableTooltip
        zIndex={300}
        fontColor={'black'}
        backgroundColor={'white'}
        placement={'bottom'}
        data={clockInOut.locationAlerts}
        tooltipHeader={'Location Issues'}
        iconName={'room'}
        iconColor={'maroon'}
        autoWidth={true}
      />

      <ReusableTooltip
        zIndex={300}
        fontColor={'black'}
        backgroundColor={'white'}
        placement={'bottom'}
        data={clockInOut.overlapAlerts}
        tooltipHeader={'Overlap Issues'}
        iconName={'settings_overscan'}
        iconColor={'maroon'}
        autoWidth={true}
      />

      <ReusableTooltip
        zIndex={300}
        fontColor={'black'}
        backgroundColor={'white'}
        placement={'bottom'}
        data={clockInOut.officeAlerts}
        tooltipHeader={'Office Issues'}
        iconName={'location_city'}
        iconColor={'maroon'}
        autoWidth={true}
      />
      {GetSourceAlert('Clock In Source', clockInOut.clockInSource)}
      {GetSourceAlert('Clock Out Source', clockInOut.clockOutSource)}
    </>
  ) : (
    <ReusableTooltip
      zIndex={300}
      fontColor={'black'}
      backgroundColor={'white'}
      placement={'bottom'}
      data={['Shift is unreconciled or not yet performed.']}
      tooltipHeader={'Unreconciled or Not Performed'}
      iconName={'assignment'}
      iconColor={'grey'}
      autoWidth={true}
    />
  );
};
const PatientsScheduledAndPerformed = (props: any) => {
  const COLUMNS = [
    {
      Header: 'SHIFT SCHEDULED',
      accessor: 'start',
      minWidth: 210,
      Cell: (row: any) => {
        return row.original.start !== null ? (
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            {moment.utc(row.original.start).format('MM/DD/YYYY HH:mm')}&nbsp;-&nbsp;
            {moment.utc(row.original.end).format('MM/DD/YYYY HH:mm')}
          </div>
        ) : (
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>Unreconciled</div>
        );
      },
    },
    {
      Header: 'HOURS SCHEDULED',
      id: 'hours',
      accessor: (row: IGenericShift) => {
        if (row.start) {
          const startTime = moment(row.start);
          const endTime = moment(row.end);
          return moment.duration(endTime.diff(startTime)).asHours();
        }
        return null;
      },
      minWidth: 65,
      getProps: () => {
        return {
          style: {
            borderRight: '1px solid silver',
          },
        };
      },
      Cell: (row: any) => (
        <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
          {row.value ? row.value.toFixed(2) : null}
        </div>
      ),
    },
    {
      Header: 'SHIFT PERFORMED',
      accessor: 'shiftPerformed',
      minWidth: 210,
      Cell: (row: any) => {
        const isFutureShift = moment(row.original.start).isAfter(moment());
        const clockInOutExists = row.original.clockInOut !== null;
        if (isFutureShift && !clockInOutExists) {
          return (
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              Not Yet Performed
            </div>
          );
        }
        if (clockInOutExists) {
          return (
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              {moment(row.original.clockInOut.adjustedClockInTimeStamp).format('MM/DD/YYYY HH:mm')}
              &nbsp;-&nbsp;
              {row.original.clockInOut.adjustedClockOutTimeStamp !== null
                ? moment(row.original.clockInOut.adjustedClockOutTimeStamp).format(
                    'MM/DD/YYYY HH:mm'
                  )
                : ' ?'}
            </div>
          );
        } else {
          return (
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>Unreconciled</div>
          );
        }
      },
    },
    {
      Header: 'LOGGED TIME',
      accessor: 'loggedTime',
      minWidth: 65,
      Cell: (row: any) => {
        if (row.original.clockInOut !== null) {
          const startTime = moment(row.original.clockInOut.adjustedClockInTimeStamp);
          if (row.original.clockInOut.adjustedClockOutTimeStamp != null) {
            const endTime = moment(row.original.clockInOut.adjustedClockOutTimeStamp);
            const loggedTime = moment.duration(endTime.diff(startTime)).asHours();
            return (
              <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                {loggedTime.toFixed(2)}
              </div>
            );
          } else {
            return (
              <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>Incomplete</div>
            );
          }
        }
        return <div />;
      },
    },
    {
      Header: 'CAREGIVER',
      id: 'aide',
      accessor: (row: any) =>
        row.clockInOut && row.clockInOut.nurseProfile && row.clockInOut.nurseProfile.user
          ? `${row.clockInOut.nurseProfile.user.givenName} ${row.clockInOut.nurseProfile.user.familyName}`
          : null,
      minWidth: 100,
      Cell: (row: any) => (
        <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>{row.value}</div>
      ),
    },
    {
      Header: 'SCHEDULED CAREGIVER',
      id: 'scheduledAide',
      accessor: (row: any) =>
        row.scheduledNurse && row.scheduledNurse.user
          ? `${row.scheduledNurse.user.givenName} ${row.scheduledNurse.user.familyName}`
          : null,
      minWidth: 100,
      Cell: (row: any) => (
        <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>{row.value}</div>
      ),
    },
    {
      Header: 'REVIEWED BY',
      accessor: 'reviewedBy',
      minWidth: 100,
      Cell: (row: any) => {
        return row.original.clockInOut && row.original.clockInOut.modifier ? (
          <div>
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              {row.original.clockInOut.modifier.givenName}&nbsp;
              {row.original.clockInOut.modifier.familyName}
            </div>
          </div>
        ) : (
          <div />
        );
      },
    },
    {
      Header: 'ALERTS',
      accessor: null,
      minWidth: 75,
      style: { overflow: 'visible' },
      Cell: (row: any) => {
        const clockInOutAlerts = getClockInOutAlerts(row.original.clockInOut);
        return <div>{clockInOutAlerts}</div>;
      },
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      minWidth: 100,
      Cell: (row: any) => {
        // default value for StatusType.PENDING
        let iconName = 'error';
        let iconText = 'Pending';
        let iconColor = 'darkorange';

        if (!row.original.start) {
          iconName = 'assignment';
          iconText = 'No Scheduled Shift';
          iconColor = 'maroon';
        } else if (!row.original.clockInOut) {
          iconName = 'timer';
          iconText = 'No Clock In/Out';
          iconColor = 'maroon';
        } else if (row.original.clockInOut.status === StatusType.APPROVED) {
          iconName = 'check_circle';
          iconText = 'Approved';
          iconColor = 'green';
        } else if (row.original.clockInOut.status === StatusType.REJECTED) {
          iconName = 'cancel';
          iconText = 'Reviewing';
          iconColor = 'maroon';
        }

        return (
          <div style={{ fontSize: '0.9rem', fontWeight: 700 }}>
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              <MaterialIcon key={iconName} icon={iconName} color={iconColor} size="small" />
            </div>
            <div
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                paddingLeft: 10,
                color: iconColor,
              }}
            >
              {iconText}
            </div>
          </div>
        );
      },
    },
    {
      Header: 'ACTIONS',
      accessor: null,
      minWidth: 75,
      Cell: (row: any) => {
        if (!row.original.start) {
          return <div />;
        }
        const isFutureShift = moment(row.original.start).isAfter(moment());
        const clockInOutExists = row.original.clockInOut !== null;
        const disableButton = isFutureShift && !clockInOutExists;
        return (
          <TableActionButton
            disabled={disableButton}
            onClick={async () => {
              if (row.original.clockInOut !== null && row.original.start !== null) {
                const dvr = (await getDvrByClockInOutReviewId(row.original.clockInOut.id)).body;
                props.setCurrentShift(dvr);
                setSelectedRow({ dvr: dvr, ...row.original });
              } else {
                setSelectedRow(row.original);
              }
            }}
            style={{
              cursor: 'pointer',
              margin: '0.5rem 1rem 0.5rem 1rem',
              padding: '0.5rem 1rem 0.5rem 1rem',
              pointerEvents: 'auto',
              fontSize: '0.9rem',
              fontWeight: 700,
              color: 'white',
              backgroundColor: disableButton ? theme.colors.aveannaGray : '#0075c9',
              borderWidth: 0,
              borderRadius: 6,
            }}
          >
            {clockInOutExists && row.original.start !== null ? 'Review' : 'Reconcile'}
          </TableActionButton>
        );
      },
    },
  ];
  const { patient, searchParams } = props;
  const [shifts, setShifts] = useState<any[]>([]);
  const [onlyEvvShifts, setOnlyEvvShifts] = useState(true);
  const [error, setError] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState<IGenericShift | null>(null);

  useEffect(() => {
    async function effect() {
      setShifts(
        await getShiftsForLocation(
          patient.id,
          searchParams.searchStartDate,
          searchParams.searchEndDate
        )
      );
      setLoading(false);
    }
    effect();
  }, []);

  const onClose = async (updated: boolean = false) => {
    setSelectedRow(null);
    props.clearCurrentShift();
    if (updated) {
      setLoading(true);
      setShifts(
        await getShiftsForLocation(
          patient.id,
          searchParams.searchStartDate,
          searchParams.searchEndDate
        )
      );
      setLoading(false);
    }
  };

  const onSave = async (review: any) => {
    await saveClockInOut(review);
    setSelectedRow(null);
    props.clearCurrentShift();
  };

  const search = async (searchStartDate: Date, searchEndDate: Date) => {
    setLoading(true);
    setShifts(await getShiftsForLocation(patient.id, searchStartDate, searchEndDate));
    setLoading(false);
  };

  let pendingReviewShifts = 0;
  const shiftsToDisplay: IGenericShift[] = [];
  if (shifts) {
    shifts.forEach((shift: IGenericShift) => {
      if (shift.clockInOuts === null || shift.clockInOuts.length === 0) {
        shiftsToDisplay.push({ ...shift, clockInOut: null });
      } else {
        shift.clockInOuts.forEach((clockInOut: IClockInOut) => {
          if (clockInOut.status === StatusType.PENDING) {
            pendingReviewShifts++;
          }
          shiftsToDisplay.push({ ...shift, clockInOut });
        });
      }
    });
  }

  let filteredShifts: IGenericShift[] = [];
  const filterEvvShifts = (event?: any) => {
    const shouldFilter = event ? event.target.checked : onlyEvvShifts;
    if (event) {
      setOnlyEvvShifts(event.target.checked);
    }

    if (shouldFilter) {
      filteredShifts = shiftsToDisplay.filter((shift: any) => {
        return isEvvSource(shift.clockInOut);
      });
    } else {
      filteredShifts = shiftsToDisplay;
    }
  };
  filterEvvShifts();

  const sidebarComponent =
    selectedRow && selectedRow.start === null && selectedRow.clockInOut ? (
      <ShiftSchedulerContainer clockInOut={selectedRow.clockInOut} onClose={onClose} />
    ) : selectedRow && selectedRow.clockInOut === null ? (
      <ReconcileContainer shift={selectedRow} onClose={onClose} />
    ) : selectedRow && selectedRow.dvr && selectedRow.clockInOut ? (
      <ShiftSidebar
        onSaveNew={() => {}}
        onSave={onSave}
        shift={selectedRow}
        dvr={selectedRow.dvr}
        review={selectedRow.clockInOut}
        onClose={onClose}
      />
    ) : (
      <div />
    );
  return (
    <div className={styles.SA_Parent}>
      <Sidebar
        sidebar={sidebarComponent}
        pullRight={true}
        open={!!selectedRow}
        styles={{
          sidebar: {
            display: 'flex',
            background: 'white',
            zIndex: 6,
          },
          overlay: { zIndex: 5 },
        }}
      >
        <div style={{ padding: 24, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ flex: 1 }}>
            <div style={{ color: theme.colors.defaultFontColor, fontSize: theme.fontSizeLg }}>
              <span
                onClick={props.goBack}
                style={{ textDecoration: 'underline', color: theme.colors.aveannaDarkBlue }}
              >
                Patient Management
              </span>
              &nbsp;| Scheduled and Performed
            </div>
            <div style={{ color: theme.colors.defaultFontColor, fontSize: theme.fontSizeSm }}>
              Patient: {patient.name} {patient.searchField}
            </div>
          </div>
          <div
            style={{ flex: 1, color: theme.colors.defaultFontColor, fontSize: theme.fontSizeLg }}
          >
            Pending Review: {pendingReviewShifts}
          </div>
          <div style={{ flex: 3 }}>
            <PatientShiftSearch search={search} patient={props.patient} />
          </div>
        </div>
        <label>
          <input type="checkbox" checked={onlyEvvShifts} onChange={filterEvvShifts} />
          {ONLY_EVV_SHIFT_FILTER}
        </label>
        <Table
          loading={loading}
          defaultPageSize={25}
          minRows={10}
          showPagination={true}
          data={filteredShifts}
          noDataText={
            error
              ? 'Could not fetch shifts.'
              : patient.externalSource === 'Encore'
              ? 'Encore Shifts Coming Soon in Scheduler'
              : 'No shifts.'
          }
          columns={COLUMNS}
          resizable={true}
          sortable={true}
          defaultSorted={[
            {
              id: 'start',
              desc: true,
            },
          ]}
          style={{
            width: 'unset',
            height: '80%',
            overflow: 'hidden',
            boxShadow: 'none',
            border: 'none',
          }}
        />
      </Sidebar>
    </div>
  );
};

const mapStateToProps = ({ searchReducer }: any) => {
  return {
    searchParams: searchReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCurrentShift: (shift: any) => dispatch(setCurrentShiftAction(shift)),
    clearCurrentShift: () => dispatch(clearCurrentShiftAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PatientsScheduledAndPerformed);
