import { post } from './apiCallHelper';

interface IResetPasswordInput {
  email: string;
  newPassword: string;
  resetToken: string;
}

export const resetPassword = async ({ email, newPassword, resetToken }: IResetPasswordInput) => {
  try {
    const resetResponse = await post('/api/user/resetPassword', {
      body: {
        username: email,
        newPassword,
        resetToken,
      },
    });

    console.log('reset response — ', resetResponse);

    if (resetResponse.status >= 300) {
      throw new Error('non 200 status code');
    }
  } catch (error) {
    console.log('reset error — ', error);
    throw new Error(
      'There was a problem resetting your password. Your new password might not meet the requirements, or your reset code was wrong.'
    );
  }
};

interface IChangePasswordInput {
  oldPassword: string;
  newPassword: string;
}

export const changePassword = async ({ oldPassword, newPassword }: IChangePasswordInput) => {
  let changePasswordResponse;

  try {
    changePasswordResponse = await post('api/user/changePassword', {
      body: {
        oldPassword,
        newPassword,
      },
    });

    if (changePasswordResponse.status >= 300) {
      throw new Error('non 200 status code');
    }
  } catch (error) {
    console.log('error — ', error);
    throw new Error(
      'Your old password was incorrect or the new one did not fulfill the requirements.'
    );
  }

  console.log('res — ', changePasswordResponse);

  return changePasswordResponse.body;
};

export const requestPasswordReset = async (email: string) => {
  const requestPasswordResetResponse = await post(`api/user/requestPasswordReset`, {
    body: {
      username: email,
    },
  });

  if (requestPasswordResetResponse.status >= 300) {
    throw new Error('Error requesting reset code, please try again.');
  }

  return requestPasswordResetResponse.body;
};
