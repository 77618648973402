import React from 'react';

export const NotFound = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'center',
        marginTop: '20px',
      }}
    >
      <h2 style={{ color: 'dimgray' }}>Ooops! Looks like there's nothing here...</h2>
      <span style={{ color: 'darkgray' }}>404: Path Not Found</span>
    </div>
  );
};
