/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

import React, { useEffect } from 'react';
import ReactGA, { FieldsObject } from 'react-ga';
import { RouteComponentProps } from 'react-router-dom';
import { Environment, getCurrentReleaseChannelFlag } from '../utils';

const withTracker = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>,
  options: FieldsObject = {}
) => {
  const trackPage = (page: string) => {
    console.log('Tracking Pageviews');
    ReactGA.set({ page, ...options });
    ReactGA.pageview(page);
  };

  return (props: P) => {
    useEffect(() => {
      const channel = getCurrentReleaseChannelFlag();

      /* run trackPage function only if env name is RC or PROD */
      if (
        channel.includes(Environment.RC.toLowerCase()) ||
        channel.includes(Environment.PROD.toLowerCase())
      ) {
        trackPage(props.location.pathname);
      }
    }, [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };
};

export default withTracker;
