// @ts-ignore
import Tooltip from 'react-simple-tooltip';
import MaterialIcon from 'material-icons-react';
import React, { Component } from 'react';
import styles from '../Aides/SingleAide/SingleAide.module.css';

type Props = {
  zIndex: number;
  fontColor: string;
  backgroundColor: string;
  placement: string; // top, bottom, left, right
  data: string | string[] | { [key: string]: string };
  tooltipHeader: string;
  iconName: string;
  iconColor: string;
  autoWidth?: boolean;
};

export const GetSourceAlert = (source: string, value: string | null) => {
  return (
    <ReusableTooltip
      zIndex={300}
      fontColor={'black'}
      backgroundColor={'white'}
      placement={'bottom'}
      data={[value === null ? 'EVV' : value]}
      tooltipHeader={source}
      iconName={
        value === 'Scheduler'
          ? 'computer'
          : value === 'Dynoforms'
          ? 'tablet'
          : 'stay_current_portrait'
      }
      iconColor={'black'}
      autoWidth={true}
    />
  );
};

class ReusableTooltip extends Component<Props, {}> {
  public constructor(props: Props) {
    super(props);
  }

  shouldRenderData(): boolean {
    if (!this.props.data) {
      return false;
    }
    if (typeof this.props.data === 'string') {
      return !!this.props.data.length;
    }

    const values = this.processData(this.props.data);

    return !!values.length;
  }

  renderData() {
    if (typeof this.props.data === 'string') {
      return <div>{this.props.data}</div>;
    }

    const values = this.processData(this.props.data);

    return values.map((each: string, index: number) => {
      return <div key={index}>{each}</div>;
    });
  }

  processData(data: string[] | { [key: string]: string }) {
    const values = Array.isArray(data) ? data : Object.values(data);
    return values.filter((x) => !!x);
  }

  public render() {
    return (
      <>
        {this.shouldRenderData() ? (
          <Tooltip
            zIndex={this.props.zIndex}
            color={this.props.fontColor}
            background={this.props.backgroundColor}
            placement={this.props.placement}
            content={
              <React.Fragment>
                <div className={this.props.autoWidth ? styles.tooltipAutoWidth : styles.tooltip}>
                  <div className={styles.tooltip_header}>{this.props.tooltipHeader}</div>
                  {this.props.children ? this.props.children : this.renderData()}
                </div>
              </React.Fragment>
            }
          >
            <div className={styles.tooltip_icon}>
              <div style={{ position: 'relative', top: '5px' }}>
                <MaterialIcon icon={this.props.iconName} color={this.props.iconColor} />
              </div>
            </div>
          </Tooltip>
        ) : null}
      </>
    );
  }
}

export default ReusableTooltip;
