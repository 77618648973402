import { ToastAppearance } from '.';

export const getDefaultToastConfig = (appearance: ToastAppearance) => ({
  appearance: appearance,
  pauseOnHover: true,
  autoDismiss: true,
  autoDismissTimeout: 10000,
});

export default getDefaultToastConfig;
