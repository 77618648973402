import React, { Component, Fragment } from 'react';

import { getSentShifts } from '../../api';
import { API_RETRY_TIMEOUT } from '../../utils';
import { tabFilterNames } from '../enums';

import { Table, TableActionButton, defaultColumns } from '../components/Table';
import { SentNurseListModal } from './SentNurseListModal';
import { Filters } from '../components/Filters';

interface IState {
  selectedShift: any;
  shifts: [any] | [];
  filterValue: string;
  filteredShifts: [any] | [];
  loading: boolean;
  error: boolean;
  openModal: boolean;
  columns: any;
}

export class SentShiftsTable extends Component<any, IState> {
  constructor(props: any) {
    super(props);

    const columns = [
      ...defaultColumns,
      {
        Header: '',
        accessor: 'default',
        Cell: ({ original }: any) => {
          // console.log('button props', props);
          return (
            <TableActionButton
              onClick={() => this.setState({ selectedShift: original, openModal: true })}
            >
              View Selected Caregivers
            </TableActionButton>
          );
        },
        minWidth: 100,
      },
    ];

    this.state = {
      selectedShift: null,
      shifts: [],
      loading: true,
      error: false,
      openModal: false,
      columns,
      filterValue: '',
      filteredShifts: [],
    };
  }

  public componentDidMount = async () => {
    this.getShiftData();
  };

  public retryGetData = async () => {
    setTimeout(this.getShiftData, API_RETRY_TIMEOUT);
  };

  public getShiftData = async () => {
    this.setState({ loading: true });

    try {
      const tempSentShifts = await getSentShifts();
      this.setState({
        shifts: tempSentShifts,
        filteredShifts: tempSentShifts,
        loading: false,
        error: false,
      });
    } catch (e) {
      this.setState({ error: true });
      this.retryGetData();
    }
  };

  public renderModal() {
    const closeModalFunction = () => this.setState({ openModal: false }, () => this.getShiftData());

    return <SentNurseListModal shift={this.state.selectedShift} closeModal={closeModalFunction} />;
  }

  public render() {
    return (
      <Fragment>
        {this.state.openModal && this.renderModal()}
        <Filters
          filterTab={tabFilterNames.sentShiftsFilters}
          shifts={this.state.shifts}
          setFilteredShifts={(filteredShifts: any) => this.setState({ filteredShifts })}
        />
        <Table
          pageSize={this.state.shifts.length}
          minRows={10}
          showPagination={false}
          data={this.state.filteredShifts}
          columns={this.state.columns}
          resizable={true}
          loading={this.state.loading}
          loadingText={this.state.error ? 'Error fetching data. Retrying...' : 'Loading...'}
          noDataText={this.state.error ? '' : 'No results found.'}
          sortable={true}
        />
      </Fragment>
    );
  }
}
