import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

// middleware
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './rootReducer';

const logger = createLogger({
  // default collapse all logs without an error
  collapsed: (getState, action, logEntry = {}) => !logEntry.error,
  // colors: { title: formatTitle },
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// iife to return middleware array
const middlewares = (() => {
  const middlewareArray = [thunk];
  if (process.env && process.env.NODE_ENV === 'development') {
    // @ts-ignore
    middlewareArray.push(logger);
  }
  return middlewareArray;
})();

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export const persistor = persistStore(store);
