import React, { Component, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// @ts-ignore - no types provided
import { withToastManager } from 'react-toast-notifications';

import {
  interfaces,
} from '../../common';
import { login as loginAction, setUser as setUserAction } from '../';
import { IUserSignInInput, ISetUserInput } from '../interfaces';
import {
  getLocalRememberedUser,
} from '../../utils';
import {
  setAppFeatureFlags as setFeatureFlags,
  setUserLocations as setLocations,
} from '../actions';
// TODO move these actions and reducers to common or appData domain
import * as actions from '../../routing/actions';
import {
  storeAuthSettings,
  getOneLoginUrl,
  getClientId,
} from '../../utils/app';
import crypto from 'crypto';
import base64url from 'base64url';
import { storePkceCodeChallege } from '../../utils/user';
import { oneLoginFeatureFlag } from '../../api/apiCallHelper';

interface IState {
  email: string;
  password: string;
  isRemembered: boolean;
  isSubmitting: boolean;
  error: string;
  ameliaVisibility: boolean;
  ameliaIsRunning: boolean;
}

interface IProps extends RouteComponentProps {
  login: ({ email, password, isRemembered }: IUserSignInInput) => void;
  setUser: ({ user, token }: ISetUserInput) => (dispatch: any) => Promise<void>;
  setAppFeatureFlags: (featureFlags: interfaces.IFeatureFlags) => void;
  user: any;
  history: any;
  toastManager: any;
  setAllReasonCodes: (reasons: interfaces.IReasonCodeObject) => void;
  setAppSettings: (appSettings: interfaces.IAppSetting[] | null) => void;
  setUserLocations: (locations: interfaces.ILocation) => void;
}

class Login extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isRemembered: false,
      isSubmitting: false,
      error: '',
      ameliaVisibility: false,
      ameliaIsRunning: false,
    };
  }

  public componentDidMount() {
    this.initializeLoginData();
    this.setState({ ameliaIsRunning: true });

    // TODO: Uncomment this when ready to start working on Amelia health check
    // fetch(getAmeliaHealthCheckEndpoint())
    //   .then((response) => this.setState({ ameliaIsRunning: response.ok }))
    //   .catch((err) => {
    //     Sentry.captureException('Amelia health check error message' + err);
    //     console.log('Amelia health check error message: ', err);
    //     this.setState({ ameliaIsRunning: false })  // Error for some reason, no Amelia visible
    //   });
  }

  private initializeLoginData = async () => {
    await this.setAuthServiceEnabled();
    const tempEmail = getLocalRememberedUser();
    if (tempEmail) {
      this.setState({ email: tempEmail, isRemembered: true });
    }
  };

  private setAuthServiceEnabled = async () => {
    try {
      const oneLoginFlag = await oneLoginFeatureFlag();

      storeAuthSettings(
        oneLoginFlag.body.cookieAuthEnabled,
        oneLoginFlag.body.clientId,
        oneLoginFlag.body.oneLoginEnv
      );
    } catch (error) {
      console.log('error getting OneLogin Flow Flag -- ', error);
    }
  };

  private oneLoginSignOn = () => {
    const oneLoginUrl = getOneLoginUrl();
    const clientId = getClientId();
    const redirectUri = process.env.REACT_APP_ONE_LOGIN_REDIRECT_URI;

    const randomCodeVerifier =
      Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

    storePkceCodeChallege(randomCodeVerifier);

    const hashCodeChallenge = base64url.encode(
      crypto.createHash('sha256').update(randomCodeVerifier).digest()
    );
    const authCodeUrl =
      `${oneLoginUrl}/oidc/2/auth` +
      `?client_id=${clientId}` +
      `&redirect_uri=${redirectUri}&` +
      `response_type=code&` +
      `scope=openid params&` +
      `code_challenge_method=S256&` +
      `code_challenge=${hashCodeChallenge}`;
    window.location.assign(authCodeUrl);
    return null;
  };

  // Function that is passed down into LoginForm child component. There
  // it's run as an OnClick method when the user clicks 'Forgot Password'
  // The method updates a state variable that is passed down into the Amelia
  // component via a prop. That state variable is a simple bool that handles
  // whether to open/show chat-box ui
  public toggleAmeliaVisibility = () => {
    this.setState({ ameliaVisibility: !this.state.ameliaVisibility });
  };

  public render() {
    return this.oneLoginSignOn();
  }
}

const mapStateToProps = ({ user }: any) => {
  return { user };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      login: loginAction,
      setUser: setUserAction,
      setAppFeatureFlags: setFeatureFlags,
      setUserLocations: setLocations,
      setAllReasonCodes: (allReasons: interfaces.IReasonCodeObject) =>
        actions.setAllReasons(allReasons),
      setAppSettings: (appSettings: interfaces.IAppSetting[] | null) =>
        actions.setAppSettings(appSettings),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withToastManager(Login));
