import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ShiftFooterButton } from '../../Aides/ShiftDetails/ShiftFooterButtons';
import { setSaveCurrentShift } from '../../Aides/actions';
import { theme } from '../../common';

interface IProps {
  isEditMode: boolean;
  onClose: () => void;
}

const QACompleteButton = ({ isEditMode, onClose }: IProps) => {
  const currentShift = useSelector(({ aides }: any) => aides.currentShift);
  const dispatch = useDispatch();

  const handleOnClickQACompleted = async () => {
    await dispatch(
      setSaveCurrentShift({
        ...currentShift,
        clockInOut: { ...currentShift.clockInOut, qaComplete: true },
      })
    );
    onClose();
  };

  return (
    <ShiftFooterButton
      onClick={handleOnClickQACompleted}
      disabled={currentShift.clockInOut.qaComplete}
      buttonColor={theme.colors.aveannaDarkBlueHex}
    >
      QA Complete
    </ShiftFooterButton>
  );
};

export default QACompleteButton;
