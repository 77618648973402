import React, { forwardRef } from 'react';
import { updatePatientOfficeId } from '../../../api/patient';
import { LocationEditorComponent } from '../LocationEditorComponent';
import { useSelector } from 'react-redux';
import { formatLocationForDisplay } from '../../locationColumnUtility';

export const PatientLocationDescription = (props: any) => {
  const locationOptions = useSelector(({ admin }: any) => admin.locations);
  if (!locationOptions) {
    return null;
  }

  const selectedOfficeLocation = locationOptions.find(
    (x: { id: number }) => x.id === props.data.officeLocationId
  );

  if (!selectedOfficeLocation) {
    return null;
  }

  return formatLocationForDisplay(selectedOfficeLocation);
};

export const PatientLocationEditor = forwardRef((props: any, ref) => {
  return (
    <LocationEditorComponent
      locationId={props.data.officeLocationId}
      updateLocation={(newOfficeLocationId: number) =>
        updatePatientOfficeId(props.data.id, newOfficeLocationId)
      }
      data={props.data}
      node={props.node}
      ref={ref}
      backingFieldName="officeLocationId"
    />
  );
});
