import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { getAllAides, getSnapShot } from '../../api';
import { StyledContentNoHeader, theme } from '../../common';
import { TableActionButton, Table } from '../components/Table';
import { StatusType } from '../../utils';
import {
  getOnlyEvvShiftFilter,
  isEvvSource,
  ONLY_EVV_SHIFT_FILTER,
  setOnlyEvvShiftFilter,
} from '../../utils/app';

interface ITableData {
  id: number;
  nurseId: number;
  fullName: string;
  avatar: string;
  pending: number;
  rejected: number;
  sources: string[];
}

type Props = RouteComponentProps;

type IState = {
  error: boolean;
  loading: boolean;
  aidesList: any;
  filteredList: any;
  onlyEvvShifts: boolean;
  columns: any[];
};

class Aides extends Component<Props, IState> {
  private mounted: boolean;
  public constructor(props: Props) {
    super(props);

    this.mounted = false;
    this.filterEvvShifts = this.filterEvvShifts.bind(this);
  }

  public state: IState = {
    error: false,
    loading: true,
    aidesList: [],
    filteredList: [],
    onlyEvvShifts: getOnlyEvvShiftFilter(),
    columns: [
      {
        Header: 'NAME OF CAREGIVER',
        accessor: 'fullName',
        minWidth: 100,
        Cell: (row: any) => {
          return (
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <img
                src={row.original.avatar}
                height="28"
                width="28"
                style={{ borderRadius: 50, marginRight: 12 }}
              />
              <div>{row.original.fullName}</div>
            </div>
          );
        },
      },
      {
        Header: 'UNPROCESSED SHIFTS',
        accessor: 'pending',
        minWidth: 100,
      },
      {
        Header: 'SHIFTS UNDER REVIEW',
        accessor: 'rejected',
        minWidth: 100,
      },
      {
        Header: 'ACTIONS',
        accessor: 'actions',
        minWidth: 100,
        Cell: (row: any) => {
          const data: ITableData = row.original;
          return (
            // <Actions
            //   menuItems={[
            //     {
            //       name: 'Review',
            //       action: () => {
            //         this.props.history.push(`/aides/${data.id}`);
            //       },
            //     }
            //   ]}
            // />

            <TableActionButton
              data-cy={'review-new-shifts-btn'}
              onClick={(event: any) => {
                this.props.history.push(`/aides/${data.nurseId}`);
              }}
            >
              Review New Shifts
            </TableActionButton>
          );
        },
      },
    ],
  };

  public componentDidMount() {
    this.mounted = true;
    this.mounted && this.getAides();
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  private async getAides() {
    try {
      const aidesList = await getSnapShot();
      this.getTableData(aidesList);
      this.filterEvvShifts();
    } catch (error) {
      console.error(error);
      this.setState({ error: true });
    }
  }

  private getTableData(aidesList: []) {
    if (aidesList === undefined || !aidesList.length) {
      this.setState({
        aidesList: [],
        filteredList: [],
        error: false,
        loading: false,
      });
      return;
    }

    // get unique user list with accumulated status (PENDING/REJECTED).
    const tableDataValues = this.generateTableDataValues(aidesList);

    this.setState({
      aidesList: aidesList,
      filteredList: tableDataValues,
      error: false,
      loading: false,
    });
  }

  private generateTableDataValues(aidesList: []) {
    const tableData: { [key: string]: ITableData } = {};
    aidesList.map((obj: any) => {
      this.mapAideObject(obj, tableData);
    });

    return Object.values(tableData).filter((td) => {
      return td.pending > 0;
    });
  }

  private mapAideObject(obj: any, tableData: { [key: string]: ITableData }) {
    const id = obj.nurseProfile.id.toString();
    if (tableData[id]) {
      // depending on the status, increase pending or rejected
      if (obj.status === StatusType.PENDING) {
        tableData[id].pending = tableData[id].pending + 1;
      } else if (obj.status === StatusType.REJECTED) {
        tableData[id].rejected = tableData[id].rejected + 1;
      }
      tableData[id].sources.push(obj.clockOutSource);
    } else {
      // add a new user with new data
      tableData[id] = {
        nurseId: obj.nurseId,
        id: obj.nurseProfile.user.id,
        fullName: obj.nurseProfile.user.givenName + ' ' + obj.nurseProfile.user.familyName,
        avatar: obj.nurseProfile.user.imageUri,
        pending: obj.status === StatusType.PENDING ? 1 : 0,
        rejected: obj.status === StatusType.REJECTED ? 1 : 0,
        sources: [obj.clockOutSource],
      };
    }
  }

  private filterEvvShifts(event?: any) {
    const onlyEvvShifts = event ? event.target.checked : this.state.onlyEvvShifts;
    if (event) {
      this.setState({
        onlyEvvShifts: event.target.checked,
      });
      setOnlyEvvShiftFilter(event.target.checked);
    }

    if (onlyEvvShifts) {
      const filteredList = this.state.aidesList.filter((aide: any) => {
        return isEvvSource(aide);
      });
      this.setState({
        filteredList: this.generateTableDataValues(filteredList),
      });
    } else {
      this.setState({
        filteredList: this.generateTableDataValues(this.state.aidesList),
      });
    }
  }

  public render() {
    return (
      <StyledContentNoHeader style={{ flexDirection: 'column', padding: 24 }}>
        <div
          style={{
            fontSize: theme.fontSizeLg,
            paddingBottom: 24,
            color: theme.colors.defaultFontColor,
          }}
        >
          New Submissions
        </div>
        <label>
          <input
            type="checkbox"
            checked={this.state.onlyEvvShifts}
            onChange={this.filterEvvShifts}
          />
          {ONLY_EVV_SHIFT_FILTER}
        </label>
        <Table
          defaultPageSize={100}
          minRows={10}
          showPagination={true}
          data={this.state.filteredList}
          columns={this.state.columns}
          resizable={true}
          loading={this.state.loading}
          loadingText={this.state.error ? 'Error fetching data. Retrying...' : 'Loading...'}
          noDataText={this.state.error ? '' : 'No results found.'}
          style={{ width: 'unset', height: '80%', boxShadow: 'none' }}
        />
      </StyledContentNoHeader>
    );
  }
}

export default Aides;
