import {
  getCurrentReleaseChannelFlag,
  generateVersionStrings,
  getApiEndpoint,
  getSocketEndpoint,
  getPDFGenerationEndpoint,
  getBuildNumber,
  getFormBuilderAccountId,
  getFormBuilderUrl,
  getReleaseEnvironmentName,
  channels,
  FeatureFlags,
  APP_NAME,
  isProduction,
} from './app';
import {
  clearLocalUser,
  storeLocalUser,
  getLocalUser,
  checkIfValidEmailAddress,
  isPasswordValid,
  setLocalUserRemembered,
  getLocalRememberedUser,
  clearLocalRememberedUser,
} from './user';
import {
  userTypes,
  PasswordStatus,
  ToastAppearance,
  API_RETRY_TIMEOUT,
  userTypeByID,
  Environment,
  StatusType,
} from './constants';
import { checkForErrors } from './errors';
import { getLocalNotifications, storeLocalNotifications } from './notifications';
import EnvironmentName from './EnvironmentName';
import { filterShiftsByDate, getTotalHours, initOnlyEvvShiftsFilter } from './shifts';
import { getChatChannelMessageFromParticipants } from './messages';
import { isCacheOutdated } from './cache';
import { getDefaultToastConfig } from './ToastConfiguration';

function nullcheck(obj: any, path: any): any {
  if (obj == null || obj == undefined) return null;

  // if there are params
  if (path && path.length) {
    // slice a key
    const key = path.splice(0, 1)[0];

    // check falsys
    if (obj[key] === false) return false;
    if (obj[key] === 0) return 0;
    if (obj[key] === null) return null;
    if (obj[key] === '') return '';
    if (obj[key] === undefined) return undefined;
    if (obj[key] === NaN) return NaN;

    // if there something there, call recursively
    if (obj[key]) return nullcheck(obj[key], path);
  }
  return obj;
}

function clog(...args: any) {
  console.log(arguments);
  // args && arguments.forEach((x) => {
  //   console.log(typeof x, x);
  // });
}

export {
  getCurrentReleaseChannelFlag,
  generateVersionStrings,
  getApiEndpoint,
  getSocketEndpoint,
  getBuildNumber,
  getFormBuilderAccountId,
  getFormBuilderUrl,
  getReleaseEnvironmentName,
  channels,
  clearLocalUser,
  storeLocalUser,
  isPasswordValid,
  checkIfValidEmailAddress,
  getLocalUser,
  checkForErrors,
  setLocalUserRemembered,
  getLocalRememberedUser,
  clearLocalRememberedUser,
  userTypes,
  PasswordStatus,
  ToastAppearance,
  FeatureFlags,
  EnvironmentName,
  Environment,
  getPDFGenerationEndpoint,
  isProduction,
  isCacheOutdated,
  getTotalHours,
  initOnlyEvvShiftsFilter,
  // notifications
  getLocalNotifications,
  storeLocalNotifications,
  filterShiftsByDate,
  // messages
  getChatChannelMessageFromParticipants,
  // constants
  API_RETRY_TIMEOUT,
  StatusType,
  APP_NAME,
  userTypeByID,
  nullcheck,
  clog,
  getDefaultToastConfig,
};
