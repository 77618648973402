import { types } from './constants';
import { interfaces } from '../common';
import { AdminPortalActions } from '../AdminPortal/constants';
import { IExternalSource } from '../common/interfaces';

export interface IAdminStoreState {
  locations: interfaces.ILocation[];
  externalSources: IExternalSource[];
  externalSourceError: Error | null;
}

const adminInitialState: IAdminStoreState = {
  locations: [],
  externalSources: [],
  externalSourceError: null,
};

export const adminReducer = (
  state = adminInitialState,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case types.SET_LOCATIONS:
      return {
        ...state,
        locations: [...payload.locations],
      };
    case types.UPDATE_LOCATION:
      return {
        ...state,
        locations: [...updateSingleLocation(state.locations, payload.updatedLocation)],
      };
    case AdminPortalActions.EXTERNAL_SOURCES_LOADED_SUCCESSFULLY:
      return {
        ...state,
        externalSources: payload.externalSources,
        externalSourceError: null,
      };
    case AdminPortalActions.EXTERNAL_SOURCES_FAILED_TO_LOAD:
      return {
        ...state,
        externalSources: [],
        externalSourceError: payload.error,
      };
    default:
      return state;
  }
};

const updateSingleLocation = (
  currentLocations: interfaces.ILocation[],
  updatedLocation: interfaces.ILocation
) =>
  currentLocations.map((tempLocation: interfaces.ILocation) => {
    return tempLocation.id === updatedLocation.id
      ? { ...tempLocation, ...updatedLocation }
      : tempLocation;
  });
