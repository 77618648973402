import { localStorageKeys } from './constants';

interface IAppSettings {
  API_URL: string;
  SOCKET_URL: string;
  PDF_GENERATOR_URL: string;
  RELEASE_CHANNEL: string;
  RELEASE_ID: string;
  BUILD_NUMBER: string;
  RELEASE_ENV_NAME: string;
  FEATURE_CANCEL_CONFIRMED_SHIFT?: string;
  FEATURE_REALTIME_NOTIFICATIONS?: string;
  RELEASE_DESC?: string;
  AMELIA_HEALTH_CHECK_URL: string;
  AMELIA_WEBVIEW_SOURCE_URL: string;
  FORM_BUILDER_ACCOUNT_ID: string;
  FORM_BUILDER_URL: string;
  GA_TRACKING_ID: string;
  GOOGLE_MAPS_KEY: string;
  FORM_GENERATOR_VERSION: string;
}

const appSettings: IAppSettings = {
  API_URL: process.env.REACT_APP_API_URL || 'http://localhost:5000',
  // @ts-ignore
  AMELIA_HEALTH_CHECK_URL: process.env.REACT_APP_AMELIA_HEALTH_CHECK_URL,
  // @ts-ignore
  AMELIA_WEBVIEW_SOURCE_URL: process.env.REACT_APP_AMELIA_WEBVIEW_SOURCE_URL,
  SOCKET_URL: process.env.REACT_APP_SOCKET_URL || 'http://localhost:5001',
  PDF_GENERATOR_URL: process.env.REACT_APP_PDF_GENERATOR_URL || 'https://appapi4.aveannadev.net/',
  RELEASE_CHANNEL: process.env.REACT_APP_RELEASE_CHANNEL || 'local',
  RELEASE_ID: process.env.REACT_APP_RELEASE_ID || '0',
  BUILD_NUMBER: process.env.REACT_APP_BUILD_NUMBER || '0',
  RELEASE_ENV_NAME: process.env.REACT_APP_RELEASE_ENV_NAME || 'local',
  FEATURE_CANCEL_CONFIRMED_SHIFT: process.env.REACT_APP_FEATURE_CANCEL_CONFIRMED_SHIFT || 'false',
  FEATURE_REALTIME_NOTIFICATIONS: process.env.REACT_APP_FEATURE_REALTIME_NOTIFICATIONS || 'false',
  FORM_BUILDER_ACCOUNT_ID: process.env.REACT_APP_FORM_BUILDER_ACCOUNT_ID || 'Et-x07b7Zla6gnMe5xRGH',
  FORM_BUILDER_URL:
    process.env.REACT_APP_FORM_BUILDER_URL || 'https://form-builder.api.7fdev.io/load',
  GA_TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID || '',
  GOOGLE_MAPS_KEY:
    process.env.REACT_APP_GOOGLE_MAPS_KEY || 'AIzaSyCoAL_VXaD33SKw-ML-y1YGnfLydv5SXak',
  FORM_GENERATOR_VERSION: process.env.REACT_APP_FORM_GENERATOR_VERSION || '4',
};

export const APP_NAME = 'Scheduler';

// SENTRY FLAGS
export enum channels {
  local = 'local',
  dev = 'stage',
  qa = 'rc',
  prod = 'prod',
}

// by production, in this case, we just mean is it actually built and deployed or not?
export const isProduction = (): boolean => {
  return process.env.NODE_ENV === 'production';
};

// import this when checking for a feature
export const FeatureFlags = Object.entries(appSettings as any).reduce<any>((acc, [key, value]) => {
  const firstWordKey = key.split('_')[0];
  // check if the template value has been replaced
  const isTemplateValue = typeof value === 'string' && value[0] === '#';
  // set export keys from appSettings, checking for FEATURE word
  if (firstWordKey === 'FEATURE' && !isTemplateValue) {
    acc[key] = value;
  }
  return acc;
}, {});

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const getCurrentReleaseChannelFlag = () => {
  return appSettings.RELEASE_CHANNEL;
};

export const getAmeliaHealthCheckEndpoint = () => {
  return appSettings.AMELIA_HEALTH_CHECK_URL;
};

export const getAmeliaWebViewSourceUrl = () => {
  return appSettings.AMELIA_WEBVIEW_SOURCE_URL;
};

export const getApiEndpoint = () => {
  return appSettings.API_URL;
};

export const getSocketEndpoint = () => {
  return appSettings.SOCKET_URL;
};

export const getPDFGenerationEndpoint = () => {
  return appSettings.PDF_GENERATOR_URL;
};

export const getReleaseID = () => {
  return `${appSettings.RELEASE_ID}`;
};

export const getBuildNumber = (): string | null => {
  return appSettings.BUILD_NUMBER;
};

export const getGATrackingId = (): string => {
  return appSettings.GA_TRACKING_ID;
};

export const getGoogleMapsKey = (): string => {
  return appSettings.GOOGLE_MAPS_KEY;
};

export const getFormBuilderAccountId = (): string => {
  return appSettings.FORM_BUILDER_ACCOUNT_ID;
};

export const getFormBuilderUrl = (): string => {
  return appSettings.FORM_BUILDER_URL;
};
export const getFormGeneratorVersion = () => {
  return appSettings.FORM_GENERATOR_VERSION;
};

export const getReleaseEnvironmentName = (): string => {
  return appSettings.RELEASE_ENV_NAME;
};

export const generateVersionStrings = () => {
  const channelFlag = getCurrentReleaseChannelFlag();
  return {
    display: `v${getReleaseID()}.${getBuildNumber()} - ${appSettings.RELEASE_ENV_NAME}`,
    release: `${getReleaseID()}`,
    sentryEnvironment: `sched-${channelFlag}`,
  };
};

export const LOCAL_STORAGE_ONLY_EVV_SHIFTS_KEY = `${
  localStorageKeys.ONLY_EVV_SHIFTS
}_${getBuildNumber()}`;
export const LOCAL_STORAGE_AUTH_FLAG_KEY = `${localStorageKeys.AUTH_FLAG}_${getBuildNumber()}`;
export const LOCAL_STORAGE_COOKIE_AUTH_FLAG_KEY = `${
  localStorageKeys.COOKIE_FLAG
}_${getBuildNumber()}`;
export const LOCAL_STORAGE_CLIENT_ID_KEY = `${localStorageKeys.CLIENT_ID}_${getBuildNumber()}`;
export const LOCAL_STORAGE_ONELOGIN_URL_KEY = `${
  localStorageKeys.ONELOGIN_URL
}_${getBuildNumber()}`;

export const ONLY_EVV_SHIFT_FILTER = 'Remove DynoForms and myUnity records';

export const getOnlyEvvShiftFilter = (): boolean => {
  const filter = localStorage.getItem(LOCAL_STORAGE_ONLY_EVV_SHIFTS_KEY);
  return filter ? JSON.parse(filter) : false;
};

export const setOnlyEvvShiftFilter = (filter: boolean) => {
  localStorage.setItem(LOCAL_STORAGE_ONLY_EVV_SHIFTS_KEY, filter ? 'true' : 'false');
};

export const isEvvSource = (obj: any) => {
  return (
    obj &&
    ((obj.clockOutSource === 'EVV' && obj.clockInSource === 'EVV') ||
      (!obj.clockOutSource && !obj.clockInSource))
  );
};

export const storeAuthSettings = (
  cookieFlag: boolean,
  clientId: string,
  oneLoginUrl: string
) => {
  localStorage.setItem(LOCAL_STORAGE_COOKIE_AUTH_FLAG_KEY, cookieFlag.toString());
  localStorage.setItem(LOCAL_STORAGE_CLIENT_ID_KEY, clientId);
  localStorage.setItem(LOCAL_STORAGE_ONELOGIN_URL_KEY, oneLoginUrl);
};

export const clearAuthSettings = () => {
  localStorage.removeItem(LOCAL_STORAGE_COOKIE_AUTH_FLAG_KEY);
  localStorage.removeItem(LOCAL_STORAGE_CLIENT_ID_KEY);
  localStorage.removeItem(LOCAL_STORAGE_ONELOGIN_URL_KEY);
};

export const isCookieAuthEnabled = (): boolean => {
  return localStorage.getItem(LOCAL_STORAGE_COOKIE_AUTH_FLAG_KEY) === 'true';
};

export const getClientId = () => {
  return localStorage.getItem(LOCAL_STORAGE_CLIENT_ID_KEY);
};

export const getOneLoginUrl = () => {
  return localStorage.getItem(LOCAL_STORAGE_ONELOGIN_URL_KEY);
};
