import React from 'react';
import styled from 'styled-components';

import { IPatient } from '../../Shifts/interfaces';
import { skillLevelColors } from '../constants';

const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50px;
  border-bottom: 1px solid #e3e3e3;
`;

const ContentWrapper = styled.div`
  padding: 8px;
`;

const PatientNameText = styled.div`
  font-weight: bold;
  color: black;
  margin-bottom: 5px;
`;

const PatientLevelDetailContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
`;
const PatientLevelDetailText = styled.div`
  flex: 1;
  color: black;
  font-size: 14px;
`;

const PatientDiagnosisText = styled.div`
  text-transform: uppercase;
  color: #707070;
  font-size: 12px;
  margin-bottom: 5px;
`;

const PatientAddressText = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
`;

export const PatientListItem = ({
  onSelectPatient,
  patient,
}: {
  patient: IPatient;
  onSelectPatient: (patient: IPatient) => void;
}) => {
  const SkillLevelMarker = styled.div`
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: ${skillLevelColors[patient.skillLevel]};
    border-radius: 3px;
    margin-right: 5px;
  `;
  return (
    <ListItemWrapper>
      <ContentWrapper
        onClick={() => {
          onSelectPatient(patient);
        }}
      >
        <PatientNameText>{patient.name}</PatientNameText>
        <PatientLevelDetailContainer>
          <PatientLevelDetailText>
            <SkillLevelMarker />
            {`Level ${patient.skillLevel}`}
          </PatientLevelDetailText>
          <PatientLevelDetailText>{`${patient.age}/${patient.sex}/${patient.weight}`}</PatientLevelDetailText>
        </PatientLevelDetailContainer>
        <PatientDiagnosisText>{patient.primaryDiagnosis}</PatientDiagnosisText>
        <PatientAddressText>{patient.address}</PatientAddressText>
      </ContentWrapper>
    </ListItemWrapper>
  );
};
