import moment, { Moment } from 'moment';
import { LOCAL_STORAGE_ONLY_EVV_SHIFTS_KEY, setOnlyEvvShiftFilter } from './app';

export const filterShiftsByDate = (shiftList: any, startDate: Moment, endDate: Moment): [] => {
  const filteredList: any = [];
  if (endDate === null) {
    endDate = startDate;
  }
  shiftList.map((shift: any) => {
    const convertedDate = moment(shift.adjustedClockInTimeStamp);
    if (convertedDate >= startDate.startOf('day') && convertedDate <= endDate.endOf('day')) {
      filteredList.push(shift);
    }
  });
  return filteredList;
};

export function getTotalHours(clockOutDateTime: any, clockInDateTime: any): number {
  if (!clockOutDateTime || !clockInDateTime) {
    return 0;
  }
  const offset1 = getTimeStampOffset(clockOutDateTime);
  const offset2 = getTimeStampOffset(clockInDateTime);
  const isMatch = compareDateOffSets(offset1, offset2);

  const duration = moment.duration(moment(clockOutDateTime).diff(clockInDateTime));
  const asHours: number = Number(duration.asHours());

  const totalHours: number = Number(asHours.toFixed(2)) as number;

  let newOffset = 0;
  if (isMatch === false) {
    newOffset = (Number(offset1) - Number(offset2)) / 100;
  }
  return Number((totalHours + newOffset).toFixed(2));
}

export const getTimeStampOffset = (timestamp: string) => {
  const parseZoneToString = moment(timestamp).parseZone().toString();
  const result = getValueAfterSplit(parseZoneToString, ['GMT']);
  // console.log('getTimeStampOffset', timestamp, parseZoneToString, typeof result);
  return result;
};

export function getValueAfterSplit(str: string, splitters: string[]) {
  const result = splitters.map((x) => {
    return str.split(x)[1];
  });
  return result && result[0];
}

export function compareDateOffSets(start: string, end: string): boolean {
  const offset1 = getTimeStampOffset(start);
  const offset2 = getTimeStampOffset(end);

  // console.log('compareDateOffSets', offset1)
  // console.log('compareDateOffSets', offset2)
  // console.log(offset1 && offset2 && offset1[0] === offset2[0])

  return (offset1 && offset2 && offset1[0] === offset2[0]) || false;
}

export function getOffsetDifference(indate: string, outdate: string): number {
  const offset1 = getTimeStampOffset(outdate);
  const offset2 = getTimeStampOffset(indate);

  const newOffset = (Number(offset1) - Number(offset2)) / 100;
  console.log('offsetdifference', offset1, offset2, newOffset);
  return Number(newOffset.toFixed(0));
}

export function initOnlyEvvShiftsFilter() {
  if (!localStorage.getItem(LOCAL_STORAGE_ONLY_EVV_SHIFTS_KEY)) {
    setOnlyEvvShiftFilter(false);
  }
}
