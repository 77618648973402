import React from 'react';
import { ControlTypes } from './constants';
import {
  StyledTaskLabel,
  StyledCheckboxControlGroup,
  StyledRadioControlGroup,
  TextAreaControlGroup,
} from './DVRFormControlGroups';
import { ISection, ITask, UpdateFormState } from './interfaces';

// Generates a form section (or, a group of tasks with a title)
export const Section = ({
  section,
  formState,
  updateFormState,
}: {
  section: ISection;
  formState: any;
  updateFormState: any;
}) => {
  const { sectionTitle, tasks } = section;
  return (
    <div style={{ marginBottom: 48 }}>
      <h3>{sectionTitle}</h3>
      <div>
        {tasks &&
          tasks.map((tempTask: ITask, index: number) => {
            return (
              <div key={`${tempTask.taskName}${index}`} style={{ flex: 1 }}>
                <StyledTaskLabel label={tempTask.taskName} />
                {generateTaskControlGroup({
                  task: tempTask,
                  formState,
                  updateFormState,
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const generateTaskControlGroup = ({
  task,
  formState,
  updateFormState,
}: {
  task: ITask;
  formState: any;
  updateFormState: UpdateFormState;
}) => {
  switch (task.controlType) {
    case ControlTypes.Checkbox:
      return (
        <div key={task.taskName} style={{ marginBottom: 24 }}>
          <StyledCheckboxControlGroup
            updateFormState={updateFormState}
            formState={formState.form}
            buttons={task.buttons}
            options={task.options}
          />
        </div>
      );
    case ControlTypes.Radio:
      return (
        <div key={task.taskName} style={{ marginBottom: 24 }}>
          <StyledRadioControlGroup
            updateFormState={updateFormState}
            formState={formState.form}
            buttons={task.buttons}
            options={task.options}
          />
        </div>
      );
    case ControlTypes.Textarea:
      return (
        <div key={task.taskName} style={{ marginBottom: 24 }}>
          <TextAreaControlGroup
            taskId={task.taskId}
            placeholder="No data provided by caregiver."
            updateFormState={updateFormState}
            formState={formState.form}
          />
        </div>
      );
    case ControlTypes.Images:
      return <p style={{ padding: `0px 12px`, fontStyle: 'italic' }}> Images can not be edited.</p>;
    default:
      console.log('no control found for control type: ', task.controlType);
      break;
  }
};
