import React, { Fragment } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import MaterialIcon from 'material-icons-react';

// TODO properly type everything
import { interfaces, theme as aveannaTheme } from '../../common';
import { ShiftFooterTextButton } from './ShiftFooterButtons';

interface IClockInOut {
  clockInOutRejectionReasons: any;
}

interface IProps {
  clockInOut: IClockInOut;
  isEdit: boolean;
  onRemove: (index: number) => void;
}

export const ShiftStatusView = ({ clockInOut, isEdit, onRemove }: IProps) => {
  if (!clockInOut) return null;

  const { clockInOutRejectionReasons = [] } = clockInOut;
  return (
    <Fragment>
      {clockInOutRejectionReasons &&
        clockInOutRejectionReasons.map((tempReason: any, index: number) => {
          return (
            <StatusContainer key={tempReason.reasonId}>
              <StatusHeader>
                <MaterialIcon icon="cancel" color={aveannaTheme.colors.error}></MaterialIcon>
                <div style={{ paddingLeft: 6 }}>Under Review</div>
                <div style={{ flex: 1 }}></div>
                {/* RE IMPLEMENT for proper edit */}
                {/* {isEdit && (
                <ShiftFooterTextButton onClick={() => onRemove(index)}>
                  remove
                </ShiftFooterTextButton>
              )} */}
              </StatusHeader>
              <StatusRow>
                <RowTitle>Rejection type</RowTitle>
                <RowContent>{`${tempReason.reason.code} - ${tempReason.reason.text}`}</RowContent>
              </StatusRow>
              <StatusRow>
                <RowTitle>Reviewed by</RowTitle>
                <RowContent>{`${tempReason.modifier.givenName} ${tempReason.modifier.familyName}`}</RowContent>
              </StatusRow>
              <StatusRow>
                <RowTitle>Rejection date</RowTitle>
                <RowContent>{`${moment(tempReason.modifiedOn).format(
                  'MM-DD-YYYY h:mm a'
                )}`}</RowContent>
              </StatusRow>
            </StatusContainer>
          );
        })}
    </Fragment>
  );
};

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  margin-bottom: 12px;
  padding: 12px;

  border-width: 8px 2px 2px 2px;
  border-color: ${({ theme }) => theme.colors.error};
  border-style: solid;
  border-radius: 6px;
`;

const StatusHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: ${({ theme }) => theme.fontSizeLg};

  margin-bottom: 12px;
`;

const StatusRow = styled.div`
  display: flex;
  flex-direction: row;

  margin-bottom: 6px;
`;

const RowTitle = styled.div`
  width: 125px;
  color: ${({ theme }) => theme.colors.aveannaDarkBlueGray};
`;

const RowContent = styled.div`
  font-weight: 700;
`;
