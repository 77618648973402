import React, { useState, FormEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { checkIfValidEmailAddress } from '../../utils';
import { ROUTES } from '../../routing';
import { requestPasswordReset } from '../../api';
import {
  StyledInputGroup,
  StyledInputLabel,
  StyledTextInput,
  StyledSubmitButton,
  StyledErrorDisplay,
  StyledLink,
} from '../../common';
import { PasswordFormLayout } from '../components';

type Props = {} & RouteComponentProps;

export const RequestPasswordReset = ({ history }: Props) => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const isFormValid = checkIfValidEmailAddress(email);

  const submitForm = async (event?: FormEvent<HTMLInputElement>) => {
    setIsSubmitting(true);
    if (event) {
      event.preventDefault();
    }

    try {
      await requestPasswordReset(email);
      // GOTO THE NEXT PHASE w/Email
      history.push(`${ROUTES.PASSWORD}${ROUTES.RESET_PASSWORD}/${email}`);
    } catch (error) {
      // we might want to go to the next screen anyway as we don't want to tell them there
      // is no account with that email
      setError(
        'Something when wrong, please try again, or contact support if this issues persists.'
      );
      setIsSubmitting(false);
    }
  };

  return (
    <PasswordFormLayout>
      <h2>Reset Password</h2>
      <StyledInputGroup style={{ marginTop: 6 }}>
        <StyledInputLabel>Email</StyledInputLabel>
        <StyledTextInput
          placeholder="email address"
          name="email"
          value={email}
          onChange={(event: any) => {
            setEmail(event.target.value);
          }}
          autoComplete="on"
        />
        <StyledLink
          style={{ alignSelf: 'flex-end' }}
          to={`${ROUTES.PASSWORD}${ROUTES.RESET_PASSWORD}`}
        >
          Already received a reset code?
        </StyledLink>
      </StyledInputGroup>
      <StyledErrorDisplay errorMessage={error} />
      <StyledSubmitButton
        type="submit"
        value="RESET"
        onClick={submitForm}
        disabled={!isFormValid || isSubmitting}
      />
    </PasswordFormLayout>
  );
};
