import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Table, defaultColumns } from '../components/Table';
import { getOrientRequests } from '../../api';

import { Filters } from '../components/Filters';
import { tabFilterNames } from '../enums';
import { API_RETRY_TIMEOUT } from '../../utils';
import { IOrientationRequest } from '../../common/interfaces';

const momentFormatString = 'ddd - MMM DD';

interface IState {
  selectedShift: any;
  shifts: IOrientationRequest[] | [];
  filterValue: string;
  filteredShifts: IOrientationRequest[] | [];
  loading: boolean;
  error: boolean;
  columns: any;
}

class OrientRequestsTableComponent extends Component<any, IState> {
  constructor(props: any) {
    super(props);

    const [date, patient, id, skill, shift, location, age, sex, weight, primaryDiagnosis] =
      defaultColumns;
    const columns = [
      {
        Header: 'DATE REQUESTED',
        id: 'createdOn',
        accessor: 'createdOn',
        Cell: (props: any) => (
          <React.Fragment>{moment(props.value).format(momentFormatString)}</React.Fragment>
        ),
        sortMethod: (a: string, b: string) => {
          const A_MS = moment(a).format('x');
          const B_MS = moment(b).format('x');
          if (A_MS > B_MS) {
            return 1;
          }
          if (A_MS < B_MS) {
            return -1;
          }
          return 0;
        },
        // OLD DAKOTA VERSION - DO WE NEED ANYTHING FROM THIS?
        // accessor: ({ createdOn }: any) => {
        //   if (createdOn) {
        //     return moment(createdOn).format('ddd - MMM DD');
        //   } else {
        //     return 'null';
        //   }
        // },
      },
      {
        Header: 'NURSE',
        id: 'nurseProfile',
        minWidth: 70,
        accessor: (item: any) => {
          try {
            const {
              nurseProfile: {
                user: { givenName, familyName },
              },
            } = item;
            return `${givenName} ${familyName}`;
          } catch (e) {
            return '';
          }
        },
      },
      patient,
      {
        Header: 'PATIENT ID',
        accessor: 'patient.searchField',
        minWidth: 50,
      },
      { ...location, minWidth: 160 },
    ];

    this.state = {
      selectedShift: null,
      shifts: [],
      loading: true,
      error: false,
      columns,
      filterValue: '',
      filteredShifts: [],
    };
  }

  public componentDidMount = async () => {
    this.getShiftData();
  };

  public retryTimeout: any;

  public componentWillUnmount() {
    clearTimeout(this.retryTimeout);
  }

  public retryGetData = async () => {
    this.retryTimeout = setTimeout(this.getShiftData, API_RETRY_TIMEOUT);
  };

  public getShiftData = async () => {
    const { user } = this.props;

    this.setState({ loading: true });

    try {
      const orientationResponse = await getOrientRequests(user.locationId || 0);
      this.setState({
        shifts: orientationResponse,
        filteredShifts: orientationResponse,
        loading: false,
        error: false,
      });
    } catch (error) {
      this.setState({ error: true });
      this.retryGetData();
    }
  };

  public render() {
    const { error, loading, shifts, columns, filteredShifts } = this.state;
    return (
      <Fragment>
        {
          // filters should work if uncommented
        }
        {/* <Filters
                    filterTab={tabFilterNames.orientRequestsFilters}
                    shifts={this.state.shifts}
                    setFilteredShifts={(filteredShifts: any) => this.setState({ filteredShifts })}
                /> */}

        <Table
          defaultSorted={[{ id: 'createdOn', desc: true }]}
          pageSize={shifts && shifts.length}
          minRows={10}
          showPagination={false}
          data={filteredShifts}
          columns={columns}
          resizable={true}
          loading={loading}
          loadingText={error ? 'Error fetching data. Retrying...' : 'Loading...'}
          noDataText={error ? '' : 'No results found.'}
          sortable={true}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user }: any) => {
  return {
    user: user.user,
  };
};

export const OrientRequestsTable = connect(mapStateToProps)(OrientRequestsTableComponent);
