import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { ROUTES } from '../routing';
import { IUser } from './interfaces';
import { MessageAll } from '../messaging';
import { device } from './device/deviceSizes';
import { interfaces } from './';
import { api, post } from '../api';

import { clearUser as clearUserAction } from '../login';
import { clearLocalUser, getLocalUser } from '../utils';

import Butterfly from '../assets/Aveanna-butterfly.png';
import Name from '../assets/Aveanna-name.png';
import AccountCircle from '../assets/account-circle.png';
import UpChevron from '../assets/chevron-up.png';
import DownChevron from '../assets/chevron-down.png';

import { IdleTimeOutModal } from '../modals/IdleTimeoutModal';

import IdleTimer from 'react-idle-timer';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserRoles } from '../utils/userRoles';
import { MenuItems } from './menuItems';
import {
  clearAuthSettings,
  getOneLoginUrl,
  isCookieAuthEnabled,
} from '../utils/app';

type IProps = {
  id: string;
  user: IUser;
  featureFlags: interfaces.IFeatureFlags;
  hasUnreadMessage: boolean;
  isShiftNotified: boolean;
  clearUser: () => void;
} & RouteComponentProps;

type IState = {
  showLogout: boolean;
  userCanSeeSchedulerRedesign: boolean;
  userCanSeeAdminRedesign: boolean;
  warningTime: number;
  signoutTime: number;
  showModal: boolean;
};

const IDLE_TIME_MINUTES: number = 15;
let idleTimer: IdleTimer | null;
let warnTimeout: NodeJS.Timeout | null;
let logoutTimeout: NodeJS.Timeout | null;
class Header extends Component<IProps, IState> {
  private roles: UserRoles;

  constructor(props: IProps) {
    super(props);

    toast.configure();
    this.roles = new UserRoles(props.user);
    idleTimer = null;
    warnTimeout = null;
    logoutTimeout = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);

    this.state = {
      showLogout: false,
      userCanSeeSchedulerRedesign: false,
      userCanSeeAdminRedesign: false,
      warningTime: 0,
      signoutTime: 1000 * 120,
      showModal: false,
    };
  }

  private onLogOut = async () => {
    const { clearUser, history } = this.props;
    const idToken = getLocalUser();
    const oneLoginUrl = getOneLoginUrl();

    try {
      await post('api/User/Logout');
      if (!isCookieAuthEnabled()) {
        api.jwt(null);
      }
      clearLocalUser();
      clearUser();
      clearAuthSettings();
      if (idToken) {
        const authCodeUrl = `${oneLoginUrl}/oidc/2/logout?post_logout_redirect_uri=${process.env.REACT_APP_ONE_LOGIN_POST_LOGOUT_REDIRECT_URI}&id_token_hint=${idToken.oneloginId}`;
        window.location.assign(authCodeUrl);
      }
    } catch (error) {
      console.log('logout error: ', error);
    }
  };

  private toggleShowLogout = () => {
    this.setState({ showLogout: !this.state.showLogout });
  };

  public handleOnAction(event: any) {
    // Don't register any actions when the inactive modal is showing
    if (this.state.showModal) {
      return;
    }

    this.clearTimeouts();
  }

  public handleOnIdle(event: any) {
    // Setup timers to (a) warn the user of inactivity and (b) log the user out
    warnTimeout = setTimeout(this.warnInactivity, this.state.warningTime);
    logoutTimeout = setTimeout(this.logoutInactivity, this.state.signoutTime);
  }

  // User has decided to continue using the site from the warning modal
  public handleClose() {
    this.setState({ showModal: false });
    this.handleOnAction({});
  }

  // Show modal warning user of inactivity
  public warnInactivity = () => {
    this.setState({ showModal: true });
  };

  // User is showing no activity, log them out
  public logoutInactivity = async () => {
    toast.info('For security reasons, you have been logged out due to inactivity.', {
      position: 'top-right',
      autoClose: false,
      draggable: false,
      closeOnClick: true,
      theme: 'colored',
    });

    await this.onLogOut();
  };

  public clearTimeouts = () => {
    if (warnTimeout) {
      clearTimeout(warnTimeout);
    }
    if (logoutTimeout) {
      clearTimeout(logoutTimeout);
    }
  };

  public render() {
    const { user, featureFlags, hasUnreadMessage, isShiftNotified } = this.props;

    return user ? (
      <Fragment>
        <HeaderContainer>
          <LogoArea>
            <img src={Butterfly} style={{ maxWidth: 'auto', height: '100%' }} />
            <Aveanna src={Name} />
          </LogoArea>
          <NavArea>
            {MenuItems(this.roles, user, featureFlags, hasUnreadMessage, isShiftNotified)}
          </NavArea>
          {this.roles.isScheduler && <MessageAll user={user} />}
          {user && (
            <UserMenu onClick={() => this.toggleShowLogout()}>
              <div
                style={{
                  textAlign: 'right',
                  paddingTop: '5px',
                }}
              >
                <div>{`${user.givenName} ${user.familyName}`}</div>
                <div
                  style={{
                    display: 'block',
                    fontSize: '10px',
                    paddingTop: '1px',
                  }}
                >{`${user.location.description}`}</div>
              </div>
              <img
                src={user.imageUri}
                onError={(event: any) => {
                  event.target.onerror = null;
                  event.target.src = AccountCircle;
                }}
                style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  marginLeft: '10px',
                }}
              />
              <div>
                {this.state.showLogout ? (
                  <Chevron src={UpChevron} />
                ) : (
                  <Chevron src={DownChevron} />
                )}
              </div>
            </UserMenu>
          )}
        </HeaderContainer>
        <IdleTimer
          ref={(ref: any) => {
            idleTimer = ref;
          }}
          timeout={1000 * 60 * IDLE_TIME_MINUTES} // when to mark the user as 'Idle'
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose.bind(this)}
          handleLogout={this.onLogOut.bind(this)}
          remainingTime={(this.state.signoutTime - this.state.warningTime) / 1000}
        />
        {this.state.showLogout && (
          <UserDropdown>
            <a onClick={() => this.onLogOut()}>Log Out</a>
            <Link to={`${ROUTES.PASSWORD}${ROUTES.CHANGE_PASSWORD}`}>Change Password</Link>
          </UserDropdown>
        )}
      </Fragment>
    ) : null;
  }
}

const mapStateToProps = ({ user, messages, notifications }: any) => {
  return {
    user: user.user,
    featureFlags: user.featureFlags,
    hasUnreadMessage: messages.hasUnreadMessage,
    isShiftNotified: Object.values(notifications).includes(true),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearUser: () => dispatch(clearUserAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));

// STYLES
const HeaderContainer: any = styled.div`
  display: flex;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.16);
  height: ${({ theme }) => `${theme.headerHeight}px`};
  align-items: center;
  justify-content: space-between;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

const UserMenu: any = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: gray;
  font-size: ${({ theme }) => theme.fontSizeSm};
`;

const LogoArea: any = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  height: 50px;
  padding: 10px;
`;

const Aveanna: any = styled.img`
  display: none;
  display: block;
  max-width: auto;
  height: 50%;
`;

const NavArea: any = styled.div`
  display: none;

  @media ${device.tablet} {
    display: flex;
    margin-left: 24px;
    margin-right: auto;
  }
`;

const animationToggle = 'normal';

const dropdownAnimation = keyframes`
  0% { opacity: 0; height: 0px; }
  25% { opacity: 0.25; height: 10px; }
  50% { opacity: 0.5; height: 30px; }
  75% { opacity: 0.75; height: 50px; }
  100% { opacity: 1; height: auto; }
`;

const UserDropdown: any = styled.div`
  position: absolute;
  margin: 1px 10px 0 1px;
  padding: 15px;
  z-index: 100;
  right: 0;
  flex-direction: column;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  border-radius: 0 0 3px 3px;
  min-width: 100px;
  animation: ${dropdownAnimation} 0.3s ease;

  a {
    font-size: 14px;
    color: gray;
    text-align: right;
    display: block;
    text-decoration: none;
    padding: 2px 0;
    border-bottom: white 1px solid;
    transition: color 0.3s, border-bottom 0.5s;

    &:visited {
      color: gray;
    }

    &:hover {
      color: darkslategray;
      border-bottom: lightgray 1px solid;
    }
  }
`;

const Chevron: any = styled.img`
  height: 15px;
  padding: 5px;
  cursor: pointer;
`;
