import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './modals.css';

export const IdleTimeOutModal = ({ showModal, handleClose, handleLogout, remainingTime }) => {
  const [seconds, setSeconds] = useState(0);
  const [originalDocumentTitle] = useState(document.title);

  // Set the countdown to the remainingTime value passed in
  const resetSeconds = () => {
    setSeconds(remainingTime);
  };

  useEffect(() => {
    let secondsUpdate;

    // While modal is open and seconds > 0, change the tab title and start a countdown
    if (showModal && seconds > 0) {
      if (document.title === originalDocumentTitle) {
        document.title = '[Inactivity Warning] ' + originalDocumentTitle;
      }

      secondsUpdate = setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    return function cleanup() {
      // Reset timer and stop the countdown
      document.title = originalDocumentTitle;
      clearTimeout(secondsUpdate);
    };
  });

  return (
    <Modal show={showModal} onShow={resetSeconds} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Session timeout warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Due to inactivity, you will be automatically logged out in <b>{seconds} second(s)</b>.
        </p>

        <p>
          If you wish to logout, click 'Logout'. Otherwise, click 'Stay' to continue using this
          site.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleLogout}>
          Logout
        </Button>
        <Button variant="outline-dark" onClick={handleClose}>
          Stay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
