import React, { Component, ReactNode } from 'react';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';

// @ts-ignore - no types provided
import { withToastManager } from 'react-toast-notifications';
import { getErrorMessage } from '../../common/useToastNotifications';

import { StyledButton, interfaces, theme } from '../../common';
import { userTypeByID, ToastAppearance } from '../../utils';
import {
  getOnboarding,
  getUsersByLocation,
  updateUser,
  getAllLocations,
} from '../../../src/api/admin';
import { LocationManager } from './LocationManager';
import { setLocations as setLocationsAction } from '../actions';
import { IDropdownOption } from '../../common/interfaces';

const TOAST_OPTION_DEFAULTS = {
  pauseOnHover: true,
  autoDismiss: true,
  autoDismissTimeout: 10000,
};

const OnboardingContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 12px;
  flex-direction: column;
  background-color: white;
  height: calc(100% - 24px);
`;

const UserListItem = styled.div`
  margin-left: 6px;
`;

type IProps = {
  id: string;
  user: interfaces.IUser;
  locations: interfaces.ILocation[];
  hasUnreadMessage: boolean;
  isShiftNotified: boolean;
  clearUser: () => void;
  setLocations: (locations: interfaces.ILocation[]) => void;
  toastManager: any;
} & RouteComponentProps;

type IState = {
  officeLocations: any[];
  userTypes: any;
  users: any[];
  form: {
    selectedLocation: interfaces.IDropdownOption | null;
    selectedUserType: interfaces.IDropdownOption | null;
    selectedUsers: Array<interfaces.IDropdownOption<interfaces.IUser>>;
  };
  isLoading: boolean;
  error: string | null;
};

enum IFormField {
  selectedLocation = 'selectedLocation',
  selectedUserType = 'selectedUserType',
  selectedUsers = 'selectedUsers',
}

type TypeFormField =
  | IFormField.selectedLocation
  | IFormField.selectedUserType
  | IFormField.selectedUsers;

class Onboarding extends Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
  }

  public state: IState = {
    officeLocations: [],
    userTypes: {},
    users: [],
    form: {
      selectedLocation: null,
      selectedUserType: null,
      selectedUsers: [],
    },
    error: null,
    isLoading: false,
  };

  public async componentDidMount() {
    this.getOnboardingOptions();
    await this.getLocationData();
  }

  private async getOnboardingOptions() {
    try {
      const onboardingOptions = await getOnboarding(1);
      this.setState({
        officeLocations: onboardingOptions.officeLocations,
        userTypes: onboardingOptions.userTypes,
      });
    } catch (error) {
      console.error(error);
    }
  }

  private getLocationData = async () => {
    const { setLocations } = this.props;

    try {
      const tempLocations: interfaces.ILocation[] = await getAllLocations();
      setLocations(tempLocations);
    } catch (error) {
      console.error(error);
      this.setState({ error: getErrorMessage(error) });
    }
  };

  private async getUsers(locationId: number) {
    try {
      const users = await getUsersByLocation(locationId);
      this.setState({ users });
    } catch (error) {
      console.error(error);
    }
  }

  private updateUsers = async () => {
    const {
      form: { selectedUsers, selectedUserType },
    } = this.state;
    const { toastManager } = this.props;
    this.setState({ isLoading: true });

    try {
      const allUserUpdateRequests = selectedUsers.map(
        async (tempUser: IDropdownOption<interfaces.IUser>) => {
          if (selectedUserType) {
            await updateUser(tempUser.value as interfaces.IUser, selectedUserType.value as number);
          } else {
            throw new Error('No UserType Selected');
          }
        }
      );

      await Promise.all(allUserUpdateRequests);
      this.setState({
        isLoading: false,
        form: { selectedLocation: null, selectedUserType: null, selectedUsers: [] },
      });
      toastManager.add('User Successfully Updated', {
        appearance: ToastAppearance.SUCCESS,
        ...TOAST_OPTION_DEFAULTS,
      });
    } catch (error) {
      console.error(error);
      toastManager.add(getErrorMessage(error), {
        appearance: ToastAppearance.ERROR,
        ...TOAST_OPTION_DEFAULTS,
      });
      this.setState({ isLoading: false });
    }
  };

  private async select(field: TypeFormField, selectedOptions: any) {
    if (IFormField.selectedLocation === field) {
      this.setState({
        ...this.state,
        form: { ...this.state.form, [field]: selectedOptions, [IFormField.selectedUsers]: [] },
      });
      await this.getUsers(selectedOptions.value.id);
    } else {
      this.setState({
        ...this.state,
        form: { ...this.state.form, [field]: selectedOptions },
      });
    }
  }

  private createLocationSelections = (
    locations: interfaces.ILocation[]
  ): Array<interfaces.IDropdownOption<interfaces.ILocation>> => {
    return locations.map(
      (tempLocation: interfaces.ILocation): interfaces.IDropdownOption<interfaces.ILocation> => {
        return {
          // tslint:disable-next-line: max-line-length
          label: `${tempLocation.description} ${tempLocation.id} ${tempLocation.externalId} ${tempLocation.externalSource}`,
          value: tempLocation,
        };
      }
    );
  };

  private formatLocationDropDownLabel = (location: interfaces.ILocation): ReactNode => (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {/* active indicator */}
      <div
        style={{
          alignSelf: 'center',
          minWidth: 12,
          minHeight: 12,
          backgroundColor: location.isActive
            ? theme.colors.footerButtonGreen
            : theme.colors.footerButtonRed,
          borderRadius: '50%',
        }}
      />
      <div style={{ paddingLeft: 4, paddingRight: 4 }}>{`[${location.id}]`}</div>
      <div>
        <b style={{ paddingRight: 4 }}>{location.description}</b>
        {`(${location.externalSource} - ${location.externalId})`}
      </div>
    </div>
  );

  private createUserTypeSelections = (options: any[]): any[] => {
    return Object.entries(options).map(([key, value]) => {
      return {
        label: (
          <div key={key + value}>
            <span>{`${key} (${value.toString()})`}</span>
          </div>
        ),
        value,
      };
    });
  };

  private createUserSelections = (users: interfaces.IUser[]): any[] => {
    return users
      ? users.map((tempUser: interfaces.IUser, i: number) => {
          return {
            label: `${tempUser.id} ${tempUser.givenName} ${tempUser.familyName}`,
            value: tempUser,
          };
        })
      : [];
  };

  private getUserFullName(user: interfaces.IUser) {
    return `${user.givenName || ''} ${(user.middleName && user.middleName) || ''} ${
      user.familyName || ''
    }`;
  }

  private isUserOnboardingValid = () => {
    const { form } = this.state;

    if (!form.selectedLocation || !form.selectedUserType || form.selectedUsers.length <= 0) {
      return false;
    }

    return true;
  };

  private getOptionLabelFromUsers = (user: interfaces.IUser): string => {
    return `${user.id} ${this.getUserFullName(user)} ${user.workdayId} ${user.userName}`;
  };

  private formatUserOptionLabel = ({ label, value }: IDropdownOption<interfaces.IUser>) => {
    const tempUser = value as interfaces.IUser;
    const tempFullName = this.getUserFullName(tempUser);
    return (
      <div
        key={tempUser.id + tempFullName}
        style={{ height: '2em', display: 'flex', alignItems: 'center' }}
      >
        <span style={{ display: 'inline-flex' }}>
          <img src={tempUser.imageUri} alt="" style={{ flex: 1 }} width="40" height="40" />
        </span>
        <span style={{ display: 'inline-flex' }}>
          <UserListItem>{`[${userTypeByID[tempUser.userType]}] ${tempFullName} (${
            tempUser.userName
          })`}</UserListItem>
          <UserListItem>
            <label style={{ fontWeight: 'bold' }}> WorkDay ID: </label>
            <span> {tempUser.workdayId} </span>
          </UserListItem>
          <UserListItem>
            <label style={{ fontWeight: 'bold' }}>ID: </label>
            <span> {tempUser.id} </span>
          </UserListItem>
        </span>
      </div>
    );
  };

  public render() {
    const { officeLocations, userTypes = [], form, users, isLoading } = this.state;
    const officeSelections: interfaces.IDropdownOption[] =
      this.createLocationSelections(officeLocations);
    const userTypeSelections = this.createUserTypeSelections(userTypes);
    const userSelections = this.createUserSelections(users);
    const tempSelectStyles = {
      container: (styles: any) => ({ ...styles, flex: 1, height: '80%' }),
    };
    const SelectStylesDiv = {
      container: (styles: any) => ({ ...styles, flex: 1, height: '80%' }),
    };

    return (
      <OnboardingContainer>
        <LocationManager />
        <p>User Setup</p>
        <i>This form is for onboarding users to a new roll or application.</i>
        <i>It will do the following.</i>
        <i>
          It is highly recommended that you screen capture your work after updates and keep as an
          audit trail until it can be automated.
        </i>
        <ol>
          <li>Activates users.</li>
          <li>Require passwords to be changed/set before next login attempt.</li>
          <li>Set the user types.</li>
        </ol>
        <ul>
          <li>
            <b>NOTE:</b>
            If users are missing, it is because they have not yet been imported by the scheduled
            background process (Harvester) to our system. (See location activation form for more
            info)
          </li>
        </ul>
        <ol>
          <li>
            Make sure the location has been activated. After activation it may take up to 4 to 8
            hours to import data (users etc.).
          </li>
          <li>
            Check with devops or integrations for the current schedule. It may be changed without
            notice.
          </li>
        </ol>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 6 }}>
          <Select
            styles={tempSelectStyles}
            options={officeSelections}
            onChange={(tempLocationOption: any) =>
              this.select(IFormField.selectedLocation, tempLocationOption)
            }
            value={form.selectedLocation}
            isDisabled={false}
            placeholder="Search/Select Location"
            formatOptionLabel={({ value }: IDropdownOption) =>
              this.formatLocationDropDownLabel(value as interfaces.ILocation)
            }
          />
          <Select
            styles={tempSelectStyles}
            options={userTypeSelections}
            value={form.selectedUserType}
            onChange={(tempUserType: any) => this.select(IFormField.selectedUserType, tempUserType)}
            isDisabled={false}
            placeholder="Search/Select Usertype"
          />
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginBottom: 6,
          }}
        >
          <Select
            styles={SelectStylesDiv}
            options={userSelections}
            value={form.selectedUsers}
            getOptionLabel={(option: IDropdownOption<interfaces.IUser>) =>
              this.getOptionLabelFromUsers(option.value as interfaces.IUser)
            }
            formatOptionLabel={this.formatUserOptionLabel}
            onChange={(tempUser: any) => this.select(IFormField.selectedUsers, tempUser)}
            isDisabled={!form.selectedLocation}
            isMulti={true}
            placeholder={'Search/Select Users'}
            // menuIsOpen={true  /* TODO!: comment this in/out for testing */}
          />
        </div>
        <StyledButton
          disabled={!this.isUserOnboardingValid()}
          isLoading={isLoading}
          onClick={this.updateUsers}
          style={{ alignSelf: 'flex-end' }}
        >
          Apply Changes
        </StyledButton>
      </OnboardingContainer>
    );
  }
}

const mapStateToProps = ({ user, messages, notifications, admin }: any) => {
  return {
    user: user.user,
    hasUnreadMessage: messages.hasUnreadMessage,
    isShiftNotified: Object.values(notifications).includes(true),
    locations: admin.locations,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLocations: (locations: interfaces.ILocation[]) => {
      dispatch(setLocationsAction(locations));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(withRouter(Onboarding)));
