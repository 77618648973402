import styled, { css } from 'styled-components';

import { theme as aveannaTheme } from '../../common';
import { device } from '../../common';

export const StyledPasswordForm = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;

  /* above mobile L */
  @media ${device.mobileL} {
    position: relative;
    width: 400px;
    height: 250px;
    padding: 48px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 1px 1px 2px 1px rgba(33, 33, 33, 0.1);
  }
`;

interface IChipProps {
  isSatisfied?: boolean;
  color?: string;
}

export const StyledChipContainer = styled.div<IChipProps>`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  padding: 4px;

  background-color: ${aveannaTheme.colors.tooltipNeutralBg};
  border-color: ${aveannaTheme.colors.tooltipNeutralBorder};

  background-color: ${aveannaTheme.colors.tooltipInfoBg};
  border-color: ${aveannaTheme.colors.tooltipInfoBorder};

  border-left-width: 3px;
  border-left-style: solid;

  ${(props) =>
    props.isSatisfied &&
    css`
      background-color: ${aveannaTheme.colors.tooltipSuccessBg};
      border-color: ${aveannaTheme.colors.tooltipSuccessText};
    `}

  ${(props) =>
    props.isSatisfied === false &&
    css`
      background-color: ${aveannaTheme.colors.tooltipErrorBg};
      border-color: ${aveannaTheme.colors.tooltipErrorText};
    `} /* PILL DESIGN - Uncomment to see and update the border radius*/
  /* background-color: ${aveannaTheme.colors.tooltipNeutralBg};
  border-color: ${aveannaTheme.colors.tooltipNeutralBorder};
  border-radius: 24px;
  border-width: 2px;
  border-style: solid;

  ${(props) =>
    props.isSatisfied &&
    css`
      background-color: ${aveannaTheme.colors.tooltipSuccessBg};
      border-color: ${aveannaTheme.colors.tooltipSuccessBorder};
    `}

  ${(props) =>
    props.isSatisfied === false &&
    css`
      background-color: ${aveannaTheme.colors.tooltipErrorBg};
      border-color: ${aveannaTheme.colors.tooltipErrorBorder};
    `} */
`;

export const StyledChipIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledChipText = styled.p<IChipProps>`
  font-size: ${aveannaTheme.fontSizeSm};
  margin: 0px;
  padding: 0px 12px;
  color: ${({ color }) => color};

  ${(props) =>
    props.isSatisfied &&
    css`
      color: ${aveannaTheme.colors.tooltipSuccessText};
    `}

  ${(props) =>
    props.isSatisfied === false &&
    css`
      color: ${aveannaTheme.colors.tooltipErrorText};
    `}
`;
