import React, { Component } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import {
  getMessages,
  setActiveChannel as setActiveChannelAction,
  clearActiveChannel as clearActiveChannelAction,
  addNewChannel as addNewChannelAction,
} from '../actions';

import Close from '../../assets/close.png';
import LeftArrow from '../../assets/left-arrow.png';

import { getUsersByOfficeId } from '../../api/user';
import { createGroupMessage } from '../../api/messages';
import { ModalWrapper, Loading } from '../../common';
import { UserListItem, NurseListItemControlType } from '../../Nurses';

import {
  ContentWrapper,
  TitleWrapper,
  Title,
  ListItemWrapper,
  MobileCloseModalButton,
  CloseModalButton,
  FooterWrapper,
  ToastWrapper,
  FooterButton,
  SubHeader,
  SubHeaderText,
} from '../../Shifts/components/ModalBody';

enum ToastText {
  success = 'New Group Chat Created',
  failed = 'Something Went Wrong',
}

class NewChannelModal extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedUsersIds: [],
      users: [],
      showToast: false,
      toastText: ToastText.success,
      channelName: '',
    };
  }

  public componentWillMount = async () => {
    const res = await getUsersByOfficeId(this.props.user.locationId);
    if (res.status === 200) {
      const withoutCurrentUser = res.body.filter((user: any) => user.id !== this.props.user.id);
      this.setState({ users: withoutCurrentUser });
    }
  };

  public toggleSelection = (id: number) => {
    if (this.state.selectedUsersIds.includes(id)) {
      this.removeSelectedUser(id);
      return;
    }
    this.addSelectedUser(id);
  };

  public addSelectedUser = (id: number) => {
    this.setState((prevState: any) => {
      prevState.selectedUsersIds.push(id);
      return prevState;
    });
  };

  public removeSelectedUser = (id: number) => {
    this.setState((prevState: any) => {
      return {
        ...prevState,
        selectedUsersIds: prevState.selectedUsersIds.filter((val: number) => val !== id),
      };
    });
  };

  public renderUserList = () => {
    if (this.state.users.length > 0) {
      return this.state.users.map((user: any) => {
        const checked = this.state.selectedUsersIds.includes(user.id) ? true : false;
        return (
          <UserListItem
            key={user.id}
            user={user}
            controlType={NurseListItemControlType.CHECKBOX}
            checked={checked}
            handleClick={() => this.toggleSelection(user.id)}
          />
        );
      });
    } else {
      return <Loading />;
    }
  };

  public renderAdjustAwardedShift = () => {
    if (!this.state.showToast && this.state.channelName && this.state.selectedUsersIds.length > 0) {
      return (
        <FooterWrapper>
          <FooterButton onClick={() => this.handleCreateGroupMessage()}>
            Create Group Message
          </FooterButton>
        </FooterWrapper>
      );
    }
  };

  public renderToast = () => {
    if (this.state.showToast) {
      return (
        <ToastWrapper failed={this.state.toastText === ToastText.failed}>
          {this.state.toastText}
        </ToastWrapper>
      );
    }
  };

  public handleCreateGroupMessage = async () => {
    const { selectedUsersIds, channelName } = this.state;
    const withCurrentUser = [this.props.user.id, ...selectedUsersIds];
    const res = await createGroupMessage({
      nurseTitle: channelName,
      schedulerTitle: channelName,
      userIds: withCurrentUser as [number],
    });
    if (res.status < 300) {
      this.props.addNewChannel(res.body);
      this.props.clearActiveChannel();
      this.props.setActiveChannel(res.body.id);
      this.setState({ showToast: true, toastText: ToastText.success });
      setTimeout(() => {
        this.props.closeModal(true);
      }, 1500);
    } else {
      this.setState({ showToast: true, toastText: ToastText.failed });
      setTimeout(() => this.props.closeModal(true), 1500);
    }
  };

  public render() {
    return (
      <ModalWrapper closeModal={this.props.closeModal}>
        <ContentWrapper>
          <TitleWrapper>
            <MobileCloseModalButton onClick={() => this.props.closeModal()}>
              <img src={LeftArrow} />
            </MobileCloseModalButton>
            <Title>Create New Group Message</Title>
            <CloseModalButton onClick={() => this.props.closeModal()}>
              <img src={Close} />
            </CloseModalButton>
          </TitleWrapper>
          <label style={{ color: 'gray', marginLeft: '5px' }}>Group Name</label>
          <input
            style={{
              height: '30px',
              borderRadius: '6px',
              borderWidth: '2px',
              borderColor: 'lightgray',
              borderStyle: 'solid',
              margin: '8px',
              padding: '8px',
              fontSize: '20px',
            }}
            value={this.state.channelName}
            onChange={({ target: { value } }) => this.setState({ channelName: value })}
          />
          <ListItemWrapper>
            <SubHeader>
              <SubHeaderText>Select Participants</SubHeaderText>
            </SubHeader>
            {this.renderUserList()}
          </ListItemWrapper>
        </ContentWrapper>
        {this.renderAdjustAwardedShift()}
        {this.renderToast()}
      </ModalWrapper>
    );
  }
}

const mapStateToProps = ({ messages, user }: any) => {
  return {
    channels: messages.channels,
    user: user.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      getMessages,
      setActiveChannel: setActiveChannelAction,
      clearActiveChannel: clearActiveChannelAction,
      addNewChannel: addNewChannelAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewChannelModal);
