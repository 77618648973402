import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';

import { device } from './device/deviceSizes';

export const slideUp = keyframes`
  0% {
    transform: translateY(15px);
    opacity: 0;
  }

 75% {
  opacity: 1;
 }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const StyledModalBody = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 7px 0 #555555;
  background-color: white;
  border-top: 1px solid #ddd;
  overflow: auto;

  width: 100vw;
  height: calc(100vh - 76px);

  *:first-child {
    max-width: 100%;
    max-height: 100%;
  }

  animation-name: ${slideUp};
  animation-direction: forward;
  animation-duration: 300ms;
  animation-timing-function: ease-out;

  @media ${device.mobileL} {
    max-width: 90vw;
    max-height: 70vh;
    border-radius: 6px;
    border: none;
    /* padding: 15px 15px 0 15px; */
  }

  @media ${device.tablet} {
    max-width: 60vw;
    max-height: 70vh;
  }

  @media ${device.laptop} {
    max-width: 50vw;
    max-height: 70vh;
  }
`;

const StyledModalBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: ${({ theme }) => `${theme.headerHeight}px`};
  left: 0;
  bottom: 0px;
  width: 100vw;
  overflow: hidden;
  z-index: 1000;

  /* above mobile L */
  @media ${device.mobileL} {
    top: 0px;
    height: 100vh;
  }
`;

// single element required to use child selector in css and limit size from exceeding modal
// modal can grow to 85% of vw/vh
export class ModalWrapper extends Component<any, any> {
  public componentWillMount() {
    // prevent background scrolling when modal is open
    document.body.style.overflow = 'hidden';
  }
  public componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }
  public render() {
    const { children, closeModal } = this.props;
    return (
      <StyledModalBackground onClick={() => closeModal()}>
        <StyledModalBody
          id="modal"
          // stop clicks on modal body from propogating and firing closeModal()
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </StyledModalBody>
      </StyledModalBackground>
    );
  }
}
