import { localStorageKeys } from './constants';

export const storeLocalNotifications = (notifications: any) => {
  if (checkIsBrowser() && notifications) {
    localStorage.setItem(localStorageKeys.NOTIFICATIONS, JSON.stringify(notifications));
  }
};

export const getLocalNotifications = () => {
  if (checkIsBrowser()) {
    const tempNotifications = localStorage.getItem(localStorageKeys.NOTIFICATIONS);
    if (tempNotifications) {
      return JSON.parse(tempNotifications);
    }
  }

  return null;
};

const checkIsBrowser = () => {
  if (typeof Storage !== 'undefined') {
    return true;
  } else {
    console.log('could not use local storage — ');
    return false;
  }
};
