import MaterialIcon from 'material-icons-react';
import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getTotalHours, compareDateOffSets, getTimeStampOffset } from '../../utils/shifts';
import { nullcheck } from '../../utils';
import styles from './ShiftDetails.module.css';
import {
  setCurrentEditedShift as setCurrentEditedShiftAction,
  revertShiftEdits as revertShiftEditsAction,
  setCurrentEditedDVR as setCurrentEditedDVRAction,
} from '../actions';
import { interfaces } from '../../common';
import { ShiftDetailsFooter } from './ShiftDetailsFooter';
import { Activities } from './ShiftDetailActivities';
import { ShiftStatusView } from './ShiftStatusView';
import { ShiftPanelBody, ShiftPanelHeading } from '../ShiftPanel/ShiftSidebar';
import ShiftDetailsHeader from './ShiftDetailsHeader';
import { RejectionReasonDropDown } from './ShiftRejectionReasonDropdown';
import { ShiftMap } from '../ShiftPanel/ShiftMap';
import { DVRFormContainer } from './DVRForm';
import { IGenericShift } from '../../Patients/PatientInterfaces';
import { formatDateString } from '../../utils/date';
import { OPT_OUT_VERBIAGE } from '../../utils/constants';

const haversine = require('haversine');

const SECTION_SPACER_HEIGHT = 24;

const ClockInNote = ({ title, note }: { title: string; note?: string }) => {
  const tempNote = note ? note : `None`;
  return (
    <div style={{ marginBottom: 24 }}>
      <ShiftPanelHeading>{title}</ShiftPanelHeading>
      <div className={`${styles.SP_Body} ${styles.ClockNotes}`}>
        <div>{tempNote}</div>
      </div>
    </div>
  );
};

const ShiftSignatures = (props: any) => {
  const { clockInOut } = props.dvr;
  return (
    <div style={{ marginBottom: SECTION_SPACER_HEIGHT }}>
      <div className={styles.SS_Signature}>
        <div className={styles.SS_Signature__cell}>Caregiver Signature</div>
        <div className={styles.SS_Signature__cell}>{GetNurseSignature(clockInOut)}</div>
        {!clockInOut.isNurseClockOutSignatureOptOut && (
          <div className={styles.SS_Signature__bumper}></div>
        )}
      </div>

      <div className={styles.SS_Signature}>
        <div className={styles.SS_Signature__cell}>Patient Signature</div>
        <div className={styles.SS_Signature__cell}>{GetPatientSignature(clockInOut)}</div>
        {!clockInOut.isPatientClockOutSignatureOptOut && (
          <div className={styles.SS_Signature__bumper}></div>
        )}
      </div>
    </div>
  );
};

const GetNurseSignature = ({
  isNurseClockOutSignatureOptOut,
  nurseSignature,
}: {
  isNurseClockOutSignatureOptOut: boolean;
  nurseSignature: string;
}) => (
  <>
    {isNurseClockOutSignatureOptOut ? (
      <p>{OPT_OUT_VERBIAGE}</p>
    ) : (
      <img alt='nurse signature' className={styles.SS_Signature__image} src={nurseSignature}></img>
    )}
  </>
);

const GetPatientSignature = ({
  isPatientClockOutSignatureOptOut,
  patientClockOutSignatureExists,
  patientSignature,
}: {
  isPatientClockOutSignatureOptOut: boolean;
  patientClockOutSignatureExists: boolean;
  patientSignature: string;
}) => {
  if (isPatientClockOutSignatureOptOut) {
    return <p>{OPT_OUT_VERBIAGE}</p>;
  }
  return patientClockOutSignatureExists ? (
    <img
      alt='patient signature'
      className={styles.SS_Signature__image}
      src={patientSignature}
    ></img>
  ) : (
    <div className={styles.iconTextRow}>
      <MaterialIcon icon="check" color="Green" />
      <div className={styles.smallItalicIconText}>Patient is unable to sign.</div>
    </div>
  );
};

const Alerts = (props: any) => {
  const shift = props.dvr;
  const timeAlert = nullcheck(shift, ['clockInOut', 'timeAlert']);
  const locationAlerts = nullcheck(shift, ['clockInOut', 'locationAlerts']);
  const overlapAlerts = nullcheck(shift, ['clockInOut', 'overlapAlerts']);
  const officeAlerts = nullcheck(shift, ['clockInOut', 'officeAlerts']);

  return (
    <div className={styles.Alerts}>
      {timeAlert && (
        <div className={styles.Alerts_row}>
          <MaterialIcon icon="schedule" color="maroon" />
          <div className={styles.iconText}>{timeAlert}</div>
        </div>
      )}

      {locationAlerts &&
        Object.values(locationAlerts).map((locationAlert: any, i: number) => {
          if (locationAlert) {
            return (
              <div className={styles.Alerts_row} key={i}>
                <MaterialIcon icon="location_on" color="maroon" />
                <div className={styles.iconText}>{locationAlert}</div>
              </div>
            );
          }
        })}

      {overlapAlerts &&
        overlapAlerts.map((overlapAlert: any, i: number) => {
          return (
            <div className={styles.Alerts_row} key={i}>
              <MaterialIcon icon="settings_overscan" color="maroon" />
              <div className={styles.iconText}>{overlapAlert}</div>
            </div>
          );
        })}

      {officeAlerts &&
        officeAlerts.map((officeAlert: any, i: number) => {
          return (
            <div className={styles.Alerts_row} key={i}>
              <MaterialIcon icon="location_city" color="maroon" />
              <div className={styles.iconText}>{officeAlert}</div>
            </div>
          );
        })}
    </div>
  );
};

const renderCustomDatePickerInput = ({
  value,
  disabled = false,
}: {
  value: any;
  disabled: boolean;
}) => {
  return (
    <div className={`${styles.datePicker} ${disabled && styles.datePicker__disabled}`}>
      <div className={styles.GeneralInformation_clockInOutCell}>
        {moment(value).format('MM/DD/YYYY')}
      </div>
      <div className={styles.GeneralInformation_clockInOutCell}>
        {moment(value).format('hh:mm A')}
      </div>
    </div>
  );
};

const GeneralInformation = ({
  editController,
  shift,
  dvr,
  isEditMode,
  isNewMode,
  locationInCorrectionOptions,
  locationOutCorrectionOptions,
  timeInCorrectionOptions,
  timeOutCorrectionOptions,
  currentEditedShift,
  setCurrentEditedShift,
  allReasonCodesOptions,
}: any) => {
  const { clockInOut: editedClockInOut } = currentEditedShift;
  const editedAdjustedCOTime = editedClockInOut.adjustedClockOutTimeStamp;
  const editedAdjustedCITime = editedClockInOut.adjustedClockInTimeStamp;

  const tempAdjustedClockInTime =
    editedClockInOut && editedAdjustedCITime
      ? moment(editedAdjustedCITime)
      : moment(editedAdjustedCOTime);

  const tempAdjustedClockOutTime =
    editedClockInOut && editedAdjustedCOTime
      ? moment(editedAdjustedCOTime)
      : moment(editedAdjustedCITime);

  // Time data
  const clockInDateTime = nullcheck(dvr, ['clockInOut', 'adjustedClockInTimeStamp']);
  const timeInTimezone = nullcheck(dvr, ['clockInOut', 'clockInOutTimezone', 'clockInTimezone']);

  const clockOutDateTime = nullcheck(dvr, ['clockInOut', 'adjustedClockOutTimeStamp']);
  const timeOutTimezone = nullcheck(dvr, ['clockInOut', 'clockInOutTimezone', 'clockOutTimezone']);

  const totalHours: number = getTotalHours(clockOutDateTime, clockInDateTime);
  const totalEditedHours: number = getTotalHours(editedAdjustedCOTime, editedAdjustedCITime);

  // Identity data
  const aideFirstName = nullcheck(dvr, ['careGiver', 'user', 'givenName']);
  const aideLastName = nullcheck(dvr, ['careGiver', 'user', 'familyName']);
  const aideName = `${aideFirstName} ${aideLastName}`;
  const patientName = nullcheck(dvr, ['patient', 'name']);

  const clockinOffsetTimeStampFormatted = getFormattedOffSetTimeStampFormatted(clockInDateTime);

  const clockoutOffsetTimeStampFormatted = getFormattedOffSetTimeStampFormatted(clockOutDateTime);

  const editedClockInOffsetTimeStampFormatted = `${moment(editedAdjustedCITime)}`;

  const editedClockoutOffsetTimeStampFormatted = `${moment(editedAdjustedCOTime)}`;

  const isTimeZoneOffsetMatch: boolean = compareDateOffSets(
    editedAdjustedCITime,
    editedAdjustedCOTime
  );

  const timeZoneMismatchErrorMessage = (
    <div>
      {`Time Zones correct?`}
      <div>
        <button
          onClick={() => {
            console.log('clicked attempt correct time zones.');
            updateClockInOut({
              adjustedClockInTimeStamp: moment(tempAdjustedClockInTime).toDate(),
              adjustedClockOutTimeStamp: moment(tempAdjustedClockOutTime).toDate(),
            });
          }}
          className={styles.linkButton}
          style={{
            display: 'flex',
            position: 'relative',
            textDecoration: 'none',
            border: '1px solid #0075c9',
            borderRadius: 5,
            outline: 'none',
            minWidth: 200,
            minHeight: 35,
          }}
        >
          <span>Sync Time Zones</span>
          <div style={{ position: 'absolute', right: 4, top: 5, margin: 0 }}>
            <MaterialIcon icon={'access_time'} key={'access_time'} color="#0075c9" />
          </div>
        </button>
      </div>
    </div>
  );
  const warnEditHours: boolean = Number(totalEditedHours) < 3 || Number(totalEditedHours) > 13;
  const warnHours: boolean = Number(totalHours) < 3 || Number(totalHours) > 13;

  const clockInDate = clockInDateTime ? moment(clockInDateTime).format('MM-DD-YYYY') : '?';
  const clockInTime = formatDateString(clockInDateTime, timeInTimezone);

  const clockOutDate = clockOutDateTime ? moment(clockOutDateTime).format('MM-DD-YYYY') : '?';
  const clockOutTime = formatDateString(clockOutDateTime, timeOutTimezone);

  const clockInCorrection = nullcheck(dvr, ['clockInOut', 'clockInTimeCorrection', 'text']);
  const clockOutCorrection = nullcheck(dvr, ['clockInOut', 'clockOutTimeCorrection', 'text']);

  // Location data
  const locationAideInLat = nullcheck(dvr, ['clockInOut', 'clockInLatitude']);
  const locationAideInLon = nullcheck(dvr, ['clockInOut', 'clockInLongitude']);
  const locationAideOutLat = nullcheck(dvr, ['clockInOut', 'clockOutLatitude']);
  const locationAideOutLon = nullcheck(dvr, ['clockInOut', 'clockOutLongitude']);
  const locationPatientLat = nullcheck(dvr, ['patient', 'location', 'latitude']);
  const locationPatientLon = nullcheck(dvr, ['patient', 'location', 'longitude']);
  const gpsInDistance =
    locationAideInLat && locationAideInLon && locationPatientLat && locationPatientLon
      ? calculateDistance(
          locationAideInLat,
          locationAideInLon,
          locationPatientLat,
          locationPatientLon
        )
      : '?';
  const gpsOutDistance =
    locationAideInLat && locationAideInLon && locationAideOutLat && locationAideOutLon
      ? calculateDistance(
          locationAideInLat,
          locationAideInLon,
          locationAideOutLat,
          locationAideOutLon
        )
      : '?';

  const gpsInCorrection = nullcheck(dvr, ['clockInOut', 'clockInLocationCorrection', 'text']);
  const gpsOutCorrection = nullcheck(dvr, ['clockInOut', 'clockOutLocationCorrection', 'text']);

  // Hooks
  const [isOpenMap, setIsOpenMap] = useState(false);

  const updateClockInOut = (updatedValue: object) => {
    setCurrentEditedShift({
      ...currentEditedShift,
      clockInOut: {
        ...currentEditedShift.clockInOut,
        ...updatedValue,
      },
    });
  };

  const getReason = (id: number | string) => {
    const tempReason = allReasonCodesOptions.find(
      (tempReasonCodeOption: interfaces.IDropdownOption) => tempReasonCodeOption.value === id
    );
    return tempReason ? tempReason : defaultDropDownOption;
  };

  return (
    <div className={styles.GeneralInformation}>
      {/* Aide */}
      <div className={styles.GeneralInformation_row}>
        <div className={styles.GeneralInformation_row__label}>Caregiver</div>
        <div className={styles.GeneralInformation_row__content}>{aideName}</div>
        <div className={styles.GeneralInformation_row__actions}></div>
      </div>

      {/* Patient */}
      <div className={styles.GeneralInformation_row}>
        <div className={styles.GeneralInformation_row__label}>Patient</div>
        <div className={styles.GeneralInformation_row__content}>{patientName}</div>
        <div className={styles.GeneralInformation_row__actions}></div>
      </div>

      {shift && shift.officeLocation && (
        <div className={styles.GeneralInformation_row}>
          <div className={styles.GeneralInformation_row__label}>Office Location</div>
          <div className={styles.GeneralInformation_row__content}>
            {`${shift.officeLocation.description} (${shift.officeLocation.id})`}
          </div>
          <div className={styles.GeneralInformation_row__actions}></div>
        </div>
      )}

      <div style={{ height: SECTION_SPACER_HEIGHT }} />

      <TotalHoursComponent
        warnEditHours={warnEditHours}
        isEditMode={isEditMode}
        totalEditedHours={totalEditedHours}
        totalHours={totalHours}
      />
      <div style={{ height: SECTION_SPACER_HEIGHT }} />

      {/* Clock In */}
      {/* Clock In Correction */}
      <div className={styles.GeneralInformation_row}>
        <div className={styles.GeneralInformation_row__label}>{isEditMode && `Clock in`}</div>
        <div
          data-cy={'shift-details-ci-edit-reason-dropdown'}
          className={styles.GeneralInformation_row__stretch}
        >
          {isEditMode && !isNewMode ? (
            <RejectionReasonDropDown
              setRejectionReason={(reason: any) => {
                updateClockInOut({ clockInTimeCorrectionId: reason.value });
              }}
              rejectionReasonOptions={timeInCorrectionOptions}
              reason={getReason(editedClockInOut.clockInTimeCorrectionId)}
              isMultiselect={false}
            />
          ) : (
            <>{clockInCorrection}</>
          )}
        </div>
      </div>
      {/* Clock In Time Picker */}
      <div>
        {isEditMode ? (
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>{!isEditMode && ``}</div>
            <div className={styles.GeneralInformation_row__content3} style={{ fontSize: '.9em' }}>
              <div>{editedClockInOffsetTimeStampFormatted}</div>
            </div>
          </div>
        ) : (
          ''
        )}

        <div className={styles.GeneralInformation_row}>
          <div className={styles.GeneralInformation_row__label}>{!isEditMode && `Clock in`}</div>
          <div className={styles.GeneralInformation_row__content}>
            {isEditMode ? (
              <>
                <DatePicker
                  disabled={!editedClockInOut.clockInTimeCorrectionId}
                  selected={moment(tempAdjustedClockInTime).toDate()}
                  onChange={(date: Date) => {
                    const isValid: boolean = isValidClockedTime(
                      tempAdjustedClockOutTime.toDate(),
                      date
                    );
                    console.log('setIsValidDate isValid', isValid);
                    editController.setIsValidDate(isValid);
                    updateClockInOut({ adjustedClockInTimeStamp: date });
                  }}
                  timeFormat="HH:mm"
                  timeInputLabel="Time:"
                  showTimeInput
                  customInput={renderCustomDatePickerInput({
                    value: tempAdjustedClockInTime,
                    disabled: !editedClockInOut.clockInTimeCorrectionId,
                  })}
                />
              </>
            ) : (
              <>
                <div className={styles.GeneralInformation_clockInOutCell}>
                  <div
                    style={{
                      fontStyle: 'italic',
                      opacity: 0.8,
                      fontSize: '.85em',
                      padding: '0 .85em .85em 0',
                    }}
                  >
                    {clockinOffsetTimeStampFormatted}
                  </div>
                  <div className={styles.GeneralInformation_clockInOutCell}>{clockInDate}</div>
                  <div className={styles.GeneralInformation_clockInOutCell}>{clockInTime}</div>
                </div>
              </>
            )}
          </div>
          <div className={styles.GeneralInformation_row__actions}></div>
        </div>
      </div>

      <div className={styles.GeneralInformation_row}>
        <div className={styles.GeneralInformation_row__label}></div>
        <div className={styles.GeneralInformation_row__content3}>
          {totalEditedHours <= 0 === false ? null : (
            <div
              style={{ fontSize: '1rem', fontStyle: 'italic', color: 'red', textAlign: 'right' }}
            >
              Please select a clock out time after your clocked in time.
            </div>
          )}
        </div>
      </div>

      <div style={{ height: 12 }} />

      {/* Clock Out */}
      {/* Clock Out Correction Reason */}
      <div className={styles.GeneralInformation_row}>
        <div className={styles.GeneralInformation_row__label}>{isEditMode && `Clock out`}</div>
        <div className={styles.GeneralInformation_row__stretch}>
          {isEditMode && !isNewMode ? (
            <RejectionReasonDropDown
              data-cy={'shift-details-co-edit-reason-dropdown'}
              setRejectionReason={(reason: any) => {
                updateClockInOut({ clockOutTimeCorrectionId: reason.value });
              }}
              rejectionReasonOptions={timeOutCorrectionOptions}
              reason={getReason(editedClockInOut.clockOutTimeCorrectionId)}
              isMultiselect={false}
            />
          ) : (
            <>{clockOutCorrection}</>
          )}
        </div>
      </div>

      {/* Clock Out Time Picker */}
      {isEditMode ? (
        <div className={styles.GeneralInformation_row}>
          <div className={styles.GeneralInformation_row__label}>{!isEditMode && ``}</div>
          <div className={styles.GeneralInformation_row__content3} style={{ fontSize: '.9em' }}>
            <div>{editedClockoutOffsetTimeStampFormatted}</div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className={styles.GeneralInformation_row}>
        <div className={styles.GeneralInformation_row__label}>{!isEditMode && `Clock out`}</div>
        <div className={styles.GeneralInformation_row__content}>
          {isEditMode ? (
            <>
              <DatePicker
                disabled={!editedClockInOut.clockOutTimeCorrectionId}
                selected={moment(tempAdjustedClockOutTime).toDate()}
                onChange={(date: Date) => {
                  const isValid: boolean = isValidClockedTime(
                    date,
                    tempAdjustedClockInTime.toDate()
                  );
                  console.log('setIsValidDate isValid', isValid);
                  editController.setIsValidDate(isValid);
                  updateClockInOut({ adjustedClockOutTimeStamp: date });
                }}
                timeFormat="HH:mm"
                timeInputLabel="Time:"
                showTimeInput
                customInput={renderCustomDatePickerInput({
                  value: tempAdjustedClockOutTime,
                  disabled: !editedClockInOut.clockOutTimeCorrectionId,
                })}
              />
            </>
          ) : (
            <>
              <div className={styles.GeneralInformation_clockInOutCell}>
                <div
                  style={{
                    fontStyle: 'italic',
                    opacity: 0.8,
                    fontSize: '.85em',
                    padding: '0 .85em .85em 0',
                  }}
                >
                  {clockoutOffsetTimeStampFormatted}
                </div>
                <div className={styles.GeneralInformation_clockInOutCell}>{clockOutDate}</div>
                <div className={styles.GeneralInformation_clockInOutCell}>{clockOutTime}</div>
              </div>
            </>
          )}
        </div>
        <div className={styles.GeneralInformation_row__actions}></div>
      </div>

      <div className={styles.GeneralInformation_row}>
        <div className={styles.GeneralInformation_row__label}></div>
        <div className={styles.GeneralInformation_row__content3}>
          {totalEditedHours <= 0 === false ? null : (
            <div
              style={{ fontSize: '1rem', fontStyle: 'italic', color: 'red', textAlign: 'right' }}
            >
              Please select a clock out time after your clocked in time.
            </div>
          )}
        </div>
      </div>

      {isEditMode ? (
        <div className={styles.GeneralInformation_row}>
          <div className={styles.GeneralInformation_row__label}>{!isEditMode && ``}</div>
          <div className={styles.GeneralInformation_row__content3} style={{ fontSize: '.9em' }}>
            <div
              style={{ fontSize: '1.3rem', fontStyle: 'italic', color: 'red' }}
              className={isTimeZoneOffsetMatch === false ? styles.warnHours : styles.warnHoursOff}
            >
              {isTimeZoneOffsetMatch === false && timeZoneMismatchErrorMessage}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div style={{ height: SECTION_SPACER_HEIGHT }} />

      {/* GPS in */}
      {!isNewMode && (
        <>
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>GPS in</div>
            <div className={styles.GeneralInformation_row__content}>
              {gpsInDistance} miles from Patient
            </div>
            <div className={styles.GeneralInformation_row__actions}></div>
          </div>

          {/* gps in correction */}
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}></div>
            <div className={styles.GeneralInformation_row__stretch}>
              {isEditMode ? (
                <RejectionReasonDropDown
                  setRejectionReason={(reason: any) => {
                    updateClockInOut({ clockInLocationCorrectionId: reason.value });
                  }}
                  rejectionReasonOptions={locationInCorrectionOptions}
                  reason={getReason(editedClockInOut.clockInLocationCorrectionId)}
                  isMultiselect={false}
                />
              ) : (
                <>{gpsInCorrection}</>
              )}
            </div>
          </div>
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>GPS out</div>
            <div className={styles.GeneralInformation_row__content}>
              {gpsOutDistance} miles from clock in
            </div>
            <div className={styles.GeneralInformation_row__actions}></div>
          </div>

          {/* gps out correction */}
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}></div>
            <div className={styles.GeneralInformation_row__stretch}>
              {isEditMode ? (
                <RejectionReasonDropDown
                  setRejectionReason={(reason: any) => {
                    updateClockInOut({ clockOutLocationCorrectionId: reason.value });
                  }}
                  rejectionReasonOptions={locationOutCorrectionOptions}
                  reason={getReason(editedClockInOut.clockOutLocationCorrectionId)}
                  isMultiselect={false}
                />
              ) : (
                <>{gpsOutCorrection}</>
              )}
            </div>
          </div>
        </>
      )}

      <div style={{ height: SECTION_SPACER_HEIGHT }} />

      {/* Review Map button */}
      <div>
        <button
          onClick={() => setIsOpenMap(!isOpenMap)}
          className={styles.linkButton}
          style={{
            display: 'flex',
            position: 'relative',
            textDecoration: 'none',
            border: '1px solid #0075c9',
            borderRadius: 5,
            outline: 'none',
            minWidth: 200,
            minHeight: 35,
          }}
        >
          <div style={{ margin: 'auto' }}>{isOpenMap ? 'Close Review Map' : 'Review Map'}</div>
          <div style={{ position: 'absolute', right: 0, top: 4, margin: 0 }}>
            <MaterialIcon
              icon={isOpenMap ? 'expand_less' : 'expand_more'}
              key={isOpenMap ? 'expand_less' : 'expand_more'}
              color="#0075c9"
            />
          </div>
        </button>
      </div>

      {isOpenMap && <ShiftMap dvr={dvr} />}
    </div>
  );
};

const defaultDropDownOption = { value: null, label: 'None' };

const mapStateToProps = ({ appData, user, aides }: any) => {
  return {
    locationOutCorrectionOptions: [defaultDropDownOption, ...appData.locationOutCorrectionOptions],
    locationInCorrectionOptions: [defaultDropDownOption, ...appData.locationInCorrectionOptions],
    timeInCorrectionOptions: [defaultDropDownOption, ...appData.timeInCorrectionOptions],
    timeOutCorrectionOptions: [defaultDropDownOption, ...appData.timeOutCorrectionOptions],
    allReasonCodesOptions: appData.allReasonCodesOptions,
    user: user.user,
    isShiftEdited: aides.isShiftEdited,
    currentEditedShift: aides.currentEditedShift,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCurrentEditedShift: (shift: any) => dispatch(setCurrentEditedShiftAction(shift)),
  };
};

const GeneralInformationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralInformation);

interface IProps {
  review: any;
  dvr: any;
  shift?: IGenericShift;
  currentShift: any;
  currentEditedShift: any;
  user: any;
  isShiftEdited: boolean;
  onClickHeader: () => void;
  openHistory: () => void;
  onSaveNew: () => void;
  onClose: () => void;
  refreshData?: () => void;
  setCurrentEditedShift: (shift: any) => void;
}

const ShiftDetailsContainer = ({
  dvr,
  shift,
  refreshData,
  onClickHeader,
  review,
  openHistory,
  onClose,
  currentShift,
  currentEditedShift,
  user,
  isShiftEdited,
  setCurrentEditedShift,
}: IProps) => {
  let disabled = false;
  let isValidEdited: boolean = false;
  let isValidHours: boolean = false;
  if (!dvr && review) {
    dvr = { clockInOut: review };
  }

  isValidEdited = currentEditedShift
    ? isValidClockedTime(
        currentEditedShift.clockInOut.adjustedClockOutTimeStamp,
        currentEditedShift.clockInOut.adjustedClockInTimeStamp
      )
    : false;

  isValidHours =
    currentShift && currentShift.clockInOut
      ? isValidClockedTime(
          currentShift.clockInOut.adjustedClockOutTimeStamp,
          currentShift.clockInOut.adjustedClockInTimeStamp
        )
      : false;

  const dispatch = useDispatch();
  const editedDVRState = useSelector(({ aides }: any) => aides.currentEditedDVRState);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSubPanelOpen, setIsSubPanelOpen] = useState(false);
  const [subPanelComponent, setSubPanelComponent] = useState(null);
  const [isValidDate, setIsValidDate] = useState(true);

  const footerRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

  if (!dvr && !review) {
    return <>Missing Shift and review.</>;
  }

  const editController = {
    isValidDate,
    setIsValidDate,
  };

  const isApproved = review.status === 1;
  const invalidData = !isValidEdited || !isValidDate;

  if ((isEditMode && invalidData) || (!isEditMode && (!isValidHours || isApproved))) {
    disabled = true;
  }

  const openSubPanel = (component: any) => {
    setSubPanelComponent(component);
    setIsSubPanelOpen(true);
  };

  const closePanel = () => {
    setIsSubPanelOpen(false);
    onClose();
  };

  const onBack = () => {
    setSubPanelComponent(null);
    setIsSubPanelOpen(false);
    if (footerRef) {
      setTimeout(scrollToBottom, 150);
    }
  };

  const scrollToBottom = () => {
    if (footerRef && footerRef.current) {
      footerRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  };

  const updateDVR = (updatedDVR: any) => {
    dispatch(setCurrentEditedDVRAction(updatedDVR));
  };

  const appendEditorInfoToReview = (tReview: any) => {
    if (user) {
      return {
        ...tReview,
        modifiedBy: user.id,
        modifier: user,
        modifiedOn: moment(new Date()).format(),
      };
    }
    return review;
  };

  const onToggleEditMode = (tIsEditMode: boolean) => {
    if (!tIsEditMode) {
      revertEditChanges();
    }

    setIsEditMode(tIsEditMode);
  };

  const revertEditChanges = () => {
    dispatch(revertShiftEditsAction());
  };

  return (
    currentShift && (
      <div className={styles.SP}>
        <ShiftDetailsHeader
          dvr={dvr}
          setIsValidDate={setIsValidDate}
          setIsEditMode={onToggleEditMode}
          isEditMode={isEditMode}
          isSubPanelOpen={isSubPanelOpen}
          openHistory={openHistory}
          onClose={() => {
            onClose();
            onClickHeader();
          }}
          onBack={onBack}
        />
        {isSubPanelOpen ? (
          subPanelComponent
        ) : (
          <ShiftPanelBody>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <ShiftStatusView
                clockInOut={review}
                isEdit={isEditMode}
                onRemove={(index: number) => console.log(index, review)}
              />
              <ShiftPanelHeading>General Information</ShiftPanelHeading>
              <div className={styles.SP_Body}>
                <GeneralInformationContainer
                  dvr={dvr}
                  shift={shift}
                  editController={editController}
                  isEditMode={isEditMode}
                />
              </div>

              <ShiftPanelHeading>Alerts</ShiftPanelHeading>
              <div className={styles.SP_Body}>
                <Alerts dvr={dvr} />
              </div>
              {/* !! DVR FORM !! */}
              <ShiftPanelHeading>Activities</ShiftPanelHeading>
              <div className={styles.SP_Body}>
                {isEditMode ? (
                  <DVRFormContainer
                    formTemplateJSON={dvr.templateJson}
                    initialFormState={editedDVRState}
                    onFormUpdate={(newDVR: any) => {
                      updateDVR(newDVR);
                    }}
                  />
                ) : (
                  <Activities dvr={dvr} />
                )}
              </div>

              <ClockInNote
                title="Clock In Note"
                note={dvr.clockInOut && dvr.clockInOut.clockInNote}
              />
              <ClockInNote
                title="Clock Out Note"
                note={dvr.clockInOut && dvr.clockInOut.clockOutNote}
              />
              <div style={{ height: 12 }} />

              <div className={styles.SP_Footer} ref={footerRef}>
                <ShiftSignatures dvr={dvr} />
                <ShiftDetailsFooter
                  refreshData={refreshData}
                  review={
                    isShiftEdited
                      ? appendEditorInfoToReview(currentEditedShift.clockInOut)
                      : currentShift.clockInOut
                  }
                  isEditMode={isEditMode}
                  onConfirm={openSubPanel}
                  onBack={onBack}
                  onClose={closePanel}
                  disabled={disabled}
                  disabledReject={(!isEditMode && isApproved) || (isEditMode && disabled)}
                  totalHours={getTotalHours(
                    currentShift.clockInOut.adjustedClockOutTimeStamp,
                    currentShift.clockInOut.adjustedClockInTimeStamp
                  )}
                />
              </div>
            </div>
          </ShiftPanelBody>
        )}
      </div>
    )
  );
};

const detailsMapStateToProps = ({ aides, user }: any) => {
  return {
    user: user.user,
    currentShift: aides.currentShift,
    currentEditedShift: aides.currentEditedShift,
    isShiftEdited: aides.isShiftEdited,
  };
};

export const ShiftDetails = connect(
  detailsMapStateToProps,
  mapDispatchToProps
)(ShiftDetailsContainer);

export function calculateDistance(
  latitudeStart: number,
  longitudeStart: number,
  latitudeEnd: number,
  longitudeEnd: number
) {
  const startCoords = {
    latitude: latitudeStart,
    longitude: longitudeStart,
  };
  const endCoords = {
    latitude: latitudeEnd,
    longitude: longitudeEnd,
  };

  return haversine(startCoords, endCoords, { unit: 'mile' }).toFixed(2);
}

const getFormattedOffSetTimeStampFormatted = (timestamp: string) => {
  const offsetnumber = getTimeStampOffset(timestamp);
  return timestamp
    ? `${new Date(timestamp).toUTCString()} ${
        Number(offsetnumber) < 0 ? '' : ''
      } ${offsetnumber.toString()}`
    : '?';
};

const isValidHoursTotal = (totalEditHours: number) => totalEditHours > 0;

export interface ITotalHoursComponentInput {
  warnEditHours: boolean;
  isEditMode: boolean;
  totalEditedHours: number;
  totalHours: number;
}

export const TotalHoursComponent = ({
  warnEditHours,
  isEditMode,
  totalEditedHours,
  totalHours,
}: ITotalHoursComponentInput) => {
  return (
    <div className={styles.GeneralInformation_row}>
      <div className={styles.GeneralInformation_row__label}>{`Clocked Hours`}</div>
      <div className={styles.GeneralInformation_row__content3}>
        <div className={styles.GeneralInformation_clockInOutCell}>
          <span className={warnEditHours ? styles.warnHours : styles.warnHoursOff}>
            {isEditMode
              ? totalEditedHours <= 0
                ? '?'
                : totalEditedHours
              : totalHours < 0
              ? '?'
              : totalHours}{' '}
            Hours
          </span>
          {warnEditHours && <div>Are you sure these hours are correct?</div>}
        </div>
      </div>
    </div>
  );
};

const isValidClockedTime = (startDate: Date, endDate: Date): boolean => {
  const total = getTotalHours(startDate, endDate);
  return isValidHoursTotal(total);
};
