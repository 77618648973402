import { IUser } from './interfaces';
import React from 'react';
import { StyledNavLink } from './nav';
import { StyledNotification } from './notification';
import { ROUTES } from '../routing';
import { UserRoles } from '../utils/userRoles';
import { interfaces } from './index';
import { LABELS } from '../routing/routing.constants';

export const MenuItems = (
  roles: UserRoles,
  user: IUser,
  featureFlags: interfaces.IFeatureFlags,
  hasUnreadMessage: boolean,
  isShiftNotified: boolean
) => {
  const renderHeaderOption = (label: string, route: string, isActive?: any) => {
    return (
      <StyledNavLink isActive={true} href={route} className="header-option">
        <StyledNotification isActive={isActive} notificationPosition={{ x: -16, y: 4.5 }} />
        {label}
      </StyledNavLink>
    );
  };

  const defaultHeaderOptions = (unreadMessage: boolean, shiftNotified: boolean) => {
    return (
      <>
        {renderHeaderOption(LABELS.MESSAGES, ROUTES.MESSAGES, unreadMessage)}
        {renderHeaderOption(LABELS.OPEN_SHIFTS, ROUTES.OPEN_SHIFTS, shiftNotified)}
        {renderHeaderOption(LABELS.MAP, ROUTES.MAP)}
      </>
    );
  };

  const renderAdminOptions = () => {
    return (
      <>
        {renderHeaderOption(LABELS.ADMIN, `${ROUTES.ADMIN}`)}
        {renderHeaderOption(LABELS.DASHBOARD, ROUTES.DASHBOARD)}
      </>
    );
  };

  const renderNewAdminOptions = () => {
    return <>{renderHeaderOption(LABELS.ADMIN_VIEW, `${ROUTES.ADMIN_VIEW}`)}</>;
  };
  const renderEVVNurseOptions = () => {
    return (
      <>
        {renderHeaderOption(LABELS.EVV_AIDES, `${ROUTES.AIDES}`)}
        {renderHeaderOption(LABELS.EVV_PATIENTS, `${ROUTES.PATIENTS}`)}
      </>
    );
  };

  const renderEVVSchedulerOptions = () => {
    return (
      <>
        {renderHeaderOption(LABELS.AIDES, `${ROUTES.AIDES}`)}
        {renderHeaderOption(LABELS.PATIENTS, `${ROUTES.PATIENTS}`)}
      </>
    );
  };

  const renderQAOptions = () => {
    return <>{renderHeaderOption(LABELS.QA_SHIFTS, `${ROUTES.QA_SHIFTS}`)}</>;
  };

  const renderScheduleRedesign = () => {
    return <>{renderHeaderOption(LABELS.CAREGIVER_SHIFTS, ROUTES.CAREGIVER_SHIFTS)}</>;
  };

  const userSeesAdminOptions = () => {
    return roles.isAdmin;
  };

  const userSeesNewAdminOptions = () => {
    return roles.isAdmin && featureFlags.featureAdminPortal;
  };
  const userSeesDefaultOptions = () => {
    return (
      (roles.isAdmin && roles.isScheduler) ||
      (roles.isEvvScheduler && roles.isScheduler) ||
      (!roles.isNurse &&
        !roles.isNurseAdmin &&
        !roles.isAdmin &&
        !roles.isAveannaStaff &&
        !roles.isScheduler &&
        !roles.isEvvNurse &&
        !roles.isEvvScheduler &&
        !roles.isQaNurse)
    );
  };

  const userSeesEvvSchedulerOptions = () => {
    return roles.isEvvScheduler && !roles.isScheduler && !roles.isEvvNurse;
  };

  const userSeesEvvNurseOptions = () => {
    return (
      (roles.isAdmin && roles.isEvvNurse) ||
      (roles.isEvvScheduler && roles.isScheduler) ||
      (roles.isEvvScheduler && roles.isEvvNurse)
    );
  };

  const userSeesQaNurseOptions = () => {
    return roles.isQaNurse;
  };

  const userSeesSchedulerRedesign = () => {
    return (
      (roles.isEvvScheduler || roles.isQaNurse) &&
      featureFlags.caregiverShifts &&
      featureFlags.costCenterLocationsEnabled
    );
  };

  // Former code, Default options were never rendered because of missing return. Potential bug.
  // } else {
  //   // NURSE_ADMIN, NURSE, ALL_NURSE_TYPES, SCHEDULER:
  //   {
  //     this.defaultHeaderOptions(hasUnreadMessage, isShiftNotified);
  //   }
  // }
  return (
    <>
      {userSeesEvvSchedulerOptions() && renderEVVSchedulerOptions()}
      {userSeesEvvNurseOptions() && renderEVVNurseOptions()}
      {userSeesDefaultOptions() && defaultHeaderOptions(hasUnreadMessage, isShiftNotified)}
      {userSeesAdminOptions() && renderAdminOptions()}
      {userSeesNewAdminOptions() && renderNewAdminOptions()}
      {userSeesQaNurseOptions() && renderQAOptions()}
      {userSeesSchedulerRedesign() && renderScheduleRedesign()}
    </>
  );
};
