import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { formatDateTime } from '../../DataHelpers';

const timeInColumn: GridColDef = {
  field: 'adjustedClockInTimeStamp',
  headerName: 'Time In',
  maxWidth: 100,
  valueFormatter: (params: GridValueFormatterParams<string>) => {
    return formatDateTime(params.value, 'LT');
  },
};

export default timeInColumn;
