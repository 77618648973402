import React, { Component } from 'react';
import { IChannel } from '../interfaces';
import backArrow from '../../assets/back-arrow.svg';
import { StyledMessagesHeader, StyledBackButton, StyledHeaderTitle } from './StyledMobileHeader';

interface IMobileHeaderProps {
  activeChannel: IChannel;
  clearActiveChannel: () => void | null;
}

class MessagesMobileHeader extends Component<IMobileHeaderProps> {
  public render() {
    const { activeChannel, clearActiveChannel } = this.props;
    return (
      <StyledMessagesHeader onClick={clearActiveChannel}>
        <StyledBackButton>
          <img src={backArrow} height={24} />
        </StyledBackButton>
        <StyledHeaderTitle>{activeChannel.title}</StyledHeaderTitle>
      </StyledMessagesHeader>
    );
  }
}

export default MessagesMobileHeader;
