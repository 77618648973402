import React from 'react';
import { useSelector } from 'react-redux';

import styles from './ShiftEditReview.module.css';

export const EditorInfo = () => {
  const tempUser = useSelector(({ user }: any) => user.user);

  return (
    <div className={styles.editGroup} style={{ marginTop: 30 }}>
      <div
        className={styles.editTitleContainer}
        style={{ display: 'flex', flex: 0.3, alignItems: 'center' }}
      >
        <p className={styles.editTitle}>Edited by</p>
      </div>
      <div style={{ display: 'flex', flex: 1.5, alignItems: 'center' }}>
        <img
          height="28"
          width="28"
          style={{ borderRadius: 50, marginRight: 12 }}
          src={tempUser.imageUri}
        />
        {tempUser.givenName} {tempUser.familyName}
      </div>
    </div>
  );
};
