import { GridColDef } from '@mui/x-data-grid';
import { ICaregiverShiftsData } from '../../../../common/interfaces';
import ActionsColumnRenderer from './Renderers/ActionsColumnRenderer';

const getActionsColumn = (
  onShiftUpdated: (shiftData: ICaregiverShiftsData) => void
): GridColDef => {
  return {
    field: 'actions',
    headerName: 'Actions',
    minWidth: 400,
    maxWidth: 800,
    valueGetter: ({ row }: { row: ICaregiverShiftsData }) => ({ ...row, onShiftUpdated }),
    renderCell: ActionsColumnRenderer,
  };
};

export default getActionsColumn;
