import { useEffect } from 'react';

import { getSocketConnection } from './api';
import { FeatureFlags } from '../utils';
import { notificationTypes } from '@aveanna/client-socket';
import { getErrorMessage } from '../common/useToastNotifications';

interface IUseConnectionArguments {
  eventName: string | notificationTypes | null;
  eventHandler: any;
}

// CUSTOM HOOK FOR CONNECTING TO THE WEBSOCKET!
export const useSocketConnection = (
  { eventName, eventHandler }: IUseConnectionArguments,
  isSubscribed: boolean = true
) => {
  useEffect(() => {
    if (!isSubscribed) {
      return;
    }

    if (
      FeatureFlags.FEATURE_REALTIME_NOTIFICATIONS === 'false' ||
      FeatureFlags.FEATURE_REALTIME_NOTIFICATIONS === false
    ) {
      console.log(
        'FEATURE FLAGS: realtime feature flag disabled, realtime notifications will not work.'
      );
      return;
    }

    if (eventName === undefined) {
      console.log('no event name passed, could not subscribe to socket event');
      return;
    }

    // if it's passed as null then it's considered to be intentional
    // at this time, so no console will happen
    if (eventName === null) {
      return;
    }

    const subscribeToNotification = async () => {
      try {
        const tempSocket = await getSocketConnection();
        if (tempSocket) {
          tempSocket.subscribeToNotificationTypeEvent(eventName, eventHandler);
        }
      } catch (error) {
        console.warn('could not subscribe to notification: ', getErrorMessage(error));
      }
    };

    const cleanupSubscription = async () => {
      try {
        const tempSocket = await getSocketConnection();
        if (tempSocket) {
          tempSocket.unsubscribeToNotificationTypeEvent(eventName, eventHandler);
        }
      } catch (error) {
        console.warn('could not unsubscribe to notification: ', getErrorMessage(error));
      }
    };

    subscribeToNotification();

    // cleanup
    return () => {
      cleanupSubscription();
    };
  }, [eventName, eventHandler, isSubscribed]);
};
