import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import styled from 'styled-components';

import { ROUTES } from '../routing/routing.constants';
import Onboarding from './containers/Onboarding';
import { interfaces, SideNav } from '../common';
import Forms from './containers/Forms';
import { userCanNavigateToAdmin } from '../routing/routeHandling';
import { useSelector } from 'react-redux';
import { handleLandingPageRoutes } from '../routing/routeHandling';

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  height: ${({ theme }) => `calc( 100vh - ${theme.headerHeight})`};
`;

const adminSideNavConfig: interfaces.ISideNavRouteConfig[] = [
  {
    title: 'Onboarding',
    route: `${ROUTES.ADMIN}${ROUTES.ONBOARD_USERS}`,
    icon: 'list_alt',
  },
  {
    title: 'Form Templates',
    route: `${ROUTES.ADMIN}${ROUTES.FORMS}`,
    icon: 'dynamic_form',
  },
];

// MASTER ADMIN COMPONENT, USED FOR SUB-ROUTING
export const AdminRouter = ({ match }: RouteComponentProps) => {
  const userToCheck = useSelector(({ user }: any) => user.user);
  const featureFlags = useSelector(({ user }: any) => user.featureFlags);
  const shouldRoute = userCanNavigateToAdmin(userToCheck) || !featureFlags.accessControlSC714;
  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <SideNav routeConfig={adminSideNavConfig} />
      <ContentContainer>
        <Switch>
          {shouldRoute ? (
            [
              <Route exact path={`${match.url}`} />,
              <Route
                exact
                path={`${ROUTES.ADMIN}${ROUTES.ONBOARD_USERS}`}
                component={Onboarding}
              />,
              <Route exact path={`${ROUTES.ADMIN}${ROUTES.FORMS}`} component={Forms} />,
            ]
          ) : (
            <Redirect to={handleLandingPageRoutes(userToCheck)} />
          )}
        </Switch>
      </ContentContainer>
    </div>
  );
};
