import React, { Component } from 'react';

import ReactGA from 'react-ga';

import Close from '../../assets/close.png';
import LeftArrow from '../../assets/left-arrow.png';

import {
  getAwardedNursesByShift,
  removeAwardedNurseShift,
  remindNursesForShift,
} from '../../api/shifts';

import { ModalWrapper, Loading, interfaces } from '../../common';
import { NurseListItem } from '../../Nurses';

import {
  ContentWrapper,
  TitleWrapper,
  Title,
  ListItemWrapper,
  MobileCloseModalButton,
  CloseModalButton,
  ShiftInfoHeader,
  FooterWrapper,
  ToastWrapper,
  FooterButton,
  SubHeader,
  SubHeaderText,
} from '../components/ModalBody';

enum ToastText {
  reassign = 'Removed - Please reassign shift',
  remind = 'Reminder(s) sent',
  failed = 'Something Went Wrong',
}

export class AwardedNurseListModal extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedNurseId: null,
      nurses: [],
      showToast: false,
      toastText: ToastText.reassign,
    };
  }

  public componentWillMount = async () => {
    const res = await getAwardedNursesByShift(this.props.shift.key);

    if (res.status === 200) {
      this.setState({ nurses: res.body });
    }
  };

  public componentDidMount() {
    // https://analytics.google.com/analytics/web/#home/
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  public renderAppliedNurseList = () => {
    if (this.state.nurses.length > 0) {
      return this.state.nurses.map((nurse: interfaces.INurse) => {
        const checked = this.state.selectedNurseId === nurse.id ? true : false;
        return (
          <NurseListItem
            key={nurse.userId + nurse.externalId}
            nurse={nurse}
            checked={checked}
            handleClick={() => {
              return;
            }}
          />
        );
      });
    } else {
      return <Loading />;
    }
  };

  public renderAdjustAwardedShift = () => {
    if (!this.state.showToast) {
      return (
        <FooterWrapper>
          <FooterButton
            style={{ backgroundColor: 'white', color: '#0075c9' }}
            onClick={() => this.handleReassignShift()}
          >
            Reassign
          </FooterButton>
          <FooterButton onClick={() => this.handleRemindShift()}>Remind</FooterButton>
        </FooterWrapper>
      );
    }
  };

  public renderToast = () => {
    if (this.state.showToast) {
      return (
        <ToastWrapper failed={this.state.toastText === ToastText.failed}>
          {this.state.toastText}
        </ToastWrapper>
      );
    }
  };

  public handleReassignShift = async () => {
    this.setState({ showToast: true });

    const res = await removeAwardedNurseShift(this.props.shift.key, this.state.nurses[0].id);

    if (res.status === 200) {
      this.setState({ showToast: true, toastText: ToastText.reassign });
      setTimeout(() => this.props.closeModal(true), 1500);
    } else {
      this.setState({ showToast: true, toastText: ToastText.failed });
      setTimeout(() => this.props.closeModal(true), 1500);
    }
  };

  public handleRemindShift = async () => {
    ReactGA.event({
      category: 'Sent Shift',
      action: 'handleRemind on AwardedNurseListModal.tsx',
      label: 'Sent Shift Remind',
      nonInteraction: false,
    });

    const res = await remindNursesForShift(this.props.shift.key);

    if (res.status === 204) {
      this.setState({ showToast: true, toastText: ToastText.remind });
      setTimeout(() => this.props.closeModal(), 1500);
    } else {
      this.setState({ showToast: true, toastText: ToastText.failed });
      setTimeout(() => this.props.closeModal(true), 1500);
    }
  };

  public render() {
    return (
      <ModalWrapper closeModal={this.props.closeModal}>
        <ContentWrapper>
          <TitleWrapper>
            <MobileCloseModalButton onClick={() => this.props.closeModal()}>
              <img src={LeftArrow} />
            </MobileCloseModalButton>
            <Title>Awarded Shift</Title>
            <CloseModalButton onClick={() => this.props.closeModal()}>
              <img src={Close} />
            </CloseModalButton>
          </TitleWrapper>
          <ShiftInfoHeader {...this.props.shift} />
          <ListItemWrapper>
            <SubHeader>
              <SubHeaderText>Caregiver</SubHeaderText>
            </SubHeader>
            {this.renderAppliedNurseList()}
          </ListItemWrapper>
        </ContentWrapper>
        {this.renderAdjustAwardedShift()}
        {this.renderToast()}
      </ModalWrapper>
    );
  }
}
