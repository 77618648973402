import React, { FC, useEffect, useState } from 'react';
import { getLastForm } from '../api/form';
import capitalize from 'lodash/capitalize';
import { useToasts } from 'react-toast-notifications';
import { getFormBuilderAccountId, ToastAppearance } from '../utils';
import { StyledButton } from '../common';
import { getFormBuilderUrl } from '../utils/app';
interface IGoToBuilderButtonProps {
  patient?: any;
  isAdminView?: boolean;
  title?: string;
  lastForm?: any;
}

const checkAndAddToObj = (fieldTitle: string, fields: any, obj: any, fieldLabel?: string) => {
  const label = `${fieldLabel ? fieldLabel : capitalize(fieldTitle)}: `;
  const foundField = fields.find((field: any) => field.sectionTitle.includes(label));
  if (foundField) {
    obj[fieldTitle] = foundField.sectionTitle.substring(label.length);
  }
};

const GoToBuilderButton: FC<IGoToBuilderButtonProps> = ({
  patient,
  isAdminView,
  title,
  lastForm,
}) => {
  const { addToast } = useToasts();
  const [autofillData, setAutofillData] = useState(
    patient
      ? {
          id: patient.searchField,
          name: patient.givenName ? `${patient.givenName} ${patient.familyName}` : patient.name,
          address: patient.address,
        }
      : {}
  );
  const showError = (message: string) => {
    addToast(message, {
      appearance: ToastAppearance.ERROR,
      pauseOnHover: true,
      autoDismiss: true,
      autoDismissTimeout: 10000,
    });
  };

  const redirect = () => {
    const config = {
      accountId: getFormBuilderAccountId(),
      config: {
        permissions: isAdminView ? 'admin' : 'recorder',
      },
      from: isAdminView
        ? `${window.location.origin}/admin/forms`
        : `${window.location.origin}/patients/${patient && patient.id}/accept-form`,
      data: JSON.stringify(autofillData),
    };
    fetch(getFormBuilderUrl()!, {
      method: 'POST',
      body: JSON.stringify(config),
    })
      .then(async (res) => {
        const response = await res.json();
        if (response.url) {
          window.location.href = response.url;
        } else {
          showError(`Unable to build a form at this time. Please try again later.`);
        }
      })
      .catch((err) => showError(err.message));
  };

  useEffect(() => {
    if (lastForm && lastForm.form) {
      getAutofillFromLastForm(lastForm.form);
    } else if (patient && patient.id) {
      getLastForm(patient.id).then((res) => {
        if (!res || !res.form) return;
        const fields = res.form;
        getAutofillFromLastForm(fields);
      });
    }
  }, [patient, lastForm]);

  const getAutofillFromLastForm = (fields: any) => {
    const autoFillFromForm: any = {};
    const fieldsToAutofill = [
      { value: 'name' },
      { value: 'service' },
      { value: 'mpi', label: 'MPI' },
      { value: 'mci', label: 'MCI' },
      { value: 'outcomeStatement', label: 'Outcome Statement' },
      { value: 'currentNeeds', label: 'Current Needs' },
      { value: 'currentNeeds', label: 'Action Needed' },
      { value: 'frequencyDuration', label: 'Frequency / Duration' },
      { value: 'ispPlanDate', label: 'ISP Plan Date' },
      { value: 'id', label: 'Medical Record Number' },
      { value: 'locationCode', label: 'Location Code' },
    ];
    fieldsToAutofill.map((field) => {
      checkAndAddToObj(field.value, fields, autoFillFromForm, field.label);
    });
    setAutofillData({
      ...autofillData,
      ...autoFillFromForm,
    });
  };

  return (
    <StyledButton primary onClick={() => redirect()}>
      {title ? title : 'Create Form'}
    </StyledButton>
  );
};

export default GoToBuilderButton;
