import { GridOptions, ValueGetterParams } from 'ag-grid-community';
import React, { useEffect, useRef, useState } from 'react';
import { getPatients } from '../../../api/patient';
import { AgGridReact } from 'ag-grid-react';
import { StatusToggleEditor, StatusFilter } from './StatusColumn';
import { PatientLocationEditor, PatientLocationDescription } from './LocationColumn';

export const PatientsTableDefinitions = () => {
  const colDef = {
    sortable: true,
    rowMultiSelectWithClick: true,
    resizable: true,
    margin: 'auto',
    floatingFilter: true,
    stopEditingWhenCellsLoseFocus: true,
  };

  const compnentName = 'Patients';

  const cols = [
    {
      width: 50,
      cellStyle: { 'border-right': '1px solid lightgray' },
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      enableCellChangeFlash: true,
    },
    {
      field: 'patientFamilyName&patientGivenName',
      headerName: 'Patient',
      sort: 'asc',
      minWidth: 200,
      filter: 'agTextColumnFilter',
      valueGetter: (params: ValueGetterParams) => {
        return params.data.givenName + ' ' + params.data.familyName;
      },
    },
    {
      field: 'patientMRNumber',
      headerName: 'MR Number',
      minWidth: 150,
      filter: 'agTextColumnFilter',
      valueGetter: (params: ValueGetterParams) => {
        return params.data.searchField;
      },
    },
    {
      field: 'externalSource',
      headerName: 'External Source',
      width: 130,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'officeLocationId',
      headerName: 'Office Location',
      width: 435,
      filter: 'agTextColumnFilter',
      editable: true,
      cellEditor: 'locationEditor',
      cellRenderer: 'locationRenderer',
    },
    {
      field: 'patientAddress',
      headerName: 'Address',
      minWidth: 350,
      filter: 'agTextColumnFilter',
      valueGetter: (params: ValueGetterParams) => {
        return params.data.address;
      },
    },
    {
      field: 'patientDischargedStatus',
      headerName: 'Status',
      minWidth: 200,
      filter: 'agTextColumnFilter',
      editable: true,
      floatingFilterComponent: 'statusFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        filterParams: {
          context: () => {
            return [
              { option: 'All', value: '' },
              { option: 'Active', value: 'Active' },
              { option: 'Discharged', value: 'Discharged' },
            ];
          },
        },
      },
      valueGetter: (params: ValueGetterParams) => {
        return params.data.isDischarged ? 'Discharged' : 'Active';
      },
      cellEditor: 'statusEditor',
    },
  ];

  const gridOptions: GridOptions = {
    defaultColDef: colDef,
    columnDefs: cols,
    pagination: true,
    paginationPageSize: 10,
    animateRows: true,
    rowSelection: 'multiple',
    frameworkComponents: {
      statusEditor: StatusToggleEditor,
      statusFilter: StatusFilter,
      locationEditor: PatientLocationEditor,
      locationRenderer: PatientLocationDescription,
    },
  };

  return gridOptions;
};

export const PatientTable = () => {
  const [rowData, setRowData] = useState<any | null>([]);
  const gridRef = useRef(null);
  let gridApi: any;

  useEffect(() => {
    gridApi = gridRef.current;
    gridApi.api.sizeColumnsToFit();
    async function fetchData() {
      const response = await getPatients();
      setRowData(response);
    }
    fetchData().then();
  }, []);

  function clearAllFilters() {
    gridApi = gridRef.current;
    if (gridApi.api) {
      gridApi.api.setFilterModel(null);
    }
  }

  return (
    <div id="ptable-parent-div">
      <button onClick={clearAllFilters} className="actionButton">
        Clear Filters
      </button>
      <div className="ag-theme-alpine dataTable" id="admin-patient-table">
        <AgGridReact gridOptions={PatientsTableDefinitions()} rowData={rowData} ref={gridRef} />
      </div>
    </div>
  );
};
