import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ShiftPanelHeading } from '../../ShiftPanel/ShiftSidebar';
import { EditedCorrectionItem } from './EditedCorrectionItem';
import { EditedTimeItem } from './EditedTimeItem';
import { EditedGenericItem } from './EditedGenericItem';
import { EditorInfo } from './EditorInfo';

import styles from './ShiftEditReview.module.css';

const ShiftEditReviewContainer = styled.div`
  padding: 0px 24px 24px 24px;
`;

export const ShiftEditReview = () => {
  const {
    currentClockInOut,
    currentEditedClockInOut,
    currentEditedDVRState,
    currentOriginalDVRState,
  } = useSelector(({ aides }: any) => {
    return {
      currentClockInOut: aides && aides.currentShift ? aides.currentShift.clockInOut : null,
      currentEditedDVRState: aides ? aides.currentEditedDVRState : null,
      currentOriginalDVRState: aides ? aides.currentDVRState : null,
      currentEditedClockInOut:
        aides && aides.currentEditedShift ? aides.currentEditedShift.clockInOut : null,
    };
  });

  const tempIsEdited =
    JSON.stringify(currentEditedDVRState) !== JSON.stringify(currentOriginalDVRState) ||
    JSON.stringify(currentClockInOut) !== JSON.stringify(currentEditedClockInOut);

  return tempIsEdited ? (
    <ShiftEditReviewContainer>
      <ShiftPanelHeading>
        You have edited shift information. Are you sure you want to save the changes?
      </ShiftPanelHeading>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <div className={styles.editGroup}>
          <div style={{ flex: 1 }}>
            <p style={{ fontSize: 14, color: '#333' }}>ORIGINAL</p>
          </div>
          <div style={{ flex: 1 }}>
            <p style={{ fontSize: 14, color: '#333' }}>NEW</p>
          </div>
        </div>
        <EditedCorrectionItem
          title="Clock In Correction"
          previousData={currentClockInOut.clockInTimeCorrectionId}
          currentData={currentEditedClockInOut.clockInTimeCorrectionId}
        />
        <EditedCorrectionItem
          title="Clock Out Correction"
          previousData={currentClockInOut.clockOutTimeCorrectionId}
          currentData={currentEditedClockInOut.clockOutTimeCorrectionId}
        />
        <EditedCorrectionItem
          title="Location In Correction"
          previousData={currentClockInOut.clockInLocationCorrectionId}
          currentData={currentEditedClockInOut.clockInLocationCorrectionId}
        />
        <EditedCorrectionItem
          title="Location Out Correction"
          previousData={currentClockInOut.clockOutLocationCorrectionId}
          currentData={currentEditedClockInOut.clockOutLocationCorrectionId}
        />
        <EditedTimeItem
          title="Clock In Time"
          previousData={currentClockInOut.adjustedClockInTimeStamp}
          currentData={currentEditedClockInOut.adjustedClockInTimeStamp}
        />
        <EditedTimeItem
          title="Clock Out Time"
          previousData={currentClockInOut.adjustedClockOutTimeStamp}
          currentData={currentEditedClockInOut.adjustedClockOutTimeStamp}
        />
      </div>
      <EditedGenericItem
        title="DVR Form"
        previousData={currentOriginalDVRState}
        currentData={currentEditedDVRState}
      />
      <EditorInfo />
    </ShiftEditReviewContainer>
  ) : null;
};
