import React from 'react';
import Select from 'react-select';

import MaterialIcon from 'material-icons-react';

import { theme as aveannaTheme } from '../../common';
import { ClearReasonButton } from '../ShiftPanel/styles';

interface IProps {
  isMultiselect?: boolean;
  reason: any;
  onRemove?: () => void;
  setReason: (value: any) => void;
  reasonOptions: any[];
}

export const ReasonDropdown = ({
  isMultiselect = true,
  reason,
  onRemove,
  setReason,
  reasonOptions,
}: IProps) => {
  // This is how you style the select — https://react-select.com/styles
  const tempSelectStyles = {
    container: (styles: any) => ({ ...styles, flex: 1 }),
  };

  let reasonReference = reason.value ? reason : null;

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
      }}
    >
      <Select
        styles={tempSelectStyles}
        options={reasonOptions}
        value={reasonReference}
        onChange={setReason}
        placeholder="Please select a reason."
      />
      {isMultiselect && (
        <ClearReasonButton onClick={onRemove}>
          <MaterialIcon icon="clear" color={aveannaTheme.colors.footerButtonRed} />
        </ClearReasonButton>
      )}
    </div>
  );
};
