import React from 'react';
import styled from 'styled-components';
import GoToBuilderButton from '../../builder/GoToBuilderButton';

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 12px;
  flex-direction: column;
  background-color: white;
  height: calc(100% - 24px);
`;

const Forms = () => {
  return (
    <Container>
      <p>Create or Update your Custom Form Templates</p>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <GoToBuilderButton isAdminView title="Configure Forms" />
      </div>
    </Container>
  );
};

export default Forms;
