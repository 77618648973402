import React from 'react';
import styles from '../../Aides/ShiftDetails/ShiftDetails.module.css';
import moment from 'moment';
import { IClockInOut } from '../PatientInterfaces';

interface IProps {
  clockInOut: IClockInOut;
}

const ClockInInformation = (props: IProps) => {
  const { clockInOut } = props;
  return (
    <div style={{ padding: '0.5rem 1.5rem' }}>
      <div className={styles.SP_Body}>
        <div className={styles.GeneralInformation}>
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>Clock In Date</div>
            <div className={styles.GeneralInformation_row__content}>
              {moment(clockInOut.adjustedClockInTimeStamp).format('MM/DD/YYYY')}
            </div>
          </div>
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>Clock In Time</div>
            <div className={styles.GeneralInformation_row__content}>
              {moment(clockInOut.adjustedClockInTimeStamp).format('HH:mm')}
            </div>
          </div>
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>Clock Out Date</div>
            <div className={styles.GeneralInformation_row__content}>
              {moment(clockInOut.adjustedClockOutTimeStamp).format('MM/DD/YYYY')}
            </div>
          </div>
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>Clock Out Time</div>
            <div className={styles.GeneralInformation_row__content}>
              {moment(clockInOut.adjustedClockOutTimeStamp).format('HH:mm')}
            </div>
          </div>
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>Caregiver</div>
            <div className={styles.GeneralInformation_row__content}>
              {clockInOut.nurseProfile.user.givenName}&nbsp;
              {clockInOut.nurseProfile.user.familyName}
            </div>
          </div>
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>Clock In Notes</div>
            <div className={styles.GeneralInformation_row__content}>
              {clockInOut.clockInNote !== null ? clockInOut.clockInNote : 'n/a'}
            </div>
          </div>
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>Clock Out Notes</div>
            <div className={styles.GeneralInformation_row__content}>
              {clockInOut.clockOutNote !== null ? clockInOut.clockOutNote : 'n/a'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClockInInformation;
