import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { ICaregiverShiftsData } from '../../../../common/interfaces';
import { formatDateTime } from '../../DataHelpers';

const dateColumn: GridColDef = {
  field: 'date',
  headerName: 'Date',
  valueGetter: ({ row }: { row: ICaregiverShiftsData }) => {
    return row && row.adjustedClockInTimeStamp;
  },
  valueFormatter: (params: GridValueFormatterParams<string>) => {
    return formatDateTime(params.value, 'l');
  },
};

export default dateColumn;
