import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { userTypeByID, userTypes } from '../../../utils';
import { updateUser } from '../../../api/admin';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { ToastAppearance } from '../../../utils';

export const UserTypeEditorRenderer = forwardRef((props: any, ref) => {
  const dropdown = useRef<HTMLSelectElement>(null);
  const userToCheck = useSelector(({ user }: any) => user.user);
  const [userType, setUserType] = useState(props.data.userType);
  const { addToast } = useToasts();

  const allTypesOption = [{ value: '', option: 'All' }];

  const userTypesAndNumbers = Object.values(userTypes)
    .filter((val): val is string => {
      return removeSuperUserIfNotSuperUser(val);
    })
    .map((userTypeNumber) => {
      return {
        value: userTypeNumber,
        option: userTypeNumber + ' - ' + userTypeByID[userTypeNumber],
      };
    });

  function removeSuperUserIfNotSuperUser(userType: any) {
    if (userType === 127) {
      return userToCheck.userType === userTypes.SUPER_USER;
    } else {
      return typeof userType === 'number';
    }
  }

  const userTypeOptions: JSX.Element[] = allTypesOption
    .concat(userTypesAndNumbers)
    .map((userType, index) => {
      return (
        <option
          value={userType.value}
          key={index}
          selected={props.data.userType.toString() === userType.value.toString()}
          data-testid={'user-type-option-' + userType.value}
        >
          {userType.option}
        </option>
      );
    });

  async function fetchData() {
    try {
      const updatedUser = await updateUser(props.data, userType, false);
      setUserType(updatedUser.userType);
      addToast(`User type successfully updated`, {
        appearance: ToastAppearance.SUCCESS,
        autoDismiss: true,
      });
    } catch (error: any) {
      console.log('Error updating user type ', error.message);
      addToast('Error updating user type ', {
        appearance: ToastAppearance.WARNING,
        autoDismiss: true,
      });
    }
  }

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (userType === '' || !userType) {
          return;
        } else {
          fetchData().then();
          return userType;
        }
      },
    };
  });

  useEffect(() => {
    // props.api.refreshCells();
    props.data.userType = userType;
  }, [userType]);

  return (
    <select
      className="user-type-select-dropdown"
      data-testid="user-type-select-dropdown"
      ref={dropdown}
      onChange={() => {
        setUserType(userTypeOptions[dropdown.current!.selectedIndex].props.value);
      }}
    >
      {userTypeOptions}
    </select>
  );
});
