import React from 'react';
import styled from 'styled-components';

const StyledComingSoon = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: white;
  height: 100%;
`;

interface IProps {
  title: string;
  icon?: string;
}

const randomIcons = ['🚀', '🚧', '🧪', '🔧', '🧬'];

const generateRandomIcon = (): string => {
  return randomIcons[Math.floor(Math.random() * +randomIcons.length)];
};

export const ComingSoon = ({ title, icon = generateRandomIcon() }: IProps) => (
  <StyledComingSoon>
    <p style={{ fontSize: 36 }}>{icon}</p>
    <p>{`${title} coming soon!`}</p>
  </StyledComingSoon>
);
