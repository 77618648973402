import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLocalUser, getLocalNotifications } from '../../utils';
import { setUser as setUserAction } from '../../login';
import { ISetUserInput } from '../../login/interfaces';
import { interfaces, Loading, FullPage, Centered } from '../../common';
import { setShiftNotification as setShiftNotificationAction } from '../../notifications';
import { getAllReasonCodes, getAppSettings } from '../../api';
import * as actions from '../actions';
import { sendAnalyticsPageView } from '../../analytics';
import AuthRouter from './AuthRouter';
import NonAuthRouter from './NonAuthRouter';
import { CacheBuster } from '../components/CacheBuster';

interface IProps extends RouteComponentProps {
  setUser: (setUserInput: ISetUserInput) => Promise<void>;
  setAppSettings: (appSettings: interfaces.IAppSetting[] | null) => void;
  setShiftNotification: any;
  setAllReasonCodes: any;
  user?: interfaces.IUser;
  clearAppData: () => void;
}

interface IState {
  isLoading: boolean;
  isUpdateNotified: boolean;
}

class RouteGuard extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isLoading: true,
      isUpdateNotified: false,
    };
  }

  private newCodeAvailableReminder: any = null;

  public async componentDidMount() {
    const { user } = this.props;
    if (!user) {
      // if there is no redux user, check for localStorage user
      await this.checkIfLocalUserExists();
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  }

  public componentDidUpdate() {
    sendAnalyticsPageView(window.location.href);
  }

  private checkIfLocalUserExists = async () => {
    const { setUser, setShiftNotification, user } = this.props;
    const tempUser = getLocalUser();
    const tempNotifications = getLocalNotifications();

    if (user && tempUser && tempUser.token) {
      setShiftNotification(tempNotifications);
      const { token } = tempUser;
      if (token) {
        await setUser({ user: user || tempUser.user, token });
        await this.getAppSettings(user);
        await this.getReasonCodes();
      }
    }
  };

  private getAppSettings = async (user: interfaces.IUser | undefined) => {
    const { setAppSettings } = this.props;
    if (user) {
      const tempAppSettings = await getAppSettings(user.locationId);
      setAppSettings(tempAppSettings);
    } else if (this.props.user) {
      const tempAppSettings = await getAppSettings(this.props.user.locationId);
      setAppSettings(tempAppSettings);
    } else {
      console.log('missing user in getAppSettings');
    }
  };

  private getReasonCodes = async () => {
    const { setAllReasonCodes } = this.props;
    try {
      const tempAllReasonCodes = await getAllReasonCodes();
      setAllReasonCodes(tempAllReasonCodes);
    } catch (error) {
      console.log('error getting the rejection reasons — ', error);
    }
  };

  private renderFullPageLoader = () => {
    return (
      <FullPage>
        <Centered>
          <Loading />
        </Centered>
      </FullPage>
    );
  };

  public render() {
    const { user } = this.props;
    const tempUser = getLocalUser();
    const hasUser = user || (tempUser && tempUser.user) ? true : false;
    const { isLoading } = this.state;

    return (
      <>
        {!isLoading && hasUser && (
          <CacheBuster
            actions={{
              getAppSettings: () => {
                this.getAppSettings(user as interfaces.IUser);
              },
              clearAppData: () => this.props.clearAppData(),
            }}
          />
        )}
        {isLoading ? this.renderFullPageLoader() : user ? <AuthRouter /> : <NonAuthRouter />}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUser: async (setUserInput: ISetUserInput) => dispatch(setUserAction(setUserInput)),
    setShiftNotification: (notificationState: any) =>
      dispatch(setShiftNotificationAction(notificationState)),
    setAllReasonCodes: (allReasons: any) => dispatch(actions.setAllReasons(allReasons)),
    setAppSettings: (appSettings: interfaces.IAppSetting[] | null) =>
      dispatch(actions.setAppSettings(appSettings)),
    clearAppData: () => dispatch(actions.clearAppData()),
  };
};

const mapStateToProps = ({ user }: any) => {
  return {
    user: user.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RouteGuard));
