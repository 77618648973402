import React, { useState } from 'react';
import styled from 'styled-components';

import { ShiftDetails } from '../ShiftDetails/ShiftDetails';
import ShiftHistory from './ShiftHistory';
import moment from 'moment';
import { IGenericShift } from '../../Patients/PatientInterfaces';

interface IProps {
  onSaveNew: () => void;
  onSave: (review: any) => void;
  shift?: IGenericShift;
  dvr: any;
  review: any;
  onClose: () => void;
  initialComponent?: ShiftSidebarDisplayComponents;
  refreshData?: () => void;
}

export enum ShiftSidebarDisplayComponents {
  DETAILS = 'DETAILS',
  HISTORY = 'HISTORY',
}

export const ShiftSidebar = ({
  dvr,
  shift,
  onClose,
  initialComponent,
  onSaveNew,
  review,
  refreshData,
}: IProps) => {
  const [displayComponent, setDisplayComponent] = useState<string>(
    initialComponent || ShiftSidebarDisplayComponents.DETAILS
  );

  const ShiftDisplay = (
    <ShiftPanel>
      <ShiftDetails
        key={`${moment().format()}`}
        onSaveNew={() => {
          onSaveNew();
        }}
        shift={shift}
        dvr={dvr}
        refreshData={refreshData}
        onClickHeader={onClose}
        review={review}
        openHistory={() => setDisplayComponent(ShiftSidebarDisplayComponents.HISTORY)}
        onClose={onClose}
      />
    </ShiftPanel>
  );

  const HistoryDisplay = (
    <ShiftPanel>
      <ShiftHistory
        dvr={dvr}
        onClickHeader={() => setDisplayComponent(ShiftSidebarDisplayComponents.DETAILS)}
      />
    </ShiftPanel>
  );

  switch (displayComponent) {
    case ShiftSidebarDisplayComponents.HISTORY:
      return HistoryDisplay;
    case ShiftSidebarDisplayComponents.DETAILS:
    default:
      return ShiftDisplay;
  }
};

const ShiftPanel = styled.div`
  min-width: 600px;
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  a,
  a:visited {
    color: #0075c9;
  }
`;

export const ShiftPanelHeader = styled.div`
  display: flex;
  /* flex: 1; */
  padding: 20px;
  flex-direction: row;
`;

export const ShiftPanelHeaderLeft = styled.div`
  justify-content: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const ShiftPanelHeaderRight = styled.div`
  justify-content: flex-end;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

export const ShiftPanelHeaderText = styled.div`
  padding: 5px;
  font-size: 12px;
`;

export const ShiftPanelHeaderLink = styled.div`
  padding: 5px;
  padding-right: 30px;
  font-size: 14px;
  font-weight: bold;
  button {
    cursor: pointer;
    background: inherit;
    color: #0075c9;
    text-decoration: underline;
    font-size: 1rem;
    font-weight: 700;
    border: 0;
    pointer-events: auto;
  }
`;

export const ShiftPanelBody = styled.div`
  flex: 1;
  display: inline-block;
  color: #333333;
  padding: 0 25px;
  overflow-y: auto;
`;

export const ShiftPanelHeading = styled.div`
  font-size: 24px;
  line-height: 28px;
  padding: 12px 0px;
  color: ${({ theme }) => theme.colors.aveannaDarkBlueTextColor};
`;

export const ShiftPanelTextBold = styled.div`
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
`;

export const ShiftPanelText = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: #3b3b3b;
`;

export const ShiftPanelHistoryItem = styled.div`
  flex: 1;
  flexdirection: 'column';
  padding: 8;
  display: '-webkit-box';
  padding: 1em 1em 1em 1em;
`;
