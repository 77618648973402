import React, { Component } from 'react';
import { Route, withRouter, RouteComponentProps, Switch, Redirect } from 'react-router-dom';

import { ROUTES } from '../routing.constants';

import { Login, OneLoginAuth } from '../../login';
import { Password } from '../../password';

interface IProps extends RouteComponentProps {}

class NonAuthRouter extends Component<IProps, any> {
  public render() {
    return (
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path={ROUTES.PASSWORD} component={Password} />
        <Route path={ROUTES.ONE_LOGIN} component={OneLoginAuth} />
        <Redirect to="/" />
      </Switch>
    );
  }
}

export default withRouter(NonAuthRouter);
