import { StatusType } from '../../utils';
import { EVV_REJECT } from '../../utils/constants';
import moment from 'moment';
import { ICaregiverShiftsData } from '../../common/interfaces';

export function getFilterFunction(
  statusType: StatusType | undefined,
  isRejected: boolean
): (caregiverShiftsData: ICaregiverShiftsData) => boolean {
  switch (statusType) {
    case StatusType.PENDING:
    case StatusType.APPROVED:
      return getPendingOrApprovedFilterFunction(statusType);
    case StatusType.REJECTED:
      if (isRejected) {
        // Rejected
        return isRejectedShift;
      } else {
        // Review
        return isReviewedShift;
      }
    case StatusType.QA_SHIFTS:
      return isQAShift;
    default:
      // All Shifts
      return (data) => true;
  }
}

function isQAShift(caregiverShiftsData: ICaregiverShiftsData): boolean {
  return caregiverShiftsData.isQa;
}

function getPendingOrApprovedFilterFunction(
  statusType: StatusType.APPROVED | StatusType.PENDING
): (caregiverShiftsData: ICaregiverShiftsData) => boolean {
  return (caregiverShiftsData: ICaregiverShiftsData) =>
    !caregiverShiftsData.isQa && caregiverShiftsData.status === statusType;
}

function isReviewedShift(caregiverShiftsData: ICaregiverShiftsData): boolean {
  return (
    !caregiverShiftsData.isQa &&
    caregiverShiftsData.status === StatusType.REJECTED &&
    !!caregiverShiftsData.clockInOutRejectionReasons &&
    caregiverShiftsData.clockInOutRejectionReasons.every((x: any) => x.reasonId !== EVV_REJECT)
  );
}

export function isRejectedShift(caregiverShiftsData: ICaregiverShiftsData): boolean {
  return (
    caregiverShiftsData.status === StatusType.REJECTED &&
    !!caregiverShiftsData.clockInOutRejectionReasons &&
    caregiverShiftsData.clockInOutRejectionReasons.some(
      (x: { reasonId: number }) => x.reasonId === EVV_REJECT
    )
  );
}

export function toProperCase(input: string) {
  return input.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

export function formatDateTime(time: string | Date, dateFormat?: string) {
  return dateFormat
    ? moment(time).utcOffset('00:00').format(dateFormat)
    : moment(time).utcOffset('00:00').format('LT');
}
