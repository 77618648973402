import React from 'react';
import styled from 'styled-components';

interface INotificationProps {
  isActive: boolean;
  notificationPosition?: {
    x: number;
    y: number;
  };
  style?: any;
}

const Notification: React.SFC<INotificationProps> = (props) => {
  const { isActive, notificationPosition, ...rest } = props;
  return <div {...rest} />;
};

export const StyledNotification: any = styled(Notification)`
  height: 12px;
  width: 12px;
  background-color: ${({ isActive }) => (isActive ? 'red' : 'transparent')};
  border-radius: 50%;
  position: absolute;
  transform: ${({ notificationPosition }) =>
    notificationPosition
      ? `translate( ${notificationPosition.x}px, ${notificationPosition.y}px)`
      : `translate(0px, 0px)`};
`;
