import React, { Fragment, ChangeEvent, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { FormCheckbox, FormRadio, FormTextArea, FormCheckboxContainer } from './inputs';
import { IOption, IFormButton, UpdateFormState } from './interfaces';

const TaskLabel: any = styled.div`
  display: flex;
  padding: 0 12px;
  background-color: ${({ theme }) => theme.colors.formTaskLabel};
  color: ${({ theme }) => theme.colors.formTaskText};
  letter-spacing: 1px;
`;

const TaskToggleText: any = styled.p`
  margin: 8px 0;
`;

export const StyledTaskLabel = ({ label }: { label: string }) => {
  return (
    <TaskLabel testID="StyledFormLabel">
      <h4>{label}</h4>
    </TaskLabel>
  );
};

interface FormButtonProps extends HTMLAttributes<HTMLButtonElement> {
  isActive?: boolean;
  onClick?: any;
}

const FormButton: React.SFC<FormButtonProps> = (props) => {
  const { isActive, ...rest } = props;
  return <button {...rest} />;
};

const StyledFormButton = styled(FormButton)`
  display: flex;
  flex: 1;
  cursor: pointer;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-width: 1px 0px 1px 1px;
  border-color: ${({ theme }) => theme.colors.formButtonBorder};
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.formButtonActive : theme.colors.white};

  :nth-child(2) {
    border-right-width: 1px;
  }
`;

const FormGroup: any = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

enum ButtonTypes {
  performed = 'performed',
  refused = 'refused',
}

// Checkbox Form Group
export const StyledCheckboxControlGroup = ({
  buttons,
  options,
  formState,
  updateFormState,
}: {
  buttons: IFormButton[];
  options: IOption[];
  formState: any;
  updateFormState: UpdateFormState;
}) => {
  const groupIds = getIds([...options, ...buttons]);
  const performedButton = getButtonOfType(buttons, ButtonTypes.performed);
  const refusedButton = getButtonOfType(buttons, ButtonTypes.refused);
  return (
    <Fragment>
      <FormGroupToggle
        performedButton={performedButton}
        refusedButton={refusedButton}
        formState={formState}
        updateFormState={updateFormState}
        groupIds={groupIds}
      />
      {formState[performedButton.id] && (
        <DynamicCheckboxControls
          options={options}
          updateFormState={updateFormState}
          formState={formState}
        />
      )}
    </Fragment>
  );
};

const FormGroupToggle = ({
  performedButton,
  refusedButton,
  groupIds,
  updateFormState,
  formState,
}: {
  performedButton: IFormButton;
  refusedButton: IFormButton;
  groupIds: string[];
  updateFormState: UpdateFormState;
  formState: any;
}) => {
  return (
    <FormGroup testID="formGroup">
      <StyledFormButton
        onClick={() => {
          updateFormState({
            id: refusedButton.id,
            value: !formState[refusedButton.id],
            fieldsToReset: groupIds,
          });
        }}
        isActive={formState[refusedButton.id]}
      >
        <TaskToggleText>{refusedButton.display}</TaskToggleText>
      </StyledFormButton>
      {/* <div style={{ width: 1, backgroundColor: 'rgba(0, 0, 0, 0.12)' }} /> */}
      <StyledFormButton
        onClick={() => {
          updateFormState({
            id: performedButton.id,
            value: !formState[performedButton.id],
            fieldsToReset: groupIds,
          });
        }}
        isActive={formState[performedButton.id]}
      >
        <TaskToggleText>{performedButton.display}</TaskToggleText>
      </StyledFormButton>
    </FormGroup>
  );
};

const DynamicCheckboxControls = ({ options, updateFormState, formState }: any) => {
  return options.length > 0 ? (
    <FormCheckboxContainer testID="FormCheckboxContainer">
      {options.map((tempOption: any) => {
        return (
          <FormCheckbox
            key={tempOption.id}
            onPress={() => {
              updateFormState({ id: tempOption.id, value: !formState[tempOption.id] });
            }}
            checked={formState[tempOption.id] ? formState[tempOption.id] : false}
            title={tempOption.display}
          />
        );
      })}
    </FormCheckboxContainer>
  ) : null;
};

// Radio Control
export const StyledRadioControlGroup = ({ buttons, options, formState, updateFormState }: any) => {
  const groupIds = getIds([...options, ...buttons]);
  const performedButton = getButtonOfType(buttons, ButtonTypes.performed);
  const refusedButton = getButtonOfType(buttons, ButtonTypes.refused);
  return (
    <Fragment>
      <FormGroupToggle
        performedButton={performedButton}
        refusedButton={refusedButton}
        formState={formState}
        updateFormState={updateFormState}
        groupIds={groupIds}
      />
      {formState[performedButton.id] && (
        <DynamicRadioControls
          options={options}
          updateFormState={updateFormState}
          formState={formState}
        />
      )}
    </Fragment>
  );
};

const DynamicRadioControls = ({ options, updateFormState, formState }: any) => {
  const optionIds = getIds(options);
  return (
    <FormCheckboxContainer testID="FormRadioContainer">
      {options.map((tempOption: any) => {
        return (
          <FormRadio
            key={tempOption.id}
            onPress={() => {
              updateFormState({
                id: tempOption.id,
                value: !formState[tempOption.id],
                fieldsToReset: optionIds,
              });
            }}
            checked={formState[tempOption.id]}
            title={tempOption.display}
          />
        );
      })}
    </FormCheckboxContainer>
  );
};

// Textarea Control Group
export const TextAreaControlGroup = ({ taskId, formState, updateFormState, placeholder }: any) => {
  return (
    <div style={{ paddingLeft: 12, paddingRight: 12, paddingTop: 6, display: 'flex' }}>
      <FormTextArea
        key={taskId}
        value={formState[taskId] ? formState[taskId] : ''}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
          updateFormState({ id: taskId, value: event.target.value, fieldsToReset: null })
        }
        placeholder={placeholder}
      />
    </div>
  );
};

const getButtonOfType = (buttons: any, buttonType: ButtonTypes) => {
  return buttons.find((tempButton: any) => tempButton.type === buttonType);
};

const getIds = (tObjectWithId: any[]) => {
  return tObjectWithId.reduce((allObjIds: any, currentObj: any) => {
    allObjIds.push(currentObj.id);
    return allObjIds;
  }, []);
};
