import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import AdminView from './AdminView';
import { useSelector } from 'react-redux';
import { userCanNavigateToAdminPortal } from '../../routing/routeHandling';
import { handleLandingPageRoutes } from '../../routing/routeHandling';

// MASTER AIDES COMPONENT, USED FOR SUB-ROUTING
export const AdminViewRouter = ({ match }: RouteComponentProps) => {
  const userToCheck = useSelector(({ user }: any) => user.user);
  const featureFlags = useSelector(({ user }: any) => user.featureFlags);
  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <div style={{ flex: 1, height: `100vh` }}>
        <Switch>
          {userCanNavigateToAdminPortal(userToCheck, featureFlags) ? (
            <Route exact path={`${match.url}`} component={AdminView} />
          ) : (
            <Redirect to={handleLandingPageRoutes(userToCheck)} />
          )}
        </Switch>
      </div>
    </div>
  );
};
