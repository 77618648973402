import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import AcceptPatientForm from './AcceptPatientForm';
import PatientForms from './PatientForms';
import Patients from './Patients';
import { userCanNavigateToPatients } from '../../routing/routeHandling';
import { useSelector } from 'react-redux';
import { handleLandingPageRoutes } from '../../routing/routeHandling';

export const PatientsRouter = ({ match }: RouteComponentProps) => {
  const userToCheck = useSelector(({ user }: any) => user.user);
  const featureFlags = useSelector(({ user }: any) => user.featureFlags);
  const shouldRoute = userCanNavigateToPatients(userToCheck) || !featureFlags.accessControlSC714;
  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <div style={{ flex: 1, height: `100vh` }}>
        <Switch>
          {shouldRoute ? (
            [
              <Route exact path={`${match.url}`} component={Patients} />,
              <Route
                exact
                path={`${match.url}/:patientId/accept-form/`}
                component={AcceptPatientForm}
              />,
              <Route exact path={`${match.url}/:patientId/forms/`} component={PatientForms} />,
            ]
          ) : (
            <Redirect to={handleLandingPageRoutes(userToCheck)} />
          )}
        </Switch>
      </div>
    </div>
  );
};
