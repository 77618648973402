// @ts-ignore — no types provided
import Frisbee from 'frisbee';
import { AveannaSocketConnection, appContext } from '@aveanna/client-socket';
import { getApiEndpoint, getSocketEndpoint, FeatureFlags } from '../utils';
import { interfaces } from '../common';
import { UserRoles } from '../utils/userRoles';

const baseURI = getApiEndpoint();
const baseSocketURL = getSocketEndpoint();
let socket: AveannaSocketConnection | null = null;

export const api = new Frisbee({
  baseURI,
  headers: {
    Accept: 'application/json',
    Application: 'Scheduler',
    'Content-Type': 'application/json',
  },
  mode: 'cors',
  credentials: 'include',
});

export const getSocketConnection = async (
  user?: interfaces.IUser
): Promise<AveannaSocketConnection | null> => {
  if (
    FeatureFlags.FEATURE_REALTIME_NOTIFICATIONS === 'false' ||
    FeatureFlags.FEATURE_REALTIME_NOTIFICATIONS === false
  ) {
    console.log(
      'FEATURE FLAG: Realtime notifications turned off, realtime notifications will not work.'
    );

    return null;
  }

  if (user) {
    const userRoles = new UserRoles(user);
    if (!userRoles.isScheduler) {
      console.log(
        'User is not a CGM Scheduler. Realtime notifications are not enabled for EVV Schedulers.'
      );
      return null;
    }
  }

  if (socket) {
    return socket;
  }

  if (!user || !user.token) {
    throw new Error(
      'No user was passed in, or the user had no authorization token. Could not make a new socket connection.'
    );
  }

  const tempUser = {
    id: user.id,
    locationId: user.locationId,
    token: user.token,
  };

  console.log('creating new socket... ');
  try {
    const tempSocket = new AveannaSocketConnection({
      onSocketEvent: socketLogger,
      socketURL: baseSocketURL,
      baseURL: baseURI,
      user: tempUser,
      appContext: appContext.SCHEDULER,
      isLogging: false,
      subscribeToEventLogs: false,
    });

    await tempSocket.init();

    // assign the new socket to the global so we can recycle it
    socket = tempSocket;
    return tempSocket;
  } catch (error) {
    console.error(`Could not create a socket...`);
    console.error(`Failure reason: ${error}`);

    return null;
  }
};

const socketLogger = (data: any) => {
  console.log('SOCKET LOGGER: socket event received: ', data);
};

export const parseApiResponse = (response: any) => {
  try {
    if (response.status >= 300) {
      throw new Error(`Something went wrong with the request ${response.status}`);
    }
    return response.body;
  } catch (error) {
    console.log('Error updating patient location ', error);
    throw error;
  }
};
