import { NotificationReducerType } from './constants';

export interface ISetNotificationArguments {
  [key: string]: boolean;
}

export const setRequestedNotification = (isNotified: boolean) => {
  return {
    type: NotificationReducerType.SET_IS_SENT_NOTIFIED,
    payload: isNotified,
  };
};

export const setPendingNotification = (isNotified: boolean) => {
  return {
    type: NotificationReducerType.SET_IS_APPLIED_NOTIFIED,
    payload: isNotified,
  };
};

export const settAwardedNotification = (isNotified: boolean) => {
  return {
    type: NotificationReducerType.SET_IS_AWARDED_NOTIFIED,
    payload: isNotified,
  };
};

export const setScheduledNotification = (isNotified: boolean) => {
  return {
    type: NotificationReducerType.SET_IS_SCHEDULED_NOTIFIED,
    payload: isNotified,
  };
};

export const setShiftNotification = (notificationInput: ISetNotificationArguments) => {
  return {
    type: NotificationReducerType.SET_SHIFT_NOTIFICATION,
    payload: notificationInput,
  };
};
