export enum NotificationReducerType {
  SET_SHIFT_NOTIFICATION = 'SET_SHIFT_NOTIFICATION',
  SET_IS_OPEN_NOTIFIED = 'SET_IS_OPEN_NOTIFIED',
  SET_IS_SENT_NOTIFIED = 'SET_IS_SENT_NOTIFIED',
  SET_IS_APPLIED_NOTIFIED = 'SET_IS_APPLIED_NOTIFIED',
  SET_IS_AWARDED_NOTIFIED = 'SET_IS_AWARDED_NOTIFIED',
  SET_IS_SCHEDULED_NOTIFIED = 'SET_IS_SCHEDULED_NOTIFIED',
  SET_IS_ORIENTATION_REQUEST_NOTIFIED = 'SET_IS_ORIENTATION_REQUEST_NOTIFIED',
}

export enum NotificationStateKeys {
  IS_OPEN_SHIFT_NOTIFIED = 'IS_OPEN_SHIFT_NOTIFIED',
  IS_SENT_SHIFT_NOTIFIED = 'IS_SENT_SHIFT_NOTIFIED',
  IS_APPLIED_SHIFT_NOTIFIED = 'IS_APPLIED_SHIFT_NOTIFIED',
  IS_AWARDED_SHIFT_NOTIFIED = 'IS_AWARDED_SHIFT_NOTIFIED',
  IS_SCHEDULED_SHIFT_NOTIFIED = 'IS_SCHEDULED_SHIFT_NOTIFIED',
  IS_ORIENTATION_REQUEST_NOFITIED = 'IS_ORIENTATION_REQUEST_NOFITIED',
}
