import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';
import 'moment-timezone';
import { connect } from 'react-redux';

// @ts-ignore — no types provided
import Sidebar from 'react-sidebar';
import MaterialIcon from 'material-icons-react';
import { useToasts } from 'react-toast-notifications';

import styles from './SingleAide.module.css';
import { Error, Loading, ReusableTooltip } from '../../common';
import {
  createClockInOut,
  getAllAides,
  getDvrByClockInOutReviewId,
  getTotalHoursPerPayPeriod,
  saveClockInOut,
} from '../../api';
import { ShiftSidebar } from '../ShiftPanel/ShiftSidebar';
import {
  clearCurrentShift as clearCurrentShiftAction,
  setCurrentShift as setCurrentShiftAction,
} from '../actions';
import { Actions } from '../containers/Actions';
import { DVRActionComponent } from '../ShiftDetails/ShiftDetailsPrinter';
import { NewShiftSidebar } from '../containers';
import { formatPhoneNumber } from '../../utils/formatter';
import { getTotalHours } from '../../utils/shifts';
import { GetSourceAlert } from '../../common/ReusableTooltip';
import {
  getOnlyEvvShiftFilter,
  isEvvSource,
  ONLY_EVV_SHIFT_FILTER,
  setOnlyEvvShiftFilter,
} from '../../utils/app';
import { parseTime } from '../../utils/date';

interface INursePayPeriod {
  startDate: string;
  endDate: string;
  approved: number;
  pending: number;
}

const formatTimeToString = (time: moment.Moment | null, format: string): string => {
  if (!time) {
    return '?';
  }
  return time.format(format);
};

const ReviewsTable = (props: any) => {
  const [nursePayPeriod, setNursePayPeriod] = useState({
    startDate: moment().day(1).format('MM-DD-YYYY'),
    endDate: moment().day(8).format('MM-DD-YYYY'),
    approved: 0,
    pending: 0,
  });
  const patients = getPatients();
  const aideHeaderInfo = getAideHeaderInfo();

  useEffect(() => {
    async function getTotalHoursAsync() {
      const currentDate = moment(new Date());
      try {
        const res = await getTotalHoursPerPayPeriod(aideHeaderInfo.aideId);
        let tempTotalHours: INursePayPeriod = {
          startDate: '',
          endDate: '',
          approved: 0,
          pending: 0,
        };
        if (res) {
          await res.map((each: any) => {
            const start = moment(each.start).format('YYYY-MM-DD hh:mm:ss');
            const end = moment(each.end).format('YYYY-MM-DD hh:mm:ss');
            if (
              moment(start).isSameOrBefore(currentDate) &&
              moment(end).isSameOrAfter(currentDate)
            ) {
              tempTotalHours = {
                startDate: moment(each.start).format('MM-DD-YYYY'),
                endDate: moment(each.end).format('MM-DD-YYYY'),
                approved: each.approved,
                pending: each.pending,
              };
            }
          });
        }
        setNursePayPeriod(tempTotalHours);
      } catch (error) {
        console.log(error);
      }
    }

    getTotalHoursAsync();
  }, []);

  function getAideHeaderInfo() {
    const reviews = props.reviews;
    let tempAideHeaderInfo = { aideName: ``, aidePhone: ``, aidePic: ``, aideId: -1 };

    if (reviews) {
      for (const r of reviews) {
        const givenName = nullcheck(r, ['nurseProfile', 'user', 'givenName']);
        const familyName = nullcheck(r, ['nurseProfile', 'user', 'familyName']);
        const imageUri = nullcheck(r, ['nurseProfile', 'user', 'imageUri']);
        const phoneNumber = nullcheck(r, ['nurseProfile', 'primaryPhone']);
        const id = nullcheck(r, ['nurseId']);

        if (givenName && familyName && phoneNumber && imageUri) {
          tempAideHeaderInfo = {
            aideName: `${givenName} ${familyName}`,
            aidePhone: phoneNumber,
            aidePic: imageUri,
            aideId: id,
          };
        }
      }
    }

    return tempAideHeaderInfo;
  }

  function getPatients() {
    // TODO: this is also definitely its own component.
    // ClockInOut Objects
    const reviews = props.reviews;
    const dict = {} as any;

    if (reviews) {
      const sortedReviews = reviews.sort((a: any, b: any) => {
        const dateA = moment(a.adjustedClockInTimeStamp);
        const dateB = moment(b.adjustedClockInTimeStamp);
        if (dateB.isBefore(dateA)) {
          return 1;
        }
        if (dateB.isAfter(dateA)) {
          return -1;
        }
        return 0;
      });

      for (const r of sortedReviews) {
        const id = nullcheck(r, ['patient', 'id']);
        const pk = 'pk' + id;
        const givenName = nullcheck(r, ['patient', 'givenName']);
        const familyName = nullcheck(r, ['patient', 'familyName']);
        const externalSource = nullcheck(r, ['patient', 'externalSource']);
        const externalId = nullcheck(r, ['patient', 'externalId']);
        const searchField = nullcheck(r, ['patient', 'searchField']);

        // New patient
        if (!Object.keys(dict).includes(pk)) {
          dict[pk] = {
            id,
            name: givenName + ' ' + familyName,
            shifts: [] as any,
            totalHours: 0,
            externalSource: externalSource,
            externalId: externalId,
            searchField: searchField,
          };
        }

        const status = nullcheck(r, ['status']);
        if (status) {
          continue;
        }

        // Existing patient (hint: new patients exist at this point)
        const sId = nullcheck(r, ['id']);

        // Get clockin/out datetimes...
        const timeInStr = nullcheck(r, ['adjustedClockInTimeStamp']);
        const timeInTimezone = nullcheck(r, ['clockInOutTimezone', 'clockInTimezone']);

        const timeOutStr = nullcheck(r, ['adjustedClockOutTimeStamp']);
        const timeOutTimezone = nullcheck(r, ['clockInOutTimezone', 'clockOutTimezone']);

        const timeIn = parseTime(timeInStr, timeInTimezone);
        const timeOut = parseTime(timeOutStr, timeOutTimezone);

        // Get strings of clock data.
        let sDate = formatTimeToString(timeIn, 'MM-DD-YYYY');

        // ...hypothetical edge case: invalid clockin with valid clockout. shouldn't be possible.
        if (sDate === '?') {
          sDate = formatTimeToString(timeOut, 'MM-DD-YYYY');
        }

        const sHrs = timeIn && timeOut ? getTotalHours(timeOut, timeIn) : `?`;
        const sLoggedTime = `${timeIn ? timeIn.format('hh:mmA') : '?'} - ${
          timeOut ? timeOut.format('hh:mmA') : '?'
        }`;

        const sAlerts = {
          timeAlerts: nullcheck(r, ['timeAlerts']),
          locationAlerts: nullcheck(r, ['locationAlerts']),
          overlapAlerts: nullcheck(r, ['overlapAlerts']),
          officeAlerts: nullcheck(r, ['officeAlerts']),
          patientSource: [externalSource],
          clockInSource: r.clockInSource,
          clockOutSource: r.clockOutSource,

          renderAlerts() {
            const timeAlertsExist = this.timeAlerts && this.timeAlerts.length > 0;
            const locationAlertsExist =
              this.locationAlerts &&
              ((this.locationAlerts.clockInAlert && this.locationAlerts.clockInAlert.length > 0) ||
                (this.locationAlerts.clockOutAlert &&
                  this.locationAlerts.clockOutAlert.length > 0));
            const overlapAlertsExist = this.overlapAlerts && this.overlapAlerts.length > 0;
            const officeAlertsExist = this.officeAlerts && this.officeAlerts.length > 0;
            const alertsExist =
              timeAlertsExist || locationAlertsExist || overlapAlertsExist || officeAlertsExist;

            return (
              <div className={styles.icons}>
                {alertsExist ? (
                  <>
                    <ReusableTooltip
                      zIndex={300}
                      fontColor={'black'}
                      backgroundColor={'white'}
                      placement={'top'}
                      data={this.timeAlerts}
                      tooltipHeader={'Time Issues'}
                      iconName={'schedule'}
                      iconColor={'maroon'}
                    />
                    <ReusableTooltip
                      zIndex={300}
                      fontColor={'black'}
                      backgroundColor={'white'}
                      placement={'top'}
                      data={this.locationAlerts}
                      tooltipHeader={'Location Issues'}
                      iconName={'location_on'}
                      iconColor={'maroon'}
                    />
                    <ReusableTooltip
                      zIndex={300}
                      fontColor={'black'}
                      backgroundColor={'white'}
                      placement={'top'}
                      data={this.overlapAlerts}
                      tooltipHeader={'Overlap Issues'}
                      iconName={'settings_overscan'}
                      iconColor={'maroon'}
                    />
                    <ReusableTooltip
                      zIndex={300}
                      fontColor={'black'}
                      backgroundColor={'white'}
                      placement={'top'}
                      data={this.officeAlerts}
                      tooltipHeader={'Office Issues'}
                      iconName={'location_city'}
                      iconColor={'maroon'}
                    />
                    <ReusableTooltip
                      zIndex={300}
                      fontColor={'black'}
                      backgroundColor={'white'}
                      placement={'bottom'}
                      data={this.patientSource}
                      tooltipHeader={'Patient Source'}
                      iconName={'account_circle'}
                      iconColor={'maroon'}
                      autoWidth={true}
                    />
                    {GetSourceAlert('Clock In Source', this.clockInSource)}
                    {GetSourceAlert('Clock Out Source', this.clockOutSource)}
                  </>
                ) : (
                  <div>
                    <MaterialIcon icon="check" color="green" />
                  </div>
                )}
              </div>
            );
          },
        };
        const sNotes = {
          // component
          clockInNote: nullcheck(r, ['clockInNote']),
          clockOutNote: nullcheck(r, ['clockOutNote']),

          renderNotes() {
            return (
              <>
                {(this.clockInNote && this.clockInNote.length) ||
                (this.clockOutNote && this.clockOutNote.length) ? (
                  <div>
                    Yes
                    <div className={styles.enable_tooltip}>
                      <ReusableTooltip
                        zIndex={300}
                        fontColor={'black'}
                        backgroundColor={'white'}
                        placement={'top'}
                        data={
                          this.clockInNote && this.clockInNote.length
                            ? this.clockInNote
                            : this.clockOutNote
                        }
                        tooltipHeader={"Caregiver's Notes"}
                        iconName={'info'}
                        iconColor={'rgb(90, 93, 133)'}
                      >
                        {this.clockInNote && this.clockInNote.length ? (
                          <div>
                            <div className={styles.tooltip_type}>Clock In</div>
                            {this.clockInNote}
                          </div>
                        ) : null}
                        {this.clockOutNote && this.clockOutNote.length ? (
                          <div>
                            <div className={styles.tooltip_type}>Clock Out</div>
                            {this.clockOutNote}
                          </div>
                        ) : null}
                      </ReusableTooltip>
                    </div>
                  </div>
                ) : (
                  'No'
                )}
              </>
            );
          },
        };
        const sActions = {
          renderActions() {
            return (
              <Actions
                menuItems={[
                  {
                    name: 'Review',
                    action: () => {
                      props.openReview(r);
                    },
                  },
                  {
                    name: 'Print DVR',
                    component: <DVRActionComponent shift={r} />,
                  },
                ]}
              />
            );
          },
        };

        // New shift
        dict[pk].shifts.push({
          id: sId,
          date: sDate,
          hrs: sHrs,
          loggedTime: sLoggedTime,
          alerts: sAlerts.renderAlerts(),
          notes: sNotes.renderNotes(),
          actions: sActions.renderActions(),
        });
      }
    }
    return dict; // dict goes to ...
  }

  // TODO: styled components
  const SA_ReviewsTable = `
        ${1 ? styles.SA_ReviewsTable : ``}
        ${props.reviews ? styles.SA_ReviewsTable__slideIn : ``}
    `;
  const SA_ReviewsTable__Header = `
        ${1 ? styles.SA_ReviewsTable__Header : ``}
    `;
  const SA_ReviewsTable__Body = `
        ${1 ? styles.SA_ReviewsTable__Body : ``}
    `;
  const SA_PatientsTable = `
        ${1 ? styles.SA_PatientsTable : ``}
    `;
  const SA_PatientsTable__Header = `
        ${1 ? styles.SA_PatientsTable__Header : ``}
        ${1 ? styles.SA_PatientsTable__Header___isSticky : ``}
    `;
  const SA_PatientsTable__Body = `
        ${1 ? styles.SA_PatientsTable__Body : ``}
    `;

  return (
    <div className={SA_ReviewsTable}>
      <div className={SA_ReviewsTable__Header}>
        Caregiver: {aideHeaderInfo.aideName}{' '}
        <span className={styles.PhoneSpan}>{formatPhoneNumber(aideHeaderInfo.aidePhone)}</span>
        <span className={styles.SA_ReviewsTable__HoursText}>
          <b>Pay period:</b>
          {nursePayPeriod.startDate} - {nursePayPeriod.endDate}
          <b>Approved total hrs:</b> {nursePayPeriod.approved.toFixed(1)} hrs
          <b>Pending total hrs:</b> {nursePayPeriod.pending.toFixed(1)} hrs
        </span>
      </div>
      <label>
        <input
          type="checkbox"
          checked={props.onlyEvvShifts}
          onChange={() => props.filterEvvShifts(props.onlyEvvShifts)}
        />
        {ONLY_EVV_SHIFT_FILTER}
      </label>
      <div className={SA_ReviewsTable__Body}>
        <div className={styles.SA_Patients}>
          {Object.keys(patients).map((pk: any, index: number) => {
            // only return the table thing if there are shifts present
            return patients[pk].shifts.length > 0 ? (
              <div className={SA_PatientsTable} key={index}>
                <div className={SA_PatientsTable__Header}>
                  <div className={styles.SA_PatientsTable__Header___patientName}>
                    Patient: {patients[pk].name} [MR #{patients[pk].searchField} -{' '}
                    {patients[pk].externalSource}]
                  </div>
                  <div>
                    <button
                      data-cy={'aides-add-new-shift-btn'}
                      onClick={() => {
                        props.addNewShift(patients[pk], aideHeaderInfo);
                      }}
                      className={`${styles.SA_Button} ${styles.SA_Button__inverted}`}
                    >
                      Add New Shift
                    </button>
                  </div>
                </div>
                <div className={SA_PatientsTable__Body}>
                  <div className={styles.SA_ShiftsTable__Header}>
                    <div className={styles.SA_ShiftsTable__Cell}>DATE</div>
                    <div className={styles.SA_ShiftsTable__Cell}>HRS</div>
                    <div className={styles.SA_ShiftsTable__Cell}>LOGGED TIME</div>
                    <div className={styles.SA_ShiftsTable__Cell}>ALERTS</div>
                    <div className={styles.SA_ShiftsTable__Cell}>NOTES</div>
                    <div className={styles.SA_ShiftsTable__Cell}>ACTIONS</div>
                  </div>
                  <div className={styles.SA_ShiftsTable__Body}>
                    {patients[pk].shifts.map((s: any, i: number) => {
                      let inc = +s.hrs; // unary for number cast
                      inc = inc ? inc : 0;
                      patients[pk].totalHours =
                        Math.floor((patients[pk].totalHours + inc) * 100) / 100;
                      return (
                        <React.Fragment key={i}>
                          <div className={styles.SA_Shift}>
                            <div className={styles.SA_ShiftsTable__Cell}>{s.date}</div>
                            <div className={styles.SA_ShiftsTable__Cell}>{s.hrs}</div>
                            <div
                              data-cy={`sa-logged-time-${s.id}`}
                              className={styles.SA_ShiftsTable__Cell}
                            >
                              {s.loggedTime}
                            </div>
                            <div className={styles.SA_ShiftsTable__Cell}>{s.alerts}</div>
                            <div className={styles.SA_ShiftsTable__Cell}>{s.notes}</div>
                            <div
                              data-cy={`sa-actions-${s.id}`}
                              className={styles.SA_ShiftsTable__Cell___actions}
                            >
                              {s.actions}
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <div className={styles.SA_PatientsTable__Footer}>
                    <div>Total</div>
                    <div>{patients[pk].totalHours}</div>
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                </div>
              </div>
            ) : null;
          })}
        </div>
      </div>
    </div>
  );
};

interface IRouteParams {
  id?: string;
}

interface IState {
  isLoading: boolean;
  reviews: any;
  filteredReviews: any;
  isShiftPanelOpen: boolean;
  hydratedShift: any;
  selectedReview: any;
  aideId: number;
  isNewShift: boolean;
  error: boolean;
  onlyEvvShifts: boolean;
}

type IProps = {
  setCurrentShift: (shift: any) => void;
  clearCurrentShift: () => void;
} & RouteComponentProps<IRouteParams>;

class SingleAide extends React.Component<IProps, IState> {
  public state: IState = {
    selectedReview: null as any,
    isLoading: true,
    reviews: null as any,
    filteredReviews: null as any,
    isShiftPanelOpen: false,
    hydratedShift: null as any,
    aideId: -1,
    isNewShift: false,
    error: false,
    onlyEvvShifts: !getOnlyEvvShiftFilter(),
  };

  constructor(props: any) {
    super(props);
    this.handleSaveReview = this.handleSaveReview.bind(this);
    this.handleOpenNewReview = this.handleOpenNewReview.bind(this);
    this.filterEvvShifts = this.filterEvvShifts.bind(this);
  }

  public componentDidMount = async () => {
    // TODO!: get patient and nurse here
    await this.getAideReviews(this.state.aideId);
    this.filterEvvShifts(this.state.onlyEvvShifts);
  };

  public render() {
    const { isLoading, filteredReviews, hydratedShift, selectedReview, isNewShift, error } =
      this.state;

    const SA_Page = `
            ${1 ? styles.SA_Page : ``}
            ${1 ? styles.SA_Content : ``}
            ${isLoading && styles.SA_Page__isLoading}
            ${filteredReviews && styles.SA_Page__hasReviews}
        `;

    return (
      <div className={styles.SA_Parent}>
        <Sidebar
          sidebar={
            isNewShift ? (
              <NewShiftSidebar
                templateShift={this.state.selectedReview}
                onClose={this.handleCloseReview}
                refreshData={() => this.getAideReviews(this.state.aideId)} // TODO!: get patient and nurse here
              ></NewShiftSidebar>
            ) : (
              <ShiftSidebar
                onSave={() => {}}
                onSaveNew={() => {
                  this.handleSaveNewReview();
                }}
                dvr={hydratedShift}
                review={selectedReview}
                onClose={this.handleCloseReview}
                refreshData={() => this.getAideReviews(this.state.aideId)} // TODO!: get patient and nurse here
              />
            )
          }
          pullRight={true}
          open={this.state.isShiftPanelOpen}
          styles={{
            sidebar: {
              display: 'flex',
              background: 'white',
              zIndex: 6,
            },
            overlay: { zIndex: 5 },
          }}
        >
          <div className={SA_Page}>
            {isLoading ? (
              <Loading style={{ display: 'flex', flex: 1 }} />
            ) : error ? (
              <Error errorMessage={'Error Fetching Shifts. Please try again or contact support.'} />
            ) : (
              <>
                <ReviewsTable
                  reviews={filteredReviews}
                  onlyEvvShifts={this.state.onlyEvvShifts}
                  filterEvvShifts={this.filterEvvShifts}
                  openReview={(r: any) => {
                    this.handleOpenReview(r);
                  }}
                  addNewShift={(patient: any, aideInfo: any) => {
                    this.handleOpenNewReview(patient, aideInfo);
                  }}
                />
              </>
            )}
          </div>
        </Sidebar>
      </div>
    );
  }

  private filterEvvShifts(onlyEvvShifts: boolean) {
    this.setState({
      onlyEvvShifts: !onlyEvvShifts,
    });
    setOnlyEvvShiftFilter(!onlyEvvShifts);

    if (!onlyEvvShifts) {
      const filteredList = this.state.reviews.filter((review: any) => {
        return isEvvSource(review);
      });
      this.setState({
        filteredReviews: filteredList,
      });
    } else {
      this.setState({
        filteredReviews: this.state.reviews,
      });
    }
  }

  private getAideReviews = async (nurseId: number = 0, patientId: number = 0) => {
    const stringId = `${this.props.match.params.id}`;
    const id = parseInt(stringId, 10);

    const body = await getAllAides(id, patientId);
    const allReviews = body
      ? lazyclone(body).map((r: any) => {
          return r;
        })
      : null;

    this.setState({
      reviews: allReviews,
      filteredReviews: allReviews,
      isLoading: false,
      aideId: id,
      error: allReviews === null,
    });
  };

  private handleOpenReview = async (r: any) => {
    const { setCurrentShift } = this.props;

    const htmlResponse = await getDvrByClockInOutReviewId(r.id);
    const hydratedShift = htmlResponse.body;
    this.setState({ isShiftPanelOpen: true, hydratedShift, selectedReview: r });

    // add to redux store
    setCurrentShift(hydratedShift);
  };

  private handleSaveNewReview = async () => {
    const review = this.state.selectedReview;
    let clockInTime = review.adjustedClockInTimeStamp;
    clockInTime = clockInTime ? clockInTime : moment().format();
    let clockOutTime = review.adjustedClockOutTimeStamp;
    clockOutTime = clockOutTime ? clockOutTime : moment().format();

    const clockInOutBody = {
      nurseId: review.nurseId,
      patientId: review.patientId,
      clockInTime,
      clockOutTime,
    };

    await createClockInOut(clockInOutBody);
    // TODO!: get patient and nurse here
    await this.getAideReviews(this.state.aideId);

    this.setState({
      isShiftPanelOpen: false,
      hydratedShift: null,
      selectedReview: null,
    });
  };

  private handleOpenNewReview = async (patient: any, aideInfo: any) => {
    const blankShift = {
      id: -1,
      aide: aideInfo,
      patient: patient,
      clockInOut: {
        adjustedClockInTimeStamp: moment().format(),
        adjustedClockOutTimeStamp: moment().format(),
      },
    };

    this.setState({
      isNewShift: true,
      hydratedShift: blankShift,
      selectedReview: blankShift,
      isShiftPanelOpen: true,
    });
  };

  private handleCloseReview = () => {
    const { clearCurrentShift } = this.props;

    this.setState({ isShiftPanelOpen: false, isNewShift: false });
    clearCurrentShift();
  };

  private async handleSaveReview(review: any) {
    await saveClockInOut(review);
    this.setState({ isShiftPanelOpen: false, hydratedShift: null, selectedReview: null });
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCurrentShift: (shift: any) => dispatch(setCurrentShiftAction(shift)),
    clearCurrentShift: () => dispatch(clearCurrentShiftAction()),
  };
};

export default connect(null, mapDispatchToProps)(SingleAide);

function lazyclone(o: any): any {
  return JSON.parse(JSON.stringify(o));
}

function nullcheck(obj: any, path: any): any {
  if (obj == null || obj == undefined) {
    return null;
  }

  // if there are params
  if (path && path.length) {
    // slice a key
    const key = path.splice(0, 1)[0];
    const value = obj[key];

    // check falsys
    if (obj[key] === false) {
      return false;
    }
    if (obj[key] === 0) {
      return 0;
    }
    if (obj[key] === null) {
      return null;
    }
    if (obj[key] === '') {
      return '';
    }
    if (obj[key] === undefined) {
      return undefined;
    }
    if (obj[key] === NaN) {
      return NaN;
    }

    // if there something there, call recursively
    if (obj[key]) {
      return nullcheck(obj[key], path);
    }
  }
  return obj;
}
