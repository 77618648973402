import React, { useEffect, useState } from 'react';
import GoogleMapReact, { Coords } from 'google-map-react';

import { ShiftPanelHeading, ShiftPanelText, ShiftPanelTextBold } from './ShiftSidebar';
import styled from 'styled-components';
import { ClockLocationMarker } from '../SingleAide/ClockLocationMarker';
import { HomeMarker } from '../SingleAide/HomeMarker';
import { getGoogleMapsKey } from '../../utils/app';

interface IProps {
  dvr: any;
  // onClickHeader: () => void;
}

export const ShiftMap = ({ dvr }: IProps) => {
  const [defaultCenter, setDefaultCenter] = useState<Coords | undefined>(undefined);
  const [center, setCenter] = useState<Coords | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<Coords[]>([]);

  useEffect(() => {
    if (dvr) {
      const coords: Coords[] = [
        {
          lat: dvr.patient.location.latitude,
          lng: dvr.patient.location.longitude,
        },
        {
          lat: dvr.clockInOut.nurseProfile.location.latitude,
          lng: dvr.clockInOut.nurseProfile.location.longitude,
        },
        {
          lat: dvr.clockInOut.clockInLatitude,
          lng: dvr.clockInOut.clockInLongitude,
        },
        {
          lat: dvr.clockInOut.clockOutLatitude,
          lng: dvr.clockInOut.clockOutLongitude,
        },
      ];

      let locations: any = {
        latitude: { min: null, max: null, difference: null, center: null },
        longitude: { min: null, max: null, difference: null, center: null },
      };

      coords.map((location: Coords) => {
        // find latitude/longitude extremes for map boundaries
        if (locations.latitude.min === null || location.lat < locations.latitude.min) {
          locations.latitude.min = location.lat;
        }
        if (locations.latitude.max === null || location.lat > locations.latitude.max) {
          locations.latitude.max = location.lat;
        }
        if (locations.longitude.min === null || location.lng < locations.longitude.min) {
          locations.longitude.min = location.lng;
        }
        if (locations.longitude.max === null || location.lng > locations.longitude.max) {
          locations.longitude.max = location.lng;
        }
      });

      // pre-calculate often used values based on the extremes
      locations.latitude.difference = locations.latitude.max - locations.latitude.min;
      locations.longitude.difference = locations.longitude.max - locations.longitude.min;
      locations.latitude.center = (locations.latitude.max + locations.latitude.min) / 2;
      locations.longitude.center = (locations.longitude.max + locations.longitude.min) / 2;

      // default region for map display
      const latitudeDelta: number = Math.max(0.2, locations.latitude.difference * 1.1);
      const longitudeDelta: number = Math.max(0.1, locations.longitude.difference * 1.1);
      setDefaultCenter({
        lat: locations.latitude.center,
        lng: locations.longitude.center,
      });
      setCenter({
        lat: locations.latitude.center,
        lng: locations.longitude.center,
      });

      setCoordinates(coords);
      setIsLoaded(true);
    }
  }, []);

  const handleApiLoaded = (map: any, maps: any) => {
    let bounds = new maps.LatLngBounds();
    coordinates.map((coord: Coords) => {
      let loc = new maps.LatLng(coord.lat, coord.lng);
      bounds.extend(loc);
    });

    map.fitBounds(bounds);
    map.panToBounds(bounds);
  };

  if (!dvr) {
    return <></>;
  }

  // adjust markers so you can see them both
  const errorMargin = 0.000008;
  if (
    Math.abs(dvr.clockInOut.clockInLatitude - dvr.clockInOut.clockOutLatitude) < errorMargin &&
    Math.abs(dvr.clockInOut.clockInLongitude - dvr.clockInOut.clockOutLongitude) < errorMargin
  ) {
    dvr.clockInOut.clockInLongitude -= errorMargin;
    dvr.clockInOut.clockOutLongitude += errorMargin;
  }

  return (
    <>
      {isLoaded && (
        <>
          <ShiftPanelHeading>GPS Locations</ShiftPanelHeading>

          <TableRow>
            <TableCell>
              <div style={{ transform: 'scale(0.6)', marginTop: 30, marginRight: 30 }}>
                <ClockLocationMarker color={'#72BF48'} />
              </div>
              <ShiftPanelText>Clock in</ShiftPanelText>
            </TableCell>

            <TableCell>
              <div style={{ transform: 'scale(0.6)', marginTop: 11, marginRight: 30 }}>
                <HomeMarker key={dvr.clockInOut.nurseProfile.locationId} color={'gray'} />
              </div>
              <ShiftPanelText>Caregiver Location</ShiftPanelText>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <div style={{ transform: 'scale(0.6)', marginTop: 30, marginRight: 30 }}>
                <ClockLocationMarker color={'#B00020'} />
              </div>
              <ShiftPanelText>Clock out</ShiftPanelText>
            </TableCell>

            <TableCell>
              <div style={{ transform: 'scale(0.6)', marginTop: 11, marginRight: 30 }}>
                <HomeMarker key={dvr.patient.id} color={'black'} />
              </div>
              <ShiftPanelText>Patient Location</ShiftPanelText>
            </TableCell>
          </TableRow>

          <MapContainer>
            <GoogleMapReact
              defaultCenter={defaultCenter}
              defaultZoom={1}
              center={center}
              zoom={1}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              bootstrapURLKeys={{
                key: getGoogleMapsKey(),
              }}
            >
              <HomeMarker
                key={dvr.clockInOut.nurseProfile.locationId}
                lat={dvr.clockInOut.nurseProfile.location.latitude}
                lng={dvr.clockInOut.nurseProfile.location.longitude}
                color={'gray'}
              />
              <HomeMarker
                key={dvr.patient.id}
                lat={dvr.patient.location.latitude}
                lng={dvr.patient.location.longitude}
                color={'black'}
              />
              <ClockLocationMarker
                key={'in'}
                lat={dvr.clockInOut.clockInLatitude}
                lng={dvr.clockInOut.clockInLongitude}
                color={'#72BF48'}
              />
              <ClockLocationMarker
                key={'out'}
                lat={dvr.clockInOut.clockOutLatitude}
                lng={dvr.clockInOut.clockOutLongitude}
                color={'#B00020'}
              />
            </GoogleMapReact>
          </MapContainer>

          <ShiftPanelHeading>Clock-in Notes</ShiftPanelHeading>
          <ShiftPanelTextBold>
            {dvr.clockInOut.clockInNote ? dvr.clockInOut.clockInNote : 'None'}
          </ShiftPanelTextBold>
          <ShiftPanelHeading>Clock-out Notes</ShiftPanelHeading>
          <ShiftPanelTextBold>
            {dvr.clockInOut.clockOutNote ? dvr.clockInOut.clockOutNote : 'None'}
          </ShiftPanelTextBold>
        </>
      )}
    </>
  );
};

const MapContainer = styled.div`
  width: 535px;
  height: 385px;
  padding-top: 25px;
`;

const TableRow = styled.div`
  display: flex;
  flex: 1;
`;

const TableCell = styled.div`
  display: flex;
  flex: 1;
  padding: 5px 0;
  & > div {
    line-height: 30px;
    &:first-of-type {
      position: relative;
      top: 0;
      margin-right: 5px;
    }
  }
`;
