import React, { useState } from 'react';
import styled from 'styled-components';

import { StyledButton, ApprovalButton, interfaces } from '../../common';
import { LocationDropdown } from './LocationDropdown';

const LocationActivatorContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 12px;
`;

const LocationActivatorControls = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
`;

interface IProps {
  locations: interfaces.ILocation[];
  title: string;
  onActivate: any;
  actionButtonTitle: string;
  info?: any;
}

export const LocationActivator = ({
  locations,
  title,
  info,
  onActivate,
  actionButtonTitle,
}: IProps) => {
  const [selectedLocation, setCurrentSelection] = useState<interfaces.IDropdownOption | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const tempLocationOptions: interfaces.IDropdownOption[] = locations.map(
    (tempLocation: interfaces.ILocation) => {
      return {
        label: tempLocation.description,
        value: tempLocation.id,
      };
    }
  );

  const activate = async () => {
    setIsLoading(true);
    if (onActivate && selectedLocation) {
      await onActivate(selectedLocation.value);
      setCurrentSelection(null);
    }

    setIsLoading(false);
  };

  return (
    <LocationActivatorContainer>
      <p>{title}</p>
      {info && info()}
      <LocationActivatorControls>
        <LocationDropdown
          value={selectedLocation}
          options={tempLocationOptions}
          onChange={(tempLocation: interfaces.IDropdownOption) => {
            setCurrentSelection(tempLocation);
          }}
          data-cy={'location-dropdown'}
        />
        <StyledButton
          data-cy={'location-activator-button'}
          disabled={!selectedLocation || isLoading}
          isLoading={isLoading}
          onClick={activate}
          style={{ alignSelf: 'flex-end' }}
        >
          {actionButtonTitle}
        </StyledButton>
      </LocationActivatorControls>
    </LocationActivatorContainer>
  );
};
