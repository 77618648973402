import { interfaces, LocationCodeNames, RejectionCodeNames, TimeCodeNames } from '../common';
import { NewShiftCodeNames } from '../common/enums';
import { get } from './apiCallHelper';
import { FormattedReasonCode, RejectionReason } from '../common/types';

export const getAndSetReasonCodes = async (setAllReasonCodes: (reasonCodes: any) => void) => {
  try {
    const tempAllReasonCodes = await getAllReasonCodes();
    setAllReasonCodes(tempAllReasonCodes);
  } catch (error) {
    console.log('error getting the rejection reasons — ', error);
  }
};

export const getAllReasonCodes = async (): Promise<interfaces.IReasonCodeObject> => {
  try {
    const tempNewShiftReasons = await getNewShiftReasons();
    const tempLocationReasons = await getLocationReasons();
    const tempTimeReasons = await getTimeReasons();
    const tempAllRejectionReasons = await getRejectionReasons();

    const tempReasonCodes: interfaces.IReasonCodeObject = {
      allReasonCodes: [...tempLocationReasons, ...tempTimeReasons, ...tempAllRejectionReasons],
      allRejectionReasons: tempAllRejectionReasons,
      timeReasons: tempTimeReasons,
      locationReasons: tempLocationReasons,
      newShiftReasons: tempNewShiftReasons,
      [LocationCodeNames.EVVLocationInCorrection]: tempLocationReasons.filter(
        (tempReason: interfaces.IReasonCode) =>
          tempReason.name === LocationCodeNames.EVVLocationInCorrection
      ),
      [LocationCodeNames.EVVLocationOutCorrection]: tempLocationReasons.filter(
        (tempReason: interfaces.IReasonCode) =>
          tempReason.name === LocationCodeNames.EVVLocationOutCorrection
      ),
      [TimeCodeNames.EVVTimeInCorrection]: tempTimeReasons.filter(
        (tempReason: interfaces.IReasonCode) =>
          tempReason.name === TimeCodeNames.EVVTimeInCorrection
      ),
      [TimeCodeNames.EVVTimeOutCorrection]: tempTimeReasons.filter(
        (tempReason: interfaces.IReasonCode) =>
          tempReason.name === TimeCodeNames.EVVTimeOutCorrection
      ),
      [RejectionCodeNames.EVVRejectOverride]: tempAllRejectionReasons.filter(
        (tempReason: interfaces.IReasonCode) =>
          tempReason.name === RejectionCodeNames.EVVRejectOverride
      ),
      [RejectionCodeNames.EVVReject]: tempAllRejectionReasons.filter(
        (tempReason: interfaces.IReasonCode) => tempReason.name === RejectionCodeNames.EVVReject
      ),
      [NewShiftCodeNames.EVVNew]: tempNewShiftReasons.filter(
        (tempReason: interfaces.IReasonCode) => tempReason.name === NewShiftCodeNames.EVVNew
      ),
    };
    return tempReasonCodes;
  } catch (error) {
    console.log('error getting reason codes — ', error);
    return {};
  }
};

export const getLocationReasons = async () => await getAllCodesOfType(LocationCodeNames);
export const getTimeReasons = async () => await getAllCodesOfType(TimeCodeNames);
export const getRejectionReasons = async () => await getAllCodesOfType(RejectionCodeNames);
export const getNewShiftReasons = async () => await getAllCodesOfType(NewShiftCodeNames, 1);

// Generic
export const getAllCodesOfType = async (type: object, version: number = 1) => {
  const allReasonAPICalls = Object.entries(type).map(async ([tempRejectTypeKey, tempTypeValue]) => {
    const literalsResponse = await getSingleReasonType(tempTypeValue, version);
    return literalsResponse;
  });

  const allReasons = await Promise.all(allReasonAPICalls);
  const combinedReasons = allReasons.reduce((accumulator, currentReason) => {
    accumulator = [...accumulator, ...currentReason];
    return accumulator;
  }, []);
  return combinedReasons;
};

const getSingleReasonType = async (rejectionName: string, version: number = 1) => {
  const tempReasonResponse = await get(
    `/api/BackOffice/Literals?App=EVV&Name=${rejectionName}&ordered=true&version=${version}`
  );
  return tempReasonResponse.body;
};

// To send back up to the API (put it in the format it needs to be in)
export const formatRejectionReasons = (
  clockInOutId: number,
  rejectionReasons: RejectionReason[]
): FormattedReasonCode[] | null => {
  if (!rejectionReasons || rejectionReasons.length === 0) {
    return null;
  }
  const formattedRejections = rejectionReasons.map((tempReason: RejectionReason) => {
    if (tempReason === null) {
      return tempReason;
    }
    // If there is an ID then it already exists, just pass it back on up unaltered
    return tempReason.id
      ? (tempReason as FormattedReasonCode)
      : { clockInOutId, reasonId: tempReason.value };
  });

  // Remove any blank reasons (user added but did not fill them out)
  const tempReasonsNonNull = formattedRejections.filter(
    (tempFormattedReason: FormattedReasonCode) => tempFormattedReason.reasonId !== null
  );

  // removes all duplicates
  const tempReasonsNonDuplicates = tempReasonsNonNull.filter(
    (tempReason: FormattedReasonCode, index: number) => {
      return (
        index ===
        tempReasonsNonNull.findIndex((reasonObj: FormattedReasonCode) => {
          return reasonObj.reasonId === tempReason.reasonId;
        })
      );
    }
  );
  return [...tempReasonsNonDuplicates];
};
