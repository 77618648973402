import React from 'react';
import styled from 'styled-components';

type Props = {
  nurse: any;
  lat: number;
  lng: number;
};

const Marker = styled.div`
  position: absolute;
  top: -30px;
  width: 30px;
  height: 30px;
  padding: 0px 3px 3px 0;
  border-radius: 50% 50% 50% 0%;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.5);
  background: white;
  transform: translate(-50%, -50%) rotateX(20deg) rotateZ(-45deg);
  transform-origin: 50% 50%;

  & > img {
    position: absolute;
    left: 0px;
    top: 0px;
    border-radius: 30px;
    transform: rotate(45deg);
    font-size: 16px;
    color: black;
    width: 33px;
    height: 33px;
  }
`;

export class NurseMapMarker extends React.PureComponent<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    return (
      <Marker>
        <img src={this.props.nurse.image} />
      </Marker>
    );
  }
}
