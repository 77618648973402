import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  getMessages,
  setActiveChannel as setActiveChannelAction,
  getNewChannelMessages as getNewChannelMessagesAction,
  checkForNewMessages as checkForNewMessagesAction,
  sendMessage as sendMessageAction,
  clearActiveChannel as clearActiveChannelAction,
} from '../actions';

import { FullPage } from '../../common';
import Channels from './Channels';
import Messages from './Messages';
import { IGetNewMessagesParams, IChannel } from '../interfaces';
import { interfaces as commonInterfaces } from '../../common';

type Props = {
  getMessages: any;
  activeChannelId: number;
  sendMessage: ({ text, channelId, sentById }: commonInterfaces.ISendMessageParams) => void;
  getNewChannelMessages: ({ channelId, lastMessageId }: IGetNewMessagesParams) => void;
  setActiveChannel: (id: number) => void;
  clearActiveChannel: () => void;
  checkForNewMessages: () => void;
  hasUnreadMessage: boolean;
  channels: any;
  user: any;
  isFetching: boolean;
} & RouteComponentProps;

class Chat extends Component<Props> {
  public componentDidMount() {
    this.props.getMessages();
  }

  private setActiveChannel = (id: number) => {
    const { setActiveChannel, clearActiveChannel, activeChannelId } = this.props;
    if (id === activeChannelId) {
      // attempting to set the same channel as active, toggle instead
      clearActiveChannel();
    } else {
      setActiveChannel(id);
    }
  };

  private getActiveChannel = () => {
    const { activeChannelId, channels } = this.props;
    const tempActiveChannel = channels.find(
      (tempChannel: IChannel) => tempChannel.id === activeChannelId
    );

    return tempActiveChannel;
  };

  private sendMessage = ({ text, channelId }: { text: string; channelId: number }) => {
    const { sendMessage, user } = this.props;
    sendMessage({ text, channelId, sentById: user.id });
  };

  private getNewChannelMessages = ({ channelId, lastMessageId }: IGetNewMessagesParams) => {
    const { getNewChannelMessages, isFetching } = this.props;
    if (!isFetching) {
      getNewChannelMessages({ channelId, lastMessageId });
    }
  };

  private checkAllChannelsForNewMessages = () => {
    const { isFetching, checkForNewMessages } = this.props;
    if (!isFetching) {
      checkForNewMessages();
    }
  };

  public render() {
    const { channels, user, clearActiveChannel, activeChannelId } = this.props;
    return (
      <FullPage id="chat">
        <div style={{ display: 'flex', flex: 1 }}>
          <Channels
            activeChannelId={activeChannelId}
            channels={channels}
            user={user}
            setActiveChannel={this.setActiveChannel}
            getNewMessages={this.getNewChannelMessages}
            refetchMessages={this.checkAllChannelsForNewMessages}
            clearActiveChannel={clearActiveChannel}
          />
          <Messages
            activeChannel={this.getActiveChannel()}
            user={user}
            sendMessage={this.sendMessage}
            clearActiveChannel={clearActiveChannel}
          />
        </div>
      </FullPage>
    );
  }
}

const mapStateToProps = ({ messages, user }: any) => {
  return {
    channels: messages.channels,
    activeChannelId: messages.currentActiveChannelId,
    isFetching: messages.isFetching,
    user: user.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      getMessages,
      setActiveChannel: setActiveChannelAction,
      getNewChannelMessages: getNewChannelMessagesAction,
      checkForNewMessages: checkForNewMessagesAction,
      sendMessage: sendMessageAction,
      clearActiveChannel: clearActiveChannelAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
