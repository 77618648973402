import { capitalize } from 'lodash';
import { useState, useEffect } from 'react';
import { getPatient } from '../../api/patient';
import { IPatient } from '../PatientInterfaces';
import usePatientForms from './usePatientForms';

interface IConfig {
  includeForms?: boolean;
}

const getFullName = (patient: IPatient) =>
  `${capitalize(patient.givenName && patient.givenName.toLowerCase())} ${capitalize(
    patient.familyName && patient.familyName.toLowerCase()
  )}`;

const usePatient = (patientId: number | string, config: IConfig = {}) => {
  const [patient, setPatient] = useState<IPatient | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const { forms, loading: formsLoading } = usePatientForms(
    config.includeForms ? patientId : undefined
  );
  useEffect(() => {
    if (patientId) {
      setLoading(true);
      getPatient(patientId)
        .then((res) => {
          setPatient({
            ...res,
            fullName: getFullName(res),
          });
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [patientId]);
  return { patient, forms, loading: loading || (config.includeForms && formsLoading) };
};

export default usePatient;
