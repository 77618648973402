// TODO refactor this to be more graceful, it got the approval stuff crammed in
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// @ts-ignore — no types provided
import { useToasts } from 'react-toast-notifications';

import { theme as aveannaTheme, interfaces } from '../../common';
import { ToastAppearance } from '../../utils';
import {
  ConfirmSubPanelContainer,
  ConfirmButtonRow,
  ConfirmMessageContainer,
  CofirmationErrorMessage,
} from '../ShiftPanel/styles';
import { ShiftFooterButton, ShiftFooterTextButton } from './ShiftFooterButtons';
import { RejectionSection } from './ShiftDetailsRejectionSection';
import { ShiftEditReview } from './ShiftEditReview/';
import { StatusType } from '../constants';
import { getErrorMessage } from '../../common/useToastNotifications';

interface IProps {
  message: string | JSX.Element;
  toastSuccessMessage: string;
  onBack: () => void;
  onClose: () => void;
  onConfirm: (reason: any) => void;
  existingRejections: interfaces.IRejectionReason[];
  rejectionRejectOptions?: interfaces.IReasonCode[];
  rejectionOverrideOptions?: interfaces.IReasonCode[];
  isApproval?: boolean;
  isEditMode?: boolean;
  currentStatus?: StatusType;
}

const ShiftDetailsConfirmationContainer = ({
  message,
  onBack,
  onClose,
  onConfirm,
  existingRejections,
  rejectionRejectOptions,
  rejectionOverrideOptions,
  toastSuccessMessage,
  isApproval = false,
  isEditMode,
  currentStatus,
}: IProps) => {
  const { addToast } = useToasts();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [rejectionReasons, setRejectionReasons] = useState([
    { value: null, label: 'Please select a reason...' },
  ]);

  useEffect(() => {
    if (existingRejections) {
      if (isApproval) {
        existingRejections = [];
      } else {
        const formattedExistingReasons = existingRejections.map((tempExisting: any) => {
          if (tempExisting.reason) {
            return {
              isDisabled: true,
              value: tempExisting.reason.id,
              label: `${tempExisting.reason.code}: ${tempExisting.reason.text}`,
              ...tempExisting,
            };
          }
        });
        setRejectionReasons([...formattedExistingReasons]);
      }
    } else {
      setRejectionReasons([{ value: null, label: 'Please select a reason...' }]);
    }
  }, []);

  const confirm = async () => {
    setIsLoading(true);

    try {
      await onConfirm(rejectionReasons);
      addToast(toastSuccessMessage, {
        appearance: ToastAppearance.SUCCESS,
        autoDismiss: true,
      });
      onClose();
    } catch (error) {
      setError(getErrorMessage(error));
      setIsLoading(false);
    }
  };

  const updateRejectionReasons = (index: number, value: any) => {
    rejectionReasons[index] = value;
    const tempRejectionReasons = [...rejectionReasons];
    setRejectionReasons(tempRejectionReasons);
  };

  const addRejectionReason = () => {
    setRejectionReasons([...rejectionReasons, { value: null, label: 'Please select a reason...' }]);
  };

  const removeRejectionReason = (indexToRemove: number) => {
    setRejectionReasons([
      ...rejectionReasons.filter(
        (tempReason: any, tempIndex: number) => tempIndex !== indexToRemove
      ),
    ]);
  };

  let approvalErrorMessage = 'Approval Error!  ';
  const approvalCodeMessage = 'Must have an approval code before clicking Yes;';
  const rejectOverrideErrorMessage = 'Please remove the review code before clicking Yes; ';

  let reasonCodeIsReviewCode = false;
  if (rejectionRejectOptions != null) {
    const mapOfRejectOptionValues = rejectionRejectOptions.map((option) => option.value);
    reasonCodeIsReviewCode = rejectionReasons.some((reason) => {
      // @ts-ignore
      return mapOfRejectOptionValues.indexOf(reason.value) >= 0;
    });
  }

  const isDisabled = () => {
    if (isLoading) {
      return true;
    }

    if (isApproval && !isEditMode && currentStatus === StatusType.PENDING) {
      return false;
    }

    if (
      rejectionReasons.length === 0 ||
      (rejectionReasons.length === 1 && rejectionReasons[0].value === null)
    ) {
      approvalErrorMessage = approvalErrorMessage.concat(approvalCodeMessage);
      return true;
    }

    if (isApproval && reasonCodeIsReviewCode) {
      approvalErrorMessage = approvalErrorMessage.concat(rejectOverrideErrorMessage);
      return true;
    }

    return (
      rejectionReasons && !rejectionReasons.some((tempReason: any) => tempReason.value !== null)
    );
  };

  const disabled = isDisabled();

  return (
    <div style={{ overflowY: `auto` }}>
      {isEditMode && <ShiftEditReview />}
      <ConfirmSubPanelContainer>
        {rejectionOverrideOptions && rejectionRejectOptions && (
          <RejectionSection
            rejectionReasons={rejectionReasons}
            setRejectionReasons={updateRejectionReasons}
            addRejectionReason={addRejectionReason}
            removeRejectionReason={removeRejectionReason}
            rejectionReasonsOptions={isApproval ? rejectionOverrideOptions : rejectionRejectOptions}
            isApproval={isApproval}
          />
        )}
        {disabled && !isApproval && (
          <div>
            <span
              style={{
                fontWeight: 'bold',
                color: 'red',
                fontSize: 13,
              }}
            >
              {approvalErrorMessage}
            </span>
          </div>
        )}
        {error && <CofirmationErrorMessage>{error}</CofirmationErrorMessage>}
        <ConfirmMessageContainer>{message}</ConfirmMessageContainer>
        <ConfirmButtonRow>
          <ShiftFooterButton
            disabled={disabled}
            onClick={confirm}
            buttonColor={
              isApproval
                ? aveannaTheme.colors.footerButtonGreen
                : aveannaTheme.colors.footerButtonRed
            }
          >
            Yes
          </ShiftFooterButton>
          <ShiftFooterTextButton onClick={onBack}>Cancel</ShiftFooterTextButton>
        </ConfirmButtonRow>
      </ConfirmSubPanelContainer>
    </div>
  );
};

const mapStateToProps = ({ appData, user }: any) => {
  return {
    rejectionRejectOptions: appData.rejectionRejectOptions,
    rejectionOverrideOptions: appData.rejectionOverrideOptions,
    user: user.user,
  };
};

export const ShiftDetailsConfirmation = connect(
  mapStateToProps,
  null
)(ShiftDetailsConfirmationContainer);
