import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ShiftFooterButton } from './ShiftFooterButtons';
import { setSaveCurrentShift } from '../actions';
import { theme } from '../../common';

interface IProps {
  isEditMode: boolean;
}

const QANurseReviewButton = ({ isEditMode }: IProps) => {
  const currentShift = useSelector(({ aides }: any) => aides.currentShift);
  const currentEditedClockInOut = useSelector(
    ({ aides }: any) => aides.currentEditedShift.clockInOut
  );
  const dispatch = useDispatch();

  const handleOnClickButton = async () => {
    await dispatch(
      setSaveCurrentShift({
        ...currentShift,
        clockInOut: { ...currentShift.clockInOut, qa: true },
      })
    );
  };

  return (
    <ShiftFooterButton
      onClick={handleOnClickButton}
      disabled={currentShift.clockInOut.qa}
      buttonColor={theme.colors.aveannaDarkBlueHex}
    >
      QA Nurse Review
    </ShiftFooterButton>
  );
};

export default QANurseReviewButton;
