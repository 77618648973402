import {
  EditableCallbackParams,
  GridApi,
  GridOptions,
  RowSelectedEvent,
  ValueGetterParams,
} from 'ag-grid-community';
import React, { useEffect, useRef, useState } from 'react';
import { ToastAppearance, userTypeByID, userTypes } from '../../../utils';
import { resendEmailNotification } from '../../../api/user';
import { getAllUsersAndNursesSnapshot, updateUserLocation } from '../../../api/admin';
import { AgGridReact } from 'ag-grid-react';
import { StatusRenderer } from './StatusColumn';
import { UserTypeEditorRenderer } from './UserTypeColumn';
import { UserLocationDescription, UserLocationEditor } from './LocationColumn';
import { UserColumnFilter } from './UserColumnFilterRenderer';
import { formatDateTime } from '../../../CaregiverShifts/components/DataHelpers';
import { useToasts } from 'react-toast-notifications';
import { connect } from 'react-redux';
import ExternalIdEditor from './ExternalIdColumn';
import ExternalSourceEditor from './ExternalSourceColumn';

function displayTypeNumberName() {
  const allTypesOption = [{ value: '', option: 'All' }];
  const userTypesAndNumbers = Object.values(userTypes)
    .filter((val): val is string => {
      return typeof val === 'string';
    })
    .map((userTypeName, index) => {
      const userTypeNumber = Object.keys(userTypes)[index];
      return { value: userTypeNumber, option: userTypeNumber + ' - ' + userTypeName };
    });
  return allTypesOption.concat(userTypesAndNumbers);
}

export const UserTableDefinitions = (userType: number) => {
  const colDef = {
    sortable: true,
    rowMultiSelectWithClick: true,
    resizable: true,
    margin: 'auto',
    floatingFilter: true,
    stopEditingWhenCellsLoseFocus: true,
  };
  const cols = [
    {
      minWidth: 50,
      cellStyle: { 'border-right': '1px solid lightgray' },
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      headerClass: 'user-table-header',
    },
    {
      field: 'workdayId',
      headerClass: 'user-table-header',
      headerName: 'Workday ID',
      minWidth: 150,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'username',
      headerClass: 'user-table-header',
      headerName: 'Username',
      minWidth: 200,
      maxWidth: 250,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'userFamilyName&userGivenName&userMiddleName',
      headerClass: 'user-table-header',
      headerName: 'User',
      minWidth: 200,
      sort: 'asc',
      filter: 'agTextColumnFilter',
      valueGetter: (params: ValueGetterParams) => {
        return (
          params.data.familyName +
          ', ' +
          (params.data.givenName || '') +
          ' ' +
          (params.data.middleName || '')
        );
      },
    },
    {
      field: 'isActive',
      headerClass: 'user-table-header',
      headerName: 'Status',
      minWidth: 200,
      filter: 'agTextColumnFilter',
      editable: true,
      floatingFilterComponent: 'userColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        filterParams: {
          context: () => {
            return [
              { option: 'All', value: '' },
              { option: 'Active', value: 'Active' },
              { option: 'Inactive', value: 'Inactive' },
            ];
          },
        },
      },
      valueGetter: (params: ValueGetterParams) => {
        return params.data.isActive ? 'Active' : 'Inactive';
      },
      cellEditor: 'statusEditor',
    },
    {
      field: 'userType',
      headerClass: 'user-table-header',
      headerName: 'User Type',
      minWidth: 300,
      editable: true,
      cellEditor: 'userTypeEditorRenderer',
      filter: 'agTextColumnFilter',
      floatingFilterComponent: 'userColumnFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        filterParams: {
          context: () => displayTypeNumberName(),
        },
      },
      valueGetter: (params: ValueGetterParams) => {
        return params.data.userType;
      },
      cellRenderer: (params: ValueGetterParams) => {
        const userTypeString = userTypeByID[params.data.userType]
          ? params.data.userType + ' - ' + userTypeByID[params.data.userType]
          : params.data.userType;
        return userTypeString;
      },
    },
    {
      field: 'locationId',
      headerClass: 'user-table-header',
      headerName: 'Location',
      minWidth: 300,
      filter: 'agTextColumnFilter',
      editable: true,
      cellEditor: 'locationEditor',
      cellRenderer: 'locationRenderer',
    },
    {
      field: 'workdayCostCenterId',
      headerClass: 'user-table-header',
      headerName: 'WD Cost Center',
      minWidth: 150,
      filter: 'agTextColumnFilter',
    },
    {
      headerClass: 'user-table-header',
      headerName: 'Nurse Information',
      groupId: 'nurses',
      columnGroupShow: 'closed',
      children: [
        {
          field: 'nurseId',
          headerClass: 'nurse-child-header',
          headerName: 'Nurse ID',
          filter: 'agTextColumnFilter',
          minWidth: 125,
          maxWidth: 175,
        },
        {
          field: 'nurseExternalId',
          headerClass: 'nurse-child-header',
          headerName: 'External ID',
          minWidth: 200,
          editable: (params: EditableCallbackParams) =>
            userType === 127 && params.data.nurseId !== null,
          cellEditor: 'externalIdEditor',
          filter: 'agTextColumnFilter',
        },
        {
          field: 'nurseExternalSource',
          headerClass: 'nurse-child-header',
          headerName: 'External Source',
          minWidth: 200,
          filter: 'agTextColumnFilter',
          editable: (params: EditableCallbackParams) =>
            userType === 127 && params.data.nurseId !== null,
          cellEditor: 'externalSourceEditor',
        },
        {
          field: 'nurseDOB',
          headerClass: 'nurse-child-header',
          headerName: 'Date of Birthday',
          minWidth: 150,
          filter: 'agDateColumnFilter',
        },
        {
          field: 'nurseLocationId',
          headerClass: 'nurse-child-header',
          headerName: 'Location',
          minWidth: 350,
          filter: 'agTextColumnFilter',
        },
        {
          field: 'nurseCreatedOn',
          headerClass: 'nurse-child-header',
          headerName: 'Created On',
          minWidth: 150,
          filter: 'agDateColumnFilter',
          valueGetter: (params: ValueGetterParams) => {
            return formatDateTime(params.data.nurseCreatedOn);
          },
        },
        {
          field: 'nurseModifiedOn',
          headerClass: 'nurse-child-header',
          headerName: 'Modified On',
          minWidth: 150,
          filter: 'agDateColumnFilter',
          valueGetter: (params: ValueGetterParams) => {
            return formatDateTime(params.data.nurseModifiedOn);
          },
        },
      ],
    },
  ];

  const gridOptions: GridOptions = {
    defaultColDef: colDef,
    columnDefs: cols,
    pagination: true,
    paginationPageSize: 10,
    animateRows: true,
    rowSelection: 'multiple',
    frameworkComponents: {
      userColumnFilter: UserColumnFilter,
      statusEditor: StatusRenderer,
      locationEditor: UserLocationEditor,
      locationRenderer: UserLocationDescription,
      userTypeEditorRenderer: UserTypeEditorRenderer,
      externalIdEditor: ExternalIdEditor,
      externalSourceEditor: ExternalSourceEditor,
    },
  };

  return gridOptions;
};

export const UserTable = (props: any) => {
  const { addToast } = useToasts();
  const [rowData, setRowData] = useState<any | null>([]);
  const [resendEmailState, setResendEmailState] = useState<{
    ids: number[];
    isInProgress: boolean;
  }>({ ids: [], isInProgress: false });
  const gridRef = useRef(null);

  const userType = props.userType;
  let gridApi: any;

  useEffect(() => {
    gridApi = gridRef.current;
    gridApi.api.sizeColumnsToFit();
    (async () => {
      const response = await getAllUsersAndNursesSnapshot();
      setRowData(response);
    })();
  }, []);

  function clearAllFilters() {
    gridApi = gridRef.current;
    if (gridApi.api) {
      gridApi.api.setFilterModel(null);
    }
  }

  const resendActivationEmail = async () => {
    if (resendEmailState.ids.length !== 1 || resendEmailState.isInProgress) {
      return;
    }
    try {
      setResendEmailState((prev) => ({ ...prev, isInProgress: true }));
      const result = await resendEmailNotification(resendEmailState.ids[0]);
      if (!result.err) {
        addToast('Resent activation email to user.', {
          appearance: ToastAppearance.SUCCESS,
          pauseOnHover: true,
          autoDismiss: true,
          autoDismissTimeout: 10000,
        });
        ((gridRef.current as any).api as GridApi).deselectAll();
      } else {
        addToast('Failed to resend activation email to user.', {
          appearance: ToastAppearance.ERROR,
          pauseOnHover: true,
          autoDismiss: true,
          autoDismissTimeout: 10000,
        });
      }
    } catch (e) {
      addToast('Failed to resend activation email to user.', {
        appearance: ToastAppearance.ERROR,
        pauseOnHover: true,
        autoDismiss: true,
        autoDismissTimeout: 10000,
      });
      console.error(e);
    } finally {
      setResendEmailState((prev) => ({ ...prev, isInProgress: false }));
    }
  };

  const handleRowSelected = (event: RowSelectedEvent) => {
    setResendEmailState((prev) => ({
      ...prev,
      ids: event.node.isSelected()
        ? prev.ids.concat(event.node.data.id)
        : prev.ids.filter((x) => x !== event.node.data.id),
    }));
  };

  return (
    <div>
      <div>
        <button onClick={clearAllFilters} className="actionButton">
          Clear Filters
        </button>
        <button
          onClick={resendActivationEmail}
          className="actionButton"
          disabled={resendEmailState.ids.length !== 1 || resendEmailState.isInProgress}
        >
          Resend Activation Email
        </button>
      </div>
      <div
        className="ag-theme-alpine dataTable"
        id="admin-user-table"
        data-test="user-grid-container"
      >
        <AgGridReact
          getRowNodeId={(data) => data.id}
          gridOptions={UserTableDefinitions(userType)}
          deltaRowDataMode={true}
          rowData={rowData}
          ref={gridRef}
          onRowSelected={handleRowSelected}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }: any) => {
  return {
    userType: user.user.userType,
  };
};

export default connect(mapStateToProps)(UserTable);
