import React, { Fragment } from 'react';
import moment from 'moment';
import { interfaces } from '../../common';
import { IMessage } from '../interfaces';
import {
  StyledProfileImage,
  StyledMessageContainer,
  StyledMessageTitle,
  StyledMessage,
  StyledMessageTimestamp,
  StyledMessageHeader,
} from './StyledMessages';

// TODO refactor using the media queries
const renderProfileImage = (user: interfaces.IUser, isMe: boolean, isGrouped: boolean) => {
  return !isGrouped ? <StyledProfileImage src={`${user.imageUri}`} isMe={isMe} /> : null;
};

const renderMessageTitle = ({
  user,
  isMe,
  isGrouped,
  message,
}: {
  user: interfaces.IUser;
  isMe: boolean;
  isGrouped: boolean;
  message: IMessage;
}) => {
  const formattedTime = moment(message.createdOn).format('MM/DD/YY - ddd h:mma');
  const timestampMessage = isMe ? `Sent ${formattedTime}` : formattedTime;
  return !isGrouped ? (
    <StyledMessageHeader isMe={isMe}>
      <StyledMessageTitle isMe={isMe}>{`${user.givenName} ${user.familyName}`}</StyledMessageTitle>
      <StyledMessageTimestamp>{timestampMessage}</StyledMessageTimestamp>
    </StyledMessageHeader>
  ) : null;
};

export const Message = ({
  message,
  isGrouped,
  isMe,
  user,
}: {
  message: IMessage;
  isGrouped: boolean;
  isMe: boolean;
  user?: interfaces.IUser | null;
}) => {
  return (
    <StyledMessageContainer isMe={isMe} isGrouped={isGrouped}>
      {user && renderProfileImage(user, isMe, isGrouped)}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {user && renderMessageTitle({ user, isMe, isGrouped, message })}
        <StyledMessage isMe={isMe} isGrouped={isGrouped}>
          {message.body}
        </StyledMessage>
      </div>
    </StyledMessageContainer>
  );
};
