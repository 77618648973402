import { GridColDef } from '@mui/x-data-grid';
import { ICaregiverShiftsData } from '../../../../common/interfaces';
import { toProperCase } from '../../DataHelpers';

const patientColumn: GridColDef = {
  field: 'patient',
  headerName: 'Patient',
  minWidth: 175,
  valueGetter: ({ row }: { row: ICaregiverShiftsData }) => {
    return `${toProperCase(row.patientsGivenName)} ${toProperCase(row.patientsFamilyName)} ${
      row.patientsSearchField
    }`;
  },
};

export default patientColumn;
