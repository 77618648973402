import React from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import PrintIcon from '@mui/icons-material/Print';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { Button } from '@material-ui/core';

type Props = {
  clearQuickFilter: () => void | Promise<void>;
  onQuickFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void | Promise<void>;
  quickFilterValue: string;
  onPrintSelectedForms: () => void | Promise<void>;
};

const QuickSearchToolbarButton = (props: Props) => {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <TextField
        variant="standard"
        value={props.quickFilterValue}
        onChange={async (event: React.ChangeEvent<HTMLInputElement>) =>
          await props.onQuickFilterChange(event)
        }
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.quickFilterValue ? 'visible' : 'hidden' }}
              onClick={async () => await props.clearQuickFilter()}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: 'auto',
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          '& .MuiSvgIcon-root': {
            mr: 0.5,
          },
          '& .MuiInput-underline:before': {
            borderBottom: 1,
            borderColor: 'divider',
          },
        }}
      />
    </Box>
  );
};

const ExportToolbarButton = () => {
  return (
    <GridToolbarExport
      csvOptions={{
        fields: [
          'status',
          'date',
          'caregiverName',
          'patient',
          'adjustedClockInTimeStamp',
          'adjustedClockOutTimeStamp',
          'distanceIn',
          'distanceOut',
          'clockInNote',
          'clockOutNote',
        ],
      }}
    />
  );
};

const PrintSelectedFormsButton = (props: { onPrintSelectedForms: () => void | Promise<void> }) => {
  return (
    <Button
      size="small"
      style={{ color: '#1976d2' }}
      onClick={async () => await props.onPrintSelectedForms()}
    >
      <PrintIcon fontSize="small" style={{ margin: '0 8 0 0' }} />
      Print Selected Forms
    </Button>
  );
};

const CustomToolbar = (props: Props) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <ExportToolbarButton />
      <PrintSelectedFormsButton {...props} />
      <QuickSearchToolbarButton {...props} />
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
