import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ISearchState } from '../../../Aides/searchReducer';
import { ISetterAction, PatientShiftSearchComponent } from './PatientShiftSearch';
import { SearchActions } from '../../../Aides/actions';

type IShiftSearchProps = {
  search: (startDate: Date, endDate: Date) => void;
  SearchActions: any;
  searchParams: ISearchState;
  patient?: any;
};

type IShiftSearchState = {
  startDate: Date;
  endDate: Date;
  isDefaultState: boolean;
};

class PatientShiftSearch extends Component<IShiftSearchProps, IShiftSearchState> {
  constructor(props: IShiftSearchProps) {
    super(props);
    this.setSearchParameterAndRequest = this.setSearchParameterAndRequest.bind(this);
    this.requestSearch = this.requestSearch.bind(this);
  }

  private async setSearchParameterAndRequest(
    setParams: ISetterAction[],
    shouldRequest: boolean = true
  ) {
    for (const { setterAction, newValue } of setParams) {
      await setterAction(newValue);
    }

    if (shouldRequest) {
      await this.requestSearch();
    }
  }

  private requestSearch() {
    const { search } = this.props;
    const { searchStartDate, searchEndDate } = this.props.searchParams;
    search(searchStartDate, searchEndDate);
  }

  public render() {
    return (
      <PatientShiftSearchComponent
        props={{
          searchParams: this.props.searchParams,
          search: this.props.search,
          patient: this.props.patient,
        }}
        actions={{
          SearchActions: this.props.SearchActions,
          requestSearch: this.requestSearch,
          setSearchParameterAndRequest: this.setSearchParameterAndRequest,
        }}
      />
    );
  }
}

const mapStateToProps = ({ searchReducer }: any) => {
  return {
    searchParams: searchReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    SearchActions: {
      setSearchStartDate: (searchStartDate: Date) => {
        dispatch(SearchActions.setSearchStartDate(searchStartDate));
      },
      setSearchEndDate: (searchEndDate: Date) => {
        dispatch(SearchActions.setSearchEndDate(searchEndDate));
      },
      setSearchTerm: (searchTerm: string) => {
        dispatch(SearchActions.setSearchTerm(searchTerm));
      },
      setDefaultSearchParameters: (isDefault: boolean) => {
        dispatch(SearchActions.setDefaultSearchParameters(isDefault));
      },
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientShiftSearch);
