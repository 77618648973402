import React from 'react';
import { FullPage, Centered, StyledContentNoHeader } from '../../common';

export const AidesNewSubmissions = () => {
  return (
    <FullPage style={{ backgroundColor: 'white' }}>
      <StyledContentNoHeader>
        <Centered>Coming soon...</Centered>
      </StyledContentNoHeader>
    </FullPage>
  );
};
