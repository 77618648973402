import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { History } from 'history';

import MaterialIcon from 'material-icons-react';
import { device } from './device/deviceSizes';
import { StyledNotification, StyledNavItem, theme as styleTheme } from './';

export const StyledFooter = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  height: ${({ theme }) => `${theme.footerHeight}px`};
  border-top: 1px solid #ddd;
  box-sizing: border-box;

  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;

  /* above mobile L */
  @media ${device.tablet} {
    display: none;
  }
`;

const StyledFooterButton = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  flex: 1;
  flex-shrink: 0;
  cursor: pointer;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #ddd;

  :last-child {
    border-right: 0px solid #ddd;
  }
`;

const Footer = ({ history, hasUnreadMessage }: { history: History; hasUnreadMessage: boolean }) => {
  return (
    <StyledFooter>
      <StyledFooterButton
        onClick={() => {
          history.push('/messages');
        }}
      >
        <StyledNotification isActive={hasUnreadMessage} notificationPosition={{ x: 8, y: -16 }} />
        <MaterialIcon icon="mail_outline" color={styleTheme.colors.aveannaDarkBlueHex} />
        <StyledNavItem isActive={true} style={{ marginRight: 0, fontSize: 12 }}>
          messages
        </StyledNavItem>
      </StyledFooterButton>
      <StyledFooterButton
        onClick={() => {
          history.push('/shifts/open');
        }}
      >
        <MaterialIcon icon="check_box_outline_blank" color={styleTheme.colors.aveannaDarkBlueHex} />
        <StyledNavItem isActive={true} style={{ marginRight: 0, fontSize: 12 }}>
          shifts
        </StyledNavItem>
      </StyledFooterButton>
    </StyledFooter>
  );
};

const mapStateToProps = ({ messages }: any) => {
  return {
    hasUnreadMessage: messages.hasUnreadMessage,
  };
};

export default connect(mapStateToProps)(Footer);
