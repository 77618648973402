import React, { useState } from 'react';

import MaterialIcon from 'material-icons-react';

import {
  ShiftPanelHeader,
  ShiftPanelHeaderLeft,
  ShiftPanelHeaderRight,
  ShiftPanelHeaderText,
  ShiftPanelHeaderLink,
} from '../ShiftPanel/ShiftSidebar';
import { ShiftDetailsPrinter } from './ShiftDetailsPrinter';
import { CancelEditConfirmation } from './ShiftDetailsCancelEditConfirmation';
import { StyledButton } from '../../common';
import { StatusType } from '../constants';
import QANurseReviewButton from './QANurseReviewButton';
import {
  setCurrentEditedShift as setCurrentEditedShiftAction,
  setCurrentShift as setCurrentShiftAction,
} from '../actions';

interface IProps {
  dvr: any;
  isEditMode: boolean;
  isSubPanelOpen: boolean;
  openHistory: () => void;
  onClose: () => void;
  onBack: () => void;
  setIsEditMode: (isEdit: boolean) => void;
  setIsValidDate: (isValid: boolean) => void;
  // setCurrentShift: (shift: any) => void;
  // setCurrentEditedShift: (shift: any) => void;
  // currentShift: any;
  // currentEditedShift: any;
}

const ShiftDetailsHeader = ({
  dvr,
  isEditMode,
  isSubPanelOpen,
  setIsEditMode,
  onClose,
  onBack,
  openHistory,
  setIsValidDate,
}: // setCurrentShift,
// setCurrentEditedShift,
// currentShift,
// currentEditedShift,
IProps) => {
  const [isCancelConfirm, setIsCancelConfirm] = useState(false);

  const isEditButtonVisible = () => {
    if (!dvr) {
      return false;
    }
    return (
      (dvr.clockInOut && dvr.clockInOut.status === StatusType.PENDING) ||
      (dvr.clockInOut && dvr.clockInOut.status === StatusType.REJECTED) ||
      // this condition is for Shifts Under Review table && Single Aide
      dvr.status === StatusType.PENDING ||
      dvr.status === StatusType.REJECTED
    );
  };

  return (
    <ShiftPanelHeader>
      <ShiftPanelHeaderLeft
        onClick={() => {
          onClose();
        }}
      >
        <MaterialIcon icon={'close'} key={'close'} />
        <ShiftPanelHeaderText>CLOSE SHIFT DETAILS</ShiftPanelHeaderText>
      </ShiftPanelHeaderLeft>
      {isSubPanelOpen ? (
        <ShiftPanelHeaderRight>
          <StyledButton
            style={{
              width: 'fit-content',
              padding: 12,
              fontWeight: 'bold',
              fontSize: 14,
            }}
            onClick={onBack}
          >
            Back
          </StyledButton>
        </ShiftPanelHeaderRight>
      ) : (
        <ShiftPanelHeaderRight>
          {!isEditMode && !isCancelConfirm && (
            <>
              <ShiftPanelHeaderLink>
                <button onClick={openHistory}>History Log</button>
              </ShiftPanelHeaderLink>
              <ShiftDetailsPrinter shift={dvr} />
            </>
          )}
          {isEditButtonVisible() &&
            (isCancelConfirm ? (
              <CancelEditConfirmation
                onConfirm={() => {
                  setIsCancelConfirm(false);
                  setIsValidDate(true);
                  setIsEditMode(false);
                }}
                onDecline={() => {
                  setIsCancelConfirm(false);
                }}
              />
            ) : (
              <StyledButton
                data-cy={'shift-details-edit-button'}
                style={{
                  width: 'fit-content',
                  padding: 12,
                  fontWeight: 'bold',
                  fontSize: 14,
                  marginLeft: 12,
                }}
                // onClick={() => setIsEditMode(!isEditMode)}
                onClick={() => {
                  isEditMode ? setIsCancelConfirm(true) : setIsEditMode(true);
                }}
              >
                {isEditMode ? `Cancel Edit` : `Edit`}
              </StyledButton>
            ))}
        </ShiftPanelHeaderRight>
      )}
    </ShiftPanelHeader>
  );
};

export default ShiftDetailsHeader;
