export const types = {
  SET_CURRENT_TAB: 'SET_CURRENT_TAB',
  // open shifts
  ADD_FILTER_OPEN_SHIFTS: 'ADD_FILTER_OPEN_SHIFTS',
  SET_FILTER_OPEN_SHIFTS: 'SET_FILTER_OPEN_SHIFTS',
  REMOVE_FILTER_OPEN_SHIFTS: 'REMOVE_FILTER_OPEN_SHIFTS',
  CLEAR_FILTERS_OPEN_SHIFTS: 'CLEAR_FILTERS_OPEN_SHIFTS',
  // sent shifts
  ADD_FILTER_SENT_SHIFTS: 'ADD_FILTER_SENT_SHIFTS',
  SET_FILTER_SENT_SHIFTS: 'SET_FILTER_SENT_SHIFTS',
  REMOVE_FILTER_SENT_SHIFTS: 'REMOVE_FILTER_SENT_SHIFTS',
  CLEAR_FILTERS_SENT_SHIFTS: 'CLEAR_FILTERS_SENT_SHIFTS',
  // applied shifts
  ADD_FILTER_APPLIED_SHIFTS: 'ADD_FILTER_APPLIED_SHIFTS',
  SET_FILTER_APPLIED_SHIFTS: 'SET_FILTER_APPLIED_SHIFTS',
  REMOVE_FILTER_APPLIED_SHIFTS: 'REMOVE_FILTER_APPLIED_SHIFTS',
  CLEAR_FILTERS_APPLIED_SHIFTS: 'CLEAR_FILTERS_APPLIED_SHIFTS',
  // awarded shifts
  ADD_FILTER_AWARDED_SHIFTS: 'ADD_FILTER_AWARDED_SHIFTS',
  SET_FILTER_AWARDED_SHIFTS: 'SET_FILTER_AWARDED_SHIFTS',
  REMOVE_FILTER_AWARDED_SHIFTS: 'REMOVE_FILTER_AWARDED_SHIFTS',
  CLEAR_FILTERS_AWARDED_SHIFTS: 'CLEAR_FILTERS_AWARDED_SHIFTS',
  // confirmed shifts
  ADD_FILTER_CONFIRMED_SHIFTS: 'ADD_FILTER_CONFIRMED_SHIFTS',
  SET_FILTER_CONFIRMED_SHIFTS: 'SET_FILTER_CONFIRMED_SHIFTS',
  REMOVE_FILTER_CONFIRMED_SHIFTS: 'REMOVE_FILTER_CONFIRMED_SHIFTS',
  CLEAR_FILTERS_CONFIRMED_SHIFTS: 'CLEAR_FILTERS_CONFIRMED_SHIFTS',
  // orient requests
  ADD_FILTER_ORIENT_REQUESTS: 'ADD_FILTER_ORIENT_REQUESTS',
  SET_FILTER_ORIENT_REQUESTS: 'SET_FILTER_ORIENT_REQUESTS',
  REMOVE_FILTER_ORIENT_REQUESTS: 'REMOVE_FILTER_ORIENT_REQUESTS',
  CLEAR_FILTERS_ORIENT_REQUESTS: 'CLEAR_FILTERS_ORIENT_REQUESTS',
};
