import React, { Component } from 'react';
import TabBar from '../components/Tabs/Tabs';

class ShiftsView extends Component {
  render() {
    return (
      <div>
        <TabBar />
      </div>
    );
  }
}

export default ShiftsView;
