import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { device } from '../../common';
import { IChannel } from '../interfaces';

interface IStytedMobileHeaderProps extends HTMLAttributes<HTMLDivElement> {
  activeChannel?: IChannel | null;
  onClick?: () => void | null;
}

const MessagesHeader: React.FC<IStytedMobileHeaderProps> = (props) => {
  const { activeChannel, ...rest } = props;
  return <div {...rest} />;
};

export const StyledMessagesHeader = styled(MessagesHeader)`
  display: ${({ activeChannel }) => (activeChannel ? 'none' : 'flex')};
  background-color: white;
  padding: 12px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.6);
  align-items: center;
  cursor: pointer;

  /* above mobile L */
  @media ${device.mobileL} {
    display: none;
  }
`;

export const StyledHeaderTitle = styled.div`
  display: flex;
  flex: 1;
`;

export const StyledBackButton = styled.button`
  margin-right: 12px;
  border: none;
  cursor: pointer;
  background-color: transparent;
`;
