import { GridColDef } from '@mui/x-data-grid';
import { ICaregiverShiftsData } from '../../../../common/interfaces';
import AlertColumnRenderer from './Renderers/AlertColumnRenderer';

const alertsColumn: GridColDef = {
  field: 'alerts',
  headerName: 'Alerts',
  minWidth: 140,
  valueGetter: ({ row }: { row: ICaregiverShiftsData }) => row,
  renderCell: AlertColumnRenderer,
};

export default alertsColumn;
