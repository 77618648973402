import { api } from './api';
import { IUser, ILocation, ID, INurse, IExternalSource } from '../common/interfaces';
import { get, post, put } from './apiCallHelper';

export const getOnboarding = async (userId: number) => {
  const result = await get(`/api/backoffice/onboarding`);
  return result.body;
};

export const getUsersByLocation = async (locationId: number) => {
  const result = await get(`/api/User/OfficeUsers/${locationId}`);
  return result.body;
};

export const getUserById = async (userId: number) => {
  const result = await get(`/api/User/GetUser/${userId}`);
  return result.body;
};

export const getAllUsersAndNursesSnapshot = async () => {
  const result = await get(`/api/User/UsersAndNurses`);
  return result.body;
};

export const updateUser = async (
  user: IUser,
  selectedUserType?: number,
  mustChangePassword: boolean = true
) => {
  try {
    if (!user.id) {
      throw new Error('admin updateUser missing user id in');
    }

    const result = await put(`/api/User/${user.id}`, {
      body: {
        ...user,
        userType: selectedUserType,
        isActive: true,
        mustChangePassword: mustChangePassword,
      },
    });

    if (result.status >= 300) {
      throw new Error(`Something went wrong with the request ${result.status}`);
    }

    return result.body;
  } catch (error) {
    console.log('Error updating user', error);
    throw new Error((error as any).message);
  }
};

export const updateUserActiveStatus = async (user: IUser, userActiveStatus: boolean) => {
  try {
    if (!user.id) {
      throw new Error('Cannot update user status - user is missing ID.');
    }

    const result = await put(`/api/User/${user.id}`, {
      body: {
        ...user,
        isActive: userActiveStatus,
      },
    });

    if (result.status >= 300) {
      throw new Error(`Something went wrong with the request ${result.status}`);
    }
    return result.body;
  } catch (error) {
    throw new Error((error as any).message);
  }
};

export const updateNurseExternalId = async (nurseId: number, externalId: string) => {
  return updateNurseData(nurseId, { externalId });
};

export const updateNurseExternalSource = (nurseId: number, externalSource: string) => {
  return updateNurseData(nurseId, { externalSource });
};

const updateNurseData = async (nurseId: number, data: any) => {
  const result = await put(`/api/Nurse/${nurseId}`, {
    body: { ...data },
  });
  if (result.status >= 300) {
    throw new Error(await result.originalResponse.text());
  }
  return result.body;
};

export const updateUserLocation = async (user: IUser, newLocationId: number) => {
  try {
    if (!user.id) {
      throw new Error('Cannot update user status - user is missing ID.');
    }

    const result = await put(`/api/User/${user.id}`, {
      body: {
        ...user,
        locationId: newLocationId,
      },
    });

    if (result.status >= 300) {
      throw new Error(`Something went wrong with the request ${result.status}`);
    }
    return result.body;
  } catch (error) {
    console.log('Error updating user location ', error);
    throw new Error((error as any).message);
  }
};

export const getAllExternalSources = async (): Promise<IExternalSource[]> => {
  const response = await get(`/api/ExternalSources`);

  if (response.status >= 300) {
    throw new Error(await response.originalResponse.body.text());
  }

  return response.body;
};

export const getAllLocations = async (): Promise<ILocation[] | any> => {
  try {
    const tempLocationResponse = await get(`/api/Location/AllOfficeLocations`);

    if (tempLocationResponse.status >= 300) {
      throw new Error(`Error fetching locations (${tempLocationResponse.status})`);
    }
    return tempLocationResponse.body;
  } catch (error) {
    console.log('error get al locations — ', error);
  }
  return [];
};

interface IToggleActivationResponse {
  officeId: ID;
  isActive: boolean;
}

export const setLocationActive = async (id: ID, setActive: boolean): Promise<ILocation> => {
  try {
    const tempActivationResponse = await put(
      `/api/BackOffice/OnBoarding/updateNurseOfficeIsActive`,
      {
        body: {
          officeId: id,
          isActive: setActive,
        },
      }
    );
    if (tempActivationResponse.status >= 300) {
      throw new Error(
        `Something went wrong activating the location (${tempActivationResponse.status})`
      );
    }

    const tempActivationResponseData: IToggleActivationResponse = tempActivationResponse.body;

    return {
      id: tempActivationResponseData.officeId,
      isActive: tempActivationResponseData.isActive,
    } as ILocation;
  } catch (error) {
    throw Error(error as any);
  }
};
