import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { IFloatingFilterParams } from 'ag-grid-community';

export const UserColumnFilter = forwardRef((props: IFloatingFilterParams, ref) => {
  const inputRef = useRef<any>();
  const params = props.filterParams.context();

  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged(parentModel: any) {
        if (!parentModel) {
          inputRef.current.value = '';
        } else {
          inputRef.current.value = parentModel.filter;
        }
      },
    };
  });

  const onInputBoxChanged = (input: any) => {
    props.parentFilterInstance((instance: any) => {
      if (input.target.value === '') {
        instance.onFloatingFilterChanged(null, null);
      }
      instance.onFloatingFilterChanged('equals', input.target.value);
    });
  };

  function dropdownOptions() {
    return params.map((option: any, index: number) => {
      return (
        <option key={index} value={option.value || ''}>
          {option.option || option}
        </option>
      );
    });
  }

  return (
    <div>
      <select ref={inputRef} onInput={onInputBoxChanged}>
        {dropdownOptions()}
      </select>
    </div>
  );
});

export default UserColumnFilter;
