import { getBuildNumber, isCookieAuthEnabled, LOCAL_STORAGE_AUTH_FLAG_KEY } from './app';
import { localStorageKeys } from './constants';
import { IUser } from '../common/interfaces';

const LOCAL_STORAGE_USER_KEY = `${localStorageKeys.USER}_${getBuildNumber()}`;
const LOCAL_STORAGE_TOKEN_KEY = `${localStorageKeys.TOKEN}_${getBuildNumber()}`;
const LOCAL_STORAGE_IS_REMEMBERED_KEY = `${localStorageKeys.IS_REMEMBERED}_${getBuildNumber()}`;
const LOCAL_STORAGE_ACCESS_TOKEN_KEY = `${localStorageKeys.ACCESS}_${getBuildNumber()}`;
const LOCAL_STORAGE_REFRESH_TOKEN_KEY = `${localStorageKeys.REFRESH}_${getBuildNumber()}`;
const LOCAL_STORAGE_ONELOGIN_ID_TOKEN_KEY = `${localStorageKeys.ONELOGIN_ID}_${getBuildNumber()}`;
const LOCAL_STORAGE_PKCE_KEY = `${localStorageKeys.PKCE}_${getBuildNumber()}`;

export const getLocalUser = () => {
  if (isBrowser()) {
    const tempUser = localStorage.getItem(LOCAL_STORAGE_USER_KEY);
    const tempToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    const tempAccess = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
    const tempRefresh = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
    const tempOneloginId = localStorage.getItem(LOCAL_STORAGE_ONELOGIN_ID_TOKEN_KEY);

    return {
      user: tempUser ? JSON.parse(tempUser) : undefined,
      token: tempToken,
      access: tempAccess,
      refresh: tempRefresh,
      oneloginId: tempOneloginId,
    };
  }

  return null;
};

export const storeLocalUser = (
  user: any,
  token: string,
  access: string,
  refresh: string,
  oneloginIdToken: string
) => {
  if (isBrowser()) {
    localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(user));
    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
    localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, access);
    localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, refresh);
    localStorage.setItem(LOCAL_STORAGE_ONELOGIN_ID_TOKEN_KEY, oneloginIdToken);
  }
};

export const storePkceCodeChallege = (challenge: string) => {
  if (isBrowser()) {
    localStorage.setItem(LOCAL_STORAGE_PKCE_KEY, challenge);
  }
};

export const getPkceCodeChallenge = () => {
  if (isBrowser()) {
    const Pkce = localStorage.getItem(LOCAL_STORAGE_PKCE_KEY);

    return { pkce: Pkce };
  }
  return null;
};

export const setLocalUserRemembered = (email: string) => {
  if (isBrowser()) {
    localStorage.setItem(LOCAL_STORAGE_IS_REMEMBERED_KEY, email);
  }
};

export const getLocalRememberedUser = () => {
  if (isBrowser()) {
    return localStorage.getItem(LOCAL_STORAGE_IS_REMEMBERED_KEY);
  }
};

export const clearLocalRememberedUser = () => {
  if (isBrowser()) {
    localStorage.removeItem(LOCAL_STORAGE_IS_REMEMBERED_KEY);
  }
};

export const clearLocalUser = () => {
  if (isBrowser()) {
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
    localStorage.removeItem(LOCAL_STORAGE_ONELOGIN_ID_TOKEN_KEY);
  }
};

export const clearPkceCodeChallenge = () => {
  if (isBrowser()) {
    localStorage.removeItem(LOCAL_STORAGE_PKCE_KEY);
  }
};

export const checkIfValidEmailAddress = (email: string): boolean => {
  const emailRegex = new RegExp(
    // tslint:disable-next-line:max-line-length
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  return emailRegex.test(email);
};

const isBrowser = () => {
  if (typeof Storage !== 'undefined') {
    return true;
  } else {
    console.log('could not use local storage — ');
    return false;
  }
};

export const isPasswordValid = (password: string): boolean => {
  const passwordRegex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/);

  return passwordRegex.test(password);
};
