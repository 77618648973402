import { userTypes } from './constants';
import { IUser } from '../common/interfaces';

export class UserRoles {
  private readonly userType: number;

  constructor(user: IUser) {
    this.userType = user.userType;
  }

  get isNurse() {
    // 1
    return this.hasType(userTypes.NURSE);
  }

  get isNurseAdmin() {
    // 2
    return this.hasType(userTypes.NURSE_ADMIN);
  }

  // 3 AKA "Super Nurse"
  // This is a person with all of the following types. This value should not actually be used in the code.
  // NURSE, SCHEDULER

  get isAdmin() {
    // 4
    return this.hasType(userTypes.ADMIN);
  }

  get isAveannaStaff() {
    // 8
    return this.hasType(userTypes.AVEANNA_STAFF);
  }

  get isScheduler() {
    // 16
    return this.hasType(userTypes.SCHEDULER);
  }

  get isEvvNurse() {
    // 32
    return this.hasType(userTypes.EVV_NURSE);
  }

  get isEvvScheduler() {
    // 64
    return this.hasType(userTypes.EVV_SCHEDULER);
  }

  // 96 - AKA Evv Super User
  // This is a person with all of the following types. This value should not actually be used in the code.
  // EVV_SCHEDULER, EVV_NURSE

  // 127 - AKA Super User
  // This is a person with all of the following types. This value should not actually be used in the code.
  // NURSE, SCHEDULER, SOMETHING, ANOTHER, WHATEVER, EVV_SCHEDULER, EVV_NURSE

  get isQaNurse() {
    // 128
    return this.hasType(userTypes.QA_NURSE);
  }

  private hasType(type: any) {
    // tslint:disable-next-line:no-bitwise
    return (this.userType & type) === type;
  }
}
