import React, { useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { api, post } from '../../api';
import { useToasts } from 'react-toast-notifications';
import { ToastAppearance } from '../../utils';
import { Loading } from '../../common';

interface IRouteParams {
  patientId: string;
}

const AcceptPatientForm = () => {
  const location = useLocation();
  const router = useHistory();
  const { patientId } = useParams<IRouteParams>();
  const { addToast } = useToasts();
  const form = new URLSearchParams(location.search).get('form');
  const showErrorPopup = (error: Error) => {
    addToast(`${error.message}`, {
      appearance: ToastAppearance.ERROR,
      pauseOnHover: true,
      autoDismiss: true,
      autoDismissTimeout: 10000,
    });
  };
  useEffect(() => {
    if (form && patientId) {
      const parsedForm = JSON.parse(form);
      const templateName = parsedForm.title;
      const activateOn = parsedForm.activateOn;
      if (templateName.length >= 255) {
        showErrorPopup(
          new Error('Unable to add this form, the title must be less than 255 characters.')
        );
        router.push(`/patients/${patientId}/forms`);
      } else {
        post(`api/form/patient/`, {
          body: {
            form,
            patientId,
            templateName,
            activateOn,
          },
        })
          .then((res: any) => {
            if (!res.ok || res.err) {
              showErrorPopup(res.err);
            } else {
              addToast(`Form Successfully Created`, {
                appearance: ToastAppearance.SUCCESS,
                pauseOnHover: true,
                autoDismiss: true,
                autoDismissTimeout: 10000,
              });
            }
          })
          .catch((error: any) => {
            showErrorPopup(error);
          })
          .finally(() => router.push(`/patients/${patientId}/forms`));
      }
    } else {
      if (patientId && !form) {
        showErrorPopup(new Error('Form Creation Cancelled'));
        router.push(`/patients/${patientId}/forms`);
      }
    }
  }, [form, patientId]);
  return <Loading />;
};

export default AcceptPatientForm;
