import React from 'react';
import styled from 'styled-components';
import { device } from './device/deviceSizes';
import { theme as aveannaTheme } from './styles';

// A PAGE WHICH TAKES UP ALL AVAILABLE CONTENT
export const FullPage = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 1;
`;

// MAIN CONTENT CONTAINER
interface IContentProps {
  id?: string;
  children?: any;
  isLogin: boolean;
}

const Content: React.SFC<IContentProps> = (props) => {
  const { isLogin, ...rest } = props;
  return <div {...rest} />;
};

export const StyledContent = styled(Content)`
  margin-top: ${({ theme, isLogin }) => (isLogin ? `0xp` : `${theme.headerHeight}px`)};
  height: ${({ theme, isLogin }) =>
    isLogin ? `100vh` : `calc(100vh - ${theme.headerHeight + theme.footerHeight}px)`};
  display: flex;
  flex: 1;

  /* above mobile L */
  @media ${device.tablet} {
    height: ${({ theme, isLogin }) =>
      isLogin ? `100vh` : `calc(100vh - ${theme.headerHeight}px)`};
  }
`;

export const StyledContentNoHeader = styled.div`
  flex: 1;
  display: flex;
  height: 100vh;
  overflow-y: hidden;
  background-color: ${aveannaTheme.colors.white};
`;

export const Centered = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-content: center;
  align-self: center;
  align-items: center;

  /* above mobile L */
  @media ${device.mobileL} {
    flex: 1;
    height: auto;
  }
`;
