import { GridCellParams, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { ICaregiverShiftsData } from '../../../../common/interfaces';
import { DistanceInColumnRenderer } from './Renderers/DistanceColumnRenderer';

const distanceInColumn: GridColDef = {
  field: 'distanceIn',
  headerName: 'Distance In',
  maxWidth: 120,
  valueGetter: ({ row }: { row: ICaregiverShiftsData }) => row,
  valueFormatter: ({ value }: GridValueFormatterParams<ICaregiverShiftsData>) => {
    return value && `${value.clockInDistance} mi`;
  },
  renderCell: DistanceInColumnRenderer,
  cellClassName: (params: GridCellParams<ICaregiverShiftsData>) => {
    return params.value && params.value.clockInAlert ? 'cell warning' : 'cell';
  },
};

export default distanceInColumn;
