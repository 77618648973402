import { REHYDRATE } from 'redux-persist';
import { setUpAppWithUserCredentials } from './actions';
import { types } from './constants';

const loginInitialState = {
  isFetching: false,
  user: null,
  token: null,
  error: null,
  rejectionReasons: null,
  featureFlags: [],
  locations: [],
};

export const loginReducer = (
  state = loginInitialState,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case types.SET_USER:
      return {
        ...state,
        user: payload.user,
        token: payload.token,
      };
    case types.CLEAR_USER:
      return {
        ...state,
        user: null,
        token: null,
      };
    case types.REQUEST_LOGIN:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        user: payload.user,
        token: payload.token,
        isFetching: false,
        error: null,
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload.error,
      };
    case types.SET_APP_FEATURE_FLAGS:
      return {
        ...state,
        featureFlags: payload.featureFlags,
      };
    case types.SET_LOCATIONS:
      return {
        ...state,
        locations: payload.locations,
      };
    case REHYDRATE:
      if (payload && payload.user && payload.user.token && payload.user.user) {
        setUpAppWithUserCredentials(payload.user.user, payload.user.token).then(() => {
          return {
            ...state,
            ...payload,
          };
        });
      }
      return state;
    default:
      return state;
  }
};
