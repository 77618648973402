import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import ReactTable from 'react-table';

// the standard styles that come with the package
import './table.css';

// export const TableLayoutWrapper: any = styled.div`
//   display: flex;
//   flex: 1;
//   flex-wrap: wrap;
//   justify-content: space-evenly;
//   align-items: stretch;
//   align-content: flex-start;

//   background-color: #f5f6fa;

//   /* offset the tab bar */
//   max-height: Calc(100% - 50px);
// `;

export const TableActionButton: any = styled.button.attrs({ className: 'no-ellipsis' })`
  padding: 10px;
  border-radius: 5px;
  border-color: #0075c9;
  color: #0075c9;
  font-weight: 600;
  cursor: pointer;
`;

export const Table: any = styled(ReactTable).attrs({
  className: '-highlight',
})`
  /* use && to override styles on the table itself */
  && {
    width: 99%;
    background-color: white;
    height: 99%;
    border: none;
    border-bottom: 8px solid #505980;
    box-shadow: inset 0 -4px 5px rgba(0, 0, 0, 0.25);
  }

  && div.rt-thead {
    background-color: #505980;
    color: white;
    display: flex;
    padding: 10px 10px 10px 10px;
    font-size: 15px;
    font-weight: 600;
    min-height: 24px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
  }

  div.rt-th {
    white-space: normal;
    align-self: center;
  }

  div.rt-th.rt-resizable-header {
    min-height: 24px;
  }

  div.rt-tbody {
    color: black;
    padding: 0 0 0 10px;
    font-size: 20px;
  }

  div.rt-tbody div.rt-td {
    border: none;
    text-align: center;
    padding: 10px 5px 10px 5px;

    .no-ellipsis {
      text-overflow: clip;
    }
  }

  div.rt-tbody div.rt-tr {
    /* padding: 10px 0px 10px 0px; */
    display: flex;
    align-items: center;
  }

  div.rt-thead div.rt-th.-sort-asc {
    width: auto;
    box-shadow: none;
    line-height: 22px;

    & .rt-resizable-header-content {
      ::after {
        content: '▲';
        position: relative;
        margin-left: 5px;
        height: 4px;
      }
    }
  }

  div.rt-thead div.rt-th.-sort-desc {
    width: auto;
    box-shadow: none;
    line-height: 22px;

    & .rt-resizable-header-content {
      ::after {
        content: '▼';
        position: relative;
        margin-left: 5px;
        height: 4px;
      }
    }
  }

  div.rt-resizer {
    height: 100%;
  }

  div.rt-th.rt-resizable-header {
    display: flex;
    border-left-width: 1px;
    border-left-style: solid;
    border-color: rgba(255, 255, 255, 0.3);
    height: 100%;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    &:first-child {
      border-left: none;
    }
  }
`;

const momentFormatString = 'dddd - MMM DD';
export const defaultColumns = [
  {
    Header: 'DATE',
    accessor: 'start',
    minWidth: 75,
    Cell: (props: any) => (
      <React.Fragment>{moment(props.value).format(momentFormatString)}</React.Fragment>
    ),
    sortMethod: (a: string, b: string) => {
      const A_MS = moment(a).format('x');
      const B_MS = moment(b).format('x');
      if (A_MS > B_MS) {
        return 1;
      }
      if (A_MS < B_MS) {
        return -1;
      }
      return 0;
    },
  },
  {
    Header: 'PATIENT',
    accessor: 'patient.name',
    minWidth: 70,
  },
  {
    Header: 'ID',
    accessor: 'patient.searchField',
    minWidth: 50,
  },
  { Header: 'SKILL LEVEL', accessor: 'patient.skillLevel', minWidth: 24 },
  {
    Header: 'SHIFT',
    id: 'shift',
    accessor: ({ start, end, hours }: any) => (
      <span style={{ width: '100%' }}>
        {moment(start).format('LT').toLowerCase()} - {moment(end).format('LT').toLowerCase()} &nbsp;{' '}
        {hours} Hours
      </span>
    ),
    minWidth: 150,
  },
  { Header: 'LOCATION', accessor: 'patient.address', minWidth: 120 },
  { Header: 'AGE', accessor: 'patient.age', minWidth: 20 },
  { Header: 'SEX', accessor: 'patient.sex', minWidth: 20 },
  { Header: 'WEIGHT', accessor: 'patient.weight', minWidth: 40 },
  { Header: 'PRIMARY DIAGNOSIS', accessor: 'patient.primaryDiagnosis', minWidth: 80 },
];
