// @ts-ignore — no types provided
import Frisbee from 'frisbee';
import { api } from './api';
import { generateStateFromTemplate } from '../Aides/ShiftDetails/DVRForm';
import { getPDFGenerationEndpoint } from '../utils';
import { getFormGeneratorVersion } from '../utils/app';
import { get, put } from './apiCallHelper';

export const getBlankDailyVisitRecordForClockOut = async () => {
  const blankDVRTemplate = await getDailyVisitForm();
  const flattenedDVRTemplate = generateStateFromTemplate(blankDVRTemplate);
  const formattedBlankDVR = formatFormForSubmission(flattenedDVRTemplate);
  return formattedBlankDVR;
};

export const formatFormForSubmission = (formState: any) => {
  const { form, error, ...formMetaData } = formState;
  if (error) {
    return {
      ...formMetaData,
      error,
    };
  }

  if (form) {
    const tempFormObjectified = Object.entries(form).map(([key, value]): any => {
      return {
        id: key,
        value,
      };
    });

    return {
      ...formMetaData,
      form: tempFormObjectified,
    };
  }

  console.log('no form data passed in!');
};

// Getting the form
export const getDailyVisitForm = async () => {
  const generatorVersion = getFormGeneratorVersion();
  const tempFormResponse = await get(`api/Form/Template/DailyVisit/${generatorVersion}`);
  if (tempFormResponse.status >= 300) {
    throw new Error('Could not get Daily Visit Record form from the server, please try again');
  }
  const tempForm = tempFormResponse.body;

  // const tempForm = localDVR;
  if (tempForm) {
    return { ...formatDvrFromApi(tempForm), generatorVersion };
  }
};

export const getLastForm = async (patientId: string) => {
  const generatorVersion = getFormGeneratorVersion();
  const tempFormResponse = await get(`api/Form/Templates/?patientId=${patientId}`);
  if (tempFormResponse.status >= 300) {
    throw new Error('Could not get last form from the server, please try again');
  }
  const tempForms = tempFormResponse.body;

  if (tempForms.forms && Array.isArray(tempForms.forms) && tempForms.forms[0]) {
    return { ...formatDvrFromApi(tempForms.forms[0]), generatorVersion };
  } else return {};
};

// converting the tasks to 'data' because
// that's what the flat list requires.
const formatDvrFromApi = (form: any) => {
  const tempForm = JSON.parse(form);

  tempForm.form = [
    ...tempForm.form.map((tempSection: any) => {
      return {
        ...tempSection,
        data: tempSection.tasks,
      };
    }),
  ];

  return tempForm;
};

// For formatting the data to send it to the API
// In the way we're expeting it
export const formatFormData = (formState: any) => {
  const { form, error, ...formMetaData } = formState;
  if (error) {
    return {
      ...formMetaData,
      error,
    };
  }

  if (form) {
    const tempFormObjectified = Object.entries(form).map(([key, value]): any => {
      return {
        id: key,
        value,
      };
    });

    return {
      ...formMetaData,
      form: tempFormObjectified,
    };
  }

  console.log('no form data passed in!');
};

export const saveDVREdits = async (form: any, clockInId: any) => {
  try {
    await put(`/api/Form/Values/${clockInId}`, {
      body: formatFormData(form),
    });
  } catch (error) {
    console.log('error — ', error);
    throw new Error('Error saving the updated DVR.');
  }
};

export const getDvrByClockInOutReviewId = async (clockInOutReviewId: number | string) => {
  const dvr = await get(`/api/Form/${clockInOutReviewId}`);

  const nurseSignature = dvr.body.clockInOut.nurseClockOutSignatureExists
    ? await getImage(dvr.body.clockInOut.nurseClockOutSignatureURL)
    : null;
  const patientSignature = dvr.body.clockInOut.patientClockOutSignatureExists
    ? await getImage(dvr.body.clockInOut.patientClockOutSignatureURL)
    : null;

  dvr.body.clockInOut.nurseSignature = nurseSignature;
  dvr.body.clockInOut.patientSignature = patientSignature;
  return dvr;
};

export const generatePDF = async (headerHTML: string, formHTML: string, footerHTML: string) => {
  try {
    const tempAPI = new Frisbee({
      raw: true,
      baseURI: getPDFGenerationEndpoint(),
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    const tempPDFResponse = await tempAPI.post(`/pdf/generate/pdf`, {
      body: JSON.stringify({ html: generateHTMLPayload(headerHTML, formHTML, footerHTML) }),
    });
    showFile(await tempPDFResponse.blob());
  } catch (error) {
    console.log('error — ', error);
  }
};

const generateHTMLPayload = (headerHTML: string, formHTML: string, footerHTML: string) => {
  return `
  <!DOCTYPE html><html lang="en">
    <head>
      <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta http-equiv="X-UA-Compatible" content="ie=edge"><title>Printable Form</title>
      <style>${cssContent}</style>
    </head>
    <body>
      <div class="form-container">
        ${headerHTML}
        <div class="form-content">
          <!-- KEY -->
          <div class="key"></div>
          <div>
            <div class="section-row key">
              <div class="key-title">Activities</div>
              <div class="key-title">Performed</div>
              <div class="key-title">Refused</div>
              <div class="key-title">Tasks</div>
            </div>
          </div>
          <!-- KEY END  -->
          ${formHTML}
        </div>
        ${footerHTML}
      </div>
    </body>
    </html>`;
};

const showFile = (blob: any) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([blob], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(newBlob);

  // TODO make sure this works on target browsers
  window.open(fileURL);
};

const getImage = async (path: string): Promise<string> => {
  return await api
    .get(path, { raw: true })
    .then((res: Response) => res.blob())
    .then((blob: Blob) => URL.createObjectURL(blob));
};

const cssContent = `
.form-container {
  display: grid;
  grid-template-rows: 64px 1fr;
  color: #333;
  font-size: 8px;
  /* because a printer is not full bleed */
  padding: 4px 12px; 
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 8px;
  margin-bottom: 6px;
}

.form-title {
  font-weight: 700;
}

.form-header-time-info {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.form-content {
  display: grid;
  grid-template-columns: 32px 1fr;
}

.section-row {
  display: grid;
  grid-template-columns: 2fr 0.75fr 0.75fr 4fr;
  border-bottom: 1px solid #333;
  page-break-inside: avoid;
}

.section-title {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dedede;
  border-bottom: 1px solid #333;
}

.section-title-text {
  transform: rotateZ(270deg);
  font-size: 8px;
}

.activity-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 6px;
}

.activity-status {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dedede;
}

.section-sub-tasks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 6px;
}

.section-sub-tasks .item {
  padding-right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.section-sub-tasks .text-item {
  display: flex;
  flex-wrap: wrap;
  word-wrap: wrap;
}

.section-sub-tasks .item-title {
  padding-left: 2px;
}

.item-box {
  height: 8px;
  width: 8px;
  border: 1px solid #333;
}

.performed {
  background-color: #4cadd3;
}

.selected {
  color: white;
  background-color: #4cadd3;
}

.rejected {
  color: white;
  background-color: #ff5526;
}

.key {
  color: white;
  background-color: #333;
  padding: 4px;
}

.key-title {
  display: flex;
  align-items: center;
  padding-left: 6px;
  font-size: 10px;
  text-transform: uppercase;
}`;
