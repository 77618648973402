const SET_REJECTION_REASONS = 'SET_REJECTION_REASONS';
const SET_LOCATION_REASONS = 'SET_LOCATION_REASONS';
const SET_TIME_REASONS = 'SET_TIME_REASONS';
const SET_ALL_REASON_CODES = 'SET_ALL_REASON_CODES';
const SET_APP_SETTINGS = 'SET_APP_SETTINGS';
const SET_NEW_SHIFT_REASONS = 'SET_NEW_SHIFT_REASONS';
const CLEAR_APP_DATA = 'CLEAR_APP_DATA';
export const types = {
  SET_ALL_REASON_CODES,
  SET_REJECTION_REASONS,
  SET_LOCATION_REASONS,
  SET_TIME_REASONS,
  SET_APP_SETTINGS,
  SET_NEW_SHIFT_REASONS,
  CLEAR_APP_DATA,
};
