import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

export const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'rgba(47,47,47,0.77)',
    color: 'white',
    maxWidth: 220,
    fontSize: '14px',
  },
  arrow: {
    color: '#ececec',
  },
}))(Tooltip);
