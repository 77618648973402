import React from 'react';
import styled from 'styled-components';

const ConfirmButton = styled.button`
  cursor: pointer;
  border: none;
  margin-top: 6px;
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: bold;
  background-color: ${({ theme }) => theme.colors.toastInfo};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.black}`};
  color: ${({ theme }) => theme.colors.white};
`;

export const CacheBustConfirmToast = ({ onConfirm }: { onConfirm: () => void }) => {
  return (
    <div>
      <div>
        A new version of the app is available. Please click the button below to log out to get
        updates.
      </div>
      <ConfirmButton onClick={onConfirm}>Get New Version</ConfirmButton>
    </div>
  );
};
