import React from 'react';
import { useSelector } from 'react-redux';

import { approveShiftStatus, rejectShift as rejectShiftUpdate, saveDVREdits } from '../../api';
import { theme as aveannaTheme } from '../../common';

import { ShiftFooterContainer, ShiftFooterButtonContainer } from '../ShiftPanel/styles';
import { ShiftDetailsConfirmation } from './ShiftDetailsConfirmation';
import { ShiftFooterButton } from './ShiftFooterButtons';
import QANurseReviewButton from './QANurseReviewButton';
import QACompleteButton from '../../QANurse/components/QACompleteButton';
import { userTypes, getTotalHours } from '../../utils';
import { StatusType } from '../constants';

interface IProps {
  review: any;
  onConfirm: (component: React.ReactNode) => void;
  isEditMode?: boolean;
  disabled?: boolean;
  disabledReject?: boolean;
  onBack: () => void;
  onClose: () => void;
  refreshData?: () => void;
  totalHours: number;
}

export const ShiftDetailsFooter = ({
  review,
  onConfirm,
  onBack,
  onClose,
  isEditMode,
  refreshData,
  disabled = false,
  disabledReject = false,
  totalHours,
}: IProps) => {
  const { editedDVR, originalDVR, editedClockInOut, currentClockInOut } = useSelector(
    ({ aides }: any) => {
      return {
        editedDVR: aides.currentEditedDVRState,
        originalDVR: aides.currentDVRState,
        editedClockInOut: aides.currentEditedShift.clockInOut,
        currentClockInOut: aides.currentShift.clockInOut,
      };
    }
  );

  const user = useSelector((state: any) => state.user.user);

  if (isEditMode === undefined) {
    isEditMode = false;
  }

  if (!review) {
    return null;
  }
  const adjustedTotalHours = getTotalHours(
    editedClockInOut.adjustedClockOutTimeStamp,
    editedClockInOut.adjustedClockInTimeStamp
  );

  const tempIsClockInOutEdited =
    JSON.stringify(editedClockInOut) !== JSON.stringify(currentClockInOut);
  const tempIsDVREdited = JSON.stringify(editedDVR) !== JSON.stringify(originalDVR);

  const approveShift = async (reasons: any) => {
    if (tempIsDVREdited) {
      await saveDVREdits(editedDVR, review.id);
    }

    const tempClockInClockOut = tempIsClockInOutEdited ? editedClockInOut : review;
    await approveShiftStatus(tempClockInClockOut, reasons);

    if (refreshData) {
      refreshData();
    }
  };

  const rejectShift = async (rejectionReasons: any) => {
    if (tempIsDVREdited) {
      await saveDVREdits(editedDVR, review.id);
    }

    const tempClockInClockOut = tempIsClockInOutEdited ? editedClockInOut : review;

    await rejectShiftUpdate(tempClockInClockOut, rejectionReasons);
    if (refreshData) {
      refreshData();
    }
  };

  const tempExistingRejections =
    review.clockInOutRejectionReasons && review.clockInOutRejectionReasons.length > 0
      ? review.clockInOutRejectionReasons
      : null;

  const message = (
    <span>
      Are you sure you want to APPROVE this shift of{' '}
      {totalHours > 24 ? (
        <span
          style={{
            textDecoration: 'underline',
            fontWeight: 'bold',
            color: 'red',
            fontSize: 24,
          }}
        >
          {adjustedTotalHours} HOURS
        </span>
      ) : (
        `${adjustedTotalHours} HOURS`
      )}
      ?
    </span>
  );

  let validationErrorMessage = 'Validation Error! Please edit the shift and add reason codes for: ';

  const isMissingGPSData = () => {
    const clockInOut = isEditMode ? editedClockInOut : currentClockInOut;
    const containsClockInGPSData = clockInOut.clockInLatitude && clockInOut.clockInLongitude;
    const containsClockOutGPSData = clockInOut.clockOutLatitude && clockInOut.clockOutLongitude;
    if (!!containsClockInGPSData && !!containsClockOutGPSData) {
      disabled = false;
      return false;
    }
    if (
      isEditMode &&
      !!clockInOut.clockInLocationCorrectionId &&
      !!clockInOut.clockOutLocationCorrectionId
    ) {
      disabled = false;
      return false;
    }
    disabled = true;
    validationErrorMessage = validationErrorMessage.concat('Missing GPS Data; ');
    return true;
  };

  const isIncorrectClockInOut = () => {
    if (adjustedTotalHours > 0) {
      disabled = false;
      return false;
    } else {
      disabled = true;
      validationErrorMessage =
        'Validation Error! For this shift, please select a clock out time after your clocked in time.';
      return true;
    }
  };

  const validationErrorsPresent = () => {
    return isMissingGPSData() || isIncorrectClockInOut();
  };

  const openApproveShiftConfirmation = async () => {
    onConfirm(
      <div>
        <ShiftDetailsConfirmation
          existingRejections={tempExistingRejections}
          message={message}
          toastSuccessMessage="Shift successfully APPROVED"
          onConfirm={approveShift}
          onBack={onBack}
          onClose={onClose}
          isEditMode={isEditMode}
          isApproval={true}
          currentStatus={review.status}
        />
      </div>
    );
  };

  const openRejectShiftConfirmation = () => {
    onConfirm(
      <div>
        <ShiftDetailsConfirmation
          existingRejections={tempExistingRejections}
          message="Are you sure you want to place this shift UNDER REVIEW?"
          toastSuccessMessage="Shift successfully set as UNDER REVIEW"
          onConfirm={rejectShift}
          onBack={onBack}
          onClose={onClose}
          isEditMode={isEditMode}
          isApproval={false}
        />
      </div>
    );
  };

  const isApproved = review.status === StatusType.APPROVED;

  return (
    <ShiftFooterContainer>
      <ShiftFooterButtonContainer>
        <div>
          {validationErrorsPresent() && (
            <div>
              <span
                style={{
                  fontWeight: 'bold',
                  color: 'red',
                  fontSize: 13,
                }}
              >
                {validationErrorMessage}
              </span>
            </div>
          )}
          <ShiftFooterButton
            disabled={isApproved || disabled}
            onClick={openApproveShiftConfirmation}
          >
            Approve Shift
          </ShiftFooterButton>
          <ShiftFooterButton
            disabled={disabledReject}
            buttonColor={aveannaTheme.colors.footerButtonRed}
            onClick={openRejectShiftConfirmation}
          >
            Under Review
          </ShiftFooterButton>
          {
            // @ts-ignore
            user.userType === userTypes.QA_NURSE &&
            (currentClockInOut.qa || currentClockInOut.systemQA) ? (
              <QACompleteButton isEditMode={isEditMode} onClose={onClose} />
            ) : (
              <QANurseReviewButton isEditMode={isEditMode} />
            )
          }
        </div>
      </ShiftFooterButtonContainer>
    </ShiftFooterContainer>
  );
};
