import { useState, useEffect } from 'react';
import { getPatientForms } from '../../api/patient';

const usePatientForms = (patientId?: number | string) => {
  const [forms, setForms] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (patientId) {
      setLoading(true);
      getPatientForms(patientId)
        .then((res) => {
          if (res && res.forms) setForms(res.forms.map(JSON.parse));
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [patientId]);
  return { forms, loading };
};

export default usePatientForms;
