import { types } from './constants';
import { IChannel, IMessage } from './interfaces';

interface IUpdateChannelsParams {
  newMessages?: IMessage[];
  channelId: number;
  channels?: IChannel[];
}

const messagesInitialState = {
  isFetching: false,
  hasUnreadMessage: false,
  currentActiveChannelId: null,
  channels: [],
  error: null,
};

export const messagesReducer = (
  state = messagesInitialState,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case types.REQUEST_MESSAGES:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case types.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        channels: appendUnreadStatusToChannels(state.channels, payload.channels),
      };
    case types.GET_MESSAGES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case types.SET_ACTIVE_CHANNEL:
      return {
        ...state,
        currentActiveChannelId: payload.channelId,
        ...setActiveChannelAsRead(state.channels, payload.channelId, state.hasUnreadMessage),
      };
    case types.CLEAR_ACTIVE_CHANNEL:
      return {
        ...state,
        currentActiveChannelId: null,
      };
    case types.ADD_NEW_CHANNEL:
      const channels = [...state.channels, payload];
      return { ...state, channels };
    case types.REQUEST_NEW_CHANNEL_MESSAGES:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_NEW_CHANNEL_MESSAGES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        channels: updateChannelMessages({
          newMessages: payload.newMessages,
          channelId: payload.channelId,
          channels: state.channels,
        }),
      };
    // checking all channels for new messages
    case types.CHECK_MESSAGES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...checkForNewMessages({
          newChannels: payload.channels,
          previousChannels: state.channels,
          activeChannelId: state.currentActiveChannelId,
          hasUnreadMessage: state.hasUnreadMessage,
        }),
      };
    case types.REQUEST_SEND_MESSAGE:
      return {
        ...state,
        isFetching: true,
      };
    case types.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        channels: addNewMessageToChannel({
          channels: state.channels,
          channelId: payload.channelId,
          newMessage: payload.message,
        }),
      };
    case types.SEND_MESSAGE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case types.REQUEST_SEND_MASS_MESSAGE:
      return {
        ...state,
        isFetching: true,
      };
    case types.SEND_MASS_MESSAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case types.SEND_MASS_MESSAGE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

// add some front end tracking to channels
const appendUnreadStatusToChannels = (prevChannels: IChannel[], newChannels: IChannel[]) => {
  // no previous channels so flag all as unread
  if (newChannels && prevChannels.length <= 0) {
    return newChannels.map((tempChannel: IChannel) => {
      return {
        ...tempChannel,
        isUnread: tempChannel.isUnread ? tempChannel.isUnread : false,
      };
    });
  }

  // if there are previous channels, then retain their unread state
  return newChannels.map((tempChannel: IChannel) => {
    const tempPrevEquivalentChannel = prevChannels.find(
      (tPrevChannel: IChannel) => tPrevChannel.id === tempChannel.id
    );

    return {
      ...tempChannel,
      isUnread:
        tempPrevEquivalentChannel && tempPrevEquivalentChannel.isUnread
          ? tempPrevEquivalentChannel.isUnread
          : false,
    };
  });
};

const setActiveChannelAsRead = (
  channels: IChannel[],
  activeChannelId: number,
  hasUnreadMessage: boolean
) => {
  let tempHasUnread = hasUnreadMessage;
  const tempChannels = channels.map((tempChannel: IChannel) => {
    if (tempChannel.id === activeChannelId) {
      tempHasUnread = false;
      return {
        ...tempChannel,
        isUnread: false,
      };
    } else {
      return tempChannel;
    }
  });

  return { channels: tempChannels, hasUnreadMessage: tempHasUnread };
};

const updateChannelMessages = ({ newMessages, channelId, channels }: IUpdateChannelsParams) => {
  if (channels && newMessages && newMessages.length > 0) {
    const updatedChannels = channels.map((tempChannel: IChannel) => {
      if (tempChannel.id === channelId) {
        tempChannel = {
          ...tempChannel,
          messages: [...tempChannel.messages, ...newMessages],
        };
      }
      return tempChannel;
    });

    return updatedChannels;
  }

  // no change in messages, return current state
  return channels;
};

// CHECK IF ANY NEW MESSAGES HAVE COME IN
const checkForNewMessages = ({
  newChannels,
  previousChannels,
  activeChannelId,
  hasUnreadMessage,
}: {
  newChannels: IChannel[];
  previousChannels: IChannel[];
  activeChannelId: number | null;
  hasUnreadMessage: boolean;
}) => {
  // if there were no previous channels
  // then just use the new ones we just got
  if (previousChannels && previousChannels.length <= 0) {
    return { channels: newChannels };
  }

  let tempHasUnreadMessage = hasUnreadMessage;
  const tempUpdatedChannels = newChannels.map((tNewChannel: IChannel) => {
    const tempPrevEquivalentChannel = previousChannels.find(
      (tPrevChannel: IChannel) => tPrevChannel.id === tNewChannel.id
    );
    if (
      tempPrevEquivalentChannel &&
      tempPrevEquivalentChannel.id !== activeChannelId &&
      tNewChannel.messages.length > tempPrevEquivalentChannel.messages.length
    ) {
      tempHasUnreadMessage = true;
      return {
        ...tNewChannel,
        isUnread: tNewChannel.isUnread ? tNewChannel.isUnread : true,
      };
    }
    return tempPrevEquivalentChannel;
  });
  return { channels: tempUpdatedChannels, hasUnreadMessage: tempHasUnreadMessage };
};

const addNewMessageToChannel = ({
  channels,
  channelId,
  newMessage,
}: {
  channels: IChannel[];
  channelId: number;
  newMessage: IMessage;
}) => {
  return channels.map((tChannel: IChannel) => {
    if (tChannel.id === channelId) {
      return { ...tChannel, messages: [...tChannel.messages, newMessage] };
    }
    return tChannel;
  });
};
