import React, { Component, FormEvent, MouseEvent } from 'react';
import {
  StyledMessageInputContainer,
  StyledMessageInputBox,
  StyledSendButton,
} from './StyledMessages';
import rightArrow from '../../assets/right-arrow.svg';

interface IState {
  messageText: string;
}

interface IProps {
  sendMessage: ({ text, channelId }: { text: string; channelId: number }) => void;
  currentChannelId?: number;
}
class MessageInput extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      messageText: '',
    };
  }

  private setMessageState = (event: FormEvent<HTMLInputElement>) => {
    this.setState({ messageText: event.currentTarget.value });
  };

  private submitMessage = (event?: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>) => {
    const { messageText } = this.state;
    const { currentChannelId = 0, sendMessage } = this.props;

    if (event) {
      event.preventDefault();
    }

    sendMessage({ text: messageText, channelId: currentChannelId });
    this.setState({ messageText: '' });
  };

  public render() {
    const { messageText } = this.state;
    return (
      <StyledMessageInputContainer onSubmit={this.submitMessage}>
        <StyledMessageInputBox
          value={messageText}
          placeholder="Type a message ..."
          onChange={this.setMessageState}
        />
        <StyledSendButton onClick={this.submitMessage} disabled={messageText.length <= 0}>
          <img src={rightArrow} height={28} />
        </StyledSendButton>
      </StyledMessageInputContainer>
    );
  }
}

export default MessageInput;
