import React from 'react';

import MaterialIcon from 'material-icons-react';

import { RejectionReasonDropDown } from './ShiftRejectionReasonDropdown';
import { theme as aveannaTheme } from '../../common';
import { AddRejectionButton } from '../ShiftPanel/styles';

interface IProps {
  isApproval?: boolean;
  rejectionReasons: any[];
  rejectionReasonsOptions: any[];
  setRejectionReasons: (index: number, value: any) => void;
  addRejectionReason: () => void;
  removeRejectionReason: (index: number) => void;
}

export const RejectionSection = ({
  rejectionReasons,
  setRejectionReasons,
  addRejectionReason,
  removeRejectionReason,
  rejectionReasonsOptions,
  isApproval = false,
}: IProps) => {
  const tempColor = isApproval
    ? aveannaTheme.colors.footerButtonGreen
    : aveannaTheme.colors.footerButtonRed;
  const tempMessage = isApproval
    ? 'Please select the most applicable Action Code'
    : 'Please select reasons for placing this shift under review';

  return (
    <div>
      <div style={{ marginBottom: 24 }}>
        <p>{tempMessage}</p>
        {rejectionReasons &&
          rejectionReasons.map((tempReason: any, index: number) => {
            return (
              <RejectionReasonDropDown
                key={index}
                rejectionReasonOptions={rejectionReasonsOptions}
                reason={tempReason}
                setRejectionReason={(value: any) => {
                  setRejectionReasons(index, value);
                }}
                onRemove={() => removeRejectionReason(index)}
              />
            );
          })}
        {!isApproval && (
          <AddRejectionButton onClick={addRejectionReason} color={tempColor}>
            <MaterialIcon key="add-button" icon="add" color={tempColor} />
          </AddRejectionButton>
        )}
      </div>
    </div>
  );
};
