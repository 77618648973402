import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ShiftFooterButtonContainer } from '../ShiftPanel/styles';
import { ShiftFooterButton, ShiftFooterTextButton } from './ShiftFooterButtons';

const ConfirmText = styled.p`
  margin: 0px;
`;

const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ConfirmTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 24px;
`;

interface IProps {
  onConfirm: () => void;
  onDecline: () => void;
}

export const CancelEditConfirmation = ({ onConfirm, onDecline }: IProps) => {
  const {
    currentClockInOut,
    currentEditedClockInOut,
    currentEditedDVRState,
    currentOriginalDVRState,
  } = useSelector(({ aides }: any) => {
    return {
      currentClockInOut: aides.currentShift.clockInOut,
      currentEditedClockInOut: aides.currentEditedShift.clockInOut,
      currentEditedDVRState: aides.currentEditedDVRState,
      currentOriginalDVRState: aides.currentDVRState,
    };
  });

  const tempIsEdited =
    JSON.stringify(currentEditedDVRState) !== JSON.stringify(currentOriginalDVRState) ||
    JSON.stringify(currentClockInOut) !== JSON.stringify(currentEditedClockInOut);

  // using as didMount
  useEffect(() => {
    if (!tempIsEdited) {
      onConfirm();
    }
  }, []);

  return (
    <ConfirmationContainer>
      <ConfirmTextContainer>
        <ConfirmText>Are you sure?</ConfirmText>
        <ConfirmText>(edits will be reverted)</ConfirmText>
      </ConfirmTextContainer>
      <ShiftFooterButtonContainer style={{ margin: 0 }}>
        <ShiftFooterButton onClick={onConfirm}>Yes</ShiftFooterButton>
        <ShiftFooterTextButton onClick={onDecline}>No</ShiftFooterTextButton>
      </ShiftFooterButtonContainer>
    </ConfirmationContainer>
  );
};
