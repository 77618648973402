import React, { Component } from 'react';

import {
  StyledInputLabel,
  StyledTextInput,
  StyledSubmitButton,
  StyledTransparentButton,
  StyledInputGroup,
  StyledCheckbox,
  StyledPasswordInput,
  StyledLink,
  StyledErrorDisplay,
} from '../../common';
import { checkIfValidEmailAddress } from '../../utils';
import { IUserSignInInput } from '../interfaces';
import { StyledLoginForm } from './StyledLoginForm';
import { Version } from './Version';
import { ROUTES } from '../../routing';
import { Button } from '@material-ui/core';

interface IProps {
  formState: IUserSignInInput;
  updateForm: any;
  submitForm: any;
  error?: string;
  isSubmitting: boolean;
  isPasswordOnly: boolean;
  updateIsRemembered: () => void;
  toggleAmeliaVisibility: () => void;
  returnToUserNameCheck?: () => void;
  ameliaIsRunning: boolean;
}

class LoginForm extends Component<IProps> {
  private isFormValid = (): boolean => {
    const {
      formState: { email, password },
    } = this.props;
    if (password && checkIfValidEmailAddress(email)) {
      return false;
    }
    return true;
  };

  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const {
      formState,
      updateForm,
      submitForm,
      error,
      updateIsRemembered,
      returnToUserNameCheck,
      isSubmitting,
      isPasswordOnly,
      ameliaIsRunning,
    } = this.props;

    return (
      <StyledLoginForm onSubmit={submitForm}>
        <StyledInputGroup style={{ marginTop: 6 }}>
          <StyledInputLabel>Email</StyledInputLabel>
          <StyledTextInput
            placeholder="email address"
            name="email"
            value={formState.email}
            onChange={updateForm}
            autoComplete="on"
            disabled={isPasswordOnly}
          />

          {!isPasswordOnly && (
            <StyledCheckbox
              align="left"
              title="Remember me"
              checked={formState.isRemembered}
              onClick={updateIsRemembered}
            />
          )}

          {isPasswordOnly && (
            <StyledTransparentButton
              type="button"
              onClick={returnToUserNameCheck}
              style={{ alignSelf: 'flex-end' }}
            >
              Not you? Click Here To Go Back
            </StyledTransparentButton>
          )}
        </StyledInputGroup>
        <StyledInputGroup>
          <StyledInputLabel>Password</StyledInputLabel>
          <StyledPasswordInput name="password" value={formState.password} onChange={updateForm} />
          {ameliaIsRunning && (
            <div style={{ alignSelf: 'flex-end' }} onClick={this.props.toggleAmeliaVisibility}>
              <StyledLink to={``}>Forgot password?</StyledLink>
            </div>
          )}
          {!ameliaIsRunning && (
            <div style={{ alignSelf: 'flex-end' }}>
              <StyledLink to={`${ROUTES.PASSWORD}${ROUTES.REQUEST_PASSWORD_RESET}`}>
                Forgot password?
              </StyledLink>
            </div>
          )}
        </StyledInputGroup>
        <StyledErrorDisplay errorMessage={error} style={{ marginTop: 12 }} />
        <StyledSubmitButton
          type="submit"
          value="LOGIN"
          onClick={submitForm}
          disabled={this.isFormValid() || isSubmitting}
        />
        <Version />
      </StyledLoginForm>
    );
  }
}

export default LoginForm;
