import React from 'react';
import styles from '../../Aides/ShiftDetails/ShiftDetails.module.css';
import moment from 'moment';

const ShiftInformation = (props: any) => {
  const { shift } = props;
  return (
    <div style={{ padding: '0.5rem 1.5rem' }}>
      <div className={styles.SP_Body}>
        <div className={styles.GeneralInformation}>
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>Shift Date</div>
            <div className={styles.GeneralInformation_row__content}>
              {moment.utc(shift.start).format('MM/DD/YYYY')}
            </div>
          </div>
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>Shift Scheduled</div>
            <div className={styles.GeneralInformation_row__content}>
              {moment.utc(shift.start).format('HH:mm')}&nbsp;-&nbsp;
              {moment.utc(shift.end).format('HH:mm')}
            </div>
          </div>
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>Shift Hours</div>
            <div className={styles.GeneralInformation_row__content}>
              {moment
                .duration(moment.utc(shift.end).diff(moment.utc(shift.start)))
                .asHours()
                .toFixed(2)}
            </div>
          </div>
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>Scheduled Caregiver</div>
            <div className={styles.GeneralInformation_row__content}>
              {shift.scheduledNurse
                ? `${shift.scheduledNurse.user.givenName} ${shift.scheduledNurse.user.familyName}`
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftInformation;
