import { GridCellParams, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { ICaregiverShiftsData } from '../../../../common/interfaces';
import { NoteOutColumnRenderer } from './Renderers/NoteColumnRenderer';

const clockOutNoteColumn: GridColDef = {
  field: 'clockOutNote',
  headerName: 'Clock Out',
  maxWidth: 115,
  valueGetter: ({ row }: { row: ICaregiverShiftsData }) => row,
  valueFormatter: ({ value }: GridValueFormatterParams<ICaregiverShiftsData>) => {
    return value && value.clockOutAlert ? 'Yes' : 'No';
  },
  cellClassName: (params: GridCellParams<ICaregiverShiftsData>) => {
    return params.value && params.value.clockOutNote ? 'cell warning' : 'cell';
  },
  renderCell: NoteOutColumnRenderer,
};

export default clockOutNoteColumn;
