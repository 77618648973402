import styled from 'styled-components';
import { StyledButton } from '../../../common';
import { theme } from '../../../common';

export const IconLabel = styled.div`
  padding: 0.5rem;
`;
export const StandardButton = styled(StyledButton)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  border-radius: ${theme.buttonBorderRadius};
`;
export const RefreshButton = StandardButton;
export const StyledAideShiftSearch = styled.div`
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;
export const StyledSearchIcon = styled.span`
  border-radius: 6px;
  position: relative;
  top: 7px;
  padding: 1em;
  cursor: pointer;
`;
export const StyledSearchBox = styled.span`
  border-radius: 6px;
  border: 1px solid #0075c9;
  display: flex;
`;
export const StyledKeywordInput = styled.input`
  border-radius: 5px;
  border: 0;

  padding: 0.75rem;
  font-size: 1.25rem;
  &::placeholder {
    font-style: italic;
  }
`;

export default {
  IconLabel,
  RefreshButton,
  StyledAideShiftSearch,
  StyledSearchIcon,
  StyledSearchBox,
  StyledKeywordInput,
  StandardButton,
};
