import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { updateUserActiveStatus } from '../../../api/admin';
import { Switch } from '@material-ui/core';
import { useToasts } from 'react-toast-notifications';
import { ToastAppearance } from '../../../utils';
import { getErrorMessage } from '../../../common/useToastNotifications';

const label = { inputProps: { 'aria-label': 'Switch demo' } };
export const StatusRenderer = forwardRef((props: any, ref) => {
  const [isActive, setIsActive] = useState<boolean>(props.data.isActive);
  const isActiveString = isActive ? 'Active' : 'Inactive';
  const { addToast } = useToasts();

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        (async () => {
          try {
            const response = await updateUserActiveStatus(props.data, isActive);
            setIsActive(response.isActive);
            addToast(`Status successfully updated`, {
              appearance: ToastAppearance.SUCCESS,
              autoDismiss: true,
            });
          } catch (error) {
            console.log('Error updating status ', getErrorMessage(error));
            addToast('Error updating status ', {
              appearance: ToastAppearance.WARNING,
              autoDismiss: true,
            });
          }
        })();

        return isActive;
      },
    };
  });

  return (
    <div id="user-status-toggle">
      <Switch
        id="user-status-switch"
        data-testid="user-status-switch"
        {...label}
        defaultChecked={isActive}
        onChange={async () => {
          setIsActive(!isActive);
        }}
      />
      <span data-testid="user-status-text">{isActiveString}</span>
    </div>
  );
});
