import React from 'react';

// Good for use in forms
// TODO make this thing always be the right size and take up the right amount of space regardless of
// TODO if there is an error or not
export const StyledErrorDisplay = ({
  errorMessage,
  style,
}: {
  errorMessage?: string | null;
  style?: any;
  marginTop?: number;
}) => {
  const calcMarginTop = style && style.marginTop ? 40 + style.marginTop : 40;
  return errorMessage ? (
    <div style={{ marginTop: 0, marginBottom: 20, color: 'maroon', textAlign: 'center', ...style }}>
      {errorMessage}
    </div>
  ) : (
    <div style={{ height: calcMarginTop }} />
  );
};

export const StyledMultiErrorDisplay = ({ errors }: { errors: string[] }) => {
  return (
    <div style={{ marginBottom: 24 }}>
      {errors &&
        Object.values(errors).map((tempErrorMessage, index) => (
          <div key={index}>{tempErrorMessage}</div>
        ))}
    </div>
  );
};
