import MaterialIcon from 'material-icons-react';
import React, { useState } from 'react';
import { ISearchActions } from '../../actions';
import _ from 'lodash';
import { ISearchState } from '../../searchReducer';
import SearchStyledComponents from './AideShiftSearchStyledComponents';
import StyledDateRangePicker from '../../../common/StyledDateRangePicker';
import moment, { Moment } from 'moment';
let debounce: any;

export interface IAideShiftSearchComponentProps {
  actions: IAideShiftSearchComponentActions;
  props: {
    searchParams: ISearchState;
  };
}

export interface ISetterAction {
  setterAction: (value?: any) => void;
  newValue: Date | string | undefined;
}

export interface IAideShiftSearchComponentActions {
  SearchActions: ISearchActions;
  setSearchParameterAndRequest: (setterActions: ISetterAction[], shouldRequest?: boolean) => void;
  requestSearch: () => void;
}

const searchOptions: string[] = [
  '"Pending"',
  '"Reviewing"',
  '"Approved"',
  'Any name',
  '"Location"',
];
const getRandomOption = () => searchOptions[Math.floor(Math.random() * searchOptions.length)];
export const AideShiftSearchComponent = ({
  props: { searchParams },
  actions,
}: IAideShiftSearchComponentProps) => {
  const [focusedInput, setFocusedInput] = useState<any>('');
  const { searchStartDate, searchEndDate, searchTerm, isDefaultState } = searchParams;
  const { setDefaultSearchParameters, setSearchTerm, setSearchStartDate, setSearchEndDate } =
    actions.SearchActions;
  const { setSearchParameterAndRequest, requestSearch } = actions;

  const dispatchSearchParamsAndRequest = (
    startDate: Moment | null,
    endDate: Moment | null,
    shouldRequest: boolean
  ) => {
    const params = [];
    if (startDate) {
      params.push({ setterAction: setSearchStartDate, newValue: startDate.toDate() });
    }
    if (endDate) {
      params.push({ setterAction: setSearchEndDate, newValue: endDate.toDate() });
    }
    setSearchParameterAndRequest(params, shouldRequest);
  };

  const changeDates = (startDate: any, endDate: any) => {
    dispatchSearchParamsAndRequest(startDate, endDate, false);
  };

  const handleOnClose = (startDate: Moment | null, endDate: Moment | null) => {
    dispatchSearchParamsAndRequest(startDate, endDate, true);
  };

  return (
    <SearchStyledComponents.StyledAideShiftSearch>
      <SearchStyledComponents.StyledSearchBox>
        <SearchStyledComponents.StyledKeywordInput
          placeholder={`Try: ${getRandomOption()}`}
          value={searchTerm}
          onChange={async (e) => {
            if (debounce) {
              debounce.cancel();
            }
            await setSearchParameterAndRequest(
              [{ setterAction: setSearchTerm, newValue: e.target.value }],
              false
            );
            debounce = await _.debounce(requestSearch, 1300);
            debounce();
          }}
        />
        <SearchStyledComponents.StyledSearchIcon onClick={() => requestSearch()}>
          <MaterialIcon icon="search" />
        </SearchStyledComponents.StyledSearchIcon>
      </SearchStyledComponents.StyledSearchBox>
      <div style={{ zIndex: 3 }}>
        <StyledDateRangePicker
          futureLimit={1}
          startDate={moment(searchStartDate)}
          startDateId={'shiftSearchStartDate'}
          endDate={moment(searchEndDate)}
          endDateId={'shiftSearchEndDate'}
          onDatesChange={(value1: any, value2: any) => changeDates(value1, value2)}
          focusedInput={focusedInput}
          onFocusChange={(value: any) => setFocusedInput(value)}
          onClose={handleOnClose}
        />
      </div>
      <SearchStyledComponents.RefreshButton
        primary
        disabled={isDefaultState}
        onClick={() => {
          setSearchParameterAndRequest([
            { setterAction: setDefaultSearchParameters, newValue: undefined },
          ]);
        }}
      >
        <MaterialIcon icon="history" color="white" />
        <SearchStyledComponents.IconLabel>Reset All</SearchStyledComponents.IconLabel>
      </SearchStyledComponents.RefreshButton>
    </SearchStyledComponents.StyledAideShiftSearch>
  );
};
