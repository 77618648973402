import { GridColDef } from '@mui/x-data-grid';
import { ICaregiverShiftsData } from '../../../../common/interfaces';

const caregiverColumn: GridColDef = {
  field: 'caregiverName',
  headerName: 'Caregiver',
  minWidth: 120,
  valueGetter: ({ row }: { row: ICaregiverShiftsData }) => {
    return row && `${row.nursesGivenName} ${row.nursesFamilyName}`;
  },
};

export default caregiverColumn;
