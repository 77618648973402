import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import Media from 'react-media';

import { device, WithTracker } from '../../common/';
import { NotFound, ROUTES, ROUTE_PARAMS } from '../../routing';

import {
  TabBar,
  TabNames,
  TableLayoutWrapper,
  ShiftsWrapper,
  CardLayoutWrapper,
} from '../components';

import {
  OpenShiftCards,
  OpenShiftsTable,
  SentShiftCards,
  SentShiftsTable,
  AppliedShiftCards,
  AppliedShiftsTable,
  AwardedShiftCards,
  AwardedShiftsTable,
  ScheduledShiftCards,
  ScheduledShiftsTable,
  OrientRequestsTable,
} from './';

import {
  checkForNewMessages as checkForNewMessagesAction,
  getMessages as getAllMessagesAction,
} from '../../messaging';

import { setCurrentTab as setCurrentTabAction } from '../actions';

const messagesPollRate = 10000; // 10s

type Props = {
  getNewMessages: () => void;
  getInitialMessage: () => void;
  isFetchingMessages: boolean;
  currentShiftTab: TabNames;
  setCurrentTab: (currentTab: TabNames) => void;
} & RouteComponentProps;

class Shifts extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentTab: this.props.currentShiftTab,
      loading: true,
      error: false,
    };
  }

  // reference to the message poll interval
  private getAllMessages: any = null;

  public componentDidMount = async () => {
    this.loadInitialData();
  };

  public loadInitialData = async () => {
    this.props.getInitialMessage();
    this.getAllMessages = setInterval(this.checkAllChannelsForNewMessages, messagesPollRate);
  };

  public componentWillUnmount() {
    clearInterval(this.getAllMessages);
  }

  private checkAllChannelsForNewMessages = () => {
    const { isFetchingMessages, getNewMessages } = this.props;
    if (!isFetchingMessages) {
      getNewMessages();
    }
  };

  public render() {
    return (
      <ShiftsWrapper>
        <TabBar />
        <Media query={device.mobileL}>
          {(queryMatched) =>
            queryMatched ? (
              <TableLayoutWrapper>
                {/*show table view */}
                <Switch>
                  <Route
                    path={`${ROUTES.SHIFTS}/${ROUTE_PARAMS.OPEN}`}
                    component={OpenShiftsTable}
                  />
                  <Route
                    path={`${ROUTES.SHIFTS}/${ROUTE_PARAMS.AWARDED}`}
                    component={AwardedShiftsTable}
                  />
                  <Route
                    path={`${ROUTES.SHIFTS}/${ROUTE_PARAMS.SENT}`}
                    component={SentShiftsTable}
                  />
                  <Route
                    path={`${ROUTES.SHIFTS}/${ROUTE_PARAMS.APPLIED}`}
                    component={AppliedShiftsTable}
                  />
                  <Route
                    path={`${ROUTES.SHIFTS}/${ROUTE_PARAMS.SCHEDULED}`}
                    component={ScheduledShiftsTable}
                  />
                  <Route
                    path={`${ROUTES.SHIFTS}/${ROUTE_PARAMS.ORIENT_REQUESTS}`}
                    component={OrientRequestsTable}
                  />
                  <Route component={NotFound} />
                </Switch>
              </TableLayoutWrapper>
            ) : (
              <CardLayoutWrapper id="card-layout-wrapper">
                {/* show card view */}
                <Switch>
                  <Route
                    path={`${ROUTES.SHIFTS}/${ROUTE_PARAMS.OPEN}`}
                    component={OpenShiftCards}
                  />
                  <Route
                    path={`${ROUTES.SHIFTS}/${ROUTE_PARAMS.AWARDED}`}
                    component={AwardedShiftCards}
                  />
                  <Route
                    path={`${ROUTES.SHIFTS}/${ROUTE_PARAMS.SENT}`}
                    component={SentShiftCards}
                  />
                  <Route
                    path={`${ROUTES.SHIFTS}/${ROUTE_PARAMS.APPLIED}`}
                    component={AppliedShiftCards}
                  />
                  <Route
                    path={`${ROUTES.SHIFTS}/${ROUTE_PARAMS.SCHEDULED}`}
                    component={ScheduledShiftCards}
                  />
                  <Route component={NotFound} />
                </Switch>
              </CardLayoutWrapper>
            )
          }
        </Media>
      </ShiftsWrapper>
    );
  }
}

const mapStateToProps = ({ messages, currentShiftTab }: any) => {
  return {
    hasUnreadMessage: messages.hasUnreadMessage,
    isFetchingMessages: messages.isFetching,
    currentShiftTab,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      getNewMessages: checkForNewMessagesAction,
      getInitialMessage: getAllMessagesAction,
      setCurrentTab: setCurrentTabAction,
    },
    dispatch
  );
};

export const ShiftsContainer = connect(mapStateToProps, mapDispatchToProps)(Shifts);
