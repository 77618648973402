import React from 'react';
import styled from 'styled-components';

import { ShiftPanelHeading } from '../../../Aides/ShiftPanel/ShiftSidebar';
import { EditedCorrectionItem } from '../../../Aides/ShiftDetails/ShiftEditReview/EditedCorrectionItem';
import { EditedTimeItem } from '../../../Aides/ShiftDetails/ShiftEditReview/EditedTimeItem';
import { EditorInfo } from '../../../Aides/ShiftDetails/ShiftEditReview/EditorInfo';
import styles from '../../../Aides/ShiftDetails/ShiftEditReview/ShiftEditReview.module.css';

const ShiftEditReviewContainer = styled.div`
  padding: 0px 24px 24px 24px;
`;

export type ReviewData = {
  clockInTimeCorrectionId?: number | null;
  clockOutTimeCorrectionId?: number | null;
  clockInLocationCorrectionId?: number | null;
  clockOutLocationCorrectionId?: number | null;
  adjustedClockInTimeStamp: string;
  adjustedClockOutTimeStamp: string;
};

type Props = {
  before: ReviewData;
  after: ReviewData;
};

export const ShiftEditReview = ({ before, after }: Props) => {
  const isEdited = () => {
    return (
      before.clockInTimeCorrectionId === after.clockInTimeCorrectionId &&
      before.clockOutTimeCorrectionId === after.clockOutTimeCorrectionId &&
      before.clockInLocationCorrectionId === after.clockInLocationCorrectionId &&
      before.clockOutLocationCorrectionId === after.clockOutLocationCorrectionId &&
      new Date(before.adjustedClockInTimeStamp).getDate() ===
        new Date(after.adjustedClockOutTimeStamp).getDate() &&
      new Date(before.adjustedClockOutTimeStamp).getDate() ===
        new Date(after.adjustedClockOutTimeStamp).getDate()
    );
  };

  return isEdited() ? (
    <ShiftEditReviewContainer>
      <ShiftPanelHeading>
        You have edited shift information. Are you sure you want to save the changes?
      </ShiftPanelHeading>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <div className={styles.editGroup}>
          <div style={{ flex: 1 }}>
            <p style={{ fontSize: 14, color: '#333' }}>ORIGINAL</p>
          </div>
          <div style={{ flex: 1 }}>
            <p style={{ fontSize: 14, color: '#333' }}>NEW</p>
          </div>
        </div>
        <EditedCorrectionItem
          title="Clock In Correction"
          previousData={before.clockInTimeCorrectionId || 0}
          currentData={after.clockInTimeCorrectionId || 0}
        />
        <EditedCorrectionItem
          title="Clock Out Correction"
          previousData={before.clockOutTimeCorrectionId || 0}
          currentData={after.clockOutTimeCorrectionId || 0}
        />
        <EditedCorrectionItem
          title="Location In Correction"
          previousData={before.clockInLocationCorrectionId || 0}
          currentData={after.clockInLocationCorrectionId || 0}
        />
        <EditedCorrectionItem
          title="Location Out Correction"
          previousData={before.clockOutLocationCorrectionId || 0}
          currentData={after.clockOutLocationCorrectionId || 0}
        />
        <EditedTimeItem
          title="Clock In Time"
          previousData={new Date(before.adjustedClockInTimeStamp)}
          currentData={new Date(after.adjustedClockInTimeStamp)}
        />
        <EditedTimeItem
          title="Clock Out Time"
          previousData={new Date(before.adjustedClockOutTimeStamp)}
          currentData={new Date(after.adjustedClockOutTimeStamp)}
        />
      </div>
      <EditorInfo />
    </ShiftEditReviewContainer>
  ) : null;
};
