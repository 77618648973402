import React, { useState } from 'react';
import Select from 'react-select';
import { IReasonCode } from '../../../common/interfaces';

type Id = number | null;

type IProps = {
  id: Id;
  relatedReasonCodes: IReasonCode[];
  onReasonCodeChange: (newValue: Id) => void;
};

type ReasonCodeOption = {
  value: number;
  label: string;
};

export default function ReasonCodeSelection({
  id,
  relatedReasonCodes,
  onReasonCodeChange,
}: IProps) {
  const [selectedId, setSelectedId] = useState(id);

  const options: ReasonCodeOption[] = relatedReasonCodes.map((x: IReasonCode) => ({
    value: x.id as number,
    label: `${x.code} - ${x.text}`,
  }));

  function handleSelectionChange(selected: any) {
    setSelectedId(selected.value);
    onReasonCodeChange(selected.value);
  }

  if (relatedReasonCodes && relatedReasonCodes.length) {
    return (
      <Select
        options={options}
        value={options.find((x) => x.value === selectedId)}
        onChange={handleSelectionChange}
      />
    );
  } else {
    return <span>No data found.</span>;
  }
}
