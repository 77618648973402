import React, { Component, RefObject } from 'react';
import styled from 'styled-components';
import { IChannel, IMessage } from '../interfaces';
import { Message, MessageInput, MessagesMobileHeader } from '../components/';

interface IProps {
  activeChannel?: IChannel;
  sendMessage: ({ text, channelId }: { text: string; channelId: number }) => void;
  clearActiveChannel: () => void;
  user: any;
}

// if the difference is greater than this
// dont auto scroll the messages
const newMessageThreshold = 200;
class Messages extends Component<IProps> {
  private messageScrollView: RefObject<HTMLDivElement> = React.createRef();

  public componentDidUpdate(prevProps: IProps) {
    const { activeChannel: currentActiveChannel } = this.props;
    const { activeChannel: prevActiveChannel } = prevProps;

    // no previous channel was selected, so scroll to the bottom
    if (prevActiveChannel === null || prevActiveChannel === undefined) {
      this.scrollToLatestMessage();
      return;
    }

    if (prevActiveChannel && currentActiveChannel) {
      if (prevActiveChannel.id === currentActiveChannel.id) {
        if (
          this.checkIfNewMessages(prevActiveChannel, currentActiveChannel) &&
          this.checkBeyondScrollThreshold()
        ) {
          this.scrollToLatestMessage();
        }
      } else {
        // user switched active channel
        this.scrollToLatestMessage();
      }
    }
  }

  // check status update functions
  private checkIfNewMessages = (
    prevChannel?: IChannel | null,
    currentChannel?: IChannel | null
  ) => {
    if (prevChannel && currentChannel) {
      if (currentChannel.messages.length > prevChannel.messages.length) {
        return true;
      }
    }
    return false;
  };

  private checkBeyondScrollThreshold = () => {
    const tempScrollView: HTMLDivElement | null =
      this.messageScrollView && this.messageScrollView.current;
    if (tempScrollView && tempScrollView.scrollTop > 0) {
      if (
        tempScrollView.scrollHeight - tempScrollView.clientHeight <=
        tempScrollView.scrollTop + newMessageThreshold
      ) {
        return true;
      }
    }
    return false;
  };

  private scrollToLatestMessage = () => {
    const tempScrollView: HTMLDivElement | null =
      this.messageScrollView && this.messageScrollView.current;

    if (tempScrollView) {
      tempScrollView.scrollTop = tempScrollView.scrollHeight;
    }
  };

  private getMessageUser = (message: IMessage, channel: IChannel) => {
    const tempUser = channel.participants.find(
      (tempParticipant) => tempParticipant.user.id === message.sentById
    );
    return tempUser ? tempUser.user : null;
  };

  private isGroupedMessage = (message: IMessage, previousMessage: IMessage) => {
    return previousMessage && message.sentById === previousMessage.sentById ? true : false;
  };

  public render() {
    const { user, sendMessage, activeChannel, clearActiveChannel } = this.props;
    return activeChannel ? (
      <StyledMessageContainer id="messages">
        <MessagesMobileHeader
          activeChannel={activeChannel}
          clearActiveChannel={clearActiveChannel}
        />
        <StyledMessageSection ref={this.messageScrollView}>
          {activeChannel &&
            activeChannel.messages.map((tMessage: IMessage, tIndex: number) => {
              const tempUser = this.getMessageUser(tMessage, activeChannel);
              return (
                <Message
                  user={tempUser}
                  key={tMessage.id}
                  message={tMessage}
                  isGrouped={this.isGroupedMessage(tMessage, activeChannel.messages[tIndex - 1])}
                  isMe={user.id === tMessage.sentById}
                />
              );
            })}
        </StyledMessageSection>
        <MessageInput sendMessage={sendMessage} currentChannelId={activeChannel.id} />
      </StyledMessageContainer>
    ) : null;
  }
}

export default Messages;

const StyledMessageContainer = styled.div`
  display: flex;
  flex: 5;
  flex-direction: column;
  border-top: 1px solid #ddd;
`;

const StyledMessageSection = styled.div`
  background-color: white;
  padding: 12px;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`;
