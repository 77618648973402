import React from 'react';
import styles from '../../Aides/ShiftDetails/ShiftDetails.module.css';
import { ShiftPanelHeading } from '../../Aides/ShiftPanel/ShiftSidebar';
import { IPatient } from '../PatientInterfaces';

interface IProps {
  patient: IPatient;
}

const PatientInformation = (props: IProps) => {
  const { patient } = props;
  return (
    <div style={{ padding: '0.5rem 1.5rem' }}>
      <ShiftPanelHeading>Unreconciled Shift Matching</ShiftPanelHeading>
      <div className={styles.SP_Body}>
        <div className={styles.GeneralInformation}>
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>Patient</div>
            <div className={styles.GeneralInformation_row__content}>{patient.name}</div>
          </div>
        </div>
      </div>
      <div className={styles.SP_Body}>
        <div className={styles.GeneralInformation}>
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>Age</div>
            <div className={styles.GeneralInformation_row__content}>{patient.age}</div>
          </div>
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>Weight</div>
            <div className={styles.GeneralInformation_row__content}>{patient.weight}</div>
          </div>
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>Sex</div>
            <div className={styles.GeneralInformation_row__content}>{patient.sex}</div>
          </div>
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>Primary Diagnosis</div>
            <div className={styles.GeneralInformation_row__content}>{patient.primaryDiagnosis}</div>
          </div>
          <div className={styles.GeneralInformation_row}>
            <div className={styles.GeneralInformation_row__label}>Address</div>
            <div className={styles.GeneralInformation_row__content}>{patient.address}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientInformation;
