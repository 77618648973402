import React, { Component, Fragment } from 'react';
import { Table, TableActionButton, defaultColumns } from '../components/Table';

import { getAllShifts } from '../../api';
import { AvailableNurseListModal } from './AvailableNurseListModal';
import { Filters } from '../components/Filters';
import { tabFilterNames } from '../enums';
import { API_RETRY_TIMEOUT } from '../../utils';

interface IState {
  selectedShift: any;
  shifts: [any] | [];
  filterValue: string;
  filteredShifts: [any] | [];
  loading: boolean;
  error: boolean;
  openModal: boolean;
  columns: any;
}

export class OpenShiftsTable extends Component<any, IState> {
  constructor(props: any) {
    super(props);

    const columns = [
      ...defaultColumns,
      {
        Header: '',
        accessor: 'default',
        Cell: ({ original }: any) => {
          return (
            <TableActionButton
              onClick={() => this.setState({ selectedShift: original, openModal: true })}
            >
              Check Availability
            </TableActionButton>
          );
        },
        minWidth: 100,
      },
    ];

    this.state = {
      selectedShift: null,
      shifts: [],
      filterValue: '',
      filteredShifts: [],
      loading: true,
      error: false,
      openModal: false,
      columns,
    };
  }

  public componentDidMount = async () => {
    this.getShiftData();
  };

  public retryTimeout: any;

  public componentWillUnmount() {
    clearTimeout(this.retryTimeout);
  }

  public retryGetData = async () => {
    this.setState({ error: true });
    this.retryTimeout = setTimeout(this.getShiftData, API_RETRY_TIMEOUT);
  };

  public getShiftData = async () => {
    this.setState({ loading: true });

    try {
      const tempAllShifts = await getAllShifts();

      this.setState({
        shifts: tempAllShifts,
        filteredShifts: tempAllShifts,
        error: false,
        loading: false,
      });
    } catch (e) {
      console.error(e);
      // this.retryGetData();
    }
  };

  public renderModal() {
    const closeModalFunction = () => this.setState({ openModal: false }, () => this.getShiftData());

    return (
      <AvailableNurseListModal shift={this.state.selectedShift} closeModal={closeModalFunction} />
    );
  }

  public render() {
    return (
      <Fragment>
        {this.state.openModal && this.renderModal()}
        <Filters
          filterTab={tabFilterNames.openShiftsFilters}
          shifts={this.state.shifts}
          setFilteredShifts={(filteredShifts: any) => this.setState({ filteredShifts })}
        />
        <Table
          defaultPageSize={this.state.shifts.length}
          minRows={10}
          showPagination={false}
          data={this.state.filteredShifts}
          columns={this.state.columns}
          resizable={true}
          loading={this.state.loading}
          loadingText={this.state.error ? 'Error fetching data. Retrying...' : 'Loading...'}
          noDataText={this.state.error ? '' : 'No results found.'}
          sortable={true}
        />
      </Fragment>
    );
  }
}
