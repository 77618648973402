import { api } from './api';
import { APP_NAME } from '../utils';
import { interfaces } from '../common';
import { get } from './apiCallHelper';

export const getAppSettings = async (
  locationId: number
): Promise<interfaces.IAppSetting[] | null> => {
  try {
    const tempAppSettings = await get(
      `/api/BackOffice/AppSettings/?appName=${APP_NAME}&locationId=${locationId}`
    );

    if (tempAppSettings.status > 300) {
      throw new Error('Could not fetch appSettings...');
    }

    return tempAppSettings.body;
  } catch (error) {
    console.log('error — ', error);
  }

  return null;
};
