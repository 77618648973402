import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { IShift } from '../../Shifts/interfaces';

const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50px;
`;

const ContentWrapper = styled.div`
  padding: 8px;
`;

const ShiftDateText = styled.div`
  font-weight: bold;
  color: black;
  margin-bottom: 5px;
`;

const ShiftTimeText = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
`;

const SendToProspectButton = styled.div`
  border-left: none;
  border-right: none;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  text-align: right;
  padding: 12px 30px;
  color: #5186ec;
  cursor: pointer;
`;

export const ShiftListItem = ({
  onSendShift,
  shift,
}: {
  onSendShift: (shift: IShift) => void;
  shift: IShift;
}) => {
  return (
    <ListItemWrapper>
      <ContentWrapper>
        <ShiftDateText>{moment(shift.start).format('ddd - MMM DD')}</ShiftDateText>
        <ShiftTimeText>
          {moment(shift.start).format('LT').toLowerCase()} -{' '}
          {moment(shift.end).format('LT').toLowerCase()} &nbsp; {shift.hours} Hours
        </ShiftTimeText>
      </ContentWrapper>
      <SendToProspectButton onClick={() => onSendShift(shift)}>
        Send To Prospect
      </SendToProspectButton>
    </ListItemWrapper>
  );
};
