import React from 'react';
import styled from 'styled-components';

import MaterialIcon from 'material-icons-react';

import { device, theme, StyledNavLink, interfaces } from './';

const SideNavContainer = styled.div`
  width: 150px;
  padding: 12px;

  @media ${device.tablet} {
    width: 175px;
    padding: 24px;
  }
`;

const RouteContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NavTitle = styled.div`
  margin-left: 8px;
`;

const SideNavLink = ({ icon, title, route }: interfaces.ISideNavRouteConfig) => {
  return (
    <StyledNavLink small href={route}>
      <NavItemContainer>
        <MaterialIcon key={`${icon}-${title}`} icon={icon} color={theme.colors.aveannaGray} />
        <NavTitle>{title}</NavTitle>
      </NavItemContainer>
    </StyledNavLink>
  );
};

export const SideNav = ({ routeConfig }: { routeConfig: interfaces.ISideNavRouteConfig[] }) => {
  return (
    <SideNavContainer>
      <RouteContainer>
        {routeConfig.map((tempRouteConfig: interfaces.ISideNavRouteConfig, index: number) => (
          <SideNavLink
            key={index}
            title={tempRouteConfig.title}
            route={tempRouteConfig.route}
            icon={tempRouteConfig.icon}
          />
        ))}
      </RouteContainer>
    </SideNavContainer>
  );
};
