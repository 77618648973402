const REQUEST_LOGIN = 'REQUEST_LOGIN';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const SET_USER = 'SET_USER';
const CLEAR_USER = 'CLEAR_USER';
const SET_APP_FEATURE_FLAGS = 'SET_APP_FEATURE_FLAGS';
const SET_LOCATIONS = 'SET_LOCATIONS';

export const types = {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  REQUEST_LOGIN,
  SET_USER,
  CLEAR_USER,
  SET_APP_FEATURE_FLAGS,
  SET_LOCATIONS,
};
