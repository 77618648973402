import React, { Dispatch, useState } from 'react';
import styles from '../../Aides/ShiftDetails/ShiftDetails.module.css';
import { Table } from '../../Shifts/components/Table';
import moment from 'moment';
import { ShiftPanelHeading } from '../../Aides/ShiftPanel/ShiftSidebar';
import { StyledCheckbox } from '../../common';
import { IClockInOut } from '../PatientInterfaces';

interface IProps {
  clockInOuts: IClockInOut[];
  selectedClockInOut: IClockInOut | null;
  selectClockInOut: Dispatch<IClockInOut>;
  loading: boolean;
}

const ClockInMatcher = (props: IProps) => {
  const COLUMNS = [
    {
      Header: 'SELECT',
      minWidth: 50,
      Cell: (row: any) => {
        return (
          <StyledCheckbox
            checked={!!props.selectedClockInOut && row.original.id === props.selectedClockInOut.id}
            disabled={!!props.selectedClockInOut && row.original.id !== props.selectedClockInOut.id}
            onClick={() => props.selectClockInOut(!!props.selectedClockInOut ? null : row.original)}
          />
        );
      },
    },
    {
      Header: 'DATE',
      id: 'date',
      accessor: (row: IClockInOut) => moment(row.adjustedClockInTimeStamp).format('MM/DD/YYYY'),
      minWidth: 100,
      Cell: (row: any) => {
        return (
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            {moment(row.original.adjustedClockInTimeStamp).format('MM/DD/YYYY')}
          </div>
        );
      },
    },
    {
      Header: 'CLOCK IN/OUT',
      id: 'times',
      accessor: (row: IClockInOut) => moment(row.adjustedClockInTimeStamp).format('HH:mm'),
      minWidth: 100,
      Cell: (row: any) => {
        const clockOutTime = row.original.adjustedClockOutTimeStamp
          ? moment(row.original.adjustedClockOutTimeStamp).format('HH:mm')
          : '?';
        return (
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            {moment(row.original.adjustedClockInTimeStamp).format('HH:mm')}&nbsp;-&nbsp;
            {clockOutTime}
          </div>
        );
      },
    },
    {
      Header: 'HOURS',
      accessor: 'hours',
      minWidth: 100,
      Cell: (row: any) => {
        let timeDisplay = '?';
        if (row.original.adjustedClockOutTimeStamp) {
          const startTime = moment(row.original.adjustedClockInTimeStamp);
          const endTime = moment(row.original.adjustedClockOutTimeStamp);
          timeDisplay = moment.duration(endTime.diff(startTime)).asHours().toFixed(2);
        }
        return (
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>{timeDisplay}</div>
        );
      },
    },
    {
      Header: 'CAREGIVER',
      accessor: 'aide',
      minWidth: 100,
      Cell: (row: any) => {
        return row.original.nurseProfile !== null ? (
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            {row.original.nurseProfile.user.givenName}&nbsp;
            {row.original.nurseProfile.user.familyName}
          </div>
        ) : (
          <div />
        );
      },
    },
  ];

  const [error, setError] = useState(false);

  return (
    <div style={{ padding: '0.5rem 1.5rem' }}>
      <div className={styles.SP_Body}>
        <ShiftPanelHeading>Unmatched Clock Ins</ShiftPanelHeading>
        <Table
          defaultPageSize={5}
          minRows={5}
          showPagination={true}
          data={props.clockInOuts}
          columns={COLUMNS}
          resizable={true}
          loading={props.loading}
          loadingText="Loading..."
          noDataText={
            error
              ? 'Could not fetch unmatched clock ins.'
              : "No unmatched clock ins for this shift's date."
          }
          sortable={true}
          defaultSorted={[
            {
              id: 'date',
              desc: true,
            },
          ]}
          style={{
            width: 'unset',
            height: '90%',
            overflow: 'hidden',
            boxShadow: 'none',
            border: 'none',
          }}
        />
      </div>
    </div>
  );
};

export default ClockInMatcher;
