import React from 'react';
import styled from 'styled-components';

type Props = {
  color: any;
  lat?: number;
  lng?: number;
};

const Marker = styled.div`
  position: absolute;
  top: -30px;
  left: 15px;
  width: 30px;
  height: 30px;
  padding: 0px 3px 3px 0;
  border-radius: 50% 50% 50% 0%;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.5);
  background: white;
  transform: translate(-50%, -50%) rotateX(20deg) rotateZ(-45deg);
  transform-origin: 50% 50%;

  & > div {
    position: absolute;
    left: 10px;
    top: 10px;
    border-radius: 50%;
    width: 12.5px;
    height: 12.5px;
    background-color: white;
  }
`;

export class ClockLocationMarker extends React.PureComponent<Props, any> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    return (
      <Marker style={{ backgroundColor: this.props.color }}>
        <div></div>
      </Marker>
    );
  }
}
