import React, { useEffect } from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import UserTable from './User/UserTableDefinitions';
import '../adminPortal.css';
import { PatientTable } from './Patient/PatientsTableDefinitions';
import { getExternalSources } from '../Actions';
import { connect } from 'react-redux';

export function Sections(props: any) {
  const [expanded, setExpanded] = React.useState<string | false>('patientPanel');

  useEffect(() => {
    props.dispatch(getExternalSources);
  }, []);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <MuiAccordion
        square
        expanded={expanded === 'patientPanel'}
        onChange={handleChange('patientPanel')}
      >
        <MuiAccordionSummary
          aria-controls="patient-content"
          id="patient-panel-header"
          className="panel-header"
        >
          <Typography>Patients</Typography>
        </MuiAccordionSummary>
        <MuiAccordionDetails className="MuiAccordion-contents">
          <PatientTable />
        </MuiAccordionDetails>
      </MuiAccordion>
      <MuiAccordion square expanded={expanded === 'userPanel'} onChange={handleChange('userPanel')}>
        <MuiAccordionSummary
          aria-controls="user-content"
          id="user-panel-header"
          className="panel-header"
        >
          <Typography>Users</Typography>
        </MuiAccordionSummary>
        <MuiAccordionDetails className="MuiAccordion-contents">
          <UserTable />
        </MuiAccordionDetails>
      </MuiAccordion>
      <MuiAccordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <MuiAccordionSummary
          aria-controls="location-content"
          id="panel3d-header"
          className="panel-header"
        >
          <Typography>Locations</Typography>
        </MuiAccordionSummary>
        <MuiAccordionDetails className="MuiAccordion-contents">
          <div>
            <div>{/* location table placeholder */}</div>
          </div>
        </MuiAccordionDetails>
      </MuiAccordion>
    </div>
  );
}

export default connect()(Sections);
