import React, { CSSProperties, SyntheticEvent } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import styled, { StyledFunction } from 'styled-components';
import { components } from 'react-select';
import { debounce, findIndex } from 'lodash';
import MaterialIcon from 'material-icons-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { skillLevelOptions } from '../constants';

function getSkillLevelStyles(mode: string) {
  let styles;
  if (mode === 'floating') {
    styles = {
      container: (provided: any) => ({
        ...provided,
        display: 'inline-block',
        width: '15%',
      }),
      control: (provided: any) => ({
        ...provided,
        borderTopLeftRadius: '0',
        borderBottomLeftRadius: '0',
        padding: '5px',
      }),
    };
  } else {
    styles = {
      container: (provided: any) => ({
        ...provided,
        display: 'inline-block',
        width: '15%',
      }),
      control: (provided: any) => ({
        ...provided,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderRightWidth: 0,
        padding: '5px',
        borderRadius: 0,
      }),
    };
  }

  return styles;
}

function getAddressSearchStyles(mode: string) {
  let styles;
  if (mode === 'floating') {
    styles = {
      container: (provided: any) => ({
        ...provided,
        display: 'inline-block',
        width: '25%',
      }),
      control: (provided: any) => ({
        ...provided,
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
        padding: '5px',
      }),
      placeholder: (provided: any) => ({
        ...provided,
        color: 'black',
      }),
    };
  } else {
    styles = {
      container: (provided: any) => ({
        ...provided,
        display: 'inline-block',
        width: '25%',
      }),
      control: (provided: any) => ({
        ...provided,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        padding: '5px',
        borderRadius: 0,
      }),
      placeholder: (provided: any) => ({
        ...provided,
        color: 'black',
      }),
    };
  }

  return styles;
}

const searchBarContainer: StyledFunction<any & React.HTMLProps<HTMLInputElement>> = styled.div;

const SearchBarContainer = searchBarContainer.attrs(({ mode }: { mode: string }) => ({
  content: mode === 'floating' ? 'center' : 'initial',
  background: mode === 'floating' ? 'transparent' : 'white',
}))`
  width: 100%;
  display: flex;
  justify-content: ${(props: any) => props.content};
  background: ${(props: any) => props.background};
`;

const searchButton: StyledFunction<any & React.HTMLProps<HTMLInputElement>> = styled.button;

const SearchButton = searchButton.attrs(({ mode }: { mode: string }) => ({
  borderRadius: mode === 'floating' ? '4px' : 0,
}))`
    background-color: #5186EC;
    color: white;
    border: none;
    border-radius: ${(props: any) => props.borderRadius};
    margin-left: 10px;
    width: 48px;
    box-shadow: 0px 3px 16px 0px rgba(0,0,0,0.3);
    cursor: pointer;
    :disabled {
      opacity: .65;
      cursor: initial;
    }
  `;

const ClearAllButton = styled.div`
  margin-left: 15px;
  align-self: center;
  color: #0076bb;
  cursor: pointer;
`;

const DatePickerContainer = styled.div`
  display: inline-block;
  position: relative;
`;

const datePickerInput: StyledFunction<any & React.HTMLProps<HTMLInputElement>> = styled.input;

const DatePickerInput = datePickerInput`
  padding: 13px 13px;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  &::placeholder {
    color: black;
    opacity: 1;
  }
  ${(props: any) =>
    props.mode === 'floating'
      ? `
    border-style: solid;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 0;
    border-color: hsl(0,0%,80%);
    min-height: 48px;
  `
      : `
    border: 0;
    min-height: 46px;
  `}
`;

const ClearDateContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 20px;
  display: flex;
  align-items: center;
  svg {
    fill: hsl(0, 0%, 80%);
  }
  &:hover {
    svg {
      fill: hsl(0, 0%, 60%);
    }
  }
`;

const SkillLevelValueContainer = styled.div`
  alignitems: center;
  display: flex;
  flex: 1;
  flexwrap: wrap;
  position: relative;
  overflow: hidden;
  boxsizing: border-box;
  padding: 2px 8px;
`;

const AsyncOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AsyncOptionImage = styled.img`
  width: 20px;
  height: 20px;
  padding: 5px;
  border-radius: 20px;
`;

const AsyncOption = (props: any) => {
  const isNurse = props.data.type === 'nurse';

  return (
    <AsyncOptionContainer>
      {isNurse ? <AsyncOptionImage src={props.data.image} /> : <MaterialIcon icon={'pin_drop'} />}
      <components.Option {...props} />
    </AsyncOptionContainer>
  );
};

const SkillLevelMultiValue = ({ children, ...props }: any) => {
  // Work around for the select not opening without the select input present
  // https://github.com/JedWatson/react-select/issues/2597#issuecomment-475593949
  const selectInput = React.Children.toArray(children).find(
    (input: any) => input.type.name === 'Input' || input.type.name === 'DummyInput'
  );

  let selectedText = 'All Levels';
  const controlValue = props.getValue();
  if (findIndex(controlValue, (item: any) => item.value === 'all') === -1) {
    const levels = controlValue.map((item: any) => {
      return item.value;
    });
    selectedText = levels.join(', ');
  }
  return (
    <SkillLevelValueContainer>
      {props.hasValue ? <div>{selectedText}</div> : <div>Skill Level</div>}
      {selectInput}
    </SkillLevelValueContainer>
  );
};

export const SearchBar = ({
  loadOptions,
  onDateChange,
  onSelectAddress,
  selectedDate,
  selectedOption,
  selectedLevels,
  mode,
  onSearch,
  onClear,
  clearDate,
  onSelectSkillLevel,
}: {
  loadOptions: (inputValue: string, callback: (items: any) => void) => void;
  onSelectAddress: (selectedOption: any) => void;
  onSelectSkillLevel: (selectedLevel: any) => void;
  onDateChange: (date: Date | null, event: SyntheticEvent<any, Event> | undefined) => void;
  onSearch: () => void;
  onClear: () => void;
  clearDate: () => void;
  selectedDate: Date;
  selectedOption: any;
  selectedLevels: any;
  mode: string;
}) => {
  return (
    <SearchBarContainer mode={mode}>
      <AsyncSelect
        loadOptions={debounce(loadOptions, 500)}
        onChange={onSelectAddress}
        isClearable
        styles={getAddressSearchStyles(mode)}
        placeholder="Enter name or location"
        components={{ Option: AsyncOption }}
        value={selectedOption}
      />
      <DatePickerContainer>
        <DatePicker
          onChange={onDateChange}
          selected={selectedDate}
          placeholderText="Dates"
          customInput={<DatePickerInput mode={mode} type="text" />}
        />
        {selectedDate && (
          <ClearDateContainer onClick={clearDate}>
            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
            </svg>
          </ClearDateContainer>
        )}
      </DatePickerContainer>
      <Select
        options={skillLevelOptions}
        placeholder="Skill Level"
        isMulti={true}
        isSearchable={false}
        components={{ ValueContainer: SkillLevelMultiValue }}
        styles={getSkillLevelStyles(mode)}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        onChange={onSelectSkillLevel}
        value={selectedLevels}
      />
      <SearchButton mode={mode} type="button" disabled={!selectedOption} onClick={onSearch}>
        <MaterialIcon icon={'search'} color="white" />
      </SearchButton>
      {mode === 'fixed' && <ClearAllButton onClick={onClear}>Clear All</ClearAllButton>}
    </SearchBarContainer>
  );
};
