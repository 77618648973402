import {
  getAllMessages,
  getNewChannelMessages as getChannelMessages,
  sendMessage as sendNewMessage,
  sendMassMessage as sendNewMassMessage,
} from '../api';
import { types } from './constants';
import { IMessage, IChannel } from './interfaces';
import { interfaces as commonInterfaces } from '../common';

// GET ALL INITIAL MESSAGESs
export const getMessages = () => {
  return async (dispatch: any) => {
    dispatch(requestMessages());

    try {
      const messagesResponse = await getAllMessages();
      dispatch(getMessagesSuccess(messagesResponse));
    } catch (error) {
      console.log('error: ', error);
      dispatch(getMessagesFailure(error));
    }
  };
};

const requestMessages = () => {
  return {
    type: types.REQUEST_MESSAGES,
  };
};

const getMessagesSuccess = (channels: any) => {
  return {
    type: types.GET_MESSAGES_SUCCESS,
    payload: { channels },
  };
};

const getMessagesFailure = (error: any) => {
  return {
    type: types.GET_MESSAGES_FAILURE,
    payload: { error },
  };
};

// SET ACTIVE CHANNEL
export const setActiveChannel = (channelId: number) => {
  return {
    payload: { channelId },
    type: types.SET_ACTIVE_CHANNEL,
  };
};

// CLEAR ACTIVE CHANNEL
export const clearActiveChannel = () => {
  return {
    type: types.CLEAR_ACTIVE_CHANNEL,
  };
};

// ADD NEW CHANNEL
export const addNewChannel = (channel: IChannel) => {
  return {
    type: types.ADD_NEW_CHANNEL,
    payload: channel,
  };
};

// NEW ACTIVE CHANNEL MESSAGES
export const getNewChannelMessages = ({
  channelId,
  lastMessageId,
}: {
  channelId: number;
  lastMessageId: number;
}) => {
  return async (dispatch: any) => {
    dispatch(requestNewChannelMessages());
    try {
      const messagesResponse = await getChannelMessages({ channelId, lastMessageId });
      dispatch(getNewChannelMessagesSuccess(messagesResponse, channelId));
    } catch (error) {
      console.log('error: ', error);
      dispatch(getNewChannelMessagesFailture(error));
    }
  };
};

const requestNewChannelMessages = () => {
  return {
    type: types.REQUEST_NEW_CHANNEL_MESSAGES,
  };
};

const getNewChannelMessagesSuccess = (newMessages: IMessage, channelId: number) => {
  return {
    type: types.GET_NEW_CHANNEL_MESSAGES_SUCCESS,
    payload: { newMessages, channelId },
  };
};

const getNewChannelMessagesFailture = (error: any) => {
  return {
    type: types.GET_NEW_CHANNEL_MESSAGES_FAILURE,
    payload: { error },
  };
};

// REFETCH ALL MESSAGES (show notifications for new messages)
export const checkForNewMessages = () => {
  return async (dispatch: any) => {
    dispatch(requestMessages());

    try {
      const messagesResponse = await getAllMessages();
      dispatch(checkForNewMessagesSuccess(messagesResponse));
    } catch (error) {
      console.log('error checking for messages... : ', error);
      dispatch(checkForNewMessagesFailure(error));
    }
  };
};

const checkForNewMessagesSuccess = (channels: IChannel) => {
  return {
    type: types.CHECK_MESSAGES_SUCCESS,
    payload: { channels },
  };
};

const checkForNewMessagesFailure = (error: any) => {
  return {
    type: types.CHECK_MESSAGES_FAILURE,
    payload: { error },
  };
};

// SEND MESSAGE
export const sendMessage = ({ text, channelId, sentById }: commonInterfaces.ISendMessageParams) => {
  return async (dispatch: any) => {
    dispatch(requestSendMessage());

    try {
      if (channelId === 0) {
        throw new Error('Message must be sent to a specific channel id (none specficied)');
      }

      const sendMessageResponse = await sendNewMessage({ text, channelId, sentById });
      dispatch(sendMessageSuccess(sendMessageResponse, channelId));
    } catch (error) {
      console.log('error: ', error);
      dispatch(sendMessageFailure(error));
    }
  };
};

const requestSendMessage = () => {
  return {
    type: types.REQUEST_SEND_MESSAGE,
  };
};

const sendMessageSuccess = (message: any, channelId: commonInterfaces.ID) => {
  return {
    type: types.SEND_MESSAGE_SUCCESS,
    payload: { message, channelId },
  };
};

const sendMessageFailure = (error: any) => {
  return {
    type: types.SEND_MESSAGE_FAILURE,
    payload: { error },
  };
};

// SEND MASS MESSAGE
export const sendMassMessage = ({
  text,
  channelId,
  sentById,
  locationId,
}: commonInterfaces.ISendMassMessageParams) => {
  return async (dispatch: any) => {
    dispatch(requestSendMassMessage());

    return new Promise<void>(async (resolve, reject) => {
      try {
        const sendMessageResponse = await sendNewMassMessage({
          text,
          channelId,
          sentById,
          locationId,
        });
        dispatch(sendMassMessageSuccess(sendMessageResponse));
        resolve();
      } catch (error) {
        console.log('error: ', error);
        dispatch(sendMassMessageFailure(error));
        reject();
      }
    });
  };
};

const requestSendMassMessage = () => {
  return {
    type: types.REQUEST_SEND_MASS_MESSAGE,
  };
};

const sendMassMessageSuccess = (message: any) => {
  return {
    type: types.SEND_MASS_MESSAGE_SUCCESS,
    payload: { message },
  };
};

const sendMassMessageFailure = (error: any) => {
  return {
    type: types.SEND_MASS_MESSAGE_FAILURE,
    payload: { error },
  };
};
