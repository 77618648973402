import React from 'react';

interface Props {
  caregiverName: string;
  caregiverWorkDayId: string | number;
  patientName: string;
  patientSearchField: string | null;
}

export const ReviewPeopleInfo = (props: Props) => {
  return (
    <div>
      <div className="row">
        <span>Caregiver Name: {props.caregiverName}</span>
      </div>
      <div className="row">
        <span>Caregiver Workday ID: {props.caregiverWorkDayId}</span>
      </div>
      <div className="row">
        <span>Patient Name: {props.patientName}</span>
      </div>
      <div className="row">
        <span>Patient MR: {props.patientSearchField}</span>
      </div>
    </div>
  );
};

export default ReviewPeopleInfo;
