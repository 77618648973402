import { useToasts } from 'react-toast-notifications';
import { ToastAppearance } from '../utils';

interface ToastConfig {
  appearance: ToastAppearance;
  pauseOnHover: boolean;
  autoDismiss: boolean;
  autoDismissTimeout: number;
}

type AddToast = (message: string, toastConfig: ToastConfig) => void;

const TOAST_DEFAULTS = {
  pauseOnHover: true,
  autoDismiss: true,
  autoDismissTimeout: 10000,
};

export const useToastNotifications = () => {
  const { addToast } = useToasts();
  return {
    displaySuccess: getSuccessDisplayer(addToast),
    displayError: getErrorDisplayer(addToast),
  };
};

export const useDisplaySuccess = () => {
  const { addToast } = useToasts();
  return getSuccessDisplayer(addToast);
};

export const useDisplayError = () => {
  const { addToast } = useToasts();
  return getErrorDisplayer(addToast);
};

const getSuccessDisplayer = (addToast: AddToast) => (message: string) =>
  addToast(message, getToastConfig(ToastAppearance.SUCCESS));

const getErrorDisplayer = (addToast: AddToast) => (error: unknown) => {
  const errorMessage = getErrorMessage(error);
  if (errorMessage) {
    addToast(errorMessage, getToastConfig(ToastAppearance.ERROR));
  }
};

const getToastConfig = (toastAppearance: ToastAppearance): ToastConfig => ({
  appearance: toastAppearance,
  ...TOAST_DEFAULTS,
});

export const getErrorMessage = (error: unknown): string => {
  if (typeof error === 'string') {
    return error;
  }
  if (error instanceof Error) {
    return error.message;
  }
  return '';
};

export default useToastNotifications;
