import { types } from './constants';
import { interfaces } from '../common';

export const setLocations = (locations: interfaces.ILocation[]) => {
  return {
    type: types.SET_LOCATIONS,
    payload: { locations },
  };
};

export const updateLocation = (location: interfaces.ILocation) => {
  return {
    type: types.UPDATE_LOCATION,
    payload: { updatedLocation: location },
  };
};
