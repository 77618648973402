import { IProfile, IUser } from '../common/interfaces';
import { userTypes } from './constants';
import DefaultAveannaLogo from '../assets/aveanna-user-logo.png';

// Should show caregiver, unless there are none, then show scheduler that isnt current one
// If all else fails, return empty string and the renderer will put in the default image
export const getChatChannelMessageFromParticipants = (participants: IProfile[]): string => {
  try {
    if (participants && participants.length > 0) {
      const nurses = participants.filter(
        ({ user }: IProfile) =>
          user.userType === userTypes.NURSE || user.userType === userTypes.NURSE_ADMIN
      );

      if (nurses.length === 1) {
        return nurses[0].user.imageUri || DefaultAveannaLogo;
      }
    }
    return DefaultAveannaLogo;
  } catch (error) {
    console.log('something went wrong with getting the channel image, returning default logo.');
    return DefaultAveannaLogo;
  }
};
