export enum ShiftClockInOutStatus {
  Pending = 0,
  Approved = 1,
  Rejected = 2,
}

export enum LocationCodeNames {
  EVVLocationInCorrection = 'EVV Location In Correction',
  EVVLocationOutCorrection = 'EVV Location Out Correction',
}

export enum TimeCodeNames {
  EVVTimeInCorrection = 'EVV Time In Correction',
  EVVTimeOutCorrection = 'EVV Time Out Correction',
}

export enum RejectionCodeNames {
  EVVReject = 'EVV Reject',
  EVVRejectOverride = 'EVV Reject Override',
}

export enum NewShiftCodeNames {
  EVVNew = 'EVV New',
}

export enum GPSAlertResponseMessage {
  WithinDistance = 'Clock in and out are within the range.',
  OutsideOfDistance = 'Clock in and/or out is outside of the range.',
  NoGpsData = 'No GPS data.',
}

export enum TimeAlertResponseMessage {
  MissingClockIn = 'Missing clock in.',
  MissingClockOut = 'Missing clock out.',
}

export enum ClockInOutIndex {
  ClockIn,
  ClockOut,
} //time alert [clock in error, clock out error]
