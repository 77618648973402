import { types } from './constants';
import { interfaces } from '../common';

export const setAllReasons = (allReasons: interfaces.IReasonCodeObject) => {
  return {
    type: types.SET_ALL_REASON_CODES,
    payload: allReasons,
  };
};

export const setRejectionReasons = (rejectionReasons: interfaces.IReasonCode[]) => {
  return {
    type: types.SET_REJECTION_REASONS,
    payload: { rejectionReasons },
  };
};

export const setLocationReasons = (locationReasons: interfaces.IReasonCode[]) => {
  return {
    type: types.SET_LOCATION_REASONS,
    payload: { locationReasons },
  };
};

export const setTimeReasons = (timeReasons: interfaces.IReasonCode[]) => {
  return {
    type: types.SET_TIME_REASONS,
    payload: { timeReasons },
  };
};
export const setNewShiftReasons = (newShiftReasons: interfaces.IReasonCode[]) => {
  return {
    type: types.SET_NEW_SHIFT_REASONS,
    payload: { newShiftReasons },
  };
};

export const setAppSettings = (appSettings: interfaces.IAppSetting[] | null) => {
  return {
    type: types.SET_APP_SETTINGS,
    payload: { appSettings },
  };
};

export const clearAppData = () => {
  return {
    type: types.CLEAR_APP_DATA,
  };
};
